import { isEqual } from 'lodash'
import { isMultiItemCollectionSchema } from '@wix/wix-data-client-common/src/collections/collectionTypes'

const validatePageIsConnectable = async (editorSdkProxy, pageRefToConvert) => {
  const validPages = await editorSdkProxy.routers.pages.listConnectablePages()
  const isRequiredPageValid = validPages.some(page =>
    isEqual(page.pageRef, pageRefToConvert),
  )
  if (!isRequiredPageValid) {
    throw new Error(
      `The given page ${JSON.stringify(pageRefToConvert)} cannot be converted`,
    )
  }
}

const isSiteWithMultiItemCollections = async collectionsApi => {
  const collections = await collectionsApi.getAll()
  return collections.some(isMultiItemCollectionSchema)
}

const biDefaults = {
  panel_name: null,
}

export default async ({
  editorSdkProxy,
  pageRef,
  origin,
  collectionsApi,
  appApi,
  experimentsManager,
}) => {
  await validatePageIsConnectable(editorSdkProxy, pageRef)

  if (
    experimentsManager.enabled(
      'specs.wixDataClient.ConvertToDynamicPagePanelV2',
    )
  ) {
    appApi.openConvertToDynamicPagePanelV2({ pageRef, origin })

    return new Promise(resolve => {
      const listener = payload => {
        resolve(payload?.pageRefs)
        appApi.removeListener('pageConversionEnded', listener)
      }
      appApi.addListener('pageConversionEnded', listener)
    })
  }

  if (await isSiteWithMultiItemCollections(collectionsApi)) {
    const result = await appApi.openConvertToDynamicPagePanel({
      origin,
      pageRef,
    })

    return result?.pageRefs
  }

  const { pageRefs } = await appApi.convertStaticToDynamicFromScratch({
    pageRef,
    biDefaults: { ...biDefaults, origin },
  })

  return pageRefs
}
