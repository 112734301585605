export const getAssetUrlInPanel = relativeUrl =>
  window.staticsUrl
    ? `${window.staticsUrl}${relativeUrl.replace('./', '')}`
    : relativeUrl

export const getAssetUrlInWorker = async (editorSdkProxy, relativeUrl) => {
  const artifactUrls = await editorSdkProxy.info.getArtifactsUrls([
    'dbsm-editor-app',
  ])
  const appBaseUrl = artifactUrls['dbsm-editor-app']
  return `${appBaseUrl}/${relativeUrl}`
}
