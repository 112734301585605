import { FieldType } from '@wix/wix-data-schema-types'
import { PAGE_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { image, video } = FieldType

export default ({ i18n }) => ({
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Page',
    helpId: 'c5eb5acd-7129-400b-9621-e316e8fc380c',
  },
  sections: [
    {
      role: PAGE_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Page_Background_Label',
          prop: 'background.src',
          supportedFieldTypes: [image, video],
          defaultFieldType: image,
          supportsReferenceFields: true,
          requiresPrimaryConnection: true,
        },
      ],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'page_live_preview_gfpp_desktop_main_action',
              label: 'Page_Live_Preview_GFPP_Desktop_Main_Action',
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Page_Manage_Content_Panel_Description',
      },
    },
  ],
})
