import convertStaticPageToDynamic from './convertStaticPageToDynamic'
import editorSdkProxyCreator from '@wix/wix-code-editor-sdk-proxy'
import getRoutingUrlByPageRef from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/routing-url/getRoutingUrlByPageRef'
import * as routerConfig from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/routerConfig'
import isFieldAllowedInRoutingUrl from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/isFieldAllowedInRoutingUrl'
import validateUrl from './validateRoutingUrl'
import {
  addField,
  lowerCaseStaticUrlParts,
} from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/routing-url/utils'
import saveRoutingUrl from './saveRoutingUrl'
import getPageRouter from '../utils/getPageRouter'
import getCollectionFields from '../utils/getCollectionFields'
import { addPresetById } from './addPresetById'
import { presetExists } from '../../business-logic/presets/getPresetDefinitionById'
import openErrorPanel from '@wix/wix-data-client-common/src/panels/error-panel/openErrorPanel'

export const createPublicApi = () => {
  let editorSdkProxy = null
  let i18n = null
  let editorType = null
  let collectionsApi = null
  let appApi = null
  let hostname = null
  let WixDataSettings = null
  let experimentsManager = null
  let safeUserPreferences = null
  let logger = null
  let httpClient = null

  const validateApiReady = methodName => {
    const dependencies = [
      editorSdkProxy,
      i18n,
      editorType,
      collectionsApi,
      appApi,
      hostname,
    ]

    if (dependencies.includes(null)) {
      throw new Error(`API must be initialized before calling ${methodName}`)
    }
  }

  return {
    init({
      editorSDK,
      token,
      i18n: _i18n,
      editorType: _editorType,
      collectionsApi: _collectionsApi,
      appApi: _appApi,
      hostname: _hostname,
      WixDataSettings: _WixDataSettings,
      logger: _logger,
      experimentsManager: _experimentsManager,
      safeUserPreferences: _safeUserPreferences,
      httpClient: _httpClient,
    }) {
      editorSdkProxy = editorSdkProxyCreator(editorSDK, token)
      i18n = _i18n
      editorType = _editorType
      collectionsApi = _collectionsApi
      appApi = _appApi
      hostname = _hostname
      WixDataSettings = _WixDataSettings
      logger = _logger
      experimentsManager = _experimentsManager
      safeUserPreferences = _safeUserPreferences
      httpClient = _httpClient
    },

    api: {
      /**
       * convert given page to dynamic page
       * @param {Object} params
       * @param {Object} params.pageRef PageRef of the page to convert to dynamic page
       * @param {string} [params.origin="public_api"] Origin to use in BI events
       * @returns {Promise<Object[]>} Promise resolves with PageRefs of dynamic pages
       */
      convertStaticPageToDynamic: ({ pageRef, origin = 'public_api' }) => {
        validateApiReady('convertStaticPageToDynamic')

        return convertStaticPageToDynamic({
          hostname,
          editorType,
          editorSdkProxy,
          i18n,
          pageRef,
          origin,
          collectionsApi,
          appApi,
          experimentsManager,
        })
      },
      isDynamicPage: async ({ pageRef }) => {
        const routerRef = await editorSdkProxy.routers.getByPage({ pageRef })
        return Boolean(routerRef)
      },
      getFieldsForDPUrl: async ({ pageRef }) => {
        const router = await getPageRouter({ editorSdkProxy, pageRef })
        const {
          def: {
            config: { collection: collectionId },
          },
        } = routerConfig.getPatternDefForPage(
          router.config,
          router.pages,
          pageRef,
        )

        const collectionFields = await getCollectionFields({
          collectionsApi,
          collectionId,
        })

        const collection = await collectionsApi.get(collectionId)

        return collectionFields.filter(field =>
          isFieldAllowedInRoutingUrl(
            {
              fields: collectionFields,
              isTPACollection: collection.isTPACollection,
            },
            field.name,
          ),
        )
      },
      validateRoutingUrl: async ({ pageRef, routingUrl }) => {
        const dynamicPageRoutingUrl = await getRoutingUrlByPageRef(
          editorSdkProxy,
          pageRef,
        )

        return validateUrl({
          editorSdkProxy,
          collectionsApi,
          i18n,
          pageRef,
          routingUrl,
          oldUrl: dynamicPageRoutingUrl,
        })
      },
      getTranslations: async (keys = []) =>
        keys.reduce(
          (acc, currentValue) =>
            Object.assign(acc, { [currentValue]: i18n.t(currentValue) }),
          {},
        ),
      getPageUrlAndValidationStatus: async ({ pageRef }) => {
        const dynamicPageRoutingUrl = await getRoutingUrlByPageRef(
          editorSdkProxy,
          pageRef,
        )

        const routingUrlValidationResult = await validateUrl({
          editorSdkProxy,
          collectionsApi,
          i18n,
          pageRef,
          routingUrl: dynamicPageRoutingUrl,
          oldUrl: dynamicPageRoutingUrl,
        })

        return { dynamicPageRoutingUrl, routingUrlValidationResult }
      },
      addFieldToRoutingUrl: async ({ pageRef, field }) => {
        const dynamicPageRoutingUrl = await getRoutingUrlByPageRef(
          editorSdkProxy,
          pageRef,
        )
        const newUrl = addField(dynamicPageRoutingUrl, field)

        const { lowerCasedUrl, routingUrlValidationResult } =
          await saveRoutingUrl({
            editorSdkProxy,
            collectionsApi,
            i18n,
            pageRef,
            newUrl,
            oldUrl: dynamicPageRoutingUrl,
          })

        return { lowerCasedUrl, routingUrlValidationResult }
      },
      addFieldToRoutingUrlWithoutSave: async ({ pageRef, field }) => {
        const dynamicPageRoutingUrl = await getRoutingUrlByPageRef(
          editorSdkProxy,
          pageRef,
        )

        const newUrl = addField(dynamicPageRoutingUrl, field)

        const routingUrlValidationResult = await validateUrl({
          editorSdkProxy,
          collectionsApi,
          i18n,
          pageRef,
          routingUrl: newUrl,
          oldUrl: dynamicPageRoutingUrl,
        })

        return {
          lowerCasedUrl: lowerCaseStaticUrlParts(newUrl),
          routingUrlValidationResult,
        }
      },
      saveRoutingUrl: async ({ pageRef, newUrl }) => {
        const dynamicPageRoutingUrl = await getRoutingUrlByPageRef(
          editorSdkProxy,
          pageRef,
        )

        const { lowerCasedUrl, routingUrlValidationResult } =
          await saveRoutingUrl({
            editorSdkProxy,
            collectionsApi,
            i18n,
            pageRef,
            newUrl,
            oldUrl: dynamicPageRoutingUrl,
          })

        return { lowerCasedUrl, routingUrlValidationResult }
      },
      addPresetById: async (presetId, options) => {
        if (!presetId) {
          throw new Error('"presetId" must be defined')
        }

        if (!options?.origin) {
          throw new Error('"origin" must be defined')
        }

        if (!presetExists({ presetId, editorType })) {
          throw new Error(`Preset with id "${presetId}" does not exist`)
        }

        return addPresetById({
          presetId,
          options,
          editorSdkProxy,
          WixDataSettings,
          logger,
          experimentsManager,
          safeUserPreferences,
          appApi,
          httpClient,
        })
      },
      removeDynamicPage: async ({ pageRef }) => {
        const routerRef = await editorSdkProxy.document.routers.getByPage({
          pageRef,
        })
        if (!routerRef) {
          throw new Error('Page is not dynamic')
        }
        const t = i18n.t.bind(i18n)

        await openErrorPanel({
          headerText: t(
            'Workspace_DYNAMIC_PAGES_WARNING_DELETE_DYNAMIC_PAGE_TITLE',
          ),
          topDescriptionText: t(
            'Workspace_Dynamic_Pages_Warning_Delete_Dynamic_Page_Text',
            {
              nameOfPage: (
                await editorSdkProxy.pages.getPageData({ pageRef })
              ).title,
            },
          ),
          mainActionText: t(
            'Workspace_Dynamic_Pages_Warning_Delete_Dynamic_Page_Button2',
          ),
          secondaryActionText: t(
            'Workspace_Dynamic_Pages_Warning_Delete_Dynamic_Page_Button1',
          ),

          editorSdkProxy,
          logger,
          helpId: '35b6fd54-22ae-4251-aa8d-2db717085ea3',
          onMainActionClick: async () => {
            await appApi.deleteDynamicPage({
              collectionsApi,
              editorSdkProxy,
              routerRef,
              pageRef,
            })
          },
        })
      },
    },
  }
}
