import { dynamicPageDeletePopupAction } from '@wix/bi-logger-platform/v2'
import { deleteDynamicPagePanel as deletePanelBiData } from '@wix/wix-data-client-common/src/bi/panelDataForBi'
import openErrorPanel from '@wix/wix-data-client-common/src/panels/error-panel/openErrorPanel'
import { getBiDefaults } from './getBiDefaults'
import isDPCollectionDeleted from './isDPCollectionDeleted'

const getPanelLabels = ({ t, collectionIsDeleted, pageTitle }) => {
  return collectionIsDeleted
    ? {
        headerText: t(
          'Workspace_Dynamic_Pages_Warning_Delete_Dynamic_Page_Removed_Collection_Title',
        ),
        topDescriptionText: t(
          'Workspace_Dynamic_Pages_Warning_Delete_Dynamic_Page_Removed_Collection_Text',
          { nameOfPage: pageTitle },
        ),
        mainActionText: t(
          'Workspace_Dynamic_Pages_Warning_Delete_Dynamic_Page_Removed_Collection_Button2',
        ),
        secondaryActionText: t(
          'Workspace_Dynamic_Pages_Warning_Delete_Dynamic_Page_Removed_Collection_Button1',
        ),
      }
    : {
        headerText: t(
          'Workspace_DYNAMIC_PAGES_WARNING_DELETE_DYNAMIC_PAGE_TITLE',
        ),
        topDescriptionText: t(
          'Workspace_Dynamic_Pages_Warning_Delete_Dynamic_Page_Text',
          { nameOfPage: pageTitle },
        ),
        mainActionText: t(
          'Workspace_Dynamic_Pages_Warning_Delete_Dynamic_Page_Button2',
        ),
        secondaryActionText: t(
          'Workspace_Dynamic_Pages_Warning_Delete_Dynamic_Page_Button1',
        ),
      }
}

export default async (
  {
    editorSdkProxy,
    logger,
    appApi,
    collectionsApi,
    i18n,
    fedopsLogger,
    isCollectionDeleted,
  },
  { pageRef, origin },
) => {
  const routerRef = await editorSdkProxy.document.routers.getByPage({
    pageRef,
  })

  if (!routerRef) {
    await editorSdkProxy.pages.remove({ pageRef })
    return
  }

  const t = i18n.t.bind(i18n)

  const collectionIsDeleted = await isDPCollectionDeleted({
    editorSdkProxy,
    routerRef,
    pageRef,
    isCollectionDeleted,
  })

  const labels = getPanelLabels({
    t,
    collectionIsDeleted,
    pageTitle: (await editorSdkProxy.pages.getPageData({ pageRef })).title,
  })

  const biDefaults = await getBiDefaults({
    editorSdkProxy,
    collectionsApi,
    pageRef,
    routerRef,
    origin,
    panelName: deletePanelBiData.panelName,
  })

  await openErrorPanel({
    ...labels,
    editorSdkProxy,
    logger,
    helpId: '35b6fd54-22ae-4251-aa8d-2db717085ea3',
    onMainActionClick: async () => {
      fedopsLogger.interactionStarted('deleteDynamicPage', {
        interactionTimeout: 6000,
      })
      logger.bi(
        dynamicPageDeletePopupAction({
          ...biDefaults,
          category: 'delete',
        }),
      )
      await appApi.deleteDynamicPage({ pageRef, routerRef })

      fedopsLogger.interactionEnded('deleteDynamicPage')
    },
    onSecondaryActionClick: () => {
      logger.bi(
        dynamicPageDeletePopupAction({
          ...biDefaults,
          category: 'cancel',
        }),
      )
    },
    onCloseClick: () => {
      logger.bi(
        dynamicPageDeletePopupAction({
          ...biDefaults,
          category: 'close',
        }),
      )
    },
  })
}
