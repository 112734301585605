import { findKey, includes } from 'lodash'
import { isOwnerField } from '../fields/fieldQueries'

export const serializeSort = (...sort) =>
  sort.map(s => ({ [s.field]: s.order }))

export const deserializeDefaultSort = ({ fieldName, direction }) => ({
  field: fieldName,
  order: direction,
})

export const createSortExpression = (field, order) => ({ field, order })

export const deserializeSort = (sort = []) => {
  return sort.map(s => {
    const field = findKey(s)
    return createSortExpression(field, s[field])
  })
}

export const isFieldSortable = field =>
  field.sortable &&
  includes(SUPPORTED_TYPES, field.type) &&
  !isOwnerField(field)

export const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
}

export const ORDER_OPTIONS = {
  TEXT_ASC: {
    label: 'Dataset_Settings_Add_Sort_Panel_Order_Option1',
    value: ORDER.ASC,
  },
  TEXT_DESC: {
    label: 'Dataset_Settings_Add_Sort_Panel_Order_Option2',
    value: ORDER.DESC,
  },
  NUMBER_ASC: {
    label: 'Dataset_Settings_Add_Sort_Panel_Order_Option3',
    value: ORDER.ASC,
  },
  NUMBER_DESC: {
    label: 'Dataset_Settings_Add_Sort_Panel_Order_Option4',
    value: ORDER.DESC,
  },
  DATETIME_ASC: {
    label: 'Dataset_Settings_Add_Sort_Panel_Order_Option6',
    value: ORDER.ASC,
  },
  DATETIME_DESC: {
    label: 'Dataset_Settings_Add_Sort_Panel_Order_Option5',
    value: ORDER.DESC,
  },
  BOOLEAN_ASC: {
    label: 'Datatype_Boolean_Order_FalseFirst',
    value: ORDER.ASC,
  },
  BOOLEAN_DESC: {
    label: 'Datatype_Boolean_Order_TrueFirst',
    value: ORDER.DESC,
  },
}

export const ORDER_OPTIONS_PER_FIELD_TYPE = {
  text: [ORDER_OPTIONS.TEXT_ASC, ORDER_OPTIONS.TEXT_DESC],
  number: [ORDER_OPTIONS.NUMBER_ASC, ORDER_OPTIONS.NUMBER_DESC],
  datetime: [ORDER_OPTIONS.DATETIME_ASC, ORDER_OPTIONS.DATETIME_DESC],
  boolean: [ORDER_OPTIONS.BOOLEAN_ASC, ORDER_OPTIONS.BOOLEAN_DESC],
}

export const SUPPORTED_TYPES = Object.getOwnPropertyNames(
  ORDER_OPTIONS_PER_FIELD_TYPE,
)
