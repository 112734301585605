export const runTransactionWithRetry = async (editorSdkProxy, transaction) => {
  try {
    return await editorSdkProxy.document.transactions.runAndWaitForApproval(
      transaction,
    )
  } catch (error) {
    const isConflictError =
      await editorSdkProxy.document.transactions.isConflictError(error)
    if (isConflictError) {
      return await editorSdkProxy.document.transactions.runAndWaitForApproval(
        transaction,
      )
    } else {
      throw error
    }
  }
}
