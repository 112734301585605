import { some, compact, isEqual } from 'lodash'
import getRepeaterItemAncestor from '../../business-logic/components/getRepeaterItemAncestor'

// optimize this function to be executed only once
// since the outcome shouldn't change while panel is open
const areAnyOtherRepeaterChildrenConnected = async function ({
  editorSdkProxy,
  componentRef,
}) {
  const repeaterItemContainer = (
    await getRepeaterItemAncestor({
      editorSdkProxy,
      componentRef,
    })
  ).getOrElse(componentRef)

  const repeaterItemChildren = await editorSdkProxy.components.getChildren({
    componentRef: repeaterItemContainer,
    recursive: true,
  })

  const repeaterChildrenExcludingComponent = compact([
    repeaterItemContainer,
    ...repeaterItemChildren,
  ]).filter(childComponentRef => !isEqual(childComponentRef, componentRef))
  const repeaterOtherChildrenProperties = await editorSdkProxy.components.get({
    componentRefs: repeaterChildrenExcludingComponent,
    properties: ['connections'],
  })
  const repeaterOtherChildrenConnections = repeaterOtherChildrenProperties.map(
    comp => comp.connections,
  )
  return some(
    repeaterOtherChildrenConnections,
    connections => connections.length > 0,
  )
}

const isRepeaterAncestorConnected = repeaterAncestor =>
  repeaterAncestor.controllerRef !== null

export default async ({
  editorSdkProxy,
  componentRef,
  repeaterAncestor,
  connecting,
}) =>
  isRepeaterAncestorConnected(repeaterAncestor) !== connecting &&
  !(await areAnyOtherRepeaterChildrenConnected({
    editorSdkProxy,
    componentRef,
    repeaterAncestor,
  }))
