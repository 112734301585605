import { FieldType } from '@wix/wix-data-schema-types'
import {
  GALLERY_ROLE,
  MEDIA_GALLERY_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
const {
  text,
  dateTime,
  number,
  url,
  stringArray,
  document,
  video,
  address,
  image,
} = FieldType

export default ({ i18n }) => ({
  componentName: 'Element_Name_Gallery',
  isConnectGfppEnabled: true,
  panel: {
    height: 467,
    title: 'Connect_Panel_Title_Gallery',
    helpId: 'bf7d9969-0ebd-4190-933f-507c03e9345c',
  },
  supportsDefaultSelectedDataset: false,
  sections: [
    {
      role: GALLERY_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Gallery_Image_Sources_Label',
          prop: 'src',
          supportedFieldTypes: [image],
          defaultFieldType: image,
          supportsReferenceFields: true,
          requiresPrimaryConnection: true,
        },
        {
          title: 'Connect_Panel_Connect_Titles_Label',
          prop: 'title',
          supportedFieldTypes: [text, number, dateTime, address, stringArray],
          defaultFieldType: text,
          supportsReferenceFields: true,
        },
        {
          title: 'Connect_Panel_Connect_Gallery_Descriptions_Label',
          prop: 'description',
          supportedFieldTypes: [text, number, dateTime, address, stringArray],
          defaultFieldType: text,
          supportsReferenceFields: true,
        },
        {
          title: 'Connect_Panel_Connect_Gallery_Links_Label',
          prop: 'link',
          supportedFieldTypes: [document, url, image, video],
          defaultFieldType: url,
          supportPageLinks: true,
          supportsReferenceFields: true,
        },
      ],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'gallery_live_preview_gfpp_desktop_main_action',
              label: 'Gallery_Live_Preview_GFPP_Desktop_Main_Action',
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Gallery_Manage_Content_Panel_Description',
      },
    },
    {
      role: MEDIA_GALLERY_ROLE,
      sections: [],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'media_gallery_live_preview_gfpp_desktop_main_action',
              label: i18n.t(
                'Media_Gallery_Live_Preview_GFPP_Desktop_Main_Action',
              ),
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Media_Gallery_Manage_Content_Panel_Description',
      },
    },
  ],
})
