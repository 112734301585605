import * as sentry from '@sentry/browser'
import { APP_NAME } from '@wix/wix-data-client-common/src/consts'

export const createSentry = ({
  appVersion,
  dsn = SENTRY_DSN /* global SENTRY_DSN */,
}) => {
  const sentryClient = new sentry.BrowserClient({
    dsn,
    release: `${APP_NAME}@${appVersion}`,
  })
  return new sentry.Hub(sentryClient)
}

export const createSentryNext = ({ appVersion }) =>
  createSentry({
    appVersion,
    dsn: SENTRY_NEXT_DSN /* global SENTRY_NEXT_DSN */,
  })
