import SingleItemCollection from './single_collection.svg'
import DataCollection from 'wix-ui-icons-common/DataCollection'

const MULTIPLE_ITEM_COLLECTION = 'MULTIPLE_ITEM_COLLECTION'
const SINGLE_ITEM_COLLECTION = 'SINGLE_ITEM_COLLECTION'

export const collectionTypes = {
  MULTIPLE_ITEM_COLLECTION,
  SINGLE_ITEM_COLLECTION,
}

export const collectionsTypeSections = [
  {
    types: [
      {
        value: MULTIPLE_ITEM_COLLECTION,
        label:
          'Workspace_Create_Collection_Screen_Singles_Type_Dropdown_MultiItemCollection',
        description:
          'Workspace_Create_Collection_Screen_Singles_Type_Dropdown_MultiItemCollection_Description',
        Icon: DataCollection,
      },
      {
        value: SINGLE_ITEM_COLLECTION,
        label:
          'Workspace_Create_Collection_Screen_Singles_Type_Dropdown_SingleItemCollection',
        description:
          'Workspace_Create_Collection_Screen_Singles_Type_Dropdown_SingleItemCollection_Description',
        Icon: SingleItemCollection,
      },
    ],
  },
]
