import { get } from 'lodash'
import { ROUTER_DATASET } from '@wix/wix-data-client-common/src/datasetTypes'
import createDatasetObject from './createDatasetObject'
import { getReadWriteType } from './datasetConfigHelper'
import { getRouterDatasetConfig } from '../../business-logic/datasets/routerDataset'

export default async function (editorSdkProxy, componentRef) {
  const controllersInfo =
    await editorSdkProxy.controllers.listConnectableControllers({
      componentRef,
    })

  const maybeControllers = await Promise.all(
    controllersInfo.map(async ({ controllerRef }) => {
      const { type, config, displayName } =
        await editorSdkProxy.controllers.getData({ controllerRef })

      try {
        const fullConfig =
          type === ROUTER_DATASET
            ? await getRouterDatasetConfig(
                editorSdkProxy,
                controllerRef,
                config,
              )
            : config
        const collectionName = get(fullConfig, ['dataset', 'collectionName'])

        return {
          controllerRef,
          collectionName,
          type,
          config,
          displayName,
        }
      } catch {
        return null
      }
    }),
  )

  return maybeControllers
    .filter(Boolean)
    .map(({ controllerRef, collectionName, displayName, type, config }) =>
      createDatasetObject(
        controllerRef,
        displayName,
        collectionName,
        type,
        getReadWriteType(config),
      ),
    )
}
