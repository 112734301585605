export const EQUALS = 'eq'
export const NOT_EQUALS = 'ne'
export const GREATER_THAN = 'gt'
export const GREATER_THAN_OR_EQUAL = 'ge'
export const LESS_THAN_OR_EQUAL = 'le'
export const LESS_THAN = 'lt'
export const CONTAINS = 'contains'
export const NOT_CONTAINS = 'notContains'
export const ENDS_WITH = 'endsWith'
export const STARTS_WITH = 'startsWith'
export const INCLUDES_ANY = 'includesAny'
export const EXCLUDES_ANY = 'excludesAny'
export const INCLUDES_ALL = 'includesAll'
export const EXCLUDES_ALL = 'excludesAll'
export const IS_EMPTY = 'isEmpty'
export const IS_NOT_EMPTY = 'isNotEmpty'
