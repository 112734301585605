import { FieldType } from '@wix/wix-data-schema-types'
import { TEXT_INPUT_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { text, number, url } = FieldType

export default () => ({
  componentName: 'Element_Name_Text_Input',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Text_Input',
    helpId: '36bf5f44-4fa0-4879-a236-0973d8b0eb57',
  },
  sections: [
    {
      role: TEXT_INPUT_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Value_Label',
          prop: 'value',
          supportedFieldTypes: [text, number, url],
          defaultFieldType: text,
          isInputProperty: true,
        },
      ],
    },
  ],
  compactErrorPanel: true,
})
