import { FieldType } from '@wix/wix-data-schema-types'
import { RICH_CONTENT_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { richContent } = FieldType

export default ({ experimentsManager, i18n }) => ({
  componentName: 'Element_Name_Rich_Content',
  isConnectGfppEnabled: experimentsManager.get('ricos_databinding') === 'new',
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Rich_Content',
    helpId: 'e03fed6e-cccd-432c-8ddd-d1a53264648a',
  },
  sections: [
    {
      role: RICH_CONTENT_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Rich_Content_Label',
          prop: 'value',
          supportedFieldTypes: [richContent],
          defaultFieldType: richContent,
          supportsReferenceFields: true,
        },
      ],
      manageContentPanel: {
        description: 'RichContent_Manage_Content_Panel_Description',
      },
    },
  ],
  compactErrorPanel: true,
})
