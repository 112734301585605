'use strict'

const getFileNameByEnv = require('@wix/dbsm-common/src/getFileNameByEnv')

const htmlFor = baseName => getFileNameByEnv(`${baseName}.html`)
const vmFor = baseName => getFileNameByEnv(`${baseName}.vm`)

const panelDef = (chunkName, entryPoint) => ({
  html: htmlFor(chunkName),
  vm: vmFor(chunkName),
  entryPoint,
  chunkName,
  url: `./${htmlFor(chunkName)}`,
})

module.exports = {
  datasetConfig: panelDef(
    'datasetConfig',
    '@wix/wix-data-client-data-binding/src/panels/dataset-config-panel/index.js',
  ),
  connectionConfig: panelDef(
    'connectionConfig',
    '@wix/wix-data-client-data-binding/src/panels/connection-config-panel/index.js',
  ),
  filterPanel: panelDef(
    'filterPanel',
    '@wix/wix-data-client-data-binding/src/panels/filter-panel/index.js',
  ),
  sortPanel: panelDef(
    'sortPanel',
    '@wix/wix-data-client-data-binding/src/panels/sort-panel/sortPanel.js',
  ),
  dynamicPagesHooksPanel: panelDef(
    'dynamicPagesHooksPanel',
    '@wix/wix-data-client-dynamic-pages/src/panels/router-hooks-panel/dynamicPagesHooksPanel.js',
  ),
  dynamicPageInfoPanel: panelDef(
    'dynamicPageInfoPanel',
    '@wix/wix-data-client-dynamic-pages/src/panels/page-info-panel/pageInfoPanel.js',
  ),
  dynamicPageSeoPanel: panelDef(
    'dynamicPageSeoPanel',
    '@wix/wix-data-client-dynamic-pages/src/panels/page-seo-panel/pageSeoPanel.js',
  ),
  addColumnPanel: panelDef(
    'addColumnPanel',
    '@wix/wix-data-client-data-binding/src/panels/add-column-panel/addColumnPanel.js',
  ),
  addFieldPanel: panelDef(
    'addFieldPanel',
    '@wix/wix-data-client-data-binding/src/panels/add-field-panel/addFieldPanel.js',
  ),
  createDatasetPanel: panelDef(
    'createDatasetPanel',
    '@wix/wix-data-client-data-binding/src/panels/create-dataset-panel/createDatasetPanel.js',
  ),
  newDynamicPagePanelV4: panelDef(
    'newDynamicPagePanelV4',
    '@wix/wix-data-client-dynamic-pages/src/panels/new-dynamic-page-panel-v4/newDynamicPagePanelV4.js',
  ),
  convertToDynamicPagePanelV2: panelDef(
    'convertToDynamicPagePanelV2',
    '@wix/wix-data-client-dynamic-pages/src/panels/convert-to-dynamic-page-panel-v2/convertToDynamicPagePanelV2.js',
  ),
  fieldPropertiesPanel: panelDef(
    'fieldPropertiesPanel',
    '@wix/wix-data-client-dynamic-pages/src/panels/field-properties-panel/fieldPropertiesPanel.js',
  ),
  convertToDynamicPagePanel: panelDef(
    'convertToDynamicPagePanel',
    '@wix/wix-data-client-dynamic-pages/src/panels/convert-to-dynamic-page-panel/convertToDynamicPagePanel.js',
  ),
  presetScanningPanel: panelDef(
    'presetScanningPanel',
    '@wix/wix-data-client-dynamic-pages/src/panels/preset-scanning-panel/presetScanningPanel.js',
  ),
  cmCreateNewCollectionApp: panelDef(
    'cmCreateNewCollectionApp',
    '@wix/wix-data-client-schema-management/src/panels/create-new-collection/index.js',
  ),
  createCollectionPanel: panelDef(
    'createCollectionPanel',
    '@wix/wix-data-client-schema-management/src/panels/create-collection-panel/index.js',
  ),
  editCollectionPanel: panelDef(
    'editCollectionPanel',
    '@wix/wix-data-client-schema-management/src/panels/edit-collection-panel/index.js',
  ),
  viewPermissionsPanel: panelDef(
    'viewPermissionsPanel',
    '@wix/wix-data-client-schema-management/src/panels/view-permissions-panel/index.js',
  ),
  externalDbDriver: panelDef(
    'externalDbDriver',
    '@wix/wix-data-client-schema-management/src/panels/external-db-driver-panel/index.js',
  ),
  hooksPanel: panelDef(
    'hooksPanel',
    '@wix/wix-data-client-schema-management/src/panels/hooks/index.js',
  ),
  cmCollectionsPermissionsApp: panelDef(
    'cmCollectionsPermissionsApp',
    '@wix/wix-data-client-schema-management/src/panels/permissions/index.js',
  ),
}
