import { flatMap, head, uniq } from 'lodash'
import { FieldType } from '@wix/wix-data-schema-types'

const supportedFieldTypes = {
  [FieldType.text]: [FieldType.text],
  [FieldType.image]: [FieldType.image],
  [FieldType.richText]: [FieldType.richText, FieldType.text],
}

export const findSupportedFieldTypes = fields =>
  uniq(flatMap(fields, ({ type }) => supportedFieldTypes[type]))

const filterAndSortFieldsByType = (fields, fieldType) =>
  supportedFieldTypes[fieldType].reduce(
    (acc, fieldType) => [
      ...acc,
      ...fields.filter(([key, { type }]) => type === fieldType),
    ],
    [],
  )

export const getCandidateFields = ({ fields, supportedFieldTypes }) =>
  Object.entries(fields).filter(
    ([key, { type, isDeleted, systemField }]) =>
      supportedFieldTypes.includes(type) && !isDeleted && !systemField,
  )

export const findRightFieldKey = (
  fields,
  preferableField,
  lessPreferableKeys = [],
) => {
  const filteredFields = filterAndSortFieldsByType(fields, preferableField.type)
  const fieldKeys = filteredFields.map(([key]) => key)

  if (fieldKeys.length <= 1) {
    return head(fieldKeys)
  }

  const preferableKey = preferableField.fieldKey

  if (fieldKeys.some(key => key === preferableKey)) {
    return preferableKey
  }

  const ordinaryKey = fieldKeys.find(key => !lessPreferableKeys.includes(key))

  return ordinaryKey || head(fieldKeys)
}
