import { differenceWith, isEqual } from 'lodash'
import getControllerConnections from './getControllerConnections'
import {
  DROPDOWN_OPTIONS_ROLE,
  FILTER_INPUT_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
import { hasFilterBindings } from '../connections/connectionConfigHelper'

const getConnectedRefsIncludingDropdownOptionsRole = async (
  editorSdkProxy,
  masterRef,
) =>
  (await getControllerConnections(editorSdkProxy, masterRef))
    .filter(
      ({ config, role }) =>
        (hasFilterBindings(config) && role !== FILTER_INPUT_ROLE) ||
        role === DROPDOWN_OPTIONS_ROLE,
    )
    .map(({ componentRef, role }) => ({
      traversedRef: componentRef,
      isController: role !== DROPDOWN_OPTIONS_ROLE,
    }))

const getControllersFilteredByCurrentDropdown = async (
  editorSdkProxy,
  componentRef,
) =>
  (
    await editorSdkProxy.controllers.listConnections({
      componentRef,
    })
  )
    .filter(({ role }) => role === FILTER_INPUT_ROLE)
    .map(({ controllerRef }) => ({
      traversedRef: controllerRef,
      isController: true,
    }))

const getAllDependencies = async ({ editorSdkProxy, ref, isController }) => {
  const refsToExclude = [ref]
  const traversalStack = [{ traversedRef: ref, isController }]

  while (traversalStack.length > 0) {
    const { traversedRef, isController } = traversalStack.pop()

    const refsFilteredByTraversedRef = await (isController
      ? getConnectedRefsIncludingDropdownOptionsRole(
          editorSdkProxy,
          traversedRef,
        )
      : getControllersFilteredByCurrentDropdown(editorSdkProxy, traversedRef))

    const notAlreadyExcluded = differenceWith(
      refsFilteredByTraversedRef,
      refsToExclude,
      isEqual,
    )

    refsToExclude.push(
      ...notAlreadyExcluded.map(({ traversedRef }) => traversedRef),
    )
    traversalStack.push(...notAlreadyExcluded)
  }

  return refsToExclude
}

export default getAllDependencies
