import { find, get, findKey, omit } from 'lodash'
import { getPageTitle } from './templates'

const getPageRole = (routerPages, pageRef) => {
  const routerPage = find(routerPages, { pageRef })
  return get(routerPage, 'pageRoles[0]')
}

export const getPatternDefForPage = (routerConfig, routerPages, pageRef) => {
  const pageRole = getPageRole(routerPages, pageRef)
  const pattern = findKey(routerConfig.patterns, { pageRole })
  return {
    pattern,
    def: routerConfig.patterns[pattern],
  }
}

export const deletePattern = (routerConfig, pattern) => ({
  ...omit(routerConfig, 'patterns'),
  patterns: omit(routerConfig.patterns, pattern),
})

export const addPattern = (routerConfig, pattern, patternDefinition) => ({
  ...routerConfig,

  patterns: {
    ...routerConfig.patterns,
    [pattern]: patternDefinition,
  },
})

export const updatePagePattern = (routerConfig, oldPattern, newPattern) => {
  const oldPatternDef = routerConfig.patterns[oldPattern]
  const newPateTitle = getPageTitle(oldPatternDef.config.collection, newPattern)
  const newPatternDef = {
    ...oldPatternDef,
    title: newPateTitle,
  }
  return addPattern(
    deletePattern(routerConfig, oldPattern),
    newPattern,
    newPatternDef,
  )
}

export const updatePatternDef = (routerConfig, pattern, newPatternDef) => ({
  ...routerConfig,

  patterns: {
    ...routerConfig.patterns,
    [pattern]: newPatternDef,
  },
})

export const getAllPatterns = routerConfig =>
  Object.keys(routerConfig.patterns) || []

export const getPatternDef = (routerConfig, pattern) =>
  routerConfig.patterns[pattern]
