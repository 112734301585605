import { trim } from 'lodash'
import generateUniqueName from '@wix/dbsm-common/src/generateUniqueName'

export const generateCollectionName = ({ baseName, collections }) =>
  generateUniqueName(
    (prefix, index) => `${prefix} ${index}`,
    trim(baseName),
    collections.map(({ displayName }) => displayName),
    {
      allowNameWithoutSuffix: true,
      endIndex: Number.POSITIVE_INFINITY,
    },
  )
