import { keyBy, flatten, get } from 'lodash'
import { toFieldPath } from '@wix/dbsm-common/src/reference-fields/fieldPath'
import { isReferenceField } from '../fields/fieldQueries'

export const addReferencedFieldsToSchema = (
  digestedSchema,
  digestedReferencedSchemas,
) => {
  const prefetchedSchemasById = keyBy(digestedReferencedSchemas, 'id')

  const getReferencedSchema = referenceField =>
    prefetchedSchemasById[referenceField.referencedCollection]

  // TODO: this does not belong here
  const enrichReferenceField = referenceField => ({
    ...referenceField,

    referencedCollectionDisplayField: get(
      getReferencedSchema(referenceField),
      'displayField',
    ),
  })

  const getReferencedSchemaFields = referenceField =>
    get(getReferencedSchema(referenceField), 'fields', []).map(
      referencedSchemaField => ({
        ...referencedSchemaField,
        name: toFieldPath(referencedSchemaField.name, referenceField.name),
        referencingFieldLabel: referenceField.displayName,
        isDeleted: referenceField.isDeleted || referencedSchemaField.isDeleted,
      }),
    )

  const fieldsWithReferences = digestedSchema.fields.map(field =>
    isReferenceField(field)
      ? [enrichReferenceField(field)].concat(getReferencedSchemaFields(field))
      : field,
  )

  return {
    ...digestedSchema,
    fields: flatten(fieldsWithReferences),
  }
}
