import { union as _union, derivations } from 'folktale/adt/union'

const unionWithDefaultDerivations = (typeId, patterns) =>
  _union(typeId, patterns).derive(
    derivations.serialization,
    derivations.debugRepresentation,
    derivations.equality,
  )

export const union = unionWithDefaultDerivations
export const any = _union.any
export const tagSymbol = _union.tagSymbol
