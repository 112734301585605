import { PAGINATION_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'

export default () => ({
  componentName: 'Element_Name_Pagination',
  isConnectGfppEnabled: true,
  panel: {
    height: 212,
    title: 'Connect_Panel_Title_Pagination',
    helpId: 'e8dc9ac9-6244-4402-9339-609cefc52709',
  },
  sections: [
    {
      role: PAGINATION_ROLE,
      bindings: [],
    },
  ],
  compactErrorPanel: true,
  supportsDefaultSelectedDataset: false,
})
