export const blank = () =>
  import(
    /* webpackChunkName: "classicPresets-blank" */ './definitions/blank.json'
  )
export const courses = () =>
  import(
    /* webpackChunkName: "classicPresets-courses" */ './definitions/courses.json'
  )
export const jobs = () =>
  import(
    /* webpackChunkName: "classicPresets-jobs" */ './definitions/jobs.json'
  )
export const news = () =>
  import(
    /* webpackChunkName: "classicPresets-news" */ './definitions/news.json'
  )
export const portfolio = () =>
  import(
    /* webpackChunkName: "classicPresets-portfolio" */ './definitions/portfolio.json'
  )
export const projects = () =>
  import(
    /* webpackChunkName: "classicPresets-projects" */ './definitions/projects.json'
  )
export const realEstateListings = () =>
  import(
    /* webpackChunkName: "classicPresets-realEstateListings" */ './definitions/realEstateListings.json'
  )
export const recipes = () =>
  import(
    /* webpackChunkName: "classicPresets-recipes" */ './definitions/recipes.json'
  )
export const services = () =>
  import(
    /* webpackChunkName: "classicPresets-services" */ './definitions/services.json'
  )
export const team = () =>
  import(
    /* webpackChunkName: "classicPresets-team" */ './definitions/team.json'
  )
