import { FieldType } from '@wix/wix-data-schema-types'
const { object } = FieldType

const isOfObjectTypeWithFields = field => field.type === object && field.fields

const ensureReferencedNestedFieldLabel = field => ({
  ...field,
  label: field.referencingFieldLabel
    ? `${field.referencingFieldLabel} / ${field.label}`
    : field.label,
})

export const addNestedFieldsToSchema = schema => {
  const processedFields = schema.fields.flatMap(field => {
    if (isOfObjectTypeWithFields(field)) {
      const normalizedField = ensureReferencedNestedFieldLabel(field)
      return [
        normalizedField,
        ...digestNestedFields(schema, normalizedField, normalizedField.name),
      ]
    }
    return field
  })
  return { ...schema, fields: processedFields }
}

const digestNestedFields = (schema, parentField, keyPath) =>
  Object.keys(parentField.fields).flatMap(nestedKey => {
    const nestedKeyPath = keyPath + '.' + nestedKey
    const currentFieldLabel = `${parentField.displayName} / ${parentField.fields[nestedKey].displayName}`

    const digestedField = {
      ...parentField.fields[nestedKey],
      isDeleted: parentField.isDeleted,
      referencingFieldLabel: parentField.referencingFieldLabel,
      name: nestedKeyPath,
      label: currentFieldLabel,
    }

    if (isOfObjectTypeWithFields(digestedField)) {
      return [
        digestedField,
        ...digestNestedFields(schema, digestedField, nestedKeyPath),
      ]
    } else {
      return {
        ...digestedField,
        isNested: true,
      }
    }
  })
