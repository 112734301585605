import { flow } from 'lodash'
import webBiLogger from '@wix/web-bi-logger'
import { BI_SOURCE, BI_CM_SOURCE } from '@wix/dbsm-common/src/bi/constants'
import {
  undefinedFilterMutator,
  objectFilterMutator,
} from '@wix/dbsm-common/src/bi/bi'
import { APP_NAME } from '../../../consts'
import { getAppUrl } from '@wix/dbsm-common/src/worker/getUrl'
import getAppVersion from '@wix/dbsm-common/src/getAppVersion'

const cmBiEventsWithPanelName = [180, 181]

const removePanelNameIfNeeded = event => {
  if (
    event.src !== BI_SOURCE &&
    (event.src !== BI_CM_SOURCE ||
      !cmBiEventsWithPanelName.includes(event.evid))
  ) {
    const { panel_name, ...remainingEventProps } = event // eslint-disable-line camelcase
    return remainingEventProps
  }
  return event
}

const removeVersionIfNeeded = event => {
  if (event.src !== BI_SOURCE) {
    const { ver, ...remainingEventProps } = event
    return remainingEventProps
  }
  return event
}

const removeNonCmDefaults = event => {
  if (event.src === BI_CM_SOURCE) {
    const {
      ver,
      pageId,
      component_id, // eslint-disable-line camelcase
      component_type, // eslint-disable-line camelcase
      ...remainingEventProps
    } = event
    return remainingEventProps
  }
  return event
}

const createBiLogger = () =>
  webBiLogger
    .factory()
    .withTransformer(
      flow(
        objectFilterMutator(),
        undefinedFilterMutator(),
        removePanelNameIfNeeded,
        removeVersionIfNeeded,
        removeNonCmDefaults,
      ),
    )
    .logger()

export const biAdapter = () => {
  const logger = createBiLogger()
  return {
    bi: event => {
      logger.report(event)
    },
    updateContext: ({ biDefaults }) => {
      biDefaults !== undefined && logger.updateDefaults(biDefaults)
    },
  }
}

export const getBiDefaults = async ({ appVersion, editorSdkProxy }) => {
  const [esi, siteId, editorWorkingMode] = await Promise.all([
    editorSdkProxy.info.getEditorSessionId(),
    editorSdkProxy.info.getSiteId(),
    editorSdkProxy.info.getEditorMode(),
  ])

  return {
    editor_working_mode: editorWorkingMode,
    esi,
    site_id: siteId,
    ver: appVersion || getAppVersion(getAppUrl(APP_NAME)),
  }
}
