import { RICH_CONTENT } from '@wix/app-definition-ids'
import { PRESETS, PERMISSIONS_BY_PRESET } from '@wix/wix-code-collections-api'
import { createPageLinkFields } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/page-link-field/editorActions'
import { runTransactionWithRetry } from '@wix/wix-data-client-common/src/business-logic/runTransactionWithRetry'
import * as UNDO_REDO_LABELS from '@wix/wix-data-client-common/src/undoRedoLabels'
import { getPagesStepsCreator } from './pagesSteps'
import { bindComponents } from './componentBindingStep'
import { getCollectionStepTitle, getPageStepTitle } from './progressBarTitles'
import { getEventsLogger } from './bi/getEventsLogger'
import createAndPopulatePresetCollection from './new-collection/createAndPopulatePresetCollection'

const collectionType = PRESETS.CONTENT_FOR_YOUR_SITE
const permissions = PERMISSIONS_BY_PRESET[collectionType]
const orderPages = ({ preset, pagesToAdd }) =>
  preset.pages
    .map(page => pagesToAdd.find(pageToAdd => pageToAdd.key === page.type))
    .filter(Boolean)

const afterAddingPages = async ({
  editorSdkProxy,
  collectionsApi,
  isNewAppPagesEnabled,
  arePageLinksCreatedOnBackend,
  collectionId,
  state,
}) => {
  if (state.installedAppIds.has(RICH_CONTENT)) {
    await editorSdkProxy.document.save()
  }

  await Promise.all([
    isNewAppPagesEnabled &&
      editorSdkProxy.document.application.reloadManifest(),

    !arePageLinksCreatedOnBackend &&
      createPageLinkFields({
        collectionsApi,
        collectionId,
        fieldsData: state.pages.map(({ key, pageTitle, pageRef }) => ({
          key: state.pageDefinitions[key].pageLinkFieldKey,
          displayName: pageTitle,
          prefix: state.routerPrefix,
          pattern: state.pageDefinitions[key].pattern,
          pageId: pageRef.id,
        })),
      }),
  ])

  await editorSdkProxy.history.add({
    label: UNDO_REDO_LABELS.ADD_DATA_BINDING_URL, // TODO: test this
  })
}

const initState = () => ({
  pages: [],
  pageDefinitions: {},
  routerPrefix: null,
  managingAppDefId: null,
  installedAppIds: new Map(),
})

export const getDpStepBuilder = ({
  collectionsApi,
  editorSdkProxy,
  wixData,
  biLogger,
  biCMLogger,
  i18n,
  editorType,
  isNewAppPagesEnabled,
  isSandboxEnabled,
  arePageLinksCreatedOnBackend,
  isDraftPublishEnabled,
  defaultDraftPublishStatus,
  pluginsPatches,
  appApi,
  logger,
  biDefaults,
}) => {
  const getPagesSteps = getPagesStepsCreator({
    editorType,
    editorSdkProxy,
    isNewAppPagesEnabled,
    arePageLinksCreatedOnBackend,
  })

  const biEvents = getEventsLogger({
    biLogger,
    biCMLogger,
    isSandboxEnabled,
    isDraftPublishEnabled,
    defaultDraftPublishStatus,
    logger,
    biDefaults,
  })

  return {
    getStepsForAddingPagesFromPreset: async ({
      collectionId,
      collectionName,
      preset,
      pagesToAdd,
      isIndexable,
      progressBar,
    }) => {
      let startTime

      const initStartTimeToCalculatePresetInstallationDuration = () => {
        startTime = Date.now()
      }

      const { schema, records } = preset.collections[0]

      const state = initState()

      const orderedPages = orderPages({ preset, pagesToAdd })

      const pagesSteps = getPagesSteps({
        orderedPages,
        collectionId,
        collectionName,
        preset,
        isFromExistingCollection: false,
        isIndexable,
        schema,
      })

      await progressBar.open()

      try {
        await progressBar.update({
          stepTitle: getCollectionStepTitle({
            i18n,
            pagesToAdd,
            collectionName,
          }),
        })
        initStartTimeToCalculatePresetInstallationDuration()
        await createAndPopulatePresetCollection({
          collectionId,
          displayName: collectionName,
          fields: schema.fields,
          displayField: schema.displayField,
          permissions,
          records,
          collectionsApi,
          wixData,
          pluginsPatches,
        })

        biEvents.logForPresetCollection({
          preset,
          collectionId,
          collectionType,
        })

        // would it make sense to install apps before pages steps?
        if (pagesToAdd.length > 0) {
          await runTransactionWithRetry(editorSdkProxy, async () => {
            for (const [i, pageStep] of pagesSteps.entries()) {
              const { key, staticPageRef, shouldBeBlank } = orderedPages[i]
              const pageStepTitle = getPageStepTitle({
                i18n,
                pageKey: key,
                staticPageRef,
                shouldBeBlank,
              })
              await progressBar.update({ stepTitle: pageStepTitle })
              await pageStep.action({ state })
            }
          })

          await afterAddingPages({
            editorSdkProxy,
            collectionsApi,
            isNewAppPagesEnabled,
            arePageLinksCreatedOnBackend,
            collectionId,
            state,
          })
        }

        biEvents.logForAddedPreset({
          state,
          preset,
          collectionId,
          startTime,
          collectionType,
        })

        await progressBar.update()
      } finally {
        await progressBar.close()
      }

      return {
        pageRefs: state.pages.map(p => p.pageRef),
      }
    },
    getStepsForAddingDefaultPagesFromExistingCollection: async ({
      preset,
      pagesToAdd,
      collectionId,
      collectionName,
      isIndexable,
      progressBar,
    }) => {
      const state = initState()

      const schema = await collectionsApi.get(collectionId)

      const orderedPages = orderPages({ preset, pagesToAdd })

      const pagesSteps = getPagesSteps({
        orderedPages,
        collectionId,
        collectionName,
        preset,
        isFromExistingCollection: true,
        isIndexable,
        schema,
      })

      await progressBar.open()

      try {
        await runTransactionWithRetry(editorSdkProxy, async () => {
          for (const [i, pageStep] of pagesSteps.entries()) {
            const { key, staticPageRef, shouldBeBlank } = orderedPages[i]
            const pageStepTitle = getPageStepTitle({
              i18n,
              pageKey: key,
              staticPageRef,
              shouldBeBlank,
            })
            await progressBar.update({ stepTitle: pageStepTitle })
            await pageStep.action({ state })
          }
        })

        await afterAddingPages({
          editorSdkProxy,
          collectionsApi,
          isNewAppPagesEnabled,
          arePageLinksCreatedOnBackend,
          collectionId,
          state,
        })

        biEvents.logForAddedPages({
          state,
          collectionId,
          collectionName,
          newCollection: false,
        })

        await progressBar.update()
      } finally {
        await progressBar.close()
      }

      return {
        pageRefs: state.pages.map(p => p.pageRef),
      }
    },
    getStepsForConvertingPageFromScratch: async ({
      preset,
      pagesToAdd,
      collectionToAdd,
      isIndexable,
      pageTitleBase,
      progressBar,
    }) => {
      const { collectionId, collectionName, displayField, fields, records } =
        collectionToAdd

      const schema = {
        id: collectionId,
        displayName: collectionName,
        displayField,
        fields,
      }

      const pageToConvert = pagesToAdd.find(page => page.staticPageRef)
      const hasBindings = Boolean(pageToConvert.bindings?.length)

      const orderedPages = orderPages({ preset, pagesToAdd })

      const pagesSteps = getPagesSteps({
        orderedPages,
        collectionId,
        collectionName,
        pageTitleBase,
        preset,
        isFromExistingCollection: false,
        isIndexable,
        schema,
      })

      const state = initState()

      await progressBar.open()

      try {
        await progressBar.update({
          stepTitle: getCollectionStepTitle({
            i18n,
            pagesToAdd,
            collectionName,
          }),
        })

        await createAndPopulatePresetCollection({
          collectionId,
          displayName: collectionName,
          displayField,
          permissions,
          fields,
          records,
          collectionsApi,
          wixData,
          pluginsPatches,
        })

        biEvents.logForCollection({
          collectionId,
          collectionName,
          permissions,
          collectionType,
        })

        await runTransactionWithRetry(editorSdkProxy, async () => {
          for (const [i, step] of pagesSteps.entries()) {
            const { key, staticPageRef, shouldBeBlank } = orderedPages[i]
            const pageStepTitle = getPageStepTitle({
              i18n,
              pageKey: key,
              staticPageRef,
              shouldBeBlank,
            })
            await progressBar.update({ stepTitle: pageStepTitle })
            await step.action({ state })
          }
        })

        await afterAddingPages({
          editorSdkProxy,
          collectionsApi,
          isNewAppPagesEnabled,
          arePageLinksCreatedOnBackend,
          collectionId,
          state,
        })

        biEvents.logForAddedPages({
          state,
          collectionId,
          collectionName,
          newCollection: true,
        })

        if (hasBindings) {
          // had to move to a separate transaction because otherwise it either gets stuck or is very slow
          await runTransactionWithRetry(editorSdkProxy, async () => {
            await progressBar.update({
              stepTitle: i18n.t(
                'Adding_Dynamic_Pages_Native_Progress_Bar_Connecting_Elements_Step_Title',
              ),
            })

            await bindComponents({
              editorSdkProxy,
              appApi,
              pageRef: pageToConvert.staticPageRef,
              bindings: pageToConvert.bindings,
            })
          })
        }

        await progressBar.update()
      } finally {
        await progressBar.close()
      }

      return {
        pageRefs: state.pages.map(p => p.pageRef),
      }
    },
  }
}
