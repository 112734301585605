import { addIncrementingSuffix } from '@wix/wix-data-client-common/src/addIncrementingSuffix'
import { FieldType } from '@wix/wix-data-schema-types'
import { upperFirst } from 'lodash'

const DEFAULT_DISPLAY_FIELD_KEY = 'title'
const lowestSuffix = 1

const hasDefaultDisplayField = fields =>
  fields[DEFAULT_DISPLAY_FIELD_KEY] &&
  fields[DEFAULT_DISPLAY_FIELD_KEY].type === FieldType.text

const findDisplayFieldKey = fields => {
  const delimiter = ''

  return addIncrementingSuffix(
    DEFAULT_DISPLAY_FIELD_KEY,
    Object.keys(fields),
    delimiter,
    lowestSuffix,
  )
}

const findExistingFieldNames = fields =>
  Object.values(fields).map(({ displayName }) => displayName)

const findDisplayFieldName = ({ fields, displayFieldKey }) => {
  const delimiter = ' '
  const suffix = displayFieldKey.match(/\d+$/)?.[0]
  const displayFieldNameFromKey = upperFirst(displayFieldKey).replace(
    /\d+$/,
    delimiter + suffix,
  )
  const existingFieldNames = findExistingFieldNames(fields)

  if (!existingFieldNames.includes(displayFieldNameFromKey)) {
    return displayFieldNameFromKey
  }

  return addIncrementingSuffix(
    upperFirst(DEFAULT_DISPLAY_FIELD_KEY),
    existingFieldNames,
    delimiter,
    lowestSuffix,
  )
}

const addDisplayField = ({ fields, displayFieldKey }) => ({
  ...fields,
  [displayFieldKey]: {
    displayName: findDisplayFieldName({ fields, displayFieldKey }),
    type: FieldType.text,
  },
})

const updateRecords = ({ records, displayFieldKey, pageTitle }) => {
  if (!records.length) {
    return [{ [displayFieldKey]: pageTitle }]
  }

  if (records.length === 1) {
    return records.map(record => ({
      ...record,
      [displayFieldKey]: pageTitle,
    }))
  }

  return records.map((record, index) => ({
    ...record,
    [displayFieldKey]: `${pageTitle} ${index + 1}`,
  }))
}

export const prepareDataForCollection = ({
  fields,
  records,
  collectionId,
  collectionName,
  pageTitle,
}) => {
  if (hasDefaultDisplayField(fields)) {
    return {
      collectionId,
      collectionName,
      displayField: DEFAULT_DISPLAY_FIELD_KEY,
      fields,
      records,
    }
  }

  const displayFieldKey = findDisplayFieldKey(fields)

  return {
    collectionId,
    collectionName,
    displayField: displayFieldKey,
    fields: addDisplayField({ fields, displayFieldKey }),
    records: updateRecords({ records, displayFieldKey, pageTitle }),
  }
}
