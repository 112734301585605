import { getPageLinkField } from './page-link-field/editorActions'
import parseInnerRouteFromLink from './parseInnerRouteFromLink'

export default async function getInnerRouteForDynamicPageItem({
  collectionId,
  collectionsApi,
  itemId,
  pattern,
  prefix,
  wixData,
}) {
  const item = await wixData.get(collectionId, itemId).catch(() => null)
  const itemIsNotFound = item === null
  if (itemIsNotFound) return
  const linkField = getPageLinkField(
    (await collectionsApi.get(collectionId)).fields,
    prefix,
    pattern,
  )
  const link = item[linkField.key]
  const innerRoute = parseInnerRouteFromLink({ link, prefix })
  return innerRoute
}
