import { get } from 'lodash'
import { getPatternDefForPage } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/routerConfig'
import getRoutingUrlByPageRef from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/routing-url/getRoutingUrlByPageRef'
import { updateSchema, updateField } from '@wix/wix-code-collections-api'

const getRouterCollectionId = async ({ editorSdkProxy, pageRef }) => {
  const routerRef = await editorSdkProxy.routers.getByPage({ pageRef })

  const { config: routerConfig, pages: routerPages } =
    await editorSdkProxy.routers.get({ routerRef })

  const {
    def: {
      config: { collection: routerCollectionId },
    },
  } = getPatternDefForPage(routerConfig, routerPages, pageRef)

  return routerCollectionId
}

export const updatePageLinkFieldTitle = async ({
  pageRef,
  pageTitle,
  editorSdkProxy,
  collectionsApi,
}) => {
  const routerCollectionId = await getRouterCollectionId({
    editorSdkProxy,
    pageRef,
  })

  const routerCollectionFields = (await collectionsApi.get(routerCollectionId))
    .fields

  const pageRoutingUrl = await getRoutingUrlByPageRef(editorSdkProxy, pageRef)

  const fieldEntry = Object.entries(routerCollectionFields).find(
    ([_, value]) =>
      get(value, 'calculator.config.pattern') === `/${pageRoutingUrl}`,
  )

  if (fieldEntry && fieldEntry[0]) {
    await collectionsApi.execute(
      updateSchema(
        routerCollectionId,
        updateField(fieldEntry[0], {
          displayName: pageTitle,
        }),
      ),
    )
  }
}
