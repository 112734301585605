import { flatten, uniq } from 'lodash'
import { addPattern, getAllPatterns, getPatternDef } from './routerConfig'
import { getCollection } from './patternDef'

export const addOrUpdateRouterWithPattern = async function (
  editorSdkProxy,
  prefix,
  pattern,
  patternDef,
) {
  let routerRef = await editorSdkProxy.routers.getByPrefix({ prefix })
  if (routerRef) {
    const { config } = await editorSdkProxy.routers.get({ routerRef })
    const updatedConfig = addPattern(config, pattern, patternDef)
    await editorSdkProxy.routers.update({ routerRef, config: updatedConfig })
  } else {
    routerRef = await editorSdkProxy.routers.add({
      prefix,
      config: addPattern({}, pattern, patternDef),
    })
  }
  return routerRef
}

export const getExistingPatternsByPrefix = async function (
  editorSdkProxy,
  prefix,
) {
  const routerRef = await editorSdkProxy.routers.getByPrefix({ prefix })
  if (routerRef) {
    const { config } = await editorSdkProxy.routers.get({ routerRef })
    return config ? getAllPatterns(config) : []
  }
  return []
}

export const getCollectionsBoundToRouter = async (
  editorSdkProxy,
  routerRef,
) => {
  const { config } = await editorSdkProxy.routers.get({ routerRef })
  const routerPatterns = getAllPatterns(config)
  const collections = routerPatterns
    .map(pattern => getPatternDef(config, pattern))
    .map(getCollection)

  return uniq(flatten(collections))
}
