import { escapeRegExp, includes } from 'lodash'

const ascending = (a, b) => a - b

export const addIncrementingSuffix = (
  string,
  existing = [],
  delimiter = '-',
  lowestSuffix = 2,
) => {
  if (!includes(existing, string)) {
    return string
  }

  const stringWithIntSuffix = new RegExp(
    escapeRegExp(string) + '(?:' + escapeRegExp(delimiter) + '(\\d+)$)',
  )
  const usedSuffixes = existing
    .map(str => {
      const matches = str.match(stringWithIntSuffix)
      return matches && parseInt(matches[1])
    })
    .filter(str => !!str)

  const highestSuffix = usedSuffixes.sort(ascending).pop()
  const suffix = highestSuffix + 1 || lowestSuffix

  return string + delimiter + suffix
}
