import { getPatternDefForPage } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/routerConfig'
import { getCollection } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/patternDef'

const getDPCollectionName = async ({ editorSdkProxy, routerRef, pageRef }) => {
  const router = await editorSdkProxy.routers.get({ routerRef })
  const patternDef = getPatternDefForPage(router.config, router.pages, pageRef)
  const collectionName = patternDef.def
    ? getCollection(patternDef.def)
    : undefined
  return collectionName
}

export default getDPCollectionName
