export const withProgressBar = async ({ editorSdkProxy, i18n }, fn) => {
  await editorSdkProxy.editor.openProgressBar({
    title: i18n.t('Duplicating_Dynamic_Page_Progress_Bar_Title'),
    totalSteps: 1,
  })

  try {
    const result = await fn()

    await editorSdkProxy.editor.updateProgressBar({
      currentStep: 1,
    })

    return result
  } finally {
    await editorSdkProxy.editor.closeProgressBar()
  }
}
