import { FieldType } from '@wix/wix-data-schema-types'
import { RATINGSDISPLAY_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { number } = FieldType

export default () => ({
  componentName: 'Element_Name_Ratings_Display',
  isConnectGfppEnabled: true,
  panel: {
    height: 528,
    title: 'Connect_Panel_Title_Ratings_Read',
    helpId: '5ab75d25-05a3-42aa-a6b7-b570b1e50e1d',
  },
  sections: [
    {
      role: RATINGSDISPLAY_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Average_Rating_Label',
          prop: 'rating',
          supportedFieldTypes: [number],
          defaultFieldType: number,
          supportsReferenceFields: true,
        },
        {
          title: 'Connect_Panel_Connect_NumberofRatings_Label',
          prop: 'numRatings',
          supportedFieldTypes: [number],
          defaultFieldType: number,
          supportsReferenceFields: true,
        },
      ],
    },
  ],
  compactErrorPanel: true,
})
