import { FieldType } from '@wix/wix-data-schema-types'
import { SECTION_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'

const { image, video } = FieldType

export default ({ i18n }) => ({
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Section',
    helpId: '85f062c6-cbb0-4336-bec5-637c007e90ca',
  },
  sections: [
    {
      role: SECTION_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Section_Background_Label',
          prop: 'background.src',
          supportedFieldTypes: [image, video],
          defaultFieldType: image,
          supportsReferenceFields: true,
          requiresPrimaryConnection: true,
        },
      ],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'section_live_preview_gfpp_desktop_main_action',
              label: i18n.t('Section_Live_Preview_GFPP_Desktop_Main_Action'),
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Section_Manage_Content_Panel_Description',
      },
    },
  ],
})
