import {
  cloneDeep,
  differenceBy,
  get,
  has,
  head,
  intersection,
  isEmpty,
  map,
  omit,
  omitBy,
  set,
  unset,
  unionBy,
  values,
  keys,
  every,
} from 'lodash'
import { FieldType } from '@wix/wix-data-schema-types'
import { dateFormats } from '@wix/wix-code-formatting'
import * as FORMAT_TYPES from '@wix/dbsm-common/src/connection-config/formatTypes'
import {
  REPEATER_ROLE,
  MEDIA_GALLERY_ROLE,
  GRID_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
const { dateTime } = FieldType

const removePropertyBinding = (config, binding) => {
  config.properties = omit(config.properties, binding.prop)
}

const removeEventBinding = (config, binding) => {
  config.events = omit(config.events, binding.event)
}

const removeBehaviorBinding = (config, binding) => {
  const supportedBehaviors = map(binding.supportedBehaviors, type => ({
    type,
  }))
  config.behaviors = differenceBy(config.behaviors, supportedBehaviors, 'type')
}

const removeBinding = (config, binding) => {
  removePropertyBinding(config, binding)
  removeEventBinding(config, binding)
  removeBehaviorBinding(config, binding)
}

const compact = config => {
  const compactConfig = omitBy(config, isEmpty)
  return isEmpty(compactConfig) ? undefined : compactConfig
}

const getDefaultFormat = ({ binding, fieldName, fieldType }) => {
  if (binding.isInputProperty) {
    return null
  }

  const fieldTypeToFormat = {
    [dateTime]: createFormat({
      formatType: FORMAT_TYPES.DATETIME,
      formatParams: {
        dateFormat: dateFormats.LONG_DATE_TIME,
      },
    }),
  }

  return fieldTypeToFormat[fieldType]
}

const bindToField = ({ prevConfig, binding, fieldName, fieldType }) => {
  const config = prevConfig ? cloneDeep(prevConfig) : {}
  removeBinding(config, binding)

  const defaultFormat = getDefaultFormat({
    binding,
    fieldName,
    fieldType,
  })

  set(
    config,
    [`properties`, binding.prop],
    defaultFormat ? { fieldName, format: defaultFormat } : { fieldName },
  )
  return compact(config)
}

const setFormat = ({ prevConfig, binding, format }) => {
  const config = prevConfig ? cloneDeep(prevConfig) : {}
  const currentPropBinding = get(config, `properties.${binding.prop}`)
  set(
    config,
    [`properties`, binding.prop],
    Object.assign(currentPropBinding, { format }),
  )
  return compact(config)
}

const createFormat = ({ formatType, formatParams }) => ({
  type: formatType,
  params: {
    ...formatParams,
  },
})

const getDateFormat = ({ config, prop }) => {
  const propertyFormat = getPropertyFormat(config, prop)
  return get(propertyFormat, `params.dateFormat`)
}

const bindToAction = (prevConfig, binding, actionName) => {
  const config = prevConfig ? cloneDeep(prevConfig) : {}
  removeBinding(config, binding)
  set(config, `events.${binding.event}`, { action: actionName })
  return compact(config)
}

const bindToBehavior = (prevConfig, binding, behaviorName) => {
  const config = prevConfig ? cloneDeep(prevConfig) : {}
  removeBinding(config, binding)
  set(
    config,
    `behaviors`,
    unionBy(config.behaviors, [{ type: behaviorName }], 'type'),
  )
  return compact(config)
}

const clearBinding = (prevConfig, binding) => {
  const config = prevConfig ? cloneDeep(prevConfig) : {}
  removeBinding(config, binding)
  return compact(config)
}

const clearPostAction = (prevConfig, eventName) => {
  const config = prevConfig ? cloneDeep(prevConfig) : {}
  unset(config, `events.${eventName}.postAction`)
  return config
}

const setPostAction = (prevConfig, eventName, postAction) => {
  const config = prevConfig ? cloneDeep(prevConfig) : {}
  set(config, `events.${eventName}.postAction`, postAction)
  return config
}

const getPostAction = (config, eventName) => {
  return get(config, `events.${eventName}.postAction`)
}

const getPropertyBindingFieldName = (config, prop) => {
  return get(config, ['properties', prop, 'fieldName'])
}

const getPropertyFormat = (config, prop) => {
  return get(config, ['properties', prop, 'format'])
}

const getEventBindingActionName = (config, eventName) => {
  return get(config, `events.${eventName}.action`)
}

const getBehaviorBindingName = (config, supportedBehaviors) => {
  return head(intersection(supportedBehaviors, map(config.behaviors, 'type')))
}

const hasBehaviorBinding = config => {
  return has(config, `behaviors[0]`)
}

const createUserInputFilterBinding = filterId => ({
  filters: { [filterId]: {} },
})

const createDatasetFilterBinding = (filterId, fieldName) =>
  set({}, ['filters', filterId, 'fieldName'], fieldName)

const getFilterBindingFieldName = (config, filterId) =>
  get(config, ['filters', filterId, 'fieldName'])

const getFilterBindingIds = config => keys(config.filters)

const hasFilterBindingById = (config, filterId) =>
  has(config, ['filters', filterId])

const hasFilterBindings = config =>
  has(config, ['filters']) && !isEmpty(config.filters)

const clearFilterBindingIds = (config, filterIdsToClear) => {
  const newFilters = omit(config.filters || {}, filterIdsToClear)
  return {
    ...config,
    filters: newFilters,
  }
}

const isEmpty_ = config =>
  !config || every(values(config), value => isEmpty(value))

const checkIfBindingSectionRequiresPrimaryConnection = ({ role, bindings }) => {
  if (
    role === REPEATER_ROLE ||
    role === MEDIA_GALLERY_ROLE ||
    role === GRID_ROLE
  ) {
    return true
  }

  const connectedBindings = bindings.filter(binding =>
    binding.value.getOrElse(null),
  )
  return connectedBindings.some(
    connectedBinding => connectedBinding.definition.requiresPrimaryConnection,
  )
}

const EVENT_NAMES = {
  ON_CLICK: 'onClick',
}

export {
  EVENT_NAMES,
  bindToAction,
  bindToBehavior,
  bindToField,
  clearBinding,
  clearPostAction,
  getBehaviorBindingName,
  getEventBindingActionName,
  getPostAction,
  getPropertyBindingFieldName,
  hasBehaviorBinding,
  setPostAction,
  setFormat,
  createUserInputFilterBinding,
  createDatasetFilterBinding,
  getFilterBindingFieldName,
  getFilterBindingIds,
  hasFilterBindingById,
  hasFilterBindings,
  clearFilterBindingIds,
  isEmpty_ as isEmpty,
  createFormat,
  getDateFormat,
  checkIfBindingSectionRequiresPrimaryConnection,
}
