import {
  findSupportedFieldTypes,
  getCandidateFields,
  findRightFieldKey,
} from '../utils'
import { DEFAULT_COLLECTION_FIELDS } from './predefinedCollection'
const { IMAGE, LONG_DESCRIPTION, SHORT_DESCRIPTION, TITLE } =
  DEFAULT_COLLECTION_FIELDS

const getImageField = fields => findRightFieldKey(fields, IMAGE)

const getTitleField = fields =>
  findRightFieldKey(fields, TITLE, [
    SHORT_DESCRIPTION.fieldKey,
    LONG_DESCRIPTION.fieldKey,
  ])

const getShortDescriptionField = fields =>
  findRightFieldKey(fields, SHORT_DESCRIPTION, [LONG_DESCRIPTION.fieldKey])

const getLongDescriptionField = fields =>
  findRightFieldKey(fields, LONG_DESCRIPTION)

export const getFieldKeysForPreset = ({ fields }) => {
  const supportedFieldTypes = findSupportedFieldTypes(DEFAULT_COLLECTION_FIELDS)

  const candidateFields = getCandidateFields({
    fields,
    supportedFieldTypes,
  })

  const imageFieldKey = getImageField(candidateFields)

  const titleFieldKey = getTitleField(candidateFields)

  const shortDescriptionFieldKey = getShortDescriptionField(
    candidateFields.filter(([key]) => key !== titleFieldKey),
  )

  const longDescriptionFieldKey = getLongDescriptionField(
    candidateFields.filter(
      ([key]) => ![titleFieldKey, shortDescriptionFieldKey].includes(key),
    ),
  )

  return {
    imageFieldKey,
    titleFieldKey,
    shortDescriptionFieldKey,
    longDescriptionFieldKey,
  }
}
