import { isEqual } from 'lodash'
import * as ALLOWED_OPERATIONS from '../../allowedOperations'

export const isWriteOnly = digestedSchema =>
  isEqual(digestedSchema.allowedOperations, [ALLOWED_OPERATIONS.INSERT])

export const isReadWriteAllowed = digestedSchema => {
  if (!digestedSchema.allowedOperations) {
    return true
  }

  const readAndRemoveIsAllowed =
    digestedSchema.allowedOperations.includes(ALLOWED_OPERATIONS.READ) &&
    digestedSchema.allowedOperations.includes(ALLOWED_OPERATIONS.REMOVE)

  const readAndUpdateIsAllowed =
    digestedSchema.allowedOperations.includes(ALLOWED_OPERATIONS.READ) &&
    digestedSchema.allowedOperations.includes(ALLOWED_OPERATIONS.UPDATE)

  return readAndRemoveIsAllowed || readAndUpdateIsAllowed
}

export const isReadAllowed = digestedSchema =>
  !digestedSchema.allowedOperations ||
  digestedSchema.allowedOperations.includes(ALLOWED_OPERATIONS.READ)

export const isWriteAllowed = digestedSchema =>
  !digestedSchema.allowedOperations ||
  digestedSchema.allowedOperations.includes(ALLOWED_OPERATIONS.INSERT)
