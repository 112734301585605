import openConfirmationPanel from '@wix/wix-data-client-common/src/panels/error-panel/openConfirmationPanel'
import { getAssetUrlInWorker } from '@wix/wix-data-client-common/src/panels/getAssetUrl'

export const openDPsFromSinglesErrorPanel = async ({
  editorSdkProxy,
  logger,
  i18n,
}) => {
  return openConfirmationPanel({
    editorSdkProxy,
    logger,
    headerText: i18n.t('Workspace_Dynamic_Page_From_Singles_Error_Title'),
    descriptionText: i18n.t(
      'Workspace_Dynamic_Page_From_Singles_Error_Description',
    ),
    mainActionText: i18n.t('Workspace_Dynamic_Page_From_Singles_Error_Button'),
    illustration: await getAssetUrlInWorker(
      editorSdkProxy,
      'panel-icons/generic_warning.svg',
    ),
  })
}
