import createWixData from '@wix/wix-data-platformized-client'
import { Segment } from '@wix/ambassador-cloud-data/types'
import { WIX_CODE } from '@wix/app-definition-ids'

export default async ({ editorSdkProxy, httpClient }) => {
  const wixCodePublicApi =
    await editorSdkProxy.document.application.getPublicAPI({
      appDefinitionId: WIX_CODE,
    })

  return createWixData({
    gridAppId: {
      get: async () => {
        const { gridAppId } = await wixCodePublicApi.getWixCodeAuthParams()
        return gridAppId
      },
    },
    segment: Segment.SANDBOX,
    httpClient,
  })
}
