import { isEmpty } from 'lodash'
import truncateCollectionName from '../truncateCollectionName'

export const getCollectionStepTitle = ({ i18n, pagesToAdd, collectionName }) =>
  isEmpty(pagesToAdd)
    ? undefined
    : i18n.t('Adding_Dynamic_Pages_Native_Progress_Bar_Collection_Step_Title', {
        collection_name: truncateCollectionName(collectionName),
        interpolation: { escapeValue: false },
      })

const getPageStepTitleKey = ({ pageKey, staticPageRef, shouldBeBlank }) => {
  if (staticPageRef) {
    return 'Adding_Dynamic_Pages_Native_Progress_Bar_Static_To_Item_Page_Step_Title'
  }

  if (shouldBeBlank) {
    return 'Adding_Dynamic_Pages_Native_Progress_Bar_Blank_Page_Step_Title'
  }

  return pageKey === 'itemPage'
    ? 'Adding_Dynamic_Pages_Native_Progress_Bar_Item_Page_Step_Title'
    : 'Adding_Dynamic_Pages_Native_Progress_Bar_List_Page_Step_Title'
}

export const getPageStepTitle = ({
  i18n,
  pageKey,
  staticPageRef,
  shouldBeBlank,
}) => {
  const stepTitleKey = getPageStepTitleKey({
    pageKey,
    staticPageRef,
    shouldBeBlank,
  })

  return i18n.t(stepTitleKey)
}
