import { removeCollectionDialogClicked } from '@wix/bi-logger-platform-cm/v2'
import openErrorPanel from '@wix/wix-data-client-common/src/panels/error-panel/openErrorPanel'
import openGenericConfirmationPanel from '@wix/wix-data-client-common/src/panels/error-panel/openGenericConfirmationPanel'
import countReferencesToCollection from '@wix/wix-data-client-common/src/collections/countReferencesToCollection'
import countConnectedDynamicPages from '@wix/wix-data-client-common/src/collections/countConnectedDynamicPages'
import getReferringSchemas from '@wix/wix-data-client-common/src/collections/getReferringSchemas'
import { findPresetByPermissions } from '@wix/wix-code-collections-api'

const getBiLoggerWithDefaults = ({
  allSchemas,
  schema,
  collectionId,
  permissions,
  fields,
  logger,
}) => {
  const referringSchemas = getReferringSchemas({
    allSchemas,
    referencedSchema: schema,
  })

  const numberOfReferringFields = countReferencesToCollection(
    collectionId,
    referringSchemas,
  )

  const numberOfConnectedDynamicPages = countConnectedDynamicPages({
    fields,
  })

  const collectionType = findPresetByPermissions(permissions)

  return category =>
    logger.bi(
      removeCollectionDialogClicked({
        collection_name: collectionId,
        collection_type: collectionType,
        category,
        num_of_connected_dynamic_pages: numberOfConnectedDynamicPages,
        num_of_reference_fields_to_collection: numberOfReferringFields,
      }),
    )
}

export default async ({
  editorSdkProxy,
  appApi,
  collectionsApi,
  logger,
  i18n,
  collectionId,
}) => {
  const t = i18n.t.bind(i18n)
  const allSchemas = await collectionsApi.getAll()

  const schema = allSchemas.find(({ id }) => id === collectionId)

  const { displayName, fields, permissions } = schema

  const logBiEvent = getBiLoggerWithDefaults({
    allSchemas,
    schema,
    collectionId,
    permissions,
    fields,
    logger,
  })

  return openErrorPanel({
    editorSdkProxy,
    logger,
    headerText: t('Workspace_Warning_Collection_Remove_Title'),
    topDescriptionText: t('Workspace_Warning_Collection_Remove_Paragraph1', {
      collection_name: displayName,
      interpolation: { escapeValue: false },
    }),
    bottomDescriptionText: t('Workspace_Warning_Collection_Remove_Paragraph2'),
    mainActionText: t('Workspace_Warning_Collection_Remove_Button2'),
    secondaryActionText: t('Workspace_Warning_Collection_Remove_Button1'),
    onMainActionClick: async () => {
      try {
        logBiEvent('remove')

        await appApi.removeCollection({ collectionId })
        await editorSdkProxy.document.application.livePreview.refresh({
          source: 'AFTER_DB_DELETION',
          shouldFetchData: true,
        })
      } catch (error) {
        logger.error(error)
        openGenericConfirmationPanel({
          editorSdkProxy,
          logger,
          t,
        })
      }
    },
    onSecondaryActionClick: () => logBiEvent('cancel'),
    onCloseClick: () => logBiEvent('close'),
  })
}
