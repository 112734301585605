import { generateCollectionName } from '@wix/wix-data-client-common/src/collections/generateCollectionName'
import generateCollectionId from '@wix/wix-data-client-common/src/collections/generateCollectionId'

export const generateCollectionIdAndName = ({ allCollections, baseName }) => {
  const collectionName = generateCollectionName({
    baseName,
    collections: allCollections,
  })

  const collectionId = generateCollectionId(collectionName, allCollections)

  return { collectionName, collectionId }
}
