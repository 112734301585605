import { FieldType } from '@wix/wix-data-schema-types'
import {
  CHECKBOX_GROUP_ROLE,
  FILTER_INPUT_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
const { stringArray } = FieldType

export default () => ({
  componentName: 'Element_Name_Checkbox_Group',
  isConnectGfppEnabled: true,
  panel: {
    height: 212,
    title: 'Connect_Panel_Title_Checkbox_Group',
    helpId: '1525c097-d872-4e67-aba7-2faae9c346e9',
  },
  sections: [
    {
      role: CHECKBOX_GROUP_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Value_Label',
          prop: 'value',
          supportedFieldTypes: [stringArray],
          defaultFieldType: stringArray,
          isInputProperty: true,
          emptyValue: [],
        },
      ],
    },
    {
      role: FILTER_INPUT_ROLE,
      bindings: [],
    },
  ],
  compactErrorPanel: true,
})
