import { ROUTER_DATASET } from '../../../datasetTypes'
import { DATA_BINDING as APP_DEF_ID } from '@wix/app-definition-ids'
import { PAGE_TYPES } from '../../../business-data/dynamicPages'
import { parseUrlPattern } from '../routing-url/utils'
import { truncate } from 'lodash'

const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1)

export const getPageTitle = (collectionName, pattern) => {
  const { fields } = parseUrlPattern(pattern)
  return fields.length > 0
    ? `${fields.map(field => `{${field}}`).join(', ')}`
    : collectionName
}

// TODO: Replace this when platform exposes sanitization (https://jira.wixpress.com/browse/EP-2069)
const sanitizeCollectionName = collectionName => {
  const chevrons = /<|>/g
  const emojis =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g // eslint-disable-line
  return collectionName.replace(chevrons, '').replace(emojis, '').trim()
}

const MAX_PAGE_NAME_LENGTH = 39

export const truncatePageTitle = pageName =>
  truncate(pageName, { length: MAX_PAGE_NAME_LENGTH })

const getLengthsForTruncatedParts = ({ staticParts, dynamicParts }) => {
  const totalAvailableLength = MAX_PAGE_NAME_LENGTH - ' ()'.length
  const shorterPartsLenght = Math.min(
    staticParts.length,
    dynamicParts.length,
    Math.floor(totalAvailableLength / 2),
  )
  const longerPartsLength = totalAvailableLength - shorterPartsLenght
  return staticParts.length < dynamicParts.length
    ? { staticLength: shorterPartsLenght, dynamicLength: longerPartsLength }
    : { staticLength: longerPartsLength, dynamicLength: shorterPartsLenght }
}

const truncatePageNameParts = ({ staticParts, dynamicParts }) => {
  const { staticLength, dynamicLength } = getLengthsForTruncatedParts({
    staticParts,
    dynamicParts,
  })
  return {
    truncatedStaticParts: truncate(staticParts, { length: staticLength }),
    truncatedDynamicParts: truncate(dynamicParts, { length: dynamicLength }),
  }
}

export const getPageName = (collectionName, pattern, collectionFields) => {
  const { fields: fieldNames, nonFields } = parseUrlPattern(pattern)
  const staticParts = [sanitizeCollectionName(collectionName)]
    .concat(nonFields)
    .map(capitalize)
    .join(' ')
  const dynamicParts =
    fieldNames.length > 0
      ? fieldNames
          .map(
            fieldName =>
              collectionFields.find(field => field.name === fieldName).label,
          )
          .map(capitalize)
          .join(', ')
      : 'All'
  const { truncatedStaticParts, truncatedDynamicParts } = truncatePageNameParts(
    {
      staticParts,
      dynamicParts,
    },
  )
  const pageName = `${truncatedStaticParts} (${truncatedDynamicParts})`
  return pageName
}

const getRouterDatasetName = (collectionName, pageType) =>
  pageType === PAGE_TYPES.ITEM
    ? capitalize(collectionName) + ' Item'
    : capitalize(collectionName)

export const getRouterDatasetDefinition = ({ collectionName, pageType }) => ({
  componentType: 'platform.components.AppController',
  connections: {
    type: 'ConnectionList',
    items: [
      {
        type: 'WixCodeConnectionItem',
        role: 'dynamicDataset',
      },
    ],
  },
  data: {
    controllerType: ROUTER_DATASET,
    name: getRouterDatasetName(collectionName, pageType),
    applicationId: APP_DEF_ID,
  },
  layout: {
    x: 450,
    y: 70,
  },
})
