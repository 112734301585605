import { collectionTypes } from './collections/collectionTypes'

export const preferences = {
  INTRO_WAS_SHOWN: 'cmCreateNewCollection.introWasShown',
}

export const screens = {
  DETAILS: 'DETAILS',
  PERMISSIONS: 'PERMISSIONS',
}

export const getScreenDimensions = ({
  screen,
  developerMode,
  isDraftPublishEnabled,
  isEditMode,
  collectionType,
  isCreateCollectionWithoutTabsEnabled,
}) => {
  switch (screen) {
    case screens.PERMISSIONS:
      return {
        width: '600',
        height: '640',
      }
    case screens.DETAILS:
      const WIDTH = '608'
      const MIN_HEIGHT = '444'

      if (isCreateCollectionWithoutTabsEnabled && !isEditMode) {
        return {
          width: '608',
          height: developerMode ? '495' : '453',
        }
      }

      if (isDraftPublishEnabled) {
        const isSinglesInEditMode =
          isEditMode &&
          collectionType === collectionTypes.SINGLE_ITEM_COLLECTION

        return {
          width: WIDTH,
          height: isSinglesInEditMode ? '512' : '619',
        }
      }

      if (developerMode) {
        return {
          width: WIDTH,
          height: '512',
        }
      }

      return {
        width: WIDTH,
        height: MIN_HEIGHT,
      }

    default:
      throw new Error('no such screen ' + screen)
  }
}
