import { disconnectDynamicPagePopupAction } from '@wix/bi-logger-platform/v2'
import { disconnectDynamicPagePanel as disconnectPanelBiData } from '@wix/wix-data-client-common/src/bi/panelDataForBi'
import openErrorPanel from '@wix/wix-data-client-common/src/panels/error-panel/openErrorPanel'
import { getAssetUrlInWorker } from '@wix/wix-data-client-common/src/panels/getAssetUrl'
import { getBiDefaults } from './getBiDefaults'
import isDPCollectionDeleted from './isDPCollectionDeleted'

export default async (
  {
    editorSdkProxy,
    collectionsApi,
    logger,
    appApi,
    i18n,
    fedopsLogger,
    isCollectionDeleted,
  },
  { pageRef, origin },
) => {
  const t = i18n.t.bind(i18n)
  const routerRef = await editorSdkProxy.document.routers.getByPage({
    pageRef,
  })

  const biDefaults = await getBiDefaults({
    editorSdkProxy,
    collectionsApi,
    pageRef,
    routerRef,
    origin,
    panelName: disconnectPanelBiData.panelName,
  })
  const disconnectDynamicPage = async () => {
    fedopsLogger.interactionStarted('disconnectDynamicPage')
    logger.bi(
      disconnectDynamicPagePopupAction({
        ...biDefaults,
        category: 'continue',
      }),
    )

    await appApi.disconnectDynamicPage({ pageRef, routerRef })

    fedopsLogger.interactionEnded('disconnectDynamicPage')
  }

  if (
    await isDPCollectionDeleted({
      editorSdkProxy,
      routerRef,
      pageRef,
      isCollectionDeleted,
    })
  ) {
    await disconnectDynamicPage()
    return
  }
  await openErrorPanel({
    editorSdkProxy,
    logger,
    helpId: 'e1add2cd-b7d4-45fa-b20f-57a3ad2c4609',
    headerText: t('Workspace_DYNAMIC_PAGES_WARNING_REMOVE_DATASET_TITLE'),
    topDescriptionText: t(
      'Workspace_Dynamic_Pages_Warning_Remove_Dataset_Paragraph1',
    ),
    mainActionText: t('Workspace_Dynamic_Pages_Warning_Remove_Dataset_Button2'),
    secondaryActionText: t(
      'Workspace_Dynamic_Pages_Warning_Remove_Dataset_Button1',
    ),
    illustration: await getAssetUrlInWorker(
      editorSdkProxy,
      'panel-icons/disconnect_dynamic_page.svg',
    ),
    onMainActionClick: async () => {
      await disconnectDynamicPage()
    },
    onSecondaryActionClick: () => {
      logger.bi(
        disconnectDynamicPagePopupAction({
          ...biDefaults,
          category: 'cancel',
        }),
      )
    },
    onCloseClick: () => {
      logger.bi(
        disconnectDynamicPagePopupAction({
          ...biDefaults,
          category: 'close',
        }),
      )
    },
  })
}
