import uuid from 'uuid'
import { omitBy, isEmpty, isNumber, isBoolean } from 'lodash'
import { getPageTitle as generatePageTitle } from './templates'

export const generate = ({
  routeDefinitionBase = {},
  pageRole = uuid.v4(),
  pattern,
  collectionId,
}) => ({
  ...routeDefinitionBase,
  pageRole,
  title: generatePageTitle(collectionId, pattern),
  config: {
    ...routeDefinitionBase.config,
    collection: collectionId,
    lowercase: true,
  },
})

export const getPageTitle = patternDef => patternDef.title

export const setPageTitle = (patternDef, newTitle) => ({
  ...patternDef,
  title: newTitle,
})

export const getCollection = patternDef => patternDef.config.collection

export const getSeoMetaTags = patternDef => patternDef.seoMetaTags || {}

export const setSeoMetaTags = (patternDef, newSeoMetaTags) => ({
  ...patternDef,
  seoMetaTags: newSeoMetaTags,
})

export const mergeSeoMetaTags = (patternDef, newSeoMetaTags) =>
  setSeoMetaTags(patternDef, {
    ...getSeoMetaTags(patternDef),
    ...newSeoMetaTags,
  })

export const setIndexing = (patternDef, isIndexable) =>
  setSeoMetaTags(patternDef, {
    ...getSeoMetaTags(patternDef),
    robots: isIndexable ? 'index' : 'noindex',
  })

export const getDatasetConfig = patternDef => patternDef.config

export const setDatasetConfig = (patternDef, newDatasetConfig) => ({
  ...patternDef,

  config: omitBy(
    {
      ...patternDef.config,
      ...newDatasetConfig,
    },
    omitByPredicate,
  ),
})

const omitByPredicate = val => isEmpty(val) && !isNumber(val) && !isBoolean(val)
