import { size } from 'lodash'
import { getExistingPatternsByPrefix } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/editorActions'
import {
  DynamicPageError,
  InvalidCharsInPrefixError,
} from './dynamicPageErrors'
import { DPErrorCodes, DYNAMIC_PAGES_PER_COLLECTION_LIMIT } from './constants'
import { validatePrefixWithLatinOnly } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/routing-url/validatePrefixWithLatinOnly'

const MAX_URL_LENGTH = 40

const SAFE_PADDING_FOR_URI_LENGTH = 5

const isPageUriSeoTooLong = (pageUriSEO, marginForError = 0) =>
  size(pageUriSEO) > MAX_URL_LENGTH - marginForError

const ensureValidUriSEOLength = pageUriSEO =>
  pageUriSEO.slice(0, MAX_URL_LENGTH - SAFE_PADDING_FOR_URI_LENGTH)

const safelyGetExistingPatternsByPrefix = async (editorSdkProxy, prefix) => {
  try {
    return await getExistingPatternsByPrefix(editorSdkProxy, prefix)
  } catch {
    return []
  }
}

const isDuplicateRouterPrefix = async ({ editorSdkProxy, prefix }) => {
  const existingPatterns = await safelyGetExistingPatternsByPrefix(
    editorSdkProxy,
    prefix,
  )

  return existingPatterns.length > 0
}

const isPrefixValid = async ({ editorSdkProxy, prefix }) => {
  const { valid, message, errorCode } = await validatePrefixWithLatinOnly({
    editorSdkProxy,
    prefix,
  })

  if (!valid) {
    const prefixErrorCodes = editorSdkProxy.routers.InvalidPrefixReason

    const errorCodesForRetry = [
      prefixErrorCodes.PREFIX_IS_DUPLICATE_OF_URI_SEO,
      prefixErrorCodes.PREFIX_IS_FORBIDDEN_WORD,
      prefixErrorCodes.PREFIX_IS_IN_USE_BY_ANOTHER_APPLICATION,
    ]

    if (errorCodesForRetry.includes(errorCode)) {
      return false
    }

    if (
      errorCode ===
      editorSdkProxy.routers.InvalidPrefixReason
        .PREFIX_CONTAINS_INVALID_CHARACTERS
    ) {
      throw new InvalidCharsInPrefixError(message)
    }

    throw new Error(`Prefix "${prefix}" is not valid: ${message} ${errorCode}`)
  }

  const isDuplicate = await isDuplicateRouterPrefix({ editorSdkProxy, prefix })
  return !isDuplicate
}

const generateUniquePrefix = async (title, editorSdkProxy) => {
  let pageUriSEO =
    await editorSdkProxy.document.pages.pageUriSEO.convertPageNameToUrl({
      title,
    })

  if (isPageUriSeoTooLong(pageUriSEO, SAFE_PADDING_FOR_URI_LENGTH)) {
    pageUriSEO = ensureValidUriSEOLength(pageUriSEO)
  }

  let prefixIsValid = false
  let increment = 0
  let prefix

  do {
    if (increment <= -DYNAMIC_PAGES_PER_COLLECTION_LIMIT) {
      throw new DynamicPageError(
        DPErrorCodes.TOO_MANY_DYNAMIC_PAGES_FOR_COLLECTION,
        `Failed to generate a unique prefix for title "${title}"`,
      )
    }

    prefix = `${pageUriSEO}${increment || ''}`
    prefixIsValid = await isPrefixValid({ editorSdkProxy, prefix })

    increment--
  } while (!prefixIsValid)

  return prefix
}

export default async ({ inputsForPrefix, editorSdkProxy }) => {
  for (const [index, input] of inputsForPrefix.entries()) {
    try {
      const uniquePrefix = await generateUniquePrefix(input, editorSdkProxy)
      return uniquePrefix
    } catch (error) {
      const isLastInput = index === inputsForPrefix.length - 1
      if (!isLastInput && error instanceof InvalidCharsInPrefixError) {
        continue
      }
      throw error
    }
  }
}
