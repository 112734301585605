import { getPatternDefForPage } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/routerConfig'

const groupTitle = (name, prefix, t) =>
  t('App_Pages_Group_Title', {
    name: prefix ? `${prefix}/${name}` : name,
    interpolation: { escapeValue: false },
  })

const getGroupOrder = ({ displayName, displayNamespace, namespace, id }) =>
  displayNamespace || namespace ? 2 : displayName ? 1 : id ? 3 : 4

const getGroupTitle = ({ displayName, displayNamespace, namespace, id }, t) =>
  (displayName && groupTitle(displayName, displayNamespace || namespace, t)) ||
  (id && t('App_Pages_Removed_Collection', { name: id })) ||
  t('App_Pages_Broken_Group_Title')

const createCollectionGroup = (collectionId, existingCollection, t) => {
  const collection = existingCollection || { id: collectionId }

  return {
    id: collectionId,
    title: getGroupTitle(collection, t),
    order: getGroupOrder(collection),
    pages: [],
    groupActions: existingCollection && [
      {
        title: t('App_Pages_Group_Menu_Open_Collection'),
        event: 'openContentManager',
        icon: 'manage_content',
      },
      {
        title: t('App_Pages_Group_Menu_Add_Blank_Page'),
        event: 'addBlankDynamicPageClicked',
        icon: 'add_new_item',
      },
      {
        title: t('App_Pages_Group_Menu_Add_Page'),
        event: 'addDefaultDynamicItemPage',
        icon: 'add_new_item',
      },
      {
        title: t('App_Pages_Group_Menu_Add_List_Page'),
        event: 'addDefaultDynamicListPage',
        icon: 'add_new_item',
      },
    ],
  }
}

const pageToRouterMapper = editorSdkProxy => (acc, router) =>
  router.pages.reduce(async (getMap, { pageRef }) => {
    const { title } = await editorSdkProxy.document.pages.getPageData({
      pageRef,
    })
    return getMap.then(pageToRouter =>
      pageToRouter.set({ title, pageRef }, router),
    )
  }, acc)

const collectionPageMapper =
  ({ collectionsById, t }) =>
  (collectionGroups, [{ title, pageRef }, { config, pages }]) => {
    const {
      def: { config: { collection: collectionId } } = {
        config: { collection: null },
      },
    } = getPatternDefForPage(config, pages, pageRef)

    const collectionGroup =
      collectionGroups[collectionId] ||
      (collectionGroups[collectionId] = createCollectionGroup(
        collectionId,
        collectionsById[collectionId],
        t,
      ))

    collectionGroup.pages.push({ title, id: pageRef.id })

    return collectionGroups
  }

const mapGroup = ([, { id, title, pages, groupActions }]) => ({
  id,
  title,
  groupActions,
  pages: pages
    .sort(({ title }, { title: compareTitle }) =>
      title.localeCompare(compareTitle),
    )
    .map(({ id }) => id),
})

const createDynamicPageGroups = async ({
  routers,
  collections,
  t,
  editorSdkProxy,
}) => {
  const pageToRouter = await routers.reduce(
    pageToRouterMapper(editorSdkProxy),
    Promise.resolve(new Map()),
  )

  const collectionsById = collections.reduce((acc, collection) => {
    acc[collection.id] = collection

    return acc
  }, {})

  const collectionGroups = Array.from(pageToRouter).reduce(
    collectionPageMapper({ collectionsById, t }),
    {},
  )

  return Object.entries(collectionGroups)
    .sort(
      (
        [, { title, order }],
        [, { title: compareTitle, order: compareOrder }],
      ) =>
        order === compareOrder
          ? title.localeCompare(compareTitle)
          : order - compareOrder,
    )
    .map(mapGroup)
}

export default createDynamicPageGroups
