import maybe from 'folktale/maybe'
import result from 'folktale/result'
import openGenericConfirmationPanel from '@wix/wix-data-client-common/src/panels/error-panel/openGenericConfirmationPanel'
import openContentManager from '../panels/openContentManager'
import { isResponsiveEditor } from '@wix/wix-data-client-common/src/infra/editorType'
import sequence from '@wix/dbsm-common/src/fp/sequence'
import externalDbDriverPanelOpener from '../panels/openExternalDbDriverPanel'
import openRemoveExternalDBDriverPanel from '../panels/openRemoveExternalDBDriverPanel'
import openMisconfiguredConnectorPanel from '../panels/openMisconfiguredConnectorPanel'
import openRemoveCollectionPanel from '../panels/openRemoveCollectionPanel'
import openDatabaseHooksPanel from '../panels/openDatabaseHooksPanel'

const getPayloadProp = (eventPayload, prop) =>
  maybe.fromNullable(eventPayload && eventPayload[prop])

export default async ({
  appApi,
  hostname,
  logger,
  experimentsManager,
  editorSdkProxy,
  i18n,
  collectionsApi,
  editorType,
  biCMLogger,
  wixDataConnectorSettingsClient,
  wixData,
  livePreview,
}) => {
  const openExternalDbDriverPanel = externalDbDriverPanelOpener({
    hostname,
    logger,
    editorSdkProxy,
    i18n,
    experimentsManager,
  })

  const isCollectionFake = collectionName => /\/__error__$/.test(collectionName)

  const t = i18n.t.bind(i18n)

  const openErrorPanel = () =>
    openGenericConfirmationPanel({
      editorSdkProxy,
      logger,
      t,
    })

  return async function ({ eventType, eventPayload }) {
    const getProp = prop => getPayloadProp(eventPayload, prop)
    const getPropResult = prop =>
      getPayloadProp(eventPayload, prop).matchWith({
        Nothing: () =>
          result.Error(`[${eventType}] eventPayload is missing ${prop}`),
        Just: ({ value }) => result.Ok(value),
      })

    const { origin } = eventPayload || {}

    switch (eventType) {
      case 'editCollectionClicked': {
        const { collectionId } = eventPayload
        await appApi.openCreateCollectionPanel({ origin, collectionId })
        break
      }
      case 'permissionsAndPrivacyClicked': {
        const { collectionId } = eventPayload
        await appApi.openCreateCollectionPanel({
          origin,
          collectionId,
          initialScreen: 'permissions',
        })
        break
      }
      case 'treeItemSelected':
        sequence(result, [getPropResult('itemName')]).matchWith({
          Error: ({ value: error }) => {
            logger.info(`${error}, not opening the CM Editor`)
            openErrorPanel()
          },
          Ok: async ({ value: [collectionName] }) => {
            isCollectionFake(collectionName)
              ? openMisconfiguredConnectorPanel({
                  editorSdkProxy,
                  openExternalDbDriverPanel: appApi.openExternalDbDriverPanel,
                  logger,
                  i18n,
                  collectionName,
                })
              : openContentManager({
                  hostname,
                  editorSdkProxy,
                  collectionName,
                  isModal: eventPayload.isModal,
                  origin: 'IDE_TREE',
                  wixData,
                  collectionsApi,
                  livePreview,
                  i18n,
                }).catch(logger.error)
          },
        })
        break

      case 'openCollectionPermissionsClicked':
        sequence(result, [getPropResult('collectionId')]).matchWith({
          Error: ({ value: error }) => {
            logger.info(`${error}, not opening collection permissions`)
            openErrorPanel()
          },
          Ok: ({ value: [collectionId] }) => {
            appApi.openCollectionPermissionsPanel({
              collectionId,
            })
          },
        })
        break
      case 'openCreateNewCollection': {
        const clickedInSiteStructure = getProp(
          'clickedInSiteStructure',
        ).getOrElse(false)

        await appApi.openCreateCollectionPanel({
          origin,
          clickedInSiteStructure,
        })
        break
      }
      case 'databasePanelCollectionSelected':
        sequence(result, [
          getPropResult('collectionName'),
          getPropResult('origin'),
        ]).matchWith({
          Error: ({ value: error }) => {
            logger.info(`${error}, not opening the CM Editor`)
            openErrorPanel()
          },
          Ok: async ({ value: [collectionName, origin] }) => {
            openContentManager({
              hostname,
              editorSdkProxy,
              collectionName,
              isModal: true,
              origin,
              isResponsiveEditor: isResponsiveEditor(editorType),
              wixData,
              collectionsApi,
              livePreview,
              i18n,
            }).catch(logger.error)
          },
        })
        break
      case 'openContentManagerCollectionsList':
        await appApi.openAutoCmsCollectionsList({ origin })
        break
      case 'openContentManagerAdvancedSettings':
        await appApi.openAutoCmsAdvancedSettings({ origin })
        break
      case 'removeCollectionClicked':
        sequence(result, [getPropResult('collectionId')]).matchWith({
          Error: ({ value: error }) => {
            logger.info(`${error}, not opening remove collection panel`)
            openErrorPanel()
          },
          Ok: ({ value: [collectionId] }) => {
            openRemoveCollectionPanel({
              editorSdkProxy,
              collectionsApi,
              appApi,
              logger,
              i18n,
              biCMLogger,
              collectionId,
            })
          },
        })
        break
      case 'removeExternalDbDriverClicked':
        sequence(result, [getPropResult('namespace')]).matchWith({
          Error: ({ value: error }) => {
            logger.info(`${error}, not opening remove external db driver panel`)
            openErrorPanel()
          },
          Ok: ({ value: [namespace] }) => {
            openRemoveExternalDBDriverPanel({
              editorSdkProxy,
              logger,
              appApi,
              wixDataConnectorSettingsClient,
              i18n,
              biCMLogger,
              onError: openErrorPanel,
              namespace,
            })
          },
        })
        break
      case 'addRemoveHooksClicked':
        sequence(result, [getPropResult('collectionId')]).matchWith({
          Error: ({ value: error }) => {
            logger.info(`${error}, not opening hooks panel`)
            openErrorPanel()
          },
          Ok: async ({ value: [collectionId] }) => {
            try {
              await openDatabaseHooksPanel({
                hostname,
                editorSdkProxy,
                i18n,
                collectionId,
              })
            } catch (err) {
              logger.error(err)
            }
          },
        })
        break
      case 'addExternalDbDriverClicked':
        openExternalDbDriverPanel()
        break
      case 'editExternalDbDriverClicked':
        openExternalDbDriverPanel(eventPayload.namespace)
        break
      default:
        // TODO log something here?
        break
    }
  }
}
