import { FieldType } from '@wix/wix-data-schema-types'
import { VIDEO_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { video } = FieldType

export default ({ experimentsManager }) => ({
  componentName: 'Element_Name_VideoBox',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Video_Box',
    helpId: '791a5719-fd58-469a-affa-79d767872a17',
  },
  sections: [
    {
      role: VIDEO_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_VideoPlayer_Src_Label',
          prop: 'src',
          supportedFieldTypes: [video],
          defaultFieldType: video,
          supportsReferenceFields: true,
        },
      ],
    },
  ],
})
