export default async ({
  editorSdkProxy,
  logger,
  shouldShowIllustration = true,
  illustration,
  helpId,
  headerText,
  descriptionText,
  mainActionText,
  secondaryActionText,
  onMainActionClick,
  onSecondaryActionClick,
  onCloseClick,
}) => {
  try {
    const panelResult = await editorSdkProxy.editor.openConfirmationPanel({
      shouldShowIllustration,
      illustration,
      helpId,
      headerText,
      descriptionText,
      mainActionText,
      secondaryActionText,
    })

    const actions = {
      mainActionClicked: onMainActionClick,
      secActionClicked: onSecondaryActionClick,
      closeActionClicked: onCloseClick,
    }

    const action = actions[panelResult]

    if (action) {
      await action()
    }
  } catch (error) {
    logger.error(error)
  }
}
