import { get } from 'lodash'
import { createBlankPresetFieldMap } from '../../../presets/blankPresetFieldMap'

export const getSortDefinition = ({
  editorType,
  fields,
  sortDefinitionBase,
}) => {
  if (Array.isArray(sortDefinitionBase)) {
    const fieldMap = createBlankPresetFieldMap({
      editorType,
      fields,
    })

    const isSortable = fieldKey => get(fields[fieldKey], 'sortable', true)

    const sort = sortDefinitionBase.reduce((acc, sortDefinition) => {
      const [key, value] = Object.entries(sortDefinition)[0]
      const fieldKey = fieldMap[key]
      return fieldKey && isSortable(fieldKey)
        ? [...acc, { [fieldKey]: value }]
        : acc
    }, [])

    return sort.length > 0 ? sort : undefined
  }
}
