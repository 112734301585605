import { FieldType } from '@wix/wix-data-schema-types'
import { DATEPICKER_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { dateTime } = FieldType

export default () => ({
  componentName: 'Element_Name_Date_Picker',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_DatePicker',
    helpId: 'be13b4b0-032a-4c9c-aa0c-f4b21ef0c7fa',
  },
  sections: [
    {
      role: DATEPICKER_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Value_Label',
          prop: 'value',
          supportedFieldTypes: [dateTime],
          defaultFieldType: dateTime,
          isInputProperty: true,
        },
      ],
    },
  ],
  compactErrorPanel: true,
})
