import { FieldType } from '@wix/wix-data-schema-types'
import { findKey } from 'lodash'
import { DATA_BINDING } from '@wix/app-definition-ids'
import {
  updateSchema,
  addField,
  deleteFieldPermanently,
  updateField,
} from '@wix/wix-code-collections-api'

import {
  isPageLinkField,
  generateDefinition as generatePageLinkFieldDefinition,
  updatePattern,
  updateDisplayName,
} from './pageLinkField'

import { hasValidLinkedRouterPage } from './linkedRouterPage'

export const getFields = async (collectionsApi, collectionId) =>
  (await collectionsApi.get(collectionId)).fields

const addFieldsToSchema = async ({
  collectionsApi,
  collectionId,
  definitions,
}) =>
  collectionsApi.execute(
    updateSchema(
      collectionId,
      definitions.map(({ key, definition }) => addField(key, definition)),
    ),
  )

const removeFieldsFromSchema = async (
  collectionsApi,
  collectionId,
  fieldKeys,
) => {
  if (!fieldKeys.length) {
    return
  }
  const patches = fieldKeys.map(fieldKey => deleteFieldPermanently(fieldKey))
  return collectionsApi.execute(updateSchema(collectionId, ...patches))
}

const updateFieldInSchema = (
  collectionsApi,
  collectionId,
  fieldKey,
  fieldConfig,
) =>
  collectionsApi.execute(
    updateSchema(collectionId, updateField(fieldKey, fieldConfig)),
  )

const getAllSchemas = collectionsApi => collectionsApi.getAll()

export const createPageLinkFields = async function ({
  collectionsApi,
  collectionId,
  fieldsData,
}) {
  const definitions = fieldsData.map(
    ({ key, displayName, prefix, pattern, pageId }) => ({
      key,
      definition: {
        isDeleted: false,
        systemField: true,
        plugin: DATA_BINDING,
        ...generatePageLinkFieldDefinition({
          pageTitle: displayName,
          prefix,
          pattern,
          pageId,
        }),
      },
    }),
  )

  await addFieldsToSchema({ collectionsApi, collectionId, definitions })
}

export const getPageLinkField = function (
  collectionFields,
  prefix,
  pattern,
  pageId,
) {
  let calculatedFieldKey = findKey(collectionFields, field =>
    isPageLinkField(field, prefix, pattern),
  )
  if (!calculatedFieldKey && pageId) {
    calculatedFieldKey = findKey(
      collectionFields,
      field =>
        field.type === FieldType.pageLink && field.linkedRouterPage === pageId,
    )
  }
  return {
    key: calculatedFieldKey,
    definition: collectionFields[calculatedFieldKey],
  }
}

export const deletePageLinkFields = async function (
  collectionsApi,
  collection,
  prefix,
  pattern,
) {
  // Workaround for https://jira.wixpress.com/browse/WEED-17600
  // Context: when DP is connected to Dataset pointing to removed Collection
  // calling editorSDK.vfs.readFile will result in promise rejection with error
  // from DS.fileSystemApi (which is expected). However, pm-rpc or some
  // Editor-specific wrapper around platform API fails to serialize the error properly
  // which in turn breaks postMessage response to the caller, so the promise caller got initially
  // is neither rejected nor resolved. This, together with the fact that Remove DP panel does not
  // support CLOSE action in Deleting state, leaves the impression of frozen browser.
  const schemas = await getAllSchemas(collectionsApi)
  const schema = schemas.find(({ id }) => id === collection)
  if (!schema) {
    return
  }

  const fieldKeysToRemove = Object.keys(schema.fields).filter(fieldKey => {
    const field = schema.fields[fieldKey]
    return isPageLinkField(field, prefix, pattern)
  })
  await removeFieldsFromSchema(collectionsApi, collection, fieldKeysToRemove)
}

export const updatePageLinkField = async function (
  collectionsApi,
  collection,
  oldPrefix,
  oldPattern,
  newDisplayName,
  newPrefix,
  newPattern,
  pageId,
) {
  const originalPageLinkField = getPageLinkField(
    await getFields(collectionsApi, collection),
    oldPrefix,
    oldPattern,
    pageId,
  )
  let updatedPageFieldDef = updatePattern(
    originalPageLinkField.definition,
    newPrefix,
    newPattern,
  )
  updatedPageFieldDef = updateDisplayName(updatedPageFieldDef, newDisplayName)

  if (!hasValidLinkedRouterPage(updatedPageFieldDef.linkedRouterPage)) {
    updatedPageFieldDef.linkedRouterPage = pageId
  }

  await updateFieldInSchema(
    collectionsApi,
    collection,
    originalPageLinkField.key,
    updatedPageFieldDef,
  )
}
