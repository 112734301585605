import { convertToDynamicPagePanelV2 } from '@wix/wix-data-client-common/src/panels/panelDefs'
import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'

export default async ({
  editorSdkProxy,
  appApi,
  hostname,
  initialData: { editorType, pageRef, origin },
}) => {
  const [url] = await Promise.all([
    getPanelUrl({
      editorSdkProxy,
      hostname,
      relativeUrl: convertToDynamicPagePanelV2.url,
    }),
    ensureCurrentPage(),
  ])

  try {
    await editorSdkProxy.editor.closePagesPanel()
    return await editorSdkProxy.editor.openSidePanel({
      url,
      initialData: { editorType, pageRef, origin },
    })
  } catch (error) {
    appApi.emit('pageConversionEnded', { error })
    throw error
  }

  async function ensureCurrentPage() {
    const currentPageRef = await editorSdkProxy.pages.getCurrent()
    if (currentPageRef.id !== pageRef.id) {
      return editorSdkProxy.pages.navigateTo({ pageRef })
    }
  }
}
