import { find, isPlainObject, first, isArray } from 'lodash'
import maybe from 'folktale/maybe'

import {
  addResolver as addDataBindingResolver,
  shouldResolve as shouldDataBindingResolve,
} from '@wix/dbsm-common/src/filter-resolvers/dataBindingFilterResolver'

import {
  addResolver as addUserInputResolver,
  shouldResolve as shouldUserInputResolve,
} from '@wix/dbsm-common/src/filter-resolvers/userInputFilterResolver'

import {
  addResolver as addCurrentUserResolver,
  shouldResolve as shouldCurrentUserResolve,
} from '@wix/dbsm-common/src/filter-resolvers/currentUserFilterResolver'

import * as conditionTypes from '@wix/wix-data-client-common/src/conditionTypes'
import { createFilterExpression, generateUniqueFilterId } from './filterUtils'

import {
  createUserInputFilterBinding,
  createDatasetFilterBinding,
  getFilterBindingFieldName,
  hasFilterBindingById,
} from '../connections/connectionConfigHelper'

import * as filterByDataset from './filterByDataset'
import * as filterByUserInput from '../filter/filterByUserInput'

const firstKey = obj => Object.keys(obj)[0]

const CONDITIONS = [
  {
    type: conditionTypes.EQUALS,
    operator: '$eq',
    positive: true,
    singleValue: true,
  },
  {
    type: conditionTypes.NOT_EQUALS,
    operator: '$ne',
    positive: true,
    singleValue: true,
  },
  {
    type: conditionTypes.GREATER_THAN,
    operator: '$gt',
    positive: true,
    singleValue: true,
  },
  {
    type: conditionTypes.GREATER_THAN_OR_EQUAL,
    operator: '$gte',
    positive: true,
    singleValue: true,
  },
  {
    type: conditionTypes.LESS_THAN,
    operator: '$lt',
    positive: true,
    singleValue: true,
  },
  {
    type: conditionTypes.LESS_THAN_OR_EQUAL,
    operator: '$lte',
    positive: true,
    singleValue: true,
  },
  {
    type: conditionTypes.CONTAINS,
    operator: '$contains',
    positive: true,
    singleValue: true,
  },
  {
    type: conditionTypes.ENDS_WITH,
    operator: '$endsWith',
    positive: true,
    singleValue: true,
  },
  {
    type: conditionTypes.STARTS_WITH,
    operator: '$startsWith',
    positive: true,
    singleValue: true,
  },
  {
    type: conditionTypes.NOT_CONTAINS,
    operator: '$contains',
    positive: false,
    singleValue: true,
  },
  {
    type: conditionTypes.INCLUDES_ANY,
    operator: '$hasSome',
    positive: true,
    singleValue: false,
  },
  {
    type: conditionTypes.EXCLUDES_ANY,
    operator: '$hasSome',
    positive: false,
    singleValue: false,
  },
  {
    type: conditionTypes.INCLUDES_ALL,
    operator: '$hasAll',
    positive: true,
    singleValue: false,
  },
  {
    type: conditionTypes.EXCLUDES_ALL,
    operator: '$hasAll',
    positive: false,
    singleValue: false,
  },
  {
    type: conditionTypes.IS_EMPTY,
    operator: '$eq',
    positive: true,
    singleValue: true,
  },
  {
    type: conditionTypes.IS_NOT_EMPTY,
    operator: '$ne',
    positive: true,
    singleValue: true,
  },
]

const ensureFilterId = filter =>
  filter.filterId
    ? filter
    : {
        ...filter,
        filterId: generateUniqueFilterId(),
      }

const isFilterByDataset = filter => isPlainObject(filter.dataset)

const isFilterByUserInput = filter => isPlainObject(filter.component)

// (filter expression) -> { (dataset filter), (connection) }
const extractFilterAndConnection = (filter, datasetRef) => {
  if (isFilterByUserInput(filter)) {
    const finalFilter = ensureFilterId(filter)

    return {
      filters: [finalFilter],
      connections: [
        filterByUserInput.createUserInputConnection({
          datasetRef,
          componentRef: filter.component,
          connectionConfig: createUserInputFilterBinding(finalFilter.filterId),
        }),
      ],
    }
  }
  if (isFilterByDataset(filter)) {
    const finalFilter = ensureFilterId(filter)

    return {
      filters: [finalFilter],
      connections: [
        filterByDataset.createMasterDetailConnection({
          masterRef: filter.dataset,
          detailRef: datasetRef,
          connectionConfig: createDatasetFilterBinding(
            finalFilter.filterId,
            finalFilter.datasetField,
          ),
        }),
      ],
    }
  }
  return { filters: [filter], connections: [] }
}

const singleFieldQuery = ({ field, condition, value, valueIsArray }) => ({
  [field]: {
    [condition.operator]:
      condition.singleValue || valueIsArray ? value : [value],
  },
})

const createQueryValue = filterExpression => {
  if (filterExpression.field === '_owner') {
    return addCurrentUserResolver()
  }
  if (isFilterByDataset(filterExpression)) {
    return addDataBindingResolver({ filterId: filterExpression.filterId })
  }
  if (isFilterByUserInput(filterExpression)) {
    return addUserInputResolver({ filterId: filterExpression.filterId })
  }
  return filterExpression.value
}

const getEmptinessConditionType = condition =>
  condition === conditionTypes.EQUALS
    ? conditionTypes.IS_EMPTY
    : conditionTypes.IS_NOT_EMPTY

const serializeExpression = filterExpression => {
  const {
    field,
    condition: filterExpressionCondition,
    path,
    datasetFieldIsArray,
    componentValueIsArray,
  } = filterExpression

  const condition = find(
    CONDITIONS,
    condition => condition.type === filterExpressionCondition,
  )

  const value = createQueryValue(filterExpression)
  const fieldQuery = singleFieldQuery({
    field: path ? `${field}.${path}` : field,
    condition,
    value,
    valueIsArray:
      datasetFieldIsArray || componentValueIsArray || isArray(value),
  })
  return condition.positive
    ? fieldQuery
    : {
        $not: [fieldQuery],
      }
}

const serializeFilter = (...filterExpressions) => {
  return {
    $and: filterExpressions.map(serializeExpression),
  }
}

const deserializeField = serializedField => {
  const [field, path] = serializedField.split('.')
  return { field, path, fieldWithPath: serializedField }
}

const deserializeQuery = connections => wixDataFieldQuery => {
  const positiveQuery = firstKey(wixDataFieldQuery) !== '$not'
  const fieldQuery = positiveQuery
    ? wixDataFieldQuery
    : wixDataFieldQuery.$not[0]

  const { field, path, fieldWithPath } = deserializeField(firstKey(fieldQuery))

  const operator = firstKey(fieldQuery[fieldWithPath])
  const condition = find(
    CONDITIONS,
    condition =>
      condition.operator === operator && condition.positive === positiveQuery,
  )
  const valueWrappedInArray = isArray(fieldQuery[fieldWithPath][operator])
  const possiblyDynamicFilterValue =
    condition.singleValue || !valueWrappedInArray
      ? fieldQuery[fieldWithPath][operator]
      : first(fieldQuery[fieldWithPath][operator])

  if (shouldCurrentUserResolve(possiblyDynamicFilterValue)) {
    return createFilterExpression({ field, condition: condition.type })
  }
  const connection = maybe.fromNullable(
    possiblyDynamicFilterValue &&
      connections.find(connection =>
        hasFilterBindingById(
          connection.config,
          possiblyDynamicFilterValue.filterId,
        ),
      ),
  )

  if (shouldUserInputResolve(possiblyDynamicFilterValue)) {
    return createFilterExpression({
      field,
      condition: condition.type,
      component: connection.map(con => con.componentRef).getOrElse({}),
      componentType: connection.map(con => con.componentType).getOrElse(null),
      filterId: possiblyDynamicFilterValue.filterId,
    })
  }

  if (shouldDataBindingResolve(possiblyDynamicFilterValue)) {
    const datasetField = connection
      .chain(({ config }) =>
        maybe.fromNullable(
          getFilterBindingFieldName(
            config,
            possiblyDynamicFilterValue.filterId,
          ),
        ),
      )
      .getOrElse('')

    const datasetRef = connection
      .map(con => filterByDataset.getMasterRefFromConnection(con))
      .getOrElse({})

    return createFilterExpression({
      field,
      condition: condition.type,
      dataset: datasetRef,
      datasetField,
      filterId: possiblyDynamicFilterValue.filterId,
      path,
      datasetFieldIsArray: !condition.singleValue && !valueWrappedInArray,
    })
  }

  const staticFilterValue = fieldQuery[fieldWithPath][operator]

  return createFilterExpression({
    field,
    condition:
      staticFilterValue === null
        ? getEmptinessConditionType(condition.type)
        : condition.type,
    value: staticFilterValue,
    path,
  })
}

const deserializeFilter = (wixDataFilter, connections) =>
  wixDataFilter ? wixDataFilter.$and.map(deserializeQuery(connections)) : []

export {
  serializeFilter,
  deserializeFilter,
  extractFilterAndConnection,
  isFilterByUserInput,
}
