import { createCollectionsApi as createWixCodeCollectionsApi } from '@wix/wix-code-collections-api'
import createEditorTransportLayer from '@wix/wix-code-collections-transport-editor'

export const createCollectionsApi = (
  editorSDK,
  appToken,
  collectionsTransport,
) =>
  createWixCodeCollectionsApi({
    transportLayer:
      collectionsTransport || createEditorTransportLayer(editorSDK, appToken),
  })
