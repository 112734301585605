import { map, pickBy, get, flow } from 'lodash'
import transformToClientAllowedOperations from '../../transformToClientAllowedOperations'
import {
  getCollectionType,
  CollectionType,
} from '@wix/wix-code-collections-api'
import { isSinglesCollectionSchema } from '../../collections/collectionTypes'
import { deserializeDefaultSort } from '../sort/sortUtils'

const systemFieldsLast = rawFields => ({
  ...pickBy(rawFields, field => !field.systemField),
  ...pickBy(rawFields, field => field.systemField),
})

const digestSchemaFields = rawSchema => {
  return flow([
    rawFields => systemFieldsLast(rawFields),
    rawFields =>
      map(rawFields, (fieldDef, fieldName) => ({
        ...fieldDef,
        label: fieldDef.displayName,
        name: fieldName,
        isDeleted: !!fieldDef.isDeleted,
        sortable: get(fieldDef, 'sortable', true),
        systemField: !!fieldDef.systemField,
      })),
  ])(rawSchema.fields)
}

export const digestSchema = rawSchema => {
  const isTPACollection =
    getCollectionType(rawSchema) === CollectionType.DB_DRIVER
  const isAppCollection = getCollectionType(rawSchema) === CollectionType.APP
  const isSinglesCollection = isSinglesCollectionSchema(rawSchema)

  return {
    ...rawSchema,

    // TODO: why ?
    name: rawSchema.displayName,

    isTPACollection,
    isAppCollection,
    isSinglesCollection,

    allowedOperations: transformToClientAllowedOperations(
      rawSchema.allowedOperations,
    ),

    defaultSort:
      rawSchema.defaultSort && deserializeDefaultSort(rawSchema.defaultSort),

    isDeleted: !!rawSchema.isDeleted,
    fields: digestSchemaFields(rawSchema),
  }
}
