import {
  PRESETS,
  getPermissionsByPreset,
  findPresetByPermissions,
  updatePlugin,
  enablePlugin,
  disablePlugin,
} from '@wix/wix-code-collections-api'

import { PUBLISHING_PLUGIN_ID } from './consts'

export const getPublishingModeByPermissions = (
  permissions,
  defaultDraftPublishStatus,
) => {
  const definedPermissions =
    permissions ?? getPermissionsByPreset(PRESETS.CONTENT_FOR_YOUR_SITE)
  switch (findPresetByPermissions(definedPermissions)) {
    case PRESETS.CONTENT_FOR_YOUR_SITE:
    case PRESETS.USER_GENERATED_CONTENT:
    case PRESETS.CONTENT_FOR_SITE_MEMBERS:
      return defaultDraftPublishStatus
    default:
      return null
  }
}

export const getPublishingPluginPatch = (
  publishingMode,
  initialPublishingMode,
) => {
  if (publishingMode === initialPublishingMode) {
    return
  }

  if (publishingMode && initialPublishingMode) {
    return updatePlugin(PUBLISHING_PLUGIN_ID, { defaultStatus: publishingMode })
  }

  if (publishingMode && !initialPublishingMode) {
    return enablePlugin(PUBLISHING_PLUGIN_ID, { defaultStatus: publishingMode })
  }

  if (!publishingMode && initialPublishingMode) {
    return disablePlugin(PUBLISHING_PLUGIN_ID)
  }
}

export const getPublishingModeFromPlugins = (
  plugins,
  defaultDraftPublishStatus,
) => {
  const publishingPlugin = plugins?.[PUBLISHING_PLUGIN_ID]
  return publishingPlugin
    ? publishingPlugin.defaultStatus ?? defaultDraftPublishStatus
    : null
}
