import { noop } from 'lodash'

export const createLogger = () => {
  const handlers = new Set()
  const callHandlers =
    namespace =>
    (...args) => {
      handlers.forEach(handler => (handler[namespace] || noop)(...args))
    }

  return {
    error: (error, extra) => callHandlers('error')(error, extra),
    info: (message, extra) => callHandlers('info')(message, extra),
    breadcrumb: ({ message, category, data }) =>
      callHandlers('breadcrumb')({ message, category, data }),

    bi: event => callHandlers('bi')(event),

    updateContext: updates => callHandlers('updateContext')(updates),

    registerHandler: handler => {
      handlers.add(handler)
      return () => {
        handlers.delete(handler)
      }
    },
  }
}
