import { pickBy } from 'lodash'
import { isResponsiveEditor } from '@wix/wix-data-client-common/src/infra/editorType'
import { FieldType } from '@wix/wix-data-schema-types'
import { createBlankPresetFieldMap as fieldMapForClassic } from './classic/blankPresetFieldMap'
import { createBlankPresetFieldMap as fieldMapForResponsive } from './responsive/blankPresetFieldMap'

const isSystemField = field =>
  field.systemField && field.type !== FieldType.pageLink

export const createBlankPresetFieldMap = ({
  editorType,
  fields,
  itemPageLinkFieldKey,
  indexPageLinkFieldKey,
}) => {
  const customFields = isResponsiveEditor(editorType)
    ? fieldMapForResponsive({
        fields,
        itemPageLinkFieldKey,
        indexPageLinkFieldKey,
      })
    : fieldMapForClassic({
        fields,
        itemPageLinkFieldKey,
        indexPageLinkFieldKey,
      })

  const systemFields = pickBy(fields, isSystemField)

  return { ...customFields, ...systemFields }
}
