import * as DATASET_TYPES from '@wix/wix-data-client-common/src/datasetTypes'

export default async function (editorSdkProxy, pageRef) {
  const pageControllerRefs = await editorSdkProxy.controllers.listControllers({
    pageRef,
  })
  for (const { controllerRef } of pageControllerRefs) {
    const controllerData = await editorSdkProxy.controllers.getData({
      controllerRef,
    })
    if (controllerData.type === DATASET_TYPES.ROUTER_DATASET) {
      return {
        ref: controllerRef,
        data: controllerData,
      }
    }
  }
}
