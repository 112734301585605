import {
  USER_SETTINGS_STORAGE_PREFIX,
  userSettingsStorage,
} from '@wix/wix-data-client-common/src/infra/userSettingsStorage'

const FIRST_TIME_CONVERSION_BANNER = 'first-time-conversion-banner'

export const handleFirstTimeDpConversion = async (
  collectionId,
  editorSdkProxy,
) => {
  const [metaSiteId, userId] = await Promise.all([
    editorSdkProxy.info.getMetaSiteId(),
    editorSdkProxy.info.getUserId(),
  ])

  const firstTimeConversionKey = `${USER_SETTINGS_STORAGE_PREFIX}_${metaSiteId}_${userId}_${FIRST_TIME_CONVERSION_BANNER}`

  if (await isKeyAlreadySet(firstTimeConversionKey)) {
    return
  }

  try {
    await userSettingsStorage.setItem(
      firstTimeConversionKey,
      JSON.stringify({ acknowledged: false, collectionId }),
    )
  } catch {}
}

const isKeyAlreadySet = async key => {
  let keyExists

  try {
    keyExists = await userSettingsStorage.getItem(key)
  } catch {
    keyExists = false
  }

  return Boolean(keyExists)
}
