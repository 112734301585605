import { getPatternDefForPage } from '../router-config/routerConfig'
import { buildUrl } from './utils'

export default async (editorSdkProxy, pageRef) => {
  const routerRef = await editorSdkProxy.routers.getByPage({ pageRef })
  const routerData = await editorSdkProxy.routers.get({ routerRef })

  const { pattern } = getPatternDefForPage(
    routerData.config,
    routerData.pages,
    pageRef,
  )

  return buildUrl(routerData.prefix, pattern)
}
