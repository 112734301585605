import { FieldType } from '@wix/wix-data-schema-types'
import { ICON_BUTTON_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { image, url, document, video } = FieldType

export default () => ({
  componentName: 'Element_Name_Icon_Button',
  isConnectGfppEnabled: true,
  panel: {
    height: 528,
    title: 'Connect_Panel_Title_Icon_Button',
    helpId: '39a0c04d-360c-4ceb-9e0c-b66b0b61820f',
  },
  sections: [
    {
      role: ICON_BUTTON_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Link_Action',
          prop: 'link',
          event: 'onClick',
          supportedFieldTypes: [document, url, image, video],
          defaultFieldType: url,
          supportPageLinks: true,
          supportsActions: true,
          supportsReferenceFields: true,
          supportsPostSubmit: true,
        },
      ],
    },
  ],
})
