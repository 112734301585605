import { newDynamicPagePanelV4 as newDynamicPagePanelV4Def } from '@wix/wix-data-client-common/src/panels/panelDefs'
import { DIMENSIONS } from './consts'
import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'

export default async ({ hostname, editorSdkProxy, i18n }, initialData) => {
  const { width, height } = DIMENSIONS
  return editorSdkProxy.editor.openModalPanel({
    url: await getPanelUrl({
      hostname,
      relativeUrl: newDynamicPagePanelV4Def.url,
      editorSdkProxy,
    }),
    width,
    height,
    shouldHideHeader: true,
    initialData,
  })
}
