import {
  dsPanelOpenTriggeredInWorker,
  connectOpenTriggeredFromWorker,
} from '@wix/bi-logger-platform/v2'
import { ROUTER_DATASET } from '@wix/wix-data-client-common/src/datasetTypes'
import { getRouterDatasetConfig } from '../business-logic/datasets/routerDataset'
import { getCollectionName } from '../business-logic/datasets/datasetConfigHelper'
import {
  datasetConfigPanel,
  connectionConfigPanel,
} from '@wix/wix-data-client-common/src/bi/panelDataForBi'
const { panelName: datasetConfigPanelName } = datasetConfigPanel
const { panelName: connectionConfigPanelName } = connectionConfigPanel

const defineCollectionName = async function (collectionId, collectionsApi) {
  try {
    // we might fail on calling the collectionsApi, so in any case where it fails, we may return null
    return (await collectionsApi.get(collectionId)).displayName
  } catch {
    return null
  }
}

export const logOpenDatasetPanelToBi = async function (
  logger,
  editorSdkProxy,
  collectionsApi,
  eventPayload,
) {
  const { controllerRef, controllerType, controllerConfig } = eventPayload
  const pageRef = await editorSdkProxy.components.getPage({
    componentRef: controllerRef,
  })
  const config =
    controllerType === ROUTER_DATASET
      ? await getRouterDatasetConfig(
          editorSdkProxy,
          controllerRef,
          controllerConfig,
        )
      : controllerConfig
  const collectionId = getCollectionName(config) || null
  const collectionName = collectionId
    ? await defineCollectionName(collectionId, collectionsApi)
    : null

  logger.bi(
    dsPanelOpenTriggeredInWorker({
      pageId: pageRef.id,
      ds_id: controllerRef.id,
      ds_type: controllerType,
      collection_id: collectionId,
      collection_name: collectionName,
      panel_name: datasetConfigPanelName,
    }),
  )
}

export const logOpenConnectionPanelToBi = async function (
  logger,
  editorSdkProxy,
  { componentRef, componentType },
) {
  const pageRef = await editorSdkProxy.components.getPage({ componentRef })
  logger.bi(
    connectOpenTriggeredFromWorker({
      component_id: componentRef.id,
      component_type: componentType,
      pageId: pageRef.id,
      panel_name: connectionConfigPanelName,
    }),
  )
}
