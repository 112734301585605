import openConfirmationPanel from '@wix/wix-data-client-common/src/panels/error-panel/openConfirmationPanel'

export default ({ editorSdkProxy, i18n, logger, namespace }) =>
  openConfirmationPanel({
    editorSdkProxy,
    logger,
    headerText: i18n.t('Dynamic_Pages_Warning_RemovedDbDriverCollection_Title'),
    descriptionText: i18n.t(
      'Dynamic_Pages_Warning_RemovedDbDriverCollection_Text',
      {
        WixApp_name: namespace,
      },
    ),
    mainActionText: i18n.t(
      'Dynamic_Pages_Warning_RemovedDbDriverCollection_Button',
    ),
  })
