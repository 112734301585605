export function createPublicApi() {
  let appApi = null

  return {
    init({ appApi: _appApi }) {
      appApi = _appApi
    },

    api: {
      getAppCollectionVisibility: () => appApi.getAppCollectionVisibility(),
      setAppCollectionVisibility: visibility =>
        appApi.setAppCollectionVisibility(visibility),
    },
  }
}
