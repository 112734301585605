export const ravenAdapter = Raven => ({
  error: (error, extra) => Raven.captureException(error, { extra }),
  info: (message, extra) =>
    Raven.captureMessage(message, {
      level: 'info',
      extra,
    }),
  breadcrumb: ({ message, category, data }) => {
    Raven.captureBreadcrumb({
      message,
      category,
      data,
    })
  },
})
