import { FieldType } from '@wix/wix-data-schema-types'
import { TIMEPICKER_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { time: TIME, dateTime: DATETIME } = FieldType

export default ({ experimentsManager }) => ({
  componentName: 'Element_Name_Time_Picker',
  isConnectGfppEnabled: true,
  compactErrorPanel: true,
  panel: {
    height: 212,
    title: 'Connect_Panel_Connect_Time_Picker_Label',
    helpId: 'f3bcef49-ea2b-4b69-a547-e4977455f5e9',
  },
  sections: [
    {
      role: TIMEPICKER_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Value_Label',
          prop: 'value',
          supportedFieldTypes: [TIME, DATETIME],
          defaultFieldType: TIME,
          isInputProperty: true,
          validNonEmptyValue: '12:00:00.000',
          emptyValue: null,
        },
      ],
    },
  ],
})
