import {
  defaults,
  groupBy,
  isEmpty,
  difference,
  merge,
  flatten,
  head,
  get,
  set,
  isEqual,
  map,
  noop,
  omitBy,
} from 'lodash'
import pick from 'lodash/fp/pick'
import * as UNDO_REDO_LABELS from '@wix/wix-data-client-common/src/undoRedoLabels'
import {
  DATASET,
  ROUTER_DATASET,
} from '@wix/wix-data-client-common/src/datasetTypes'
import * as FORMAT_TYPES from '@wix/dbsm-common/src/connection-config/formatTypes'
import {
  FILTER_INPUT_ROLE,
  TEXT_ROLE,
  REPEATER_ROLE,
  GRID_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
import * as defaultDatasetConfiguration from '@wix/wix-data-client-common/src/dataset-configuration/defaults'
import { runTransactionWithRetry } from '@wix/wix-data-client-common/src/business-logic/runTransactionWithRetry'
import {
  serializeFilter,
  deserializeFilter,
  extractFilterAndConnection,
} from '../../business-logic/filter/transform'
import {
  serializeSort,
  deserializeSort,
} from '@wix/wix-data-client-common/src/business-logic/sort/sortUtils'
import {
  saveRouterDatasetConfig,
  getRouterDatasetConfig,
} from './routerDataset'
import * as connectionConfigHelper from '../../business-logic/connections/connectionConfigHelper'
import getDatasetComponentDefinition from '../../business-logic/datasets/datasetComponentDefinition'
import getRepeaterAncestor from '../../business-logic/components/getRepeaterAncestor'
import getRepeaterItemAncestor from '../../business-logic/components/getRepeaterItemAncestor'
import shouldConnectionChangeAffectRepeater from './shouldConnectionChangeAffectRepeater'
import { getComponentTypeData } from '../components/getComponentTypeData'
import Maybe from 'folktale/maybe'
import { Binding } from '../bindings/Binding'
import { BEHAVIORS } from '@wix/dbsm-common/src/connection-config/behaviors'
import syncPropsDerivedFromConnections from './syncPropsDerivedFromConnections'

/**
 * DatasetConfig type definition
 * @typedef {Object} DatasetConfig
 * @property {string} collectionId
 * @property {string[]} includes
 * @property {string} readWriteType
 * @property {number} pageSize
 * @property {boolean} deferred
 * @property {filterExpression[]} filters
 * @property {sortExpression[]} sorts
 */

/**
 * DatasetInfo type definition
 * @typedef {Object} DatasetInfo
 * @property {*} controllerRef
 * @property {string} controllerType
 * @property {string} displayName
 * @property {DatasetConfig} config
 */

const getCollectionIdFromConfig = controllerConfig =>
  get(controllerConfig, 'dataset.collectionName', null)

const datasetPropertiesToOmitIfFalse = ['deferred', 'cursor']
const omitFalseProperties = datasetConfig =>
  omitBy(
    datasetConfig,
    (value, key) =>
      datasetPropertiesToOmitIfFalse.includes(key) && value === false,
  )

const setDisplayName = async (
  editorSdkProxy,
  controllerRef,
  newDisplayName,
) => {
  await editorSdkProxy.controllers.setDisplayName({
    controllerRef,
    name: newDisplayName,
  })
  await editorSdkProxy.history.add({
    label: UNDO_REDO_LABELS.SET_DISPLAY_NAME,
  })
}

/**
 * Update a Dataset or RouterDataset's config.
 * Any config property that is not passed, will not be updated.
 * @param {*} editorSdkProxy
 * @param {*} controllerRef
 * @param {DatasetConfig} apiConfig
 */
const updateConfig = async (
  editorSdkProxy,
  controllerRef,
  apiConfig,
  livePreview,
) => {
  const { type: controllerType, config: controllerConfig } =
    await editorSdkProxy.controllers.getData({ controllerRef })

  if (![DATASET, ROUTER_DATASET].includes(controllerType)) {
    throw new Error(`Unsupported controller type ${controllerType}`)
  }

  const existingControllerConfig =
    controllerType === DATASET
      ? controllerConfig
      : await getRouterDatasetConfig(
          editorSdkProxy,
          controllerRef,
          controllerConfig,
        )

  const {
    datasetConfig: datasetConfigUpdates,
    filterConnections: filterConnectionsUpdates,
  } = fromApiConfig(apiConfig, controllerRef)

  const wasCollectionChanged =
    datasetConfigUpdates.collectionName &&
    getCollectionIdFromConfig(existingControllerConfig) !==
      getCollectionIdFromConfig({ dataset: datasetConfigUpdates })

  const updatedDataset = defaults(
    {},
    datasetConfigUpdates,
    existingControllerConfig.dataset,
    defaultDatasetConfiguration,
  )

  const updatedControllerConfig = {
    ...existingControllerConfig,

    dataset: omitFalseProperties(
      wasCollectionChanged
        ? defaults({}, datasetConfigUpdates, defaultDatasetConfiguration)
        : updatedDataset,
    ),
  }

  const saveConfigPromise =
    controllerType === DATASET
      ? runTransactionWithRetry(editorSdkProxy, () =>
          editorSdkProxy.controllers.saveConfiguration({
            controllerRef,
            config: updatedControllerConfig,
          }),
        )
      : saveRouterDatasetConfig(
          editorSdkProxy,
          controllerRef,
          updatedControllerConfig,
        )

  const clearExistingCollectionsPromise = wasCollectionChanged
    ? clearAllControllerConnections(editorSdkProxy, controllerRef)
    : Promise.resolve()

  if (apiConfig.filters) {
    await clearExistingCollectionsPromise.then(async () => {
      const actualFilterConnections = await fetchFilterConnections(
        editorSdkProxy,
        controllerRef,
      )
      return Promise.all([
        syncFilterConnections(
          editorSdkProxy,
          filterConnectionsUpdates,
          actualFilterConnections,
        ),
      ])
    })
  }

  await Promise.all([saveConfigPromise, clearExistingCollectionsPromise])

  const appApi = await editorSdkProxy.editor.getAppAPI()
  await appApi.refreshControllersState({
    controllerRefs: [controllerRef],
  })

  await editorSdkProxy.history.add({
    label: UNDO_REDO_LABELS.SAVE_CONFIGURATION,
  })

  if (
    !isEqual(
      get(existingControllerConfig, 'dataset.filter'),
      updatedControllerConfig.dataset.filter,
    )
  ) {
    await editorSdkProxy.editor.routers.refresh().catch(() => {}) // Editor X does not have implementation for it yet
  }

  if (livePreview) {
    await editorSdkProxy.document.application.livePreview.refresh({
      source: 'DATASET_CONFIG_CHANGE',
    })
  }
}

const fetchFilterConnections = async (editorSdkProxy, targetRef) => {
  const [componentConnections, controllerConnections] = await Promise.all([
    editorSdkProxy.controllers
      .getControllerConnections({ controllerRef: targetRef })
      .then(cs =>
        Promise.all(
          cs
            .filter(({ connection }) => connection.role === FILTER_INPUT_ROLE)
            .map(async ({ connection, componentRef }) => {
              const { sdkType: componentType } = await getComponentTypeData({
                editorSdkProxy,
                componentRef,
              })
              return {
                ...connection,
                componentRef,
                componentType,
              }
            }),
        ),
      ),
    editorSdkProxy.controllers
      .listConnections({ componentRef: targetRef })
      .then(cs =>
        cs.map(c => ({
          ...c,
          componentRef: targetRef,
        })),
      ),
  ])

  return componentConnections.concat(controllerConnections)
}

/**
 * Fetch Dataset / RouterDataset's info & configurations
 * @param {*} editorSdkProxy
 * @param {*} controllerRef
 * @returns {DatasetInfo}
 */
const fetchDataset = async (editorSdkProxy, controllerRef) => {
  const {
    type: controllerType,
    config: controllerConfig,
    displayName,
  } = await editorSdkProxy.controllers.getData({ controllerRef })

  if (![DATASET, ROUTER_DATASET].includes(controllerType)) {
    throw new Error(`Unsupported controller type ${controllerType}`)
  }

  const existingControllerConfig =
    controllerType === DATASET
      ? controllerConfig
      : await getRouterDatasetConfig(
          editorSdkProxy,
          controllerRef,
          controllerConfig,
        )

  const filterConnections = await fetchFilterConnections(
    editorSdkProxy,
    controllerRef,
  )

  const apiDatasetConfig = toApiConfig(
    existingControllerConfig.dataset,
    filterConnections,
  )

  return {
    controllerRef,
    type: controllerType,
    displayName,
    config: apiDatasetConfig,
  }
}

const connectionId = connection =>
  JSON.stringify({
    controllerRef: connection.controllerRef,
    componentRef: connection.componentRef || connection.connectToRef, // TODO ??
    role: connection.role,
  })

const mergeConnections = connections => {
  const grouped = groupBy(connections, connection => connectionId(connection))
  return flatten(
    Object.values(grouped).map(
      connectionGroup =>
        connectionGroup.reduce((final, current) => ({
          ...final,
          ...current,
          config: merge({}, final.config, current.config),
        })),
      {},
    ),
  )
}

const getFilterIds = connections =>
  flatten(
    connections.map(connection =>
      connectionConfigHelper.getFilterBindingIds(connection.config),
    ),
  )

const syncFilterConnections = async (
  editorSdkProxy,
  expectedConnections,
  actualConnections,
) => {
  const actualFilterIds = getFilterIds(actualConnections)
  const expectedFilterIds = getFilterIds(expectedConnections)
  const filterIdsToRemove = difference(actualFilterIds, expectedFilterIds)

  const actualConnectionsWithoutRemovedFilterIds = actualConnections.map(
    connection => ({
      ...connection,

      config: connectionConfigHelper.clearFilterBindingIds(
        connection.config,
        filterIdsToRemove,
      ),
    }),
  )

  const expectedAndEmptyConnections = mergeConnections(
    actualConnectionsWithoutRemovedFilterIds.concat(expectedConnections),
  )

  await Promise.all(
    expectedAndEmptyConnections.map(
      async ({ config, controllerRef, componentRef, role }) => {
        if (connectionConfigHelper.isEmpty(config)) {
          await runTransactionWithRetry(editorSdkProxy, () =>
            editorSdkProxy.controllers.disconnect({
              controllerRef,
              connectToRef: componentRef,
              role,
            }),
          )
        } else {
          await runTransactionWithRetry(editorSdkProxy, () =>
            editorSdkProxy.controllers.connect({
              controllerRef,
              connectToRef: componentRef,
              role,
              connectionConfig: config,
            }),
          )
        }
      },
    ),
  )
}

const toApiConfig = (datasetConfig, filterConnections) => {
  const datasetConfigWithDefaults = defaults(
    {},
    datasetConfig,
    defaultDatasetConfiguration,
  )
  return {
    collectionId: datasetConfigWithDefaults.collectionName,
    includes: datasetConfigWithDefaults.includes,
    readWriteMode: datasetConfigWithDefaults.readWriteType,
    pageSize: datasetConfigWithDefaults.pageSize,
    deferred: Boolean(datasetConfigWithDefaults.deferred),
    cursor: Boolean(datasetConfigWithDefaults.cursor),
    sorts: datasetConfigWithDefaults.sort
      ? deserializeSort(datasetConfigWithDefaults.sort)
      : [],
    filters: deserializeFilter(
      datasetConfigWithDefaults.filter,
      filterConnections,
    ),
    nested: datasetConfigWithDefaults.nested,
  }
}

const fromApiConfig = (apiDatasetConfig, controllerRef) => {
  const { filter, connections: filterConnections } = fromApiFilters(
    apiDatasetConfig.filters,
    controllerRef,
  )
  return {
    datasetConfig: {
      collectionName: apiDatasetConfig.collectionId,
      includes: apiDatasetConfig.includes,
      readWriteType: apiDatasetConfig.readWriteMode,
      pageSize: apiDatasetConfig.pageSize,
      deferred: apiDatasetConfig.deferred,
      cursor: apiDatasetConfig.cursor,
      sort: isEmpty(apiDatasetConfig.sorts)
        ? apiDatasetConfig.sorts && null
        : serializeSort(...apiDatasetConfig.sorts),
      filter: apiDatasetConfig.filters && filter,
      nested: apiDatasetConfig.nested,
    },
    filterConnections,
  }
}

const fromApiFilters = (apiFilters, controllerRef) => {
  if (isEmpty(apiFilters)) {
    return {
      filter: null,
      connections: [],
    }
  }

  const { filters, connections } = apiFilters
    .map(filter => extractFilterAndConnection(filter, controllerRef))
    .reduce(
      (result, current) => ({
        ...result,
        filters: result.filters.concat(current.filters),
        connections: result.connections.concat(current.connections),
      }),
      { filters: [], connections: [] },
    )

  return {
    connections,
    filter: serializeFilter(...filters),
  }
}

const fetchConnectableDatasets = async (editorSdkProxy, componentRef) =>
  editorSdkProxy.controllers
    .listConnectableControllers({ componentRef })
    .then(connectableControllers =>
      Promise.all(
        connectableControllers.map(connectableController =>
          fetchDataset(
            editorSdkProxy,
            connectableController.controllerRef,
          ).catch(() => null),
        ),
      ),
    )
    .then(connectableControllers => connectableControllers.filter(Boolean))

const _disconnectBindingSection = async (
  editorSdkProxy,
  controllerRef,
  componentRef,
  role,
) =>
  runTransactionWithRetry(editorSdkProxy, () =>
    editorSdkProxy.controllers.disconnect({
      controllerRef,
      role,
      connectToRef: componentRef,
    }),
  )

const clearAllControllerConnections = async (editorSdkProxy, controllerRef) => {
  const connectedComponentRefs =
    await editorSdkProxy.controllers.listConnectedComponents({ controllerRef })
  await Promise.all(
    connectedComponentRefs.map(componentRef =>
      editorSdkProxy.controllers
        .listConnections({ componentRef })
        .then(componentConnections =>
          Promise.all(
            componentConnections
              .filter(connection =>
                isEqual(controllerRef, connection.controllerRef),
              )
              .map(connectionToOurController =>
                runTransactionWithRetry(editorSdkProxy, () =>
                  editorSdkProxy.controllers.disconnect({
                    controllerRef,
                    connectToRef: componentRef,
                    role: connectionToOurController.role,
                  }),
                ),
              ),
          ),
        ),
    ),
  )
}

const createDatetimeFormatConfig = format => ({
  format: {
    type: FORMAT_TYPES.DATETIME,
    params: {
      dateFormat: format,
    },
  },
})

const createConnectionConfig = (bindings = []) => {
  const connectionConfig = bindings.reduce(
    (config, binding) =>
      binding.matchWith({
        Field: ({ prop, fieldPath, format }) => {
          const bindingConfig = {
            fieldName: fieldPath,
            ...format.map(createDatetimeFormatConfig).getOrElse({}),
          }
          set(config, ['properties', prop], bindingConfig)
          return config
        },
        Action: ({ event, action, postAction }) => {
          set(config, `events.${event}.action`, action)
          postAction.fold(noop, value => {
            set(config, `events.${event}.postAction`, pick('navigate', value))
          })

          return config
        },
        Behavior: ({ behavior }) => {
          set(config, 'behaviors', [{ type: behavior }])
          return config
        },
      }),
    {},
  )
  return Object.keys(connectionConfig).length ? connectionConfig : undefined
}

const getComponentBehaviorBinding = bindings =>
  Maybe.fromNullable(
    bindings.find(binding => Binding.Behavior.hasInstance(binding)),
  )

const syncComponentBehaviorMetadata = async (
  editorSdkProxy,
  componentRef,
  newBindings,
  oldBindings,
) => {
  await getComponentBehaviorBinding(oldBindings)
    .map(() => clearComponentBehaviorMetadata(editorSdkProxy, componentRef))
    .getOrElse(Promise.resolve())

  await getComponentBehaviorBinding(newBindings)
    .map(({ behavior }) =>
      updateComponentBehaviorMetadata(editorSdkProxy, componentRef, behavior),
    )
    .getOrElse(Promise.resolve())
}

const updateComponentBehaviorMetadata = async (
  editorSdkProxy,
  componentRef,
  behaviorType,
) => {
  await runTransactionWithRetry(editorSdkProxy, () =>
    editorSdkProxy.components.data.update({
      componentRef,
      data: { text: BEHAVIORS[behaviorType].textElement },
    }),
  )
  await runTransactionWithRetry(editorSdkProxy, () =>
    editorSdkProxy.components.properties.update({
      componentRef,
      props: { isHidden: true },
    }),
  )
}

const clearComponentBehaviorMetadata = (editorSdkProxy, componentRef) =>
  runTransactionWithRetry(editorSdkProxy, () =>
    editorSdkProxy.document.transactions.runAndWaitForApproval(() =>
      editorSdkProxy.components.properties.update({
        componentRef,
        props: { isHidden: false },
      }),
    ),
  )

const connectRepeaterAncestorIfNeeded = async ({
  editorSdkProxy,
  componentRef,
  repeaterAncestor,
  controllerRef,
  livePreview,
}) =>
  repeaterAncestor
    .map(async repeaterAncestor => {
      const shouldUpdateRepeaterConnection =
        await shouldConnectionChangeAffectRepeater({
          editorSdkProxy,
          componentRef,
          repeaterAncestor,
          connecting: true,
        })

      if (shouldUpdateRepeaterConnection) {
        const isPrimary =
          livePreview &&
          (await checkIfComponentHasNoOtherPrimaryConnection({
            editorSdkProxy,
            componentRef: repeaterAncestor.componentRef,
            role: REPEATER_ROLE,
          }))

        await runTransactionWithRetry(editorSdkProxy, () => {
          editorSdkProxy.controllers.connect({
            controllerRef,
            role: REPEATER_ROLE,
            connectionConfig: {},
            connectToRef: repeaterAncestor.componentRef,
            isPrimary,
          })
        })
        return Maybe.Just({ controllerRef })
      }
      return Maybe.Nothing()
    })
    .getOrElse(Promise.resolve(Maybe.Nothing()))

const removeTableColumns = async ({
  editorSdkProxy,
  componentRef,
  controllerRef,
  collections,
}) => {
  await syncPropsDerivedFromConnections({
    editorSdkProxy,
    controllerRef,
    componentRef,
    newColumns: [],
    collections,
  })

  await runTransactionWithRetry(editorSdkProxy, () => {
    editorSdkProxy.components.properties.update({
      componentRef,
      props: { columns: [] },
    })
  })
}

const saveTableColumns = async ({
  editorSdkProxy,
  componentRef,
  newColumns,
}) => {
  await runTransactionWithRetry(editorSdkProxy, () =>
    editorSdkProxy.components.properties.update({
      componentRef,
      props: { columns: newColumns },
    }),
  )
}

const connectBindingSection = async ({
  editorSdkProxy,
  componentRef,
  controllerRef,
  newBindings,
  newColumns = [],
  role,
  requiresPrimaryConnection,
  livePreview,
  collections,
}) => {
  const { controllerRef: previousControllerRef, bindings: oldBindings = [] } =
    await fetchComponentBindings(editorSdkProxy, componentRef).then(
      sections => sections.find(section => section.role === role) || {},
    )
  const repeaterAncestor = await getRepeaterAncestor({
    editorSdkProxy,
    componentRef,
  })
  const connectToNewController =
    !previousControllerRef || controllerRef.id !== previousControllerRef.id

  await syncPropsDerivedFromConnections({
    editorSdkProxy,
    controllerRef,
    componentRef,
    newBindings,
    oldBindings: connectToNewController ? [] : oldBindings,
    newColumns,
    collections,
  })

  if (role === TEXT_ROLE) {
    await syncComponentBehaviorMetadata(
      editorSdkProxy,
      componentRef,
      newBindings,
      oldBindings,
    )
  } else if (role === GRID_ROLE) {
    await saveTableColumns({
      editorSdkProxy,
      componentRef,
      newColumns,
    })
  }

  if (previousControllerRef) {
    if (connectToNewController) {
      await syncPropsDerivedFromConnections({
        editorSdkProxy,
        controllerRef: previousControllerRef,
        componentRef,
        oldBindings,
        newBindings: [],
        collections,
      })
    }

    await _disconnectBindingSection(
      editorSdkProxy,
      previousControllerRef,
      componentRef,
      role,
    )
  }
  const isPrimary =
    requiresPrimaryConnection &&
    (await checkIfComponentHasNoOtherPrimaryConnection({
      editorSdkProxy,
      componentRef,
      role,
    }))

  await runTransactionWithRetry(editorSdkProxy, () =>
    editorSdkProxy.controllers.connect({
      controllerRef,
      role,
      connectToRef: componentRef,
      connectionConfig: createConnectionConfig(newBindings),
      isPrimary,
    }),
  )

  const updatedRepeaterAncestor = await connectRepeaterAncestorIfNeeded({
    editorSdkProxy,
    componentRef,
    repeaterAncestor,
    controllerRef,
    livePreview,
  })

  return {
    repeaterAncestor: updatedRepeaterAncestor,
  }
}

const checkIfComponentHasNoOtherPrimaryConnection = async ({
  editorSdkProxy,
  componentRef,
  role,
}) => {
  const componentConnections = await editorSdkProxy.controllers.listConnections(
    { componentRef },
  )
  const componentHasNoOtherPrimaryConnection = componentConnections.every(
    componentConnection =>
      !componentConnection.isPrimary || componentConnection.role === role,
  )
  return componentHasNoOtherPrimaryConnection
}

const disconnectBindingSection = async ({
  editorSdkProxy,
  componentRef,
  controllerRef,
  role,
  collections,
}) => {
  await syncPropsDerivedFromConnections({
    editorSdkProxy,
    controllerRef,
    componentRef,
    collections,
  })

  if (role === TEXT_ROLE) {
    await clearComponentBehaviorMetadata(editorSdkProxy, componentRef)
  } else if (role === GRID_ROLE) {
    await removeTableColumns({
      editorSdkProxy,
      componentRef,
      controllerRef,
      collections,
    })
  }

  await _disconnectBindingSection(
    editorSdkProxy,
    controllerRef,
    componentRef,
    role,
  )
}

const parseBindings = ({ properties, events, behaviors } = {}) => {
  const fieldBindings = map(properties, ({ fieldName, format }, prop) =>
    Binding.Field({
      prop,
      fieldPath: fieldName,
      format: get(format, 'params.dateFormat'),
    }),
  )
  const actionBindings = map(events, ({ action, postAction }, event) =>
    Binding.Action({ event, action, postAction }),
  )
  const behaviorBindings = map(behaviors, behavior =>
    Binding.Behavior({ behavior: behavior.type }),
  )

  return fieldBindings.concat(behaviorBindings).concat(actionBindings)
}

const fetchComponentBindings = async (editorSdkProxy, componentRef) =>
  editorSdkProxy.controllers
    .listConnections({ componentRef })
    .then(connections =>
      Promise.all(
        connections.map(async ({ controllerRef, role, config }) => {
          const bindings = await parseBindings(config)
          return { controllerRef, role, bindings }
        }),
      ),
    )

const fetchConnectedDataset = async (editorSdkProxy, componentRef) =>
  editorSdkProxy.controllers
    .listConnections({ componentRef })
    .then(connections => {
      const { controllerRef } = connections.pop()
      return fetchDataset(editorSdkProxy, controllerRef)
    })

const createDataset = async (
  editorSdkProxy,
  pageRef,
  { displayName, collectionId, readWriteMode, pageSize }, // TODO: support all configs
) => {
  const { datasetConfig } = fromApiConfig(
    { collectionId, readWriteMode, pageSize },
    undefined, // TODO: remove the need to pass controllerRef here
  )

  const controllerConfig = {
    dataset: omitFalseProperties(
      defaults({}, datasetConfig, defaultDatasetConfiguration),
    ),
  }

  const datasetComponentDefinition = getDatasetComponentDefinition(
    displayName,
    controllerConfig,
  )

  const controllerRef = await editorSdkProxy.components.add({
    componentDefinition: datasetComponentDefinition,
    pageRef,
  })
  await editorSdkProxy.controllers.setState({
    state: { configured: [controllerRef] },
  })

  const apiDatasetConfig = toApiConfig(controllerConfig.dataset, [])

  return {
    controllerRef,
    type: DATASET,
    displayName,
    config: apiDatasetConfig,
  }
}

const fetchRepeaterChildrenBindings = async ({
  editorSdkProxy,
  componentRef,
  repeaterChildRef = null,
}) => {
  const getComponentChildren = async componentRef => {
    const componentChildren = await editorSdkProxy.components.getChildren({
      componentRef,
    })

    return isEmpty(componentChildren)
      ? editorSdkProxy.components.getChildren({
          componentRef,
          fromDocument: true,
        })
      : componentChildren
  }

  const repeaterItemContainerRef = repeaterChildRef
    ? (
        await getRepeaterItemAncestor({
          editorSdkProxy,
          componentRef: repeaterChildRef,
        })
      ).getOrElse(repeaterChildRef)
    : head(await getComponentChildren(componentRef))

  const containerChildren = await editorSdkProxy.components.getChildren({
    componentRef: repeaterItemContainerRef,
    recursive: true,
  })
  const childrenRefs = containerChildren.concat(repeaterItemContainerRef)
  const childStructures = await editorSdkProxy.components.get({
    componentRefs: childrenRefs,
    properties: ['sdkType', 'connections'],
  })
  return childStructures.map(({ componentRef, sdkType, connections }) => ({
    componentRef,
    componentType: sdkType,
    connections: connections.map(({ controllerRef, role, config }) => {
      const bindings = parseBindings(config)
      return { controllerRef, role, bindings }
    }),
  }))
}

export {
  setDisplayName,
  updateConfig,
  fetchDataset,
  fetchConnectableDatasets,
  connectBindingSection,
  disconnectBindingSection,
  fetchComponentBindings,
  fetchConnectedDataset,
  fetchRepeaterChildrenBindings,
  createDataset,
  saveTableColumns,
  removeTableColumns,
}
