import isEqual from 'lodash/fp/isEqual'
import * as filterByDataset from '../business-logic/filter/filterByDataset'
import * as filterByUserInput from '../business-logic/filter/filterByUserInput'
import getAllDependencies from '../business-logic/filter/getAllDependencies'

const getConnectableComponents = async ({
  editorSdkProxy,
  controllerRef,
  experimentsManager,
}) => {
  const masterControllerCandidatesPromise =
    filterByDataset.getMasterControllerCandidates(editorSdkProxy, controllerRef)

  const userInputFilterComponentCandidatesPromise =
    filterByUserInput.getUserInputFilterComponentCandidates({
      editorSdkProxy,
      isExperimentEnabled: spec => experimentsManager.enabled(spec),
    })

  const controllerDependenciesPromise = getAllDependencies({
    editorSdkProxy,
    ref: controllerRef,
    isController: true,
  })

  const [
    masterControllerCandidates,
    userInputFilterComponentCandidates,
    controllerDependencies,
  ] = await Promise.all([
    masterControllerCandidatesPromise,
    userInputFilterComponentCandidatesPromise,
    controllerDependenciesPromise,
  ])

  const controllers = masterControllerCandidates.map(controller => ({
    ...controller,

    disabled:
      controller.disabled ||
      controllerDependencies.some(isEqual(controller.controllerRef)),
  }))

  const components = userInputFilterComponentCandidates.map(
    ({ componentRef, componentType, disabled }) => ({
      componentRef,
      componentType,
      disabled: disabled || controllerDependencies.some(isEqual(componentRef)),
    }),
  )
  return { controllers, components }
}

export { getConnectableComponents }
