import { dynamicPageCustomRouterCreatePageClicked } from '@wix/bi-logger-platform/v2'
import { runTransactionWithRetry } from '@wix/wix-data-client-common/src/business-logic/runTransactionWithRetry'
import { addPattern } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/routerConfig'
import * as UNDO_REDO_LABELS from '@wix/wix-data-client-common/src/undoRedoLabels'
import { PAGE_TYPES } from '@wix/wix-data-client-common/src/business-data/dynamicPages'
import addDPLinkToSiteMenu from '../addDPLinkToSiteMenu'
import { isDynamicRoute } from '../isDynamicRoute'
import { getFedopsInteractionName } from '../getFedopsInteractionName'
import { addPageLinkField } from './addPageLinkField'
import { getDataForNewPage } from './getDataForNewPage'
import { withProgressBar } from './withProgressBar'
import { defaultPanelBiParams } from '../../../worker/defaultBiParams'

const duplicatePageAndConnectToRouter = async ({
  editorSdkProxy,
  pageRef,
  routerPrefix,
  route,
  routeDefinition,
  pageTitle,
}) => {
  const routerPromise = editorSdkProxy.routers.add({
    prefix: routerPrefix,
    config: addPattern({}, route, routeDefinition),
  })

  const duplicatePagePromise = editorSdkProxy.document.pages.duplicate({
    pageId: pageRef.id,
    shouldAddMenuItem: true, // ensures page appears in Pages Panel in case it was ever converted to static page
    shouldDuplicatePageCode: true,
  })

  const [routerRef, newPageRef] = await Promise.all([
    routerPromise,
    duplicatePagePromise,
  ])

  await Promise.all([
    editorSdkProxy.routers.pages.connect({
      routerRef,
      pageRef: newPageRef,
      pageRoles: [routeDefinition.pageRole],
    }),
    editorSdkProxy.pages.rename({ pageRef: newPageRef, title: pageTitle }),
  ])

  return { newPageRef }
}

const updatePagePanelPagesList = editorSdkProxy =>
  editorSdkProxy.document.application.reloadManifest()

const duplicatePage = async ({
  editorSdkProxy,
  pageRef,
  origin,
  collectionsApi,
  logger,
  arePageLinksCreatedOnBackend,
  i18n,
}) => {
  const { route, routerPrefix, routeDefinition, pageTitle, menuItemLabel } =
    await getDataForNewPage({ editorSdkProxy, i18n, pageRef })

  const collectionId = routeDefinition.config.collection

  const result = await runTransactionWithRetry(
    editorSdkProxy,
    () =>
      duplicatePageAndConnectToRouter({
        editorSdkProxy,
        pageRef,
        routerPrefix,
        route,
        routeDefinition,
        pageTitle,
      }),
    true,
  )

  await updatePagePanelPagesList(editorSdkProxy)

  const navigateToPagePromise = editorSdkProxy.pages.navigateTo({
    pageRef: result.newPageRef,
  })

  const addPageLinkPromise = addPageLinkField({
    collectionsApi,
    routerPrefix,
    route,
    collectionId,
    pageId: result.newPageRef.id,
    arePageLinksCreatedOnBackend,
    displayName: pageTitle,
  })

  await Promise.all([navigateToPagePromise, addPageLinkPromise])

  const isStaticRoute = !isDynamicRoute({ route })

  if (isStaticRoute) {
    addDPLinkToSiteMenu({
      editorSdkProxy,
      pageRef: result.newPageRef,
      innerRoute: route,
      label: menuItemLabel,
    })
  }

  const addHistoryLabel = editorSdkProxy.history.add({
    label: UNDO_REDO_LABELS.ADD_DATA_BINDING_URL,
  })

  const [{ displayName }] = await Promise.all([
    collectionsApi.get(collectionId),
    addHistoryLabel,
  ])

  logger.bi(
    dynamicPageCustomRouterCreatePageClicked({
      ...defaultPanelBiParams,
      page_type: isStaticRoute ? PAGE_TYPES.COLLECTION : PAGE_TYPES.ITEM,
      collection_id: collectionId,
      collection_name: displayName,
      new_url: `${routerPrefix}${route}`,
      is_auto_bound: null,
      new_collection: false,
      page_ids: result.newPageRef.id,
      action_type: 'duplicate_dynamic_page',
      origin,
    }),
  )

  return result
}

export const duplicateDynamicPage = async ({
  pageRef,
  origin,
  editorSdkProxy,
  collectionsApi,
  logger,
  i18n,
  editorType,
  fedopsLogger,
  arePageLinksCreatedOnBackend,
}) => {
  const fedopsInteractionName =
    getFedopsInteractionName(editorType)('duplicate')

  fedopsLogger.interactionStarted(fedopsInteractionName)

  const { newPageRef } = await withProgressBar({ editorSdkProxy, i18n }, () =>
    duplicatePage({
      arePageLinksCreatedOnBackend,
      editorSdkProxy,
      pageRef,
      origin,
      collectionsApi,
      logger,
      i18n,
    }),
  )

  fedopsLogger.interactionEnded(fedopsInteractionName)

  await editorSdkProxy.editor.openPagesPanel({
    pageRef: newPageRef,
    renameEnabled: true,
  })
}
