import maybe from 'folktale/maybe'
import result from 'folktale/result'
import openDeletePagePanel from './delete-dynamic-page/openDeletePagePanel'
import openDisconnectPagePanel from './delete-dynamic-page/openDisconnectPagePanel'
import {
  getRouterCollectionIdsFromNamespace,
  getFirstRouterCollectionId,
} from '../worker/utils/routerCollections'
import openAddDynamicPagePanel from './openAddDynamicPagePanel'
import { isWriteAllowed } from '@wix/wix-data-client-common/src/business-logic/collections/schemaQueries'
import { updatePageLinkFieldTitle } from '../business-logic/dynamic-pages/updatePageLinkFieldTitle'
import { getPatternDefForPage } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/routerConfig'
import { getCollection } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/patternDef'
import openDpErrorPanelForRemovedCollection from './openDpErrorPanelForRemovedCollection'
import openDpErrorPanelForRemovedDbDriver from './openDpErrorPanelForRemovedDbDriver'
import { ensureDataModeIsEnabled } from './enableDataMode'
import { createDPErrorHandler } from './utils/createDPErrorHandler'

const getPayloadProp = (eventPayload, prop) =>
  maybe.fromNullable(eventPayload && eventPayload[prop])

const isDbDriver = collectionId => collectionId.includes('/')

const getNamespace = collectionId => collectionId.split('/')[0]

export default async ({
  appApi,
  logger,
  experimentsManager,
  biLogger,
  editorSdkProxy,
  i18n,
  collectionsApi,
  WixDataSettings,
  safeUserPreferences,
  fedopsLogger,
  httpClient,
}) => {
  const isSiteWithoutCollections = async function () {
    const collections = await collectionsApi.getAll()
    return collections.length === 0
  }

  const openAutoCMSForDynamicPage = async ({
    pageRef,
    newItem = false,
    origin,
  }) => {
    const routerRef = await editorSdkProxy.document.routers.getByPage({
      pageRef,
    })
    const router = await editorSdkProxy.routers.get({ routerRef })
    const { def: patternDef } = getPatternDefForPage(
      router.config,
      router.pages,
      pageRef,
    )
    const collectionId = getCollection(patternDef)

    newItem
      ? appApi.openAutoCmsNewItemView({
          collectionId,
          origin,
        })
      : appApi.openAutoCmsCollectionView({
          collectionId,
          origin,
        })
  }

  const isCollectionDeleted = async collectionId =>
    (await collectionsApi.getAll({ includeHiddenAppCollections: true })).every(
      s => s.id !== collectionId || s.isDeleted,
    )

  return async function ({ eventType, eventPayload }) {
    const getPropResult = prop =>
      getPayloadProp(eventPayload, prop).matchWith({
        Nothing: () =>
          result.Error(`[${eventType}] eventPayload is missing ${prop}`),
        Just: ({ value }) => result.Ok(value),
      })
    const { pageRef, origin } = eventPayload || {}

    const withDPErrorHandler = createDPErrorHandler({
      i18n,
      logger,
      editorSdkProxy,
      experimentsManager,
    })

    switch (eventType) {
      case 'openAddPreset':
        await withDPErrorHandler(async () => {
          const { enableDataModeTriggered } = await ensureDataModeIsEnabled({
            editorSdkProxy,
            WixDataSettings,
            experimentsManager,
            safeUserPreferences,
            appApi,
            logger,
            httpClient,
          })

          const isFirstTimeExperience = enableDataModeTriggered

          appApi.openAddDynamicPagePanelV4({
            origin: eventPayload.origin,
            isFirstTimeExperience,
          })
        })

        break

      case 'addBlankDynamicPageClicked': {
        appApi.addBlankDynamicPage({
          collectionId: eventPayload.collectionId || eventPayload.groupId,
          origin,
          panelName: 'NEW_DYNAMIC_PAGE',
        })

        break
      }

      case 'addDefaultDynamicItemPage':
        appApi.addDefaultDynamicItemPage({
          collectionId: eventPayload.groupId || eventPayload.collectionId,
          origin,
        })

        break

      case 'addDefaultDynamicListPage':
        appApi.addDefaultDynamicListPage({
          collectionId: eventPayload.groupId || eventPayload.collectionId,
          origin,
        })

        break

      case 'addDynamicPageClicked': {
        const { routerRef } = eventPayload
        let collectionId
        let namespace

        if (routerRef) {
          const firstRouterCollectionId = await getFirstRouterCollectionId({
            editorSdkProxy,
            routerRef,
          })
          const collectionIsDeleted = await isCollectionDeleted(
            firstRouterCollectionId,
          )

          if (isDbDriver(firstRouterCollectionId)) {
            namespace = getNamespace(firstRouterCollectionId)

            if (collectionIsDeleted) {
              return openDpErrorPanelForRemovedDbDriver({
                editorSdkProxy,
                i18n,
                logger,
                namespace,
              })
            }
          } else {
            collectionId = firstRouterCollectionId

            if (collectionIsDeleted) {
              return openDpErrorPanelForRemovedCollection({
                editorSdkProxy,
                i18n,
                logger,
                collectionId: firstRouterCollectionId,
              })
            }
          }
        } else {
          collectionId = eventPayload.collectionId || eventPayload.groupId
        }

        const introOrigins = [
          'pagesPanel',
          'responsivePagesPanel',
          'pagesPanel/QuickAddMenu',
          'panel/pages',
        ]
        const originatesFromDynamicPagesIntro =
          introOrigins.includes(origin) && !collectionId

        const { enableDataModeTriggered } = await ensureDataModeIsEnabled({
          editorSdkProxy,
          WixDataSettings,
          experimentsManager,
          safeUserPreferences,
          appApi,
          logger,
          httpClient,
        })

        const isFirstTimeExperience = enableDataModeTriggered

        await openAddDynamicPagePanel({
          origin,
          pageRef,
          namespace,
          collectionId,
          editorSdkProxy,
          appApi,
          collectionsApi,
          logger,
          i18n,
          originatesFromDynamicPagesIntro,
          isSiteWithoutCollections,
          isFirstTimeExperience,
          experimentsManager,
        })

        break
      }

      case 'deleteDynamicPageClicked':
        openDeletePagePanel(
          {
            editorSdkProxy,
            logger,
            appApi,
            collectionsApi,
            i18n,
            biLogger,
            fedopsLogger,
            isCollectionDeleted,
          },
          eventPayload,
        )
        break

      case 'disconnectPageFromRouterClicked': {
        const canAddStaticPages = await editorSdkProxy.editor.canAddStaticPage()
        canAddStaticPages
          ? await openDisconnectPagePanel(
              {
                editorSdkProxy,
                collectionsApi,
                logger,
                appApi,
                i18n,
                biLogger,
                fedopsLogger,
                isCollectionDeleted,
              },
              eventPayload,
            )
          : editorSdkProxy.editor.openConfirmationPanel({
              headerText: i18n.t(
                'Dynamic_Pages_Remove_Connections_100_Pages_Warning_Title',
              ),
              descriptionText: i18n.t(
                'Dynamic_Pages_Remove_Connections_100_Pages_Warning_Description',
              ),
              mainActionText: i18n.t(
                'Dynamic_Pages_Remove_Connections_100_Pages_Warning_Button_Text',
              ),
              shouldShowIllustration: true,
            })
        break
      }
      case 'removeRouterClicked':
        getPropResult('routerRef').matchWith({
          Error: ({ value: error }) => {
            logger.info(`${error}, cannot remove router`)
          },
          Ok: ({ value: routerRef }) => {
            editorSdkProxy.routers.remove({ routerRef }).catch(logger.error)
          },
        })
        break
      case 'pageRenamed': {
        const { title } = eventPayload

        updatePageLinkFieldTitle({
          pageRef,
          pageTitle: title,
          editorSdkProxy,
          collectionsApi,
        })

        break
      }
      case 'addNewItemClicked':
        openAutoCMSForDynamicPage({
          newItem: true,
          ...eventPayload,
        })
        break
      case 'manageContentClicked':
        openAutoCMSForDynamicPage(eventPayload)
        break

      case 'duplicateDynamicPage':
        await appApi.duplicateDynamicPage({
          pageRef: eventPayload.pageRef,
          origin: eventPayload.origin,
        })
        break
      case 'addNewRouterItemClicked': {
        const { routerRef } = eventPayload
        const collectionId = await getFirstRouterCollectionId({
          editorSdkProxy,
          routerRef,
        })

        if (isDbDriver(collectionId)) {
          const collectionIdsForNamespace =
            await getRouterCollectionIdsFromNamespace({
              editorSdkProxy,
              namespace: getNamespace(collectionId),
            })

          if (collectionIdsForNamespace.length > 1) {
            await appApi.openAutoCmsCollectionsList({ origin })
            break
          }
        }

        const collectionIsDeleted = await isCollectionDeleted(collectionId)
        if (collectionIsDeleted) {
          await appApi.openAutoCmsCollectionView({
            collectionId,
            origin,
          })
          break
        }

        const schema = await collectionsApi.get(collectionId)
        if (isWriteAllowed(schema)) {
          await appApi.openAutoCmsNewItemView({
            collectionId,
            origin,
          })
        } else {
          await appApi.openAutoCmsCollectionView({
            collectionId,
            origin,
          })
        }

        break
      }
      case 'openContentManager': {
        const { groupId, origin } = eventPayload

        await appApi.openAutoCmsCollectionView({
          collectionId: groupId,
          origin,
        })

        break
      }
      case 'openPresetScanningPanel':
        return appApi.openPresetScanningPanel({
          pageRef: eventPayload.pageRef,
        })
    }
  }
}
