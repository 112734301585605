import { DPErrorCodes, DYNAMIC_PAGES_PER_COLLECTION_LIMIT } from './constants'

export const DPErrorPanelData = {
  [DPErrorCodes.TOO_MANY_DYNAMIC_PAGES_FOR_COLLECTION]: ({ t }) => ({
    title: t(
      'Workspace_Dynamic_Page_Error_Too_Many_Dynamic_Pages_For_Collection_Title',
    ),
    text: t(
      'Workspace_Dynamic_Page_Error_Too_Many_Dynamic_Pages_For_Collection_Text',
      {
        dynamic_page_limit: DYNAMIC_PAGES_PER_COLLECTION_LIMIT,
      },
    ),
    buttonLabel: t(
      'Workspace_Dynamic_Page_Error_Too_Many_Dynamic_Pages_For_Collection_Button',
    ),
  }),
  [DPErrorCodes.NO_FIELD_KEY_IN_DB_DRIVER_COLLECTION]: ({ t }) => ({
    title: t('Workspace_Dynamic_Page_With_DB_Driver_Collection_Error_Title'),
    text: t('Workspace_Dynamic_Page_With_DB_Driver_Collection_Error_Text'),
    buttonLabel: t(
      'Workspace_Dynamic_Page_With_DB_Driver_Collection_Error_Button',
    ),
    helpId: '28d400bb-9d4c-4241-8121-a3ace7705849',
  }),
}

export class DynamicPageError extends Error {
  constructor(code, ...params) {
    super(...params)
    this.name = 'DynamicPageError'
    this.code = code
    this.__proto__ = DynamicPageError.prototype // eslint-disable-line
  }
}

export class InvalidCharsInPrefixError extends Error {
  constructor(...params) {
    super(...params)
    this.__proto__ = InvalidCharsInPrefixError.prototype // eslint-disable-line
  }
}

export class DBDriverDynamicPageError extends Error {
  constructor(code, ...params) {
    super(...params)
    this.name = 'DBDriverDynamicPageError'
    this.code = code
    this.__proto__ = DBDriverDynamicPageError.prototype // eslint-disable-line
  }
}
