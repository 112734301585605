import { mapValues } from 'lodash'
import {
  isOwnerField,
  isSystemField,
} from '@wix/wix-data-client-common/src/business-logic/fields/fieldQueries'
import { FieldType } from '@wix/wix-data-schema-types'
import { isResponsiveEditor } from '@wix/wix-data-client-common/src/infra/editorType'
import { SEO_FIELD_TYPES } from '../../constants'
import { DEFAULT_COLLECTION_FIELDS as fieldsInClassic } from '../../../presets/classic/predefinedCollection'
import { DEFAULT_COLLECTION_FIELDS as fieldsInResponsive } from '../../../presets/responsive/predefinedCollection'

const getSeoDescription = ({ fields, title, responsiveEditor }) => {
  const descriptionFieldName = responsiveEditor
    ? fieldsInResponsive.SHORT_DESCRIPTION.fieldKey
    : fieldsInClassic.DESCRIPTION.fieldKey

  const candidateFields = fields
    .filter(
      ({ name, type }) =>
        name !== title && SEO_FIELD_TYPES.description.includes(type),
    )
    .map(({ name }) => name)

  return candidateFields.includes(descriptionFieldName)
    ? descriptionFieldName
    : candidateFields[0]
}

const getSeoImage = ({ fields, responsiveEditor }) => {
  const imageFieldName = responsiveEditor
    ? fieldsInResponsive.IMAGE.fieldKey
    : fieldsInClassic.IMAGE.fieldKey

  const imageFields = fields.filter(({ type }) => type === FieldType.image)
  const image =
    imageFields.find(({ name }) => name === imageFieldName) || imageFields[0]
  return image && image.name
}

export const getSeoValues = ({ collection, editorType, title }) => {
  const unsortedFields = Object.entries(collection.fields)
    .map(([name, fieldDef]) => ({ ...fieldDef, name }))
    .filter(field => !field.isDeleted && !isOwnerField(field))

  const userFields = unsortedFields.filter(field => !isSystemField(field))
  const systemFields = unsortedFields.filter(isSystemField)
  const fields = [...userFields, ...systemFields]

  const responsiveEditor = isResponsiveEditor(editorType)

  const description = getSeoDescription({ fields, title, responsiveEditor })

  const image = getSeoImage({ fields, responsiveEditor })

  return mapValues({ description, image }, value => value && `{${value}}`)
}
