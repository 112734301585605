import { NEW_DYNAMIC_PAGE_FLOWS } from '../../business-logic/dynamic-pages/constants'
import openConvertToDynamicPagePanel from './openPanel'

export default ({ hostname, editorSdkProxy, i18n, appApi }) =>
  async ({ origin, pageRef }) => {
    const panelResult = await openConvertToDynamicPagePanel(
      {
        hostname,
        editorSdkProxy,
        i18n,
      },
      {
        origin,
        pageRef,
      },
    )

    const actions = {
      [NEW_DYNAMIC_PAGE_FLOWS.CONVERT_STATIC_TO_DYNAMIC_FROM_SCRATCH]:
        appApi.convertStaticToDynamicFromScratch,
      [NEW_DYNAMIC_PAGE_FLOWS.CONVERT_STATIC_TO_DYNAMIC_FROM_COLLECTION]:
        appApi.convertStaticToDynamicFromCollection,
    }

    const action = panelResult && actions[panelResult.type]

    return action && action(panelResult.payload)
  }
