import { flow } from 'lodash'
import { wrapWithBreadcrumbs } from '@wix/wix-code-editor-sdk-proxy/src/wrapWithBreadcrumbs'
import withDataFixers from '@wix/wix-code-editor-sdk-proxy/src/wrapWithDataFixers'
import connectionConfigsFixers from '@wix/dbsm-common/src/data-fixers/connection-config-fixers'

const initEditorSDK = (logger, editorSDK) =>
  flow(
    tempEditorSDK =>
      wrapWithBreadcrumbs({
        editorSDK: tempEditorSDK,
        onBreadcrumb: bc => logger.breadcrumb(bc),
      }),
    tempEditorSDK =>
      withDataFixers(tempEditorSDK, {
        connectionConfigsFixers,
      }),
  )(editorSDK)

export const init = initEditorSDK
