import deepFreeze from 'deep-freeze'

export default ({ wixDataSchemasClient }) => {
  let cachedSchemasById = null

  const updateCache = updatedSchemas => {
    if (cachedSchemasById) {
      updatedSchemas.forEach(schema => cachedSchemasById.set(schema.id, schema))
    }
  }
  const clearCache = () => {
    cachedSchemasById = null
  }

  const listSchemas = async ({ includeDeletedCollections }) => {
    if (!cachedSchemasById) {
      const schemasById = await wixDataSchemasClient.list({
        includeDeletedCollections: true,
      })
      Object.values(schemasById).map(deepFreeze)
      cachedSchemasById = new Map(Object.entries(schemasById))
    }

    const schemas = Array.from(cachedSchemasById.values())

    return includeDeletedCollections
      ? schemas
      : schemas.filter(schema => !schema.isDeleted)
  }

  const loadSchema = async collectionId => {
    if (!cachedSchemasById || !cachedSchemasById.get(collectionId)) {
      /*
        Having no cache here means one of the following:

        1. something called loadSchema very early in Databinding app bootstrap phase, before initial listSchemas() response is received

        2. cache was cleared externally (due to schema change in backend, external DB Driver being added, etc)

        Both of these flows imply that full cache refresh is in progress, that is a listSchemas() request is pending and cache will be re-populated soon
      */
      return wixDataSchemasClient.get(collectionId, {
        includeDeletedCollections: true,
      })
    }

    return cachedSchemasById.get(collectionId)
  }

  const saveSchema = async (collectionId, schema) => {
    const updatedSchemas = await wixDataSchemasClient.save(collectionId, schema)
    updateCache(updatedSchemas)
  }

  const removeSchema = async collectionId => {
    const updatedSchemas = await wixDataSchemasClient.remove(collectionId)
    updateCache(updatedSchemas)
  }

  return {
    listSchemas,
    loadSchema,
    saveSchema,
    removeSchema,
    clearCache,
  }
}
