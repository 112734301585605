export default () => {
  let isPolling
  let pollsLeft
  let concurrentCalls

  const reset = () => {
    isPolling = false
    concurrentCalls = 0
  }

  reset()

  const callFunctionWhenValueChanges = ({
    pollInterval,
    pollTimes,
    getValue,
    fn,
    callBackAfter = setTimeout,
    isEqual,
  }) => {
    pollsLeft = pollTimes
    concurrentCalls++
    if (isPolling) {
      return
    }
    isPolling = true

    const poll = initialValue => {
      if (pollsLeft === 0 || concurrentCalls === 0) {
        reset()
        return
      }
      pollsLeft--
      callBackAfter(
        () =>
          getValue().then(value => {
            if (!isEqual(value, initialValue)) {
              fn()
              concurrentCalls--
            }
            poll(value)
          }),
        pollInterval,
      )
    }

    getValue().then(poll)
  }

  return callFunctionWhenValueChanges
}
