'use strict';

class AssertionError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

function assertDefined(target, message) {
  if (target === undefined) {
    throw new AssertionError(message);
  }
}

function assertObject(target, message) {
  if (target !== undefined && (typeof target !== 'object' || Array.isArray(target) || target === null)) {
    throw new AssertionError(message);
  }
}

function assertOk(target, message) {
  if (!target) {
    throw new AssertionError(message);
  }
}

function assertFunc(target, message) {
  if (target !== undefined && typeof target !== 'function') {
    throw new AssertionError(message);
  }
}

function assertBoolean(target, message) {
  if (target !== undefined && typeof target !== 'boolean') {
    throw new AssertionError(message);
  }
}

function assertNumber(target, message) {
  if (target !== undefined && typeof target !== 'number') {
    throw new AssertionError(message);
  }
}

function assertArray(target, message) {
  if (typeof Array.isArray === 'function') {
    if (!Array.isArray(target)) {
      throw new AssertionError(message);
    }
  } else if (Object.prototype.toString.call(target) !== '[object Array]') {
    throw new AssertionError(message);
  }
}

module.exports.defined = assertDefined;
module.exports.object = assertObject;
module.exports.ok = assertOk;
module.exports.func = assertFunc;
module.exports.boolean = assertBoolean;
module.exports.number = assertNumber;
module.exports.array = assertArray;
module.exports.AssertionError = AssertionError;
