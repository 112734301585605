import { FieldType } from '@wix/wix-data-schema-types'
import { IMAGE_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const {
  text,
  dateTime,
  number,
  url,
  stringArray,
  document,
  video,
  address,
  image,
} = FieldType

export default ({ i18n }) => ({
  componentName: 'Element_Name_Image',
  isConnectGfppEnabled: true,
  panel: {
    height: 528,
    title: 'CONNECT_PANEL_TITLE_IMAGE',
    helpId: 'd7cd3e7b-6702-4613-9197-bf580cd560d9',
  },
  sections: [
    {
      role: IMAGE_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Image_Label',
          prop: 'src',
          supportedFieldTypes: [image],
          defaultFieldType: image,
          supportsReferenceFields: true,
          requiresPrimaryConnection: true,
        },
        {
          title: 'Connect_Panel_Connect_Alttext_Label',
          prop: 'alt',
          supportedFieldTypes: [text, number, dateTime, address, stringArray],
          defaultFieldType: text,
          supportsReferenceFields: true,
        },
        {
          title: 'Connect_Panel_Connect_Tooltip_Label',
          prop: 'tooltip',
          supportedFieldTypes: [text, number, dateTime, address, stringArray],
          defaultFieldType: text,
          supportsReferenceFields: true,
        },
        {
          title: 'Connect_Panel_Connect_Link_Label',
          prop: 'link',
          supportedFieldTypes: [document, url, image, video],
          defaultFieldType: url,
          event: 'onClick',
          supportPageLinks: true,
          supportsActions: true,
          supportsReferenceFields: true,
          supportsPostSubmit: true,
        },
      ],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'image_live_preview_gfpp_desktop_main_action',
              label: 'Image_Live_Preview_GFPP_Desktop_Main_Action',
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Image_Manage_Content_Panel_Description',
      },
    },
  ],
})
