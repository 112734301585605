import { FieldType } from '@wix/wix-data-schema-types'
import { SIGNATURE_INPUT_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { image } = FieldType

export default () => ({
  componentName: 'Element_Name_Signature_Input',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Signature_Input',
    helpId: 'bb28abbf-3f69-4209-8097-9e9863c92215',
  },
  sections: [
    {
      role: SIGNATURE_INPUT_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Value_Label',
          prop: 'value',
          supportedFieldTypes: [image],
          defaultFieldType: image,
          isInputProperty: true,
        },
      ],
    },
  ],
  compactErrorPanel: true,
})
