import { FieldType } from '@wix/wix-data-schema-types'

export const DEFAULT_COLLECTION_FIELDS = {
  IMAGE: {
    fieldKey: 'image',
    displayName: 'Image',
    type: FieldType.image,
  },
  DESCRIPTION: {
    fieldKey: 'subtitle',
    displayName: 'Subtitle',
    type: FieldType.text,
  },
  TITLE: {
    fieldKey: 'title',
    displayName: 'Title',
    type: FieldType.text,
  },
  LONG_DESCRIPTION: {
    fieldKey: 'itemPageText',
    displayName: 'Item Page Text',
    type: FieldType.text,
  },
  ALT_TEXT: {
    fieldKey: 'altText',
    displayName: 'Alt Text',
    type: FieldType.text,
  },
}
