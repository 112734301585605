import {
  findSupportedFieldTypes,
  getCandidateFields,
  findRightFieldKey,
} from '../utils'
import { DEFAULT_COLLECTION_FIELDS } from './predefinedCollection'
const { IMAGE, DESCRIPTION, LONG_DESCRIPTION, TITLE, ALT_TEXT } =
  DEFAULT_COLLECTION_FIELDS

const getImageFieldKey = fields => findRightFieldKey(fields, IMAGE)

const getTitleFieldKey = fields =>
  findRightFieldKey(fields, TITLE, [DESCRIPTION.fieldKey])

const getDescriptionFieldKey = fields => findRightFieldKey(fields, DESCRIPTION)

const getLongDescriptionFieldKey = fields =>
  findRightFieldKey(fields, LONG_DESCRIPTION)

const getAltTextFieldKey = fields => findRightFieldKey(fields, ALT_TEXT)

export const getFieldKeysForPreset = ({ fields }) => {
  const supportedFieldTypes = findSupportedFieldTypes(DEFAULT_COLLECTION_FIELDS)

  const candidateFields = getCandidateFields({
    fields,
    supportedFieldTypes,
  })

  const imageFieldKey = getImageFieldKey(candidateFields)

  const titleFieldKey = getTitleFieldKey(candidateFields)

  const descriptionCandidateFields = candidateFields.filter(
    ([key]) => key !== titleFieldKey,
  )
  const descriptionFieldKey = getDescriptionFieldKey(descriptionCandidateFields)

  const longDescriptionCandidateFields = descriptionCandidateFields.filter(
    ([key]) => key !== descriptionFieldKey,
  )

  const longDescriptionFieldKey = getLongDescriptionFieldKey(
    longDescriptionCandidateFields,
  )

  const altTextFieldKey = getAltTextFieldKey(
    longDescriptionCandidateFields.filter(
      ([key]) => key !== longDescriptionFieldKey,
    ),
  )

  return {
    imageFieldKey,
    titleFieldKey,
    descriptionFieldKey,
    longDescriptionFieldKey,
    altTextFieldKey,
  }
}
