import isFieldAllowedInRoutingUrl from './isFieldAllowedInRoutingUrl'

const getFieldKeyForUrl = ({
  collectionFields,
  displayField,
  isTPACollection,
}) => {
  const schema = { fields: collectionFields, isTPACollection }
  if (isFieldAllowedInRoutingUrl(schema, displayField)) {
    return displayField
  }
  const field = collectionFields.find(
    ({ name, isDeleted }) =>
      !isDeleted && isFieldAllowedInRoutingUrl(schema, name),
  )

  return field && field.name
}

export default getFieldKeyForUrl
