import { PAGE_TYPES } from '@wix/wix-data-client-common/src/business-data/dynamicPages'
import { createBlankPresetFieldMap } from '../../../presets/blankPresetFieldMap'
import {
  modifyBlankPresetPageDefinition,
  modifyRegularPresetPageDefinition,
} from './dataFixers'
import { installAppsInPage } from '../../appInstallation'

const getRouterDatasetName = ({ pageType, collectionName }) =>
  pageType === PAGE_TYPES.ITEM ? `${collectionName} Item` : collectionName

export const applyDataFixers = async ({
  page,
  pageType,
  presetId,
  editorType,
  fields,
  itemPageLinkFieldKey,
  indexPageLinkFieldKey,
  editorSdkProxy,
}) => {
  switch (presetId) {
    case 'blank':
      return {
        createPreset: ({ collectionName }) => {
          const routerDatasetName = getRouterDatasetName({
            pageType,
            collectionName,
          })

          const fieldMap = createBlankPresetFieldMap({
            editorType,
            fields,
            itemPageLinkFieldKey,
            indexPageLinkFieldKey,
          })

          return modifyBlankPresetPageDefinition({
            initialDefinition: page.definition,
            fieldMap,
            routerDatasetName,
          })
        },
      }

    default: {
      const installedAppIds = await installAppsInPage(editorSdkProxy, page)
      return {
        createPreset: ({ collectionName, routerId }) => {
          const routerDatasetName = getRouterDatasetName({
            pageType,
            collectionName,
          })

          const pageLinkFieldKey =
            pageType === PAGE_TYPES.ITEM
              ? indexPageLinkFieldKey
              : itemPageLinkFieldKey

          return modifyRegularPresetPageDefinition({
            initialDefinition: page.definition,
            pageLinkFieldKey,
            routerDatasetName,
            routerId,
            installedAppIds,
          })
        },
        installedAppIds,
      }
    }
  }
}
