import { get, has, filter, cloneDeep } from 'lodash'
import crawl from 'tree-crawl'
import { viewerTypes } from '@wix/dbsm-common/src/componentTypes'

const getComponents = component => component.components || []

const getMobileComponents = component =>
  component.mobileComponents || component.components || []

const traverse = (root, modifiers) => {
  const iteratee = (...args) => modifiers.forEach(fn => fn(...args))

  crawl(root, iteratee, { getChildren: getComponents })

  if (root.mobileComponents.length) {
    crawl(root, iteratee, { getChildren: getMobileComponents })
  }
}

const modifyPageDefinition = ({ initialDefinition, modifiers }) => {
  const definition = cloneDeep(initialDefinition)

  traverse(definition, modifiers)

  return definition
}

const isRouterDataset = component =>
  component.componentType === 'platform.components.AppController' &&
  get(component, 'data.controllerType') === 'router_dataset'

const isRouterDatasetWithCustomConnections = component =>
  isRouterDataset(component) && has(component, 'custom.relatedConnections')

const isConnectedComponent = component =>
  has(component, 'connections.items[1].config')

const requiresNonEmptyField = key => ['$text', 'src'].includes(key)

const forEachRelatedConnection = (controllerComponent, fn) => {
  Object.entries(controllerComponent.custom.relatedConnections).forEach(
    ([componentId, [connection]]) => {
      fn({ componentId, connection })
    },
  )
}

const createIsNonEmptyFieldFn = fieldMap => property =>
  Boolean(fieldMap[property.fieldName])

const hasNecessaryFields = (fieldMap, properties) => {
  const isNonEmptyField = createIsNonEmptyFieldFn(fieldMap)

  const requiredFields = filter(properties, (property, key) =>
    requiresNonEmptyField(key),
  )

  return requiredFields.every(isNonEmptyField)
}

const hasAtLeastOneNonEmptyField = (fieldMap, properties) => {
  const isNonEmptyField = createIsNonEmptyFieldFn(fieldMap)

  return filter(properties, isNonEmptyField).length >= 1
}

const removeComponent = context => {
  context.parent.components.splice(context.index, 1)
  context.remove()
}

const updateFieldNames = (fieldMap, properties) =>
  Object.keys(properties).forEach(key => {
    const property = properties[key]
    const newFieldName = fieldMap[property.fieldName]
    if (newFieldName) {
      property.fieldName = newFieldName
    } else {
      delete properties[key]
    }
  })

const fixComponentConnection = (fieldMap, component, context) => {
  const config = JSON.parse(component.connections.items[1].config)

  if (config.properties) {
    if (hasNecessaryFields(fieldMap, config.properties)) {
      if (hasAtLeastOneNonEmptyField(fieldMap, config.properties)) {
        updateFieldNames(fieldMap, config.properties)
        component.connections.items[1].config = JSON.stringify(config)
      } else {
        component.connections.items.pop()
      }
    } else {
      removeComponent(context)
    }
  }
}

const fixControllerConnections = (fieldMap, component) => {
  forEachRelatedConnection(component, ({ componentId, connection }) => {
    if (has(connection, 'config.properties')) {
      const properties = connection.config.properties
      if (
        hasAtLeastOneNonEmptyField(fieldMap, properties) &&
        hasNecessaryFields(fieldMap, properties)
      ) {
        updateFieldNames(fieldMap, properties)
      } else {
        delete component.custom.relatedConnections[componentId]
      }
    }
  })
}

const fixConnections = fieldMap => (component, context) => {
  if (isRouterDatasetWithCustomConnections(component)) {
    fixControllerConnections(fieldMap, component)
  }

  if (isConnectedComponent(component)) {
    fixComponentConnection(fieldMap, component, context)
  }
}

const fixLinks = pageLinkFieldKey => component => {
  if (isRouterDatasetWithCustomConnections(component)) {
    forEachRelatedConnection(component, ({ connection }) => {
      if (has(connection, 'config.properties.link')) {
        connection.config.properties.link.fieldName = pageLinkFieldKey
      }
    })
  }

  if (isConnectedComponent(component)) {
    const config = JSON.parse(component.connections.items[1].config)

    if (has(config, 'properties.link')) {
      config.properties.link.fieldName = pageLinkFieldKey
      component.connections.items[1].config = JSON.stringify(config)
    }
  }
}

const updateInstalledAppIds = installedAppIds => component => {
  if (component.componentType === viewerTypes.TPAWidget) {
    const applicationId = installedAppIds.get(component.data.appDefinitionId)
    component.data.applicationId = String(applicationId)
  }
}

const updateRouterDatasetName = datasetName => component => {
  if (isRouterDataset(component)) {
    component.data.name = datasetName
  }
}

const setCorrectRouterId = ({ page, routerId }) => {
  const stringifiedPage = JSON.stringify(page)
  const pageWithUpdatedRouterId = stringifiedPage.replace(
    /"routerId":"[^"]+"/g,
    `"routerId":"${routerId}"`,
  )
  return JSON.parse(pageWithUpdatedRouterId)
}

export const modifyBlankPresetPageDefinition = ({
  initialDefinition,
  fieldMap,
  routerDatasetName,
}) => {
  const modifiers = [
    updateRouterDatasetName(routerDatasetName),
    fixConnections(fieldMap),
  ]

  return modifyPageDefinition({ initialDefinition, modifiers })
}

export const modifyRegularPresetPageDefinition = ({
  initialDefinition,
  pageLinkFieldKey,
  routerDatasetName,
  routerId,
  installedAppIds,
}) => {
  const definitionWithFixedRouterId = setCorrectRouterId({
    page: initialDefinition,
    routerId,
  })

  const modifiers = [
    updateRouterDatasetName(routerDatasetName),
    fixLinks(pageLinkFieldKey),
    installedAppIds.size > 0 && updateInstalledAppIds(installedAppIds),
  ].filter(Boolean)

  return modifyPageDefinition({
    initialDefinition: definitionWithFixedRouterId,
    modifiers,
  })
}
