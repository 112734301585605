import { FieldType } from '@wix/wix-data-schema-types'
import { SLIDER_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { number } = FieldType

export default () => ({
  componentName: 'Element_Name_Slider',
  isConnectGfppEnabled: true,
  panel: {
    height: 212,
    title: 'Connect_Panel_Title_Slider',
    helpId: 'b8ec278c-78a9-463f-b3ff-9112ed9e7e40',
  },
  sections: [
    {
      role: SLIDER_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Slider_Value_Label',
          prop: 'value',
          supportedFieldTypes: [number],
          defaultFieldType: number,
          isInputProperty: true,
        },
      ],
    },
  ],
  compactErrorPanel: true,
})
