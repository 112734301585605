/* global self */

import {
  APP_NAME,
  CLOUD_DATA_URL,
  LOCALE_FALLBACK_URL,
  LOCALE_FOLDER,
  EXPERIMENTS_HOST,
  EXPERIMENTS_SCOPE,
} from '@wix/wix-data-client-common/src/consts'

import i18nCreator from '@wix/wix-code-i18n/src/fetchCreator'
import createI18nInstance from '@wix/dbsm-common/src/worker/createI18nInstance'
import englishTranslations from '@wix/dbsm-locales/src/messages_en.json'
import { WixDataSettings } from '@wix/wix-data-settings-client'
import { WixDataConnectorSettings } from '@wix/wix-data-connector-settings-client'
import Experiments from '@wix/wix-experiments'
import { create as createFedopsLogger } from '@wix/fedops-logger'
import manifests from '@wix/wix-code-data-type-manifests'
import createWixData from '@wix/wix-data-client-common/src/infra/createWixData'
import { WixDataSchemas } from '@wix/wix-data-schemas-client'

import commonModule from './modules/common'
import coreModule from '@wix/dbsm-editor-worker/src/core-module'
import dataBindingModule from '@wix/wix-data-client-data-binding/src/worker'
import dynamicPagesModule from '@wix/wix-data-client-dynamic-pages/src/worker'
import schemaManagementModule from '@wix/wix-data-client-schema-management/src/worker'
import createWorker from '@wix/dbsm-editor-worker/src/worker'

const i18nInstance = createI18nInstance(
  i18nCreator,
  APP_NAME,
  LOCALE_FOLDER,
  LOCALE_FALLBACK_URL,
  englishTranslations,
)
const experimentsManager = new Experiments({
  scope: EXPERIMENTS_SCOPE,
  baseUrl: EXPERIMENTS_HOST,
})

const FEDOPS_ID = 'wix-code-data-binding-editor-worker'
const fedopsLogger = createFedopsLogger(FEDOPS_ID)
const cloudDataUrl = CLOUD_DATA_URL
const loadDataTypeManifests = () => Promise.resolve(manifests)

const createWixDataSchemas = ({ httpClient, gridAppId, instance }) =>
  new WixDataSchemas(httpClient, instance, gridAppId, {
    baseUrl: `${cloudDataUrl}/v1/schemas`,
  })

const worker = createWorker({
  hostname: self.location.host,
  i18nInstance,
  experimentsManager,
  fedopsLogger,
  i18nCreator,
  loadDataTypeManifests,
  WixDataSettings,
  WixDataConnectorSettings,
  cloudDataUrl,
  createWixData,
  createWixDataSchemas,
  moduleExports: [
    commonModule,
    coreModule,
    dataBindingModule,
    dynamicPagesModule,
    schemaManagementModule,
  ],
})

export const editorReady = worker.editorReady
export const exports = worker.exports
export const getAppManifest = worker.getAppManifest
export const getControllerPresets = worker.getControllerPresets
export const handleAction = worker.handleAction
export const onEvent = worker.onEvent
