import {
  LocalStorageCachedCapsule,
  WixStorageStrategy,
  InMemoryStorageCapsule,
} from 'data-capsule'

const isTestEnv = process.env.JEST_WORKER_ID

const createUserSettingsStorage = namespace =>
  isTestEnv
    ? InMemoryStorageCapsule({ namespace })
    : LocalStorageCachedCapsule({
        remoteStrategy: new WixStorageStrategy(),
        namespace,
      })

const storage = new WeakMap()

class UserSettingsStorage {
  constructor(namespace) {
    storage.set(this, createUserSettingsStorage(namespace))
  }

  getItem(...args) {
    return storage.get(this).getItem(...args)
  }

  setItem(...args) {
    return storage.get(this).setItem(...args)
  }

  async getAllItems(...args) {
    const allItems = await storage.get(this).getAllItems(...args)
    return allItems || {}
  }

  async clearAll() {
    const allItems = await this.getAllItems()
    await Promise.all(
      Object.keys(allItems).map(key => storage.get(this).removeItem(key)),
    )
  }
}

export const USER_SETTINGS_STORAGE_PREFIX = 'auto-cms'
export const userSettingsStorage = new UserSettingsStorage(
  USER_SETTINGS_STORAGE_PREFIX,
)
