/**
 * rejects original promise after specified timeout
 * @param {Promise<R>} promise Original promise
 * @param {string} [message] Additional message for timeout reject
 * @param {number} timeout Time for reject
 * @return {Promise<R>}
 */
module.exports.timedPromise = function (promise, {message, timeout}) {
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(reject, timeout, message ? `Timeout: ${message}` : 'Timeout');
  });
  return Promise.race([promise, timeoutPromise]);
};

/**
 * Works like Promise.all expect uses object insetead of array.
 * @param {Object} promiseObject
 * @return {Promise} Promise wiht original keys and resolved values
 */
module.exports.allAsObject = function (promiseObject) {
  const keys = Object.keys(promiseObject);
  return Promise.all(keys.map(key => promiseObject[key]))
    .then(resolved => {
      return resolved.reduce((acc, value, i) => {
        acc[keys[i]] = value;
        return acc;
      }, {});
    });
};



