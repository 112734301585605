import { isEmpty, flatten, get, isEqual, uniq, map } from 'lodash'
import { isFieldNested } from '@wix/wix-data-client-common/src/business-logic/fields/fieldQueries'
import {
  getFieldByFieldName,
  getNestedRootFieldName,
  getReferencedNestedRootFieldName,
} from '@wix/wix-data-client-common/src/business-logic/fields/fieldSelectors'
import { getReferenceFieldName } from '@wix/dbsm-common/src/reference-fields/fieldPath'
import { Binding } from '../bindings/Binding'
import { fetchDataset, updateConfig } from './datasetApi'
import { GRID_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'

const getAllControllerConnections = async function (
  editorSdkProxy,
  controllerRef,
) {
  const connectedComponents =
    await editorSdkProxy.controllers.listConnectedComponents({
      controllerRef,
    })
  const connections = await Promise.all(
    connectedComponents.map(async componentRef => {
      const connections = await editorSdkProxy.controllers.listConnections({
        componentRef,
      })
      return connections.map(connection => ({
        componentRef,
        ...connection,
      }))
    }),
  )

  return flatten(connections).filter(c =>
    isEqual(c.controllerRef, controllerRef),
  )
}

const getFieldPathsFromBindings = (bindings = []) =>
  bindings
    .filter(binding => Binding.Field.hasInstance(binding))
    .map(binding => binding.fieldPath)

const getDataPathsFromColumns = (columns = []) =>
  columns.map(column => column.dataPath)

const getLinkPathsFromColumns = (columns = []) =>
  columns.map(column => column.linkPath)

const getFieldPathsFromConnectionConfigsAndColumns =
  connectionConfigsAndColumns =>
    connectionConfigsAndColumns
      .flatMap(({ config: { properties = {} } = {}, columns = [] }) =>
        map(properties, ({ fieldName }) => fieldName)
          .concat(getDataPathsFromColumns(columns))
          .concat(getLinkPathsFromColumns(columns)),
      )
      .filter(Boolean)

const getNestedFieldRootKeys = (collection, fieldPaths = []) =>
  fieldPaths.reduce((fieldKeys, fieldPath) => {
    const field = getFieldByFieldName(collection.fields, fieldPath)

    return isFieldNested(field)
      ? field.referencingFieldLabel
        ? fieldKeys.concat([getReferencedNestedRootFieldName(fieldPath)])
        : fieldKeys.concat([getNestedRootFieldName(fieldPath)])
      : fieldKeys
  }, [])

const getAllFieldPaths = ({ oldConfigsAndColumns, newBindings, newColumns }) =>
  getFieldPathsFromConnectionConfigsAndColumns(oldConfigsAndColumns)
    .concat(getFieldPathsFromBindings(newBindings))
    .concat(getDataPathsFromColumns(newColumns))
    .concat(getLinkPathsFromColumns(newColumns))

const getDatasetNestedFields = async ({
  dataset,
  oldConfigsAndColumns,
  newBindings = [],
  newColumns = [],
  collection,
}) => {
  const oldNestedFieldKeys = get(dataset, 'config.nested') || []

  const fieldPaths = getAllFieldPaths({
    oldConfigsAndColumns,
    newBindings,
    newColumns,
  })

  const newNestedFieldKeys = uniq(
    getNestedFieldRootKeys(collection, fieldPaths),
  )

  return {
    oldNestedFieldKeys,
    newNestedFieldKeys,
  }
}

const getIncludes = (collection, fieldPaths = []) =>
  fieldPaths.reduce((includes, fieldPath) => {
    const field = getFieldByFieldName(collection.fields, fieldPath)

    return field && field.referencingFieldLabel
      ? includes.concat([getReferenceFieldName(fieldPath)])
      : includes
  }, [])

const getDatasetIncludes = async ({
  dataset,
  oldConfigsAndColumns,
  newBindings = [],
  newColumns = [],
  collection,
}) => {
  const oldIncludes = get(dataset, 'config.includes') || []

  const fieldPaths = getAllFieldPaths({
    oldConfigsAndColumns,
    newBindings,
    newColumns,
  })

  const newIncludes = uniq(getIncludes(collection, fieldPaths))

  return {
    oldIncludes,
    newIncludes,
  }
}

export default async ({
  editorSdkProxy,
  controllerRef,
  componentRef,
  newBindings = [],
  newColumns = [],
  livePreview,
  collections,
}) => {
  const [connectionConfigs, dataset] = await Promise.all([
    getAllControllerConnections(editorSdkProxy, controllerRef),
    fetchDataset(editorSdkProxy, controllerRef).catch(() => undefined),
  ])

  const datasetDoesNotExist = dataset === undefined
  if (datasetDoesNotExist) {
    return
  }

  const oldConfigsAndColumns = await Promise.all(
    connectionConfigs
      .filter(({ componentRef: { id } }) => id !== componentRef.id)
      .map(async ({ config, componentRef, role }) => {
        if (role === GRID_ROLE) {
          const properties = await editorSdkProxy.components.properties.get({
            componentRef,
          })
          return { config, columns: properties.columns }
        }
        return { config }
      }),
  )

  const collectionId = get(dataset, 'config.collectionId')
  const collection = collections.find(({ id }) => id === collectionId)

  const { oldIncludes, newIncludes } = await getDatasetIncludes({
    dataset,
    oldConfigsAndColumns,
    newBindings,
    newColumns,
    collection,
  })

  const { oldNestedFieldKeys, newNestedFieldKeys } =
    await getDatasetNestedFields({
      dataset,
      oldConfigsAndColumns,
      newBindings,
      newColumns,
      collection,
    })

  if (
    !isEqual(newIncludes, oldIncludes) ||
    !isEqual(newNestedFieldKeys, oldNestedFieldKeys)
  ) {
    await updateConfig(
      editorSdkProxy,
      controllerRef,
      {
        includes: isEmpty(newIncludes) ? null : newIncludes,
        nested: newNestedFieldKeys,
      },
      livePreview,
    )
  }
}
