export const getScreenDimensionsV2 = ({ developerMode }) => {
  return developerMode
    ? {
        width: '668',
        height: '500',
      }
    : {
        width: '668',
        height: '410',
      }
}
