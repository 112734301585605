import { filter } from 'lodash'
import { FieldType } from '@wix/wix-data-schema-types'

export default (referencedCollectionId, collections) =>
  collections.reduce((numberOfReferringFields, collection) => {
    const referringFields = filter(
      collection.fields,
      ({ type, referencedCollection }) =>
        type === FieldType.reference &&
        referencedCollection === referencedCollectionId,
    )
    return numberOfReferringFields + Object.keys(referringFields).length
  }, 0)
