import { FieldType } from '@wix/wix-data-schema-types'
const { text, number } = FieldType
export const PAGE_TYPES = {
  ITEM: 'item',
  COLLECTION: 'collection',
}

export const SUPPRTED_FIELD_TYPES_IN_URL = [text, number]

export const USER_PREFERENCES = {
  CREATE_DATA_BINDING_ROUTER_INTRO: {
    DONT_SHOW_AGAIN: 'createDataBindingRouter.firstTime.dontShowAgain',
  },
}

export const ERROR_TYPES = {
  COLLECTION_REMOVED: 'COLLECTION_REMOVED',
}
