import { registry } from '@wix/editor-elements-registry/2.0/dataBinding'
import {
  getComponentsLibrariesFromURL,
  whitelist as componentsLibrariesWhitelistArtifactIDs,
} from '@wix/editor-elements-registry/2.0/toolbox'

const createFallbackRegistry = () => ({
  getComponentsLoaders: () => [],
})

export const createBindingDefinitionsRegistry = async ({
  editorSdkProxy,
  logger,
}) => {
  /**
   * get all artifact ids from the whitelist
   */
  const artifactIds = [
    ...new Set(
      componentsLibrariesWhitelistArtifactIDs.map(
        ({ artifactId }) => artifactId,
      ),
    ),
  ]

  /**
   * get URLs for GAed artifact's versions
   */
  const artifactUrls = await editorSdkProxy.document.info.getArtifactsUrls(
    artifactIds,
  )

  /**
   * get all `*-override` query params
   */
  const urlOverrides = await editorSdkProxy.document.info.getArtifactOverrides(
    '',
  )

  const url = `?${Object.entries(urlOverrides)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`

  const libraries = [
    ...componentsLibrariesWhitelistArtifactIDs.map(lib => ({
      ...lib,
      url: artifactUrls[lib.artifactId],
    })),
    ...getComponentsLibrariesFromURL(url),
  ]
  try {
    return await registry({
      mode: 'eager',
      libraries,
    })
  } catch (err) {
    logger.info(`Binding definitions registry initialization failed: ${err}`)
    return createFallbackRegistry()
  }
}
