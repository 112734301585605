import { FieldType } from '@wix/wix-data-schema-types'
import {
  CHECKBOX_ROLE,
  FILTER_INPUT_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
const { boolean } = FieldType

export default () => ({
  componentName: 'Element_Name_Checkbox',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Checkbox',
    helpId: 'aab94d19-74fb-4ec6-a487-2445e3706842',
  },
  sections: [
    {
      role: CHECKBOX_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Checked_Value_Label',
          prop: 'checked',
          supportedFieldTypes: [boolean],
          defaultFieldType: boolean,
          isInputProperty: true,
        },
      ],
    },
    {
      role: FILTER_INPUT_ROLE,
      bindings: [],
    },
  ],
  compactErrorPanel: true,
})
