const getSdkVersionInPanel = async editorSdkProxy => {
  const appApi = await editorSdkProxy.editor.getAppAPI()
  return appApi.getSdkVersion()
}

const getSdkVersionInWorker = editorSdkProxy =>
  editorSdkProxy.document.info.getSdkVersion().version

export default async editorSdkProxy => {
  try {
    return await getSdkVersionInWorker(editorSdkProxy)
  } catch {
    try {
      return await getSdkVersionInPanel(editorSdkProxy)
    } catch {
      return undefined
    }
  }
}
