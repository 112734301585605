export const SCREEN = {
  choice: 'choice',
  regularPresetInfo: 'regularPresetInfo',
  blankPresetInfo: 'blankPresetInfo',
}

export const DIMENSIONS = {
  width: 1025,
  height: 648,
}
