import {
  getPatternDefForPage,
  updatePatternDef,
} from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/routerConfig'
import {
  getDatasetConfig,
  setDatasetConfig,
} from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/patternDef'
import { runTransactionWithRetry } from '@wix/wix-data-client-common/src/business-logic/runTransactionWithRetry'

const getRouterDatasetConfig = async function (
  editorSdkProxy,
  controllerRef,
  controllerConfig = {},
) {
  const pageRef = await editorSdkProxy.components.getPage({
    componentRef: controllerRef,
  })
  const routerRef = await editorSdkProxy.routers.getByPage({ pageRef })
  const { config: routerConfig, pages: routerPages } =
    await editorSdkProxy.routers.get({ routerRef })
  const { def: patternDef } = getPatternDefForPage(
    routerConfig,
    routerPages,
    pageRef,
  )
  const datasetConfigFromRouter = getDatasetConfig(patternDef)
  return {
    ...controllerConfig,

    dataset: {
      ...controllerConfig.dataset,
      collectionName: datasetConfigFromRouter.collection,
      filter: datasetConfigFromRouter.filter,
      sort: datasetConfigFromRouter.sort,
      pageSize: datasetConfigFromRouter.pageSize,
      includes: datasetConfigFromRouter.includes,
      cursor: datasetConfigFromRouter.cursor,
      nested: datasetConfigFromRouter.nested,
    },
  }
}

const saveRouterDatasetConfig = async function (
  editorSdkProxy,
  controllerRef,
  config,
) {
  const pageRef = await editorSdkProxy.components.getPage({
    componentRef: controllerRef,
  })
  const routerRef = await editorSdkProxy.routers.getByPage({ pageRef })
  const currentRouterData = await editorSdkProxy.routers.get({ routerRef })
  const { pattern, def: currentPatternDef } = getPatternDefForPage(
    currentRouterData.config,
    currentRouterData.pages,
    pageRef,
  )

  const newPatternDef = setDatasetConfig(currentPatternDef, {
    filter: config.dataset.filter,
    sort: config.dataset.sort,
    pageSize: config.dataset.pageSize,
    includes: config.dataset.includes,
    lowercase: currentPatternDef.config.lowercase,
    cursor: config.dataset.cursor,
    nested: config.dataset.nested,
  })

  const newRouterConfig = updatePatternDef(
    currentRouterData.config,
    pattern,
    newPatternDef,
  )
  const newControllerConfig = {
    dataset: {
      readWriteType: config.dataset.readWriteType,
    },
  }

  await Promise.all([
    runTransactionWithRetry(editorSdkProxy, () => {
      editorSdkProxy.routers.update({ routerRef, config: newRouterConfig })
    }),
    runTransactionWithRetry(editorSdkProxy, () => {
      editorSdkProxy.controllers.saveConfiguration({
        controllerRef,
        config: newControllerConfig,
      })
    }),
  ])
}

export { getRouterDatasetConfig, saveRouterDatasetConfig }
