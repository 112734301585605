import { FieldType } from '@wix/wix-data-schema-types'
import { VECTOR_IMAGE_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { image, url, document, video } = FieldType

export default () => ({
  componentName: 'Element_Name_Vector_Art',
  isConnectGfppEnabled: true,
  panel: {
    height: 467,
    title: 'Connect_Panel_Title_Vector_Art',
    helpId: '753851ba-4055-41dc-a17d-8dee3921514b',
  },
  sections: [
    {
      role: VECTOR_IMAGE_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Link_Label',
          prop: 'link',
          supportedFieldTypes: [document, url, image, video],
          defaultFieldType: url,
          event: 'onClick',
          supportPageLinks: true,
          supportsActions: true,
          supportsReferenceFields: true,
          supportsPostSubmit: true,
        },
      ],
    },
  ],
  compactErrorPanel: true,
})
