import { NEW_DYNAMIC_PAGE_FLOWS } from '../../business-logic/dynamic-pages/constants'
import openNewDynamicPagePanelV4 from './openPanel'

export default ({
    hostname,
    editorSdkProxy,
    i18n,
    editorType,
    appApi,
    isFirstTimeExperience,
  }) =>
  async ({ origin }) => {
    const panelResult = await openNewDynamicPagePanelV4(
      {
        hostname,
        editorSdkProxy,
        i18n,
      },
      {
        origin,
        editorType,
        isFirstTimeExperience,
      },
    )

    if (!panelResult) {
      return
    }

    const { type, payload } = panelResult
    const { presetId, pagesToAdd, collectionName } = payload

    if (type === NEW_DYNAMIC_PAGE_FLOWS.ADD_PRESET_WITH_GIVEN_PAGES) {
      return appApi.addPresetWithGivenPages({
        presetId,
        pagesToAdd,
        collectionName,
        origin,
        isFirstTimeExperience,
      })
    }

    if (type === NEW_DYNAMIC_PAGE_FLOWS.ADD_FROM_PRESET) {
      return appApi.addPreset({
        presetId,
        origin,
        isFirstTimeExperience,
      })
    }
  }
