import { getAssetUrlInWorker } from '@wix/wix-data-client-common/src/panels/getAssetUrl'
import { preferences } from '@wix/wix-data-client-common/src/createOrEditCollectionPanel'

const markIntroAsShown = safeUserPreferences => {
  safeUserPreferences.set({
    [preferences.INTRO_WAS_SHOWN]: true,
  })
}

export const openIntroPanel = async ({
  editorSdkProxy,
  t,
  safeUserPreferences,
}) => {
  const result = await editorSdkProxy.editor.openPromotionalPanel({
    titleText: t('Workspace_Create_Collection_Welcome_Screen_Title'),
    subtitleText: t('Workspace_Create_Collection_Welcome_Screen_Subtitle'),
    illustration: await getAssetUrlInWorker(
      editorSdkProxy,
      'panel-icons/dev_mode_intro_workspace.svg',
    ),
    contentArray: [
      t('Workspace_Create_Collection_Welcome_Screen_List_Item1'),
      t('Workspace_Create_Collection_Welcome_Screen_List_Item2'),
      t('Workspace_Create_Collection_Welcome_Screen_List_Item3'),
      t('Workspace_Create_Collection_Welcome_Screen_List_Item4'),
    ],
    mainActionText: t('Workspace_Create_Collection_Welcome_Screen_Button'),
    KBLinkText: t('Workspace_Create_Collection_Welcome_Screen_Link'),
    helpId: '5861acdf-7649-4097-9894-b92e0fb41978',
  })

  markIntroAsShown(safeUserPreferences)

  return result
}

export const shouldShowIntroPanel = async ({
  editMode,
  inAppBuilder,
  safeUserPreferences,
  initialScreen,
  experimentsManager,
}) => {
  if (
    experimentsManager.enabled(
      'specs.wixDataClient.RemoveVeloIntroForCollection',
    )
  ) {
    return false
  }

  if (initialScreen) {
    markIntroAsShown(safeUserPreferences)
    return false
  }
  if (inAppBuilder || editMode) {
    return false
  }
  const fallbackPreferences = {
    [preferences.INTRO_WAS_SHOWN]: false,
  }

  const preferencesState = await safeUserPreferences.get(
    [preferences.INTRO_WAS_SHOWN],
    fallbackPreferences,
  )

  return !preferencesState[preferences.INTRO_WAS_SHOWN]
}

export const MAIN_ACTION_CLICKED = 'mainActionClicked'
