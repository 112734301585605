import { FieldType } from '@wix/wix-data-schema-types'
import { BEHAVIOR_TYPES } from '@wix/dbsm-common/src/connection-config/behaviors'
import { TEXT_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { text, number, url, boolean, dateTime, richText, address, stringArray } =
  FieldType

export default ({ experimentsManager, i18n }) => ({
  componentName: 'Element_Name_Text',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Text',
    helpId: 'a0b9d29b-65b9-42eb-a7a5-6fb3f2cd408c',
  },
  sections: [
    {
      role: TEXT_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Text_Label',
          prop: '$text',
          supportedFieldTypes: [
            text,
            number,
            url,
            boolean,
            dateTime,
            richText,
            address,
            stringArray,
          ],
          defaultFieldType: text,
          supportsReferenceFields: true,
          supportedBehaviors: [
            BEHAVIOR_TYPES.SAVE_FAILURE_FEEDBACK,
            BEHAVIOR_TYPES.SAVE_SUCCESS_FEEDBACK,
          ],
          requiresPrimaryConnection: true,
        },
      ],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'text_live_preview_gfpp_desktop_main_action',
              label: 'Text_Live_Preview_GFPP_Desktop_Main_Action',
            },
            mainAction2: 'DEFAULT1',
          },
        },
        behavior: { dataEditOptions: 'TEXT_STYLE_ONLY' },
      },
      manageContentPanel: {
        description: 'Text_Manage_Content_Panel_Description',
      },
    },
  ],
  compactErrorPanel: true,
})
