import { escapeRegExp } from 'lodash'

const pageNameMarker = '___PAGE_NAME___'
const copyCountMarker = '___COPY_COUNT___'

export const prefixTitleWithCopyOf = (i18n, str) => {
  const singularRegexInput = escapeRegExp(
    i18n.t('Duplicated_Page_Title_Prefix_Singular', {
      pageName: pageNameMarker,
    }),
  ).replace(pageNameMarker, '(.+)')
  const singularRegex = new RegExp(`^${singularRegexInput}$`)

  const singularCopyOfMatch = singularRegex.exec(str)
  if (singularCopyOfMatch) {
    const currentPageName = singularCopyOfMatch[1]
    return i18n.t('Duplicated_Page_Title_Prefix_Plural', {
      pageName: currentPageName,
      count: 2,
    })
  }

  const pluralRegexInput = escapeRegExp(
    i18n.t('Duplicated_Page_Title_Prefix_Plural', {
      pageName: pageNameMarker,
      count: copyCountMarker,
    }),
  )
    .replace(pageNameMarker, '(.+)')
    .replace(copyCountMarker, '(\\d+)')
  const pluralRegex = new RegExp(`^${pluralRegexInput}$`)

  const pluralCopyOfMatch = pluralRegex.exec(str)
  if (pluralCopyOfMatch) {
    const { currentPageName, currentCount } =
      getNamedPluralCaptureGroups(pluralCopyOfMatch)

    return i18n.t('Duplicated_Page_Title_Prefix_Plural', {
      pageName: currentPageName,
      count: Number(currentCount) + 1,
    })
  }

  return i18n.t('Duplicated_Page_Title_Prefix_Singular', {
    pageName: str,
  })
}

const getNamedPluralCaptureGroups = match => {
  const group1 = match[1]
  const group2 = match[2]
  const isNumeric = input => /\d+/.test(input)

  if (isNumeric(group1)) {
    return {
      currentPageName: group2,
      currentCount: group1,
    }
  }
  return {
    currentPageName: group1,
    currentCount: group2,
  }
}

export const prefixUrlWithCopyOf = str => {
  if (str.match(/^copy-of-/)) {
    return str.replace(/^copy-of-/, 'copy-2-of-')
  }

  const val = str.match(/^copy-(\d+)-of-/)

  if (val) {
    const nextNum = Number(val[1]) + 1
    return str.replace(/^copy-\d+-of/, `copy-${nextNum}-of`)
  }

  const prefixText = 'copy-of-'

  return `${prefixText}${str}`
}
