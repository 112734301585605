import { head } from 'lodash'
import getRepeaterAncestor from '../../business-logic/components/getRepeaterAncestor'
import * as datasetApi from '../../business-logic/datasets/datasetApi'

import {
  DROPDOWN_OPTIONS_ROLE,
  GALLERY_ROLE,
  GRID_ROLE,
  REPEATER_ROLE,
  SELECTION_TAGS_OPTIONS_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'

import { getComponentTypeData } from '../../business-logic/components/getComponentTypeData'
import { ManageContentPanelResult } from '@wix/wix-data-client-common/src/manageContentPanelResult'
import getTypeOfProbableDynamicPage from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/getTypeOfProbableDynamicPage'
import * as DYNAMIC_PAGE_TYPES from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/dynamicPageTypes'
import * as DATASET_TYPES from '@wix/wix-data-client-common/src/datasetTypes'
import { openConnectPanel } from '../connection-config-panel/openPanel.js'

const ITEM_VIEW_INCOMPATBIBLE_ROLES = [
  DROPDOWN_OPTIONS_ROLE,
  GALLERY_ROLE,
  GRID_ROLE,
  REPEATER_ROLE,
  SELECTION_TAGS_OPTIONS_ROLE,
]

const itemViewShouldBeUsedInsteadOfCollectionView = async ({
  editorSdkProxy,
  experimentsManager,
  componentRef,
  datasetType,
}) => {
  if (
    datasetType === DATASET_TYPES.ROUTER_DATASET &&
    (await getTypeOfProbableDynamicPage({
      editorSdkProxy,
      pageRef: await editorSdkProxy.document.pages.getCurrent(),
    })) === DYNAMIC_PAGE_TYPES.ITEM_PAGE
  ) {
    const binding = head(
      await datasetApi.fetchComponentBindings(editorSdkProxy, componentRef),
    )

    if (ITEM_VIEW_INCOMPATBIBLE_ROLES.includes(binding.role)) {
      return false
    }

    const componentisInsideRepeater = (
      await getRepeaterAncestor({
        editorSdkProxy,
        componentRef,
      })
    ).fold(
      () => false,
      () => true,
    )

    return !componentisInsideRepeater
  }
  return false
}

const openManageContentPanel = async (
  {
    hostname,
    appApi,
    editorSdkProxy,
    experimentsManager,
    logger,
    i18n,
    editorType,
    collectionsApi,
  },
  { componentRef, role, origin },
) => {
  const dataset = await datasetApi.fetchConnectedDataset(
    editorSdkProxy,
    componentRef,
    role,
  )

  const { sdkType: componentType } = await getComponentTypeData({
    editorSdkProxy,
    componentRef,
  })

  const bindingDefinition = await appApi.getBindingDefinitions({
    componentType,
  })
  const manageContentPanelDefinition = bindingDefinition.sections.find(
    section => role === section.role,
  ).manageContentPanel

  const {
    config: { collectionId },
    type: datasetType,
  } = dataset

  const collectionSchema = await collectionsApi.get(collectionId)
  const manageContentPanelResult =
    await editorSdkProxy.editor.openManageContentPanel({
      title: i18n.t('Manage_Content_Panel_Title'),
      descriptionText: i18n.t(manageContentPanelDefinition.description, {
        collectionId: collectionSchema.displayName,
        interpolation: { escapeValue: false },
      }),
      mainActionText: i18n.t('Manage_Content_Panel_Main_Action'),
      secondaryActionText: i18n.t('Manage_Content_Panel_Secondary_Action'),
      helpId: i18n.t('d000335e-a223-4932-bd93-4e9605722e64'),
      origin,
    })

  switch (manageContentPanelResult) {
    case ManageContentPanelResult.MAIN_ACTION_SELECTED: {
      ;(await itemViewShouldBeUsedInsteadOfCollectionView({
        editorSdkProxy,
        experimentsManager,
        componentRef,
        datasetType,
      }))
        ? await appApi.openAutoCmsItemViewForCurrentDynamicPage({
            origin: 'manage_content_panel',
          })
        : await appApi.openAutoCmsCollectionView({
            collectionId,
            origin: 'manage_content_panel',
          })
      break
    }

    case ManageContentPanelResult.SECONDARY_ACTION_SELECTED: {
      openConnectPanel(
        {
          hostname,
          appApi,
          editorSdkProxy,
          experimentsManager,
          logger,
          i18n,
          editorType,
          initiator: 'manually',
          origin: 'manage_content_panel',
        },
        { componentRef },
      )

      break
    }
  }
}

export default openManageContentPanel
