import { FieldType } from '@wix/wix-data-schema-types'
import { PROGRESS_BAR_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { number } = FieldType

export default ({ i18n }) => ({
  componentName: 'Element_Name_Progress_Bar',
  isConnectGfppEnabled: true,
  compactErrorPanel: true,
  panel: {
    height: 302,
    title: 'Connect_Panel_Title_progressBar',
    helpId: '6c16f2c0-63cc-4b7a-bb78-4a18bb04816e',
  },
  sections: [
    {
      role: PROGRESS_BAR_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_progressBar_CurrentValue_Label',
          prop: 'value',
          supportedFieldTypes: [number],
          defaultFieldType: number,
          supportsReferenceFields: true,
          emptyValue: null,
        },
        {
          title: 'Connect_Panel_Connect_progressBar_TargetValue_Label',
          prop: 'targetValue',
          supportedFieldTypes: [number],
          defaultFieldType: number,
          supportsReferenceFields: true,
          emptyValue: null,
          requiresPrimaryConnection: true,
        },
      ],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'progress_bar_live_preview_gfpp_desktop_main_action',
              label: i18n.t(
                'Progress_Bar_Live_Preview_GFPP_Desktop_Main_Action',
              ),
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Progress_Bar_Manage_Content_Panel_Description',
      },
    },
  ],
})
