export default async editorSdkProxy => {
  if (process.env.JEST_WORKER_ID) {
    return
  }
  const artifactUrls = await editorSdkProxy.info.getArtifactsUrls([
    'dbsm-editor-app',
  ])
  const workerChunkBasePath = `${artifactUrls['dbsm-editor-app']}/`
  __webpack_public_path__ = workerChunkBasePath // eslint-disable-line
}
