export const datasetConfigPanel = {
  panelName: 'DATASET_CONFIG',
}

export const connectionConfigPanel = {
  panelName: 'CONNECTION_CONFIG',
}

export const filterPanel = {
  panelName: 'FILTER_PANEL',
}

export const sortPanel = {
  panelName: 'SORT_PANEL',
}

export const addColumnPanel = {
  panelName: 'ADD_COLUMN_PANEL',
}

export const createDatasetPanel = {
  panelName: 'CREATE_DATASET_PANEL',
}

export const changeCollectionPanel = {
  panelName: 'CHANGE_COLLECTION_PANEL',
}

export const deleteDynamicPagePanel = {
  panelName: 'DELETE_DYNAMIC_PAGE_PANEL',
}

export const dynamicPageInfoPanel = {
  panelName: 'DYNAMIC_PAGE_INFO_PANEL',
}

export const dynamicPageSeoPanel = {
  panelName: 'DYNAMIC_PAGE_SEO_PANEL',
}

export const dynamicPagesHooksPanel = {
  panelName: 'DYNAMIC_PAGE_HOOKS_PANEL',
}

export const newPageIntroPanel = {
  panelName: 'NEW_PAGE_INTRO',
}

export const newPageChooseTypePanel = {
  panelName: 'NEW_PAGE_CHOOSE_TYPE',
}

export const newPageFilterDataPanel = {
  panelName: 'NEW_PAGE_FILTER_DATA',
}

export const disconnectDynamicPagePanel = {
  panelName: 'DISCONNECT_DYNAMIC_PAGE_PANEL',
}

export const addFieldPanel = {
  panelName: 'ADD_FIELD_PANEL',
}

export const addingDynamicPagesPanel = {
  panelName: 'ADDING_CONTENT_PAGES_PANEL',
}

export const createCollectionPanel = {
  panelName: 'CREATE_COLLECTION_PANEL',
}

export const createExternalCollectionPanel = {
  panelName: 'CREATE_EXTERNAL_COLLECTION_PANEL',
}

export const newDynamicPagePanelV4 = {
  panelName: 'NEW_DYNAMIC_PAGE_V4',
}

export const convertToDynamicPagePanel = {
  panelName: 'NEW_DYNAMIC_PAGE',
}

export const convertToDynamicPagePanelV2 = {
  panelName: 'CONVERT_TO_DYNAMIC_PAGE_PANEL_V2',
}

export const fieldPropertiesPanel = {
  panelName: 'FIELD_PROPERTIES_PANEL',
}
