export const ADD_DATA_BINDING_URL = 'addDataBindingUrl'
export const REMOVE_PAGE_FROM_DATA_BINDING_ROUTER =
  'removePageFromDataBindingRouter'
export const DISCONNECT_PAGE_FROM_DATA_BINDING_ROUTER =
  'disconnectPageFromDataBindingRouter'
export const UPDATE_DYNAMIC_PAGE_SEO_METATAGS = 'updateDynamicPageSeoMetatags'
export const UPDATE_DYNAMIC_PAGE_URL = 'updateDynamicPageUrl'
export const SAVE_CONFIGURATION = 'saveConfiguration'
export const SET_DISPLAY_NAME = 'setDisplayName'
export const CREATE_DATASET = 'createDataset'
