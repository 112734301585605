import { GRID_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'

export default ({ i18n }) => ({
  componentName: 'Element_Name_Table',
  isConnectGfppEnabled: true,
  panel: {
    height: 528,
    title: 'Connect_Panel_Title_Table',
    helpId: '41011fe0-f4cc-447a-8632-f9514d7502e7',
  },
  sections: [
    {
      role: GRID_ROLE,
      bindings: [],
      manifest: {
        gfpp: {
          desktop: {
            mainAction2: {
              actionId: 'table_live_preview_gfpp_desktop_main_action',
              label: 'Table_Live_Preview_GFPP_Desktop_Main_Action',
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Table_Manage_Content_Panel_Description',
      },
    },
  ],
  supportsDefaultSelectedDataset: false,
})
