import { difference, isEqual, omitBy, isUndefined } from 'lodash'
import { parseUrl, parseUrlPattern, buildUrl } from './utils'
import { INVALID_PATTERN_CHARS } from './invalid-chars-regex'
import * as validationKeys from './validationKeys'
import isFieldAllowedInDynamicUrl from '../isFieldAllowedInRoutingUrl'
import { validatePrefixWithLatinOnly } from './validatePrefixWithLatinOnly'

const COLLECTION_FIELD_PLACEHOLDER = /\{[^{}]+\}/g
const ONLY_ONE_COLLECTION_FIELD_PLACEHOLDER = new RegExp(
  COLLECTION_FIELD_PLACEHOLDER.source +
    '[^/]*' +
    COLLECTION_FIELD_PLACEHOLDER.source,
  'g',
)
const MAX_URL_LENGTH = 500

const invalidResult = (errorKey, errorParams) =>
  omitBy({ valid: false, error: errorKey, params: errorParams }, isUndefined)

export const arePatternsConflicting = (pattern1, pattern2) => {
  const pattern1Parts = pattern1
    .replace(COLLECTION_FIELD_PLACEHOLDER, '#')
    .split('/')
    .filter(part => !!part)
  const pattern2Parts = pattern2
    .replace(COLLECTION_FIELD_PLACEHOLDER, '#')
    .split('/')
    .filter(part => !!part)
  return isEqual(pattern1Parts, pattern2Parts)
}

const validateUrlPrefix = async function (editorSdkProxy, url) {
  const { prefix } = parseUrl(url)
  if (prefix.startsWith('{')) {
    return invalidResult(validationKeys.PREFIX_STARTS_WITH_TEXT)
  }
  if (prefix.match(COLLECTION_FIELD_PLACEHOLDER)) {
    return invalidResult(validationKeys.NO_FIELD_IN_PREFIX)
  }
  const isValidPrefix = await validatePrefixWithLatinOnly({
    prefix,
    editorSdkProxy,
  })
  if (!isValidPrefix.valid) {
    return { valid: false, error: isValidPrefix.message }
  }

  return { valid: true }
}

export const validateUrlPattern = function (url, collection, existingPatterns) {
  const { prefix, pattern } = parseUrl(url)
  const { fields: urlFieldNames } = parseUrlPattern(pattern)
  const urlFieldsFromCollection = collection.fields.filter(field =>
    urlFieldNames.includes(field.name),
  )

  const patternWithoutFields = pattern.replace(COLLECTION_FIELD_PLACEHOLDER, '')
  if (patternWithoutFields.match(INVALID_PATTERN_CHARS)) {
    return invalidResult(validationKeys.NO_SPECIAL_CHARS)
  }
  if (
    urlFieldsFromCollection.some(
      field => !isFieldAllowedInDynamicUrl(collection, field.name),
    )
  ) {
    return invalidResult(validationKeys.UNSUPPORTED_FIELDS)
  }
  if (urlFieldsFromCollection.some(field => field.isDeleted)) {
    return invalidResult(validationKeys.NO_DELETED_FIELD)
  }
  const existingFieldNames = collection.fields
    .filter(({ isDeleted }) => !isDeleted)
    .map(({ name }) => name)
  if (difference(urlFieldNames, existingFieldNames).length > 0) {
    return invalidResult(validationKeys.ONLY_EXISTING_FIELDS)
  }
  const conflictingPattern = existingPatterns.find(existingPattern =>
    arePatternsConflicting(existingPattern, pattern),
  )
  if (conflictingPattern) {
    return invalidResult(validationKeys.PATTERN_ALREADY_EXISTS, {
      conflicting_url: buildUrl(prefix, conflictingPattern),
      interpolation: { escapeValue: false },
    })
  }
  if (pattern.match(ONLY_ONE_COLLECTION_FIELD_PLACEHOLDER)) {
    return invalidResult(validationKeys.SEPARATE_FIELDS)
  }

  return { valid: true }
}

export const validateUrl = async function (
  editorSdkProxy,
  url,
  collection,
  existingPatterns = [],
) {
  if (url === '') {
    return invalidResult(validationKeys.URL_EMPTY)
  }

  const { prefix, pattern } = parseUrl(url)

  if (url.length > MAX_URL_LENGTH) {
    return invalidResult(validationKeys.URL_TOO_LONG, {
      max_length: MAX_URL_LENGTH,
    })
  }

  if (prefix && prefix.length > 0) {
    const prefixValidity = await validateUrlPrefix(editorSdkProxy, url)
    if (!prefixValidity.valid) {
      return prefixValidity
    }
  }

  if (pattern && pattern.length > 0) {
    const patternValidity = validateUrlPattern(
      url,
      collection,
      existingPatterns,
    )
    if (!patternValidity.valid) {
      return patternValidity
    }
  }

  return { valid: true }
}
