import { appCollectionVisibilityChanged } from '@wix/wix-data-client-common/src/appCollectionVisibility'
import { createBindingDefinitionsRegistry } from './bindingDefinitionsRegistry'
import onEventCreator from './events'
import appApiCreator from './api'
import { createPublicApi } from './public-api'
import { updateControllersState as doUpdateControllersState } from './controllersState'

const createModuleFactories = async services => {
  const {
    editorSdkProxy,
    logger,
    bindingDefinitionLoaders,
    livePreview,
    bindingDefinitionsRegistry = await createBindingDefinitionsRegistry({
      editorSdkProxy,
      logger,
    }),
    collectionsApi,
    finalAppApiPromise,
  } = services

  const updateControllersState = appApi =>
    doUpdateControllersState({ editorSdkProxy, collectionsApi, appApi }).catch(
      logger.error,
    )

  finalAppApiPromise.then(appApi => {
    updateControllersState(appApi)

    appApi.addListener(appCollectionVisibilityChanged, () => {
      updateControllersState(appApi)
    })
  })

  /**
   * Merge registry loaders with `bindingDefinitionLoaders`
   */
  Object.entries(bindingDefinitionsRegistry.getComponentsLoaders()).forEach(
    ([componentType, loader]) => {
      bindingDefinitionLoaders.set(componentType, loader)
    },
  )

  await editorSdkProxy.document.application.registerToCustomEvents({
    eventTypes: ['componentAddedToStage'],
  })

  if (livePreview) {
    await editorSdkProxy.document.application.registerToCustomEvents({
      eventTypes: ['componentSelectionChanged'],
    })
  }

  return {
    createAppApi: () => appApiCreator(services),
    createEventHandlers: ({ appApi }) =>
      onEventCreator({ ...services, appApi }),
  }
}

const publicApi = createPublicApi()

export default { createModuleFactories, publicApi }
