import Maybe from 'folktale/maybe'
import { get, some } from 'lodash'
import { Repeater } from '@wix/dbsm-common/src/componentTypes'

export default async function ({ componentRef, editorSdkProxy }) {
  const ancestorsRefs = await editorSdkProxy.components.getAncestors({
    componentRef,
  })
  if (ancestorsRefs.length === 0) {
    return Maybe.Nothing()
  }

  const ancestors = await editorSdkProxy.components.get({
    componentRefs: ancestorsRefs,
    properties: ['sdkType', 'connections'],
  })
  const firstRepeaterAncestor = ancestors.find(
    ({ sdkType }) => sdkType === Repeater,
  )
  if (!firstRepeaterAncestor) {
    return Maybe.Nothing()
  }

  const allDatasets = await editorSdkProxy.controllers.listAllControllers()
  const databindingConnections = firstRepeaterAncestor.connections.filter(
    ({ controllerRef }) => some(allDatasets, { controllerRef }),
  )

  return Maybe.Just({
    componentRef: firstRepeaterAncestor.componentRef,
    controllerRef: get(databindingConnections, [0, 'controllerRef'], null),
  })
}
