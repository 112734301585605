import ComponentTypes from '@wix/dbsm-common/src/componentTypes'

const getComponentTypeData = async ({ editorSdkProxy, componentRef }) => {
  const [{ sdkType, componentType: viewerType }] =
    await editorSdkProxy.components.get({
      componentRefs: [componentRef],
      properties: ['sdkType', 'componentType'],
    })

  return {
    sdkType: resolveComponentSdkType({ sdkType, viewerType }),
    viewerType,
  }
}

const resolveComponentSdkType = ({ sdkType, viewerType }) => {
  if (sdkType === ComponentTypes.Button) {
    return viewerType === ComponentTypes.viewerTypes.IconButton
      ? ComponentTypes.IconButton
      : sdkType
  }
  return sdkType
}

export { getComponentTypeData }
