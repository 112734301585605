import { PAGE_TYPES } from '@wix/wix-data-client-common/src/business-data/dynamicPages'
import generateUniquePrefix from '../generateUniquePrefix'
import { generateKey as generatePageLinkFieldKey } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/page-link-field/pageLinkField'
import { parseUrl } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/routing-url/utils'
import { getPageUrl } from './new-page/getPageUrl'
import { DATA_BINDING } from '@wix/app-definition-ids'

const determinePageLinks = ({
  pagesToAdd,
  collectionFields,
  routerPrefix,
  arePageLinksCreatedOnBackend,
  displayField,
  isTPACollection,
}) => {
  const newPageLinks = {}
  const existingFieldKeys = collectionFields.map(({ name }) => name)

  for (const pageToAdd of pagesToAdd) {
    const pageLink = determinePageLink({
      pageToAdd,
      routerPrefix,
      arePageLinksCreatedOnBackend,
      existingFieldKeys,
      collectionFields,
      displayField,
      isTPACollection,
    })

    newPageLinks[pageToAdd.key] = pageLink
    existingFieldKeys.push(pageLink.pageLinkFieldKey)
  }

  return newPageLinks
}

const determinePageLink = ({
  pageToAdd,
  routerPrefix,
  arePageLinksCreatedOnBackend,
  existingFieldKeys,
  collectionFields,
  displayField,
  isTPACollection,
}) => {
  const pageType =
    pageToAdd.key === 'indexPage' ? PAGE_TYPES.COLLECTION : PAGE_TYPES.ITEM

  const url = getPageUrl({
    pageType,
    routerPrefix,
    collectionFields,
    displayField,
    isTPACollection,
  })

  const { pattern } = parseUrl(url)

  const pageLinkFieldKey = generatePageLinkFieldKey({
    prefix: routerPrefix,
    pattern,
    arePageLinksCreatedOnBackend,
    existingFieldKeys,
  })

  return { url, pattern, pageLinkFieldKey }
}

const determineManagingAppDefId = async ({
  pagesToAdd,
  editorSdkProxy,
  isNewAppPagesEnabled,
}) => {
  const staticPageRef = pagesToAdd.find(p => p.staticPageRef)?.staticPageRef

  if (staticPageRef) {
    const pageData = await editorSdkProxy.pages.getPageData({
      pageRef: staticPageRef,
    })

    if (pageData.managingAppDefId) {
      return pageData.managingAppDefId
    }
  }

  return isNewAppPagesEnabled ? DATA_BINDING : undefined
}

const clearPageUriSEO = ({ editorSdkProxy, pageRef }) =>
  editorSdkProxy.document.pages.data.update({
    pageRef,
    data: { pageUriSEO: '' },
  })

const createPrefixWithSafelyClearedStaticPageUriSEO = async ({
  editorSdkProxy,
  pagesToAdd,
  createPrefix,
}) => {
  const pageRef = pagesToAdd.find(p => p.staticPageRef)?.staticPageRef
  if (!pageRef) {
    return createPrefix()
  }

  await clearPageUriSEO({ editorSdkProxy, pageRef })
  return createPrefix()
}

export const prepareDataForPages = async ({
  editorSdkProxy,
  collectionId,
  collectionName,
  pagesToAdd,
  collectionFields,
  isNewAppPagesEnabled,
  arePageLinksCreatedOnBackend,
  displayField,
  isTPACollection,
}) => {
  const [routerPrefix, managingAppDefId] = await Promise.all([
    createPrefixWithSafelyClearedStaticPageUriSEO({
      editorSdkProxy,
      pagesToAdd,
      createPrefix: () =>
        generateUniquePrefix({
          inputsForPrefix: [collectionName, collectionId],
          editorSdkProxy,
        }),
    }),
    determineManagingAppDefId({
      pagesToAdd,
      editorSdkProxy,
      isNewAppPagesEnabled,
    }),
  ])

  const pageLinks = determinePageLinks({
    pagesToAdd,
    collectionFields,
    routerPrefix,
    arePageLinksCreatedOnBackend,
    displayField,
    isTPACollection,
  })

  return {
    routerPrefix,
    managingAppDefId,
    pageLinks,
  }
}
