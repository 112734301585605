import { getPublishingPluginPatch } from '@wix/wix-data-client-common/src/publishing'
import { isSinglesCollectionSchema } from '@wix/wix-data-client-common/src/collections/collectionTypes'

import {
  disconnectDynamicPage,
  deleteDynamicPage,
  updateDynamicPageUrl,
  updateDynamicPageSEO,
  appHasDPsForCollection,
  duplicateDynamicPage,
  getDpCreationFlows,
} from '../business-logic/dynamic-pages/dynamicPagesApi'
import generateCollectionNameAndIdFromPage from '../business-logic/dynamic-pages/generateCollectionNameAndIdFromPage'
import generateCollectionId from '@wix/wix-data-client-common/src/collections/generateCollectionId'
import { createDPErrorHandler } from '../worker/utils/createDPErrorHandler'
import createNewDynamicPageV4PanelOpener from '../panels/new-dynamic-page-panel-v4/getPanelOpener'
import createConvertStaticToDynamicPagePanelOpener from '../panels/convert-to-dynamic-page-panel/getPanelOpener'
import { getIsSandboxEnabled } from '@wix/wix-data-client-common/src/utils/isSandboxEnabled'
import { getInstance } from '@wix/wix-data-client-common/src/utils/getInstance'
import { getDefaultDraftPublishStatus } from '@wix/wix-data-client-common/src/utils/getDefaultDraftPublishStatus'
import { openPanelByController as openDynamicPageInfoPanel } from '../panels/page-info-panel/openPanel'
import openPresetScanningPanel from '../panels/preset-scanning-panel/openPanel'
import { defaultPanelBiParams } from './defaultBiParams'
import { addPreset } from './addPreset'
import { openDPsFromSinglesErrorPanel } from './openDPsFromSinglesErrorPanel'
import openConvertToDynamicPagePanelV2 from '../panels/convert-to-dynamic-page-panel-v2/openPanel'
import openFieldPropertiesPanel from '../panels/field-properties-panel/openPanel'

export default ({
  fedopsLogger,
  i18n,
  logger,
  biLogger,
  biCMLogger,
  editorSdkProxy,
  collectionsApi,
  wixData,
  editorType,
  hostname,
  finalAppApiPromise,
  experimentsManager,
  appPagesEnabled,
}) => {
  const getDpFlows = async ({ biDefaults }) => {
    const { origin } = biDefaults

    const biLoggerWithDefaults = params =>
      biLogger({
        ...biDefaults,
        ...params,
      })

    const biCMLoggerWithDefaults = params =>
      biCMLogger({
        origin,
        is_first_data_modal: biDefaults.is_first_data_modal,
        ...params,
      })

    const isSandboxEnabled = await getIsSandboxEnabled({
      instance: await getInstance(editorSdkProxy),
    })

    const isDraftPublishEnabled = experimentsManager.enabled(
      'specs.wixCode.DraftPublish',
    )

    const isDefaultDraftPublishStatusDraft = experimentsManager.enabled(
      'specs.wixCode.DraftPublishDefaultStatusIsDraft',
    )

    const defaultDraftPublishStatus = getDefaultDraftPublishStatus(
      isDefaultDraftPublishStatusDraft,
    )

    const arePageLinksCreatedOnBackend =
      experimentsManager.enabled(
        'specs.clouddata.EnableEditorDocumentChangeEvent',
      ) && experimentsManager.enabled('specs.WixCodeOpenCodeAppIdEnabled')

    const isDraftPublishActivatedByDefault = experimentsManager.enabled(
      'specs.wixCode.DraftPublishActivatedByDefault',
    )

    const isFirstTimeConversionBannerEnabled = experimentsManager.enabled(
      'specs.wixDataClient.ConvertToDynamicPagePanelV2',
    )

    const withPublishingPlugin =
      isDraftPublishEnabled && isDraftPublishActivatedByDefault

    const pluginsPatches = []

    withPublishingPlugin &&
      pluginsPatches.push(getPublishingPluginPatch(defaultDraftPublishStatus))

    return getDpCreationFlows({
      collectionsApi,
      editorSdkProxy,
      wixData,
      biLogger: biLoggerWithDefaults,
      biCMLogger: biCMLoggerWithDefaults,
      i18n,
      editorType,
      isNewAppPagesEnabled: appPagesEnabled,
      isSandboxEnabled,
      arePageLinksCreatedOnBackend,
      isDraftPublishEnabled,
      defaultDraftPublishStatus,
      pluginsPatches,
      logger,
      fedopsLogger,
      appApi: await finalAppApiPromise,
      isFirstTimeConversionBannerEnabled,
      biDefaults,
    })
  }

  const addDefaultPage =
    ({ pageKey }) =>
    async ({
      collectionId,
      origin,
      panelName = defaultPanelBiParams.panel_name,
    }) =>
      withDPErrorHandler(async () => {
        const schema = await collectionsApi.get(collectionId)

        if (isSinglesCollectionSchema(schema)) {
          return openDPsFromSinglesErrorPanel({
            experimentsManager,
            editorSdkProxy,
            logger,
            i18n,
          })
        }

        const appApi = await finalAppApiPromise

        return appApi.addDynamicPages({
          presetId: 'blank',
          pagesToAdd: [{ key: pageKey }],
          collectionId,
          collectionName: schema.displayName,
          fromExistingCollection: true,
          biDefaults: {
            is_first_data_modal: false,
            panel_name: panelName,
            origin,
          },
        })
      })

  const withDPErrorHandler = createDPErrorHandler({
    i18n,
    logger,
    editorSdkProxy,
    experimentsManager,
  })

  return {
    async saveNewDynamicPageUrl({ pageRef, collectionId, newUrl }) {
      return updateDynamicPageUrl({
        editorSdkProxy,
        collectionsApi,
        pageRef,
        collectionId,
        newUrl,
      })
    },

    async updateDynamicPageSeo({ pageRef, title, metatags }) {
      return updateDynamicPageSEO({
        editorSdkProxy,
        pageRef,
        title,
        metatags,
      })
    },

    addDynamicPages: async ({
      pagesToAdd,
      collectionId,
      collectionName,
      biDefaults,
      presetId = 'blank',
      fromExistingCollection = false,
    }) => {
      const dpFlows = await getDpFlows({ biDefaults })

      const params = {
        pagesToAdd,
        collectionId,
        collectionName,
        origin: biDefaults.origin,
      }

      if (pagesToAdd.some(page => page.staticPageRef)) {
        return fromExistingCollection
          ? dpFlows.convertFromCollection(params)
          : dpFlows.convertFromScratch(params)
      }

      return fromExistingCollection
        ? dpFlows.addDefaultDynamicPages(params)
        : dpFlows.addPreset({ ...params, presetId })
    },

    addBlankDynamicPage: async ({ collectionId, origin, panelName }) =>
      withDPErrorHandler(async () => {
        const schema = await collectionsApi.get(collectionId)

        if (isSinglesCollectionSchema(schema)) {
          return openDPsFromSinglesErrorPanel({
            editorSdkProxy,
            logger,
            i18n,
          })
        }

        const appApi = await finalAppApiPromise

        return appApi.addDynamicPages({
          pagesToAdd: [{ key: 'itemPage', shouldBeBlank: true }],
          collectionId,
          collectionName: schema.displayName,
          biDefaults: {
            origin,
            panel_name: panelName,
            is_first_data_modal: false,
          },
          fromExistingCollection: true,
        })
      }),

    addDefaultDynamicItemPage: addDefaultPage({
      pageKey: 'itemPage',
    }),

    addDefaultDynamicListPage: addDefaultPage({
      pageKey: 'indexPage',
    }),

    convertToDynamicAndBindComponents: async ({
      pageRef,
      collectionId,
      collectionName,
      fields = {},
      records = [],
      bindings = [],
      biDefaults,
    }) =>
      withDPErrorHandler(async () => {
        const dpFlows = await getDpFlows({
          biDefaults: { ...biDefaults, is_first_data_modal: false },
        })

        return dpFlows.convertFromScratchAndBindComponents({
          pageRef,
          collectionId,
          collectionName,
          fields,
          records,
          bindings,
          origin: biDefaults.origin,
        })
      }),

    convertStaticToDynamicFromScratch: async ({ pageRef, biDefaults }) =>
      withDPErrorHandler(async () => {
        const { collectionId, collectionName } =
          await generateCollectionNameAndIdFromPage({
            pageRef,
            editorSdkProxy,
            collectionsApi,
          })

        const pagesToAdd = [
          { key: 'itemPage', staticPageRef: pageRef },
          { key: 'indexPage' },
        ]

        const appApi = await finalAppApiPromise

        return appApi.addDynamicPages({
          pagesToAdd,
          collectionId,
          collectionName,
          biDefaults: {
            ...biDefaults,
            is_first_data_modal: false,
          },
        })
      }),

    convertStaticToDynamicFromCollection: async ({
      pageRef,
      collectionId,
      biDefaults,
    }) =>
      withDPErrorHandler(async () => {
        const schema = await collectionsApi.get(collectionId)

        if (isSinglesCollectionSchema(schema)) {
          return openDPsFromSinglesErrorPanel({
            experimentsManager,
            editorSdkProxy,
            logger,
            i18n,
          })
        }

        const { managingAppDefId } = await editorSdkProxy.pages.getPageData({
          pageRef,
        })

        const isNewConversionFlowEnabled = experimentsManager.enabled(
          'specs.wixDataClient.ConvertToDynamicPagePanelV2',
        )

        const shouldAddOnlyItemPage =
          isNewConversionFlowEnabled ||
          (await appHasDPsForCollection({
            editorSdkProxy,
            collectionId,
            managingAppDefId,
          }))

        const pagesToAdd = shouldAddOnlyItemPage
          ? [{ key: 'itemPage', staticPageRef: pageRef }]
          : [{ key: 'itemPage', staticPageRef: pageRef }, { key: 'indexPage' }]

        const appApi = await finalAppApiPromise

        return appApi.addDynamicPages({
          pagesToAdd,
          collectionId,
          collectionName: schema.displayName,
          biDefaults: {
            ...biDefaults,
            is_first_data_modal: false,
          },
          fromExistingCollection: true,
        })
      }),

    addPresetWithGivenPages: async ({
      presetId,
      pagesToAdd,
      collectionName,
      origin,
      isFirstTimeExperience = false,
    }) =>
      withDPErrorHandler(async () => {
        const allCollections = await collectionsApi.getAll()

        const collectionId = generateCollectionId(
          collectionName,
          allCollections,
        )

        const appApi = await finalAppApiPromise
        return appApi.addDynamicPages({
          presetId,
          pagesToAdd,
          collectionId,
          collectionName,
          biDefaults: {
            origin,
            is_first_data_modal: isFirstTimeExperience,
          },
        })
      }),

    addPreset: async ({
      presetId,
      origin,
      isFirstTimeExperience,
      propagateErrors = false,
    }) =>
      withDPErrorHandler(
        async () =>
          addPreset({
            appApi: await finalAppApiPromise,
            collectionsApi,
            editorType,
            presetId,
            origin,
            isFirstTimeExperience,
          }),
        propagateErrors,
      ),
    async disconnectDynamicPage({ routerRef, pageRef }) {
      return disconnectDynamicPage({
        editorSdkProxy,
        collectionsApi,
        routerRef,
        pageRef,
        appApi: await finalAppApiPromise,
      })
    },

    async deleteDynamicPage({ routerRef, pageRef }) {
      return deleteDynamicPage({
        editorSdkProxy,
        collectionsApi,
        routerRef,
        pageRef,
      })
    },

    async openConvertToDynamicPagePanel({ origin, pageRef }) {
      const openConvertToDynamicPagePanel =
        createConvertStaticToDynamicPagePanelOpener({
          appApi: await finalAppApiPromise,
          hostname,
          editorSdkProxy,
          i18n,
          editorType,
        })
      return openConvertToDynamicPagePanel({
        origin,
        pageRef,
      })
    },

    async openAddDynamicPagePanelV4({ origin, isFirstTimeExperience = false }) {
      const openNewDynamicPagePanel = createNewDynamicPageV4PanelOpener({
        appApi: await finalAppApiPromise,
        hostname,
        editorSdkProxy,
        i18n,
        editorType,
        isFirstTimeExperience,
      })
      return openNewDynamicPagePanel({
        origin,
      })
    },

    async openDynamicPageInfoPanel({ controllerRef }) {
      return openDynamicPageInfoPanel({ editorSdkProxy }, { controllerRef })
    },

    async openPresetScanningPanel({ pageRef }) {
      return openPresetScanningPanel({
        hostname,
        editorSdkProxy,
        editorType,
        pageRef,
      })
    },

    duplicateDynamicPage: async ({ pageRef, origin }) =>
      withDPErrorHandler(() =>
        duplicateDynamicPage({
          arePageLinksCreatedOnBackend:
            experimentsManager.enabled(
              'specs.clouddata.EnableEditorDocumentChangeEvent',
            ) &&
            experimentsManager.enabled('specs.WixCodeOpenCodeAppIdEnabled'),
          pageRef,
          origin,
          editorSdkProxy,
          collectionsApi,
          logger,
          i18n,
          editorType,
          fedopsLogger,
        }),
      ),

    openConvertToDynamicPagePanelV2: async ({ pageRef, origin }) =>
      openConvertToDynamicPagePanelV2({
        editorSdkProxy,
        appApi: await finalAppApiPromise,
        hostname,
        initialData: { editorType, pageRef, origin },
      }),

    openFieldPropertiesPanel: async ({
      name,
      keyIsRequired,
      key,
      typeOptions,
      type,
      otherKeys,
      origin,
      y,
    }) =>
      openFieldPropertiesPanel({
        editorSdkProxy,
        hostname,
        i18n,
        initialData: {
          name,
          keyIsRequired,
          key,
          typeOptions,
          type,
          otherKeys,
          origin,
          editorType,
        },
        y,
      }),
  }
}
