import getPageRouter from '../utils/getPageRouter'
import * as routerConfig from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/routerConfig'
import { updateDynamicPageUrl } from '../../business-logic/dynamic-pages/dynamicPagesApi'
import { lowerCaseStaticUrlParts } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/routing-url/utils'
import validateRoutingUrl from './validateRoutingUrl'

export default async ({
  editorSdkProxy,
  collectionsApi,
  i18n,
  pageRef,
  newUrl,
  oldUrl,
}) => {
  const router = await getPageRouter({ editorSdkProxy, pageRef })
  const {
    def: {
      config: { collection: collectionId },
    },
  } = routerConfig.getPatternDefForPage(router.config, router.pages, pageRef)

  const lowerCasedUrl = lowerCaseStaticUrlParts(newUrl)

  const routingUrlValidationResult = await validateRoutingUrl({
    editorSdkProxy,
    collectionsApi,
    i18n,
    pageRef,
    routingUrl: lowerCasedUrl,
    oldUrl,
  })

  if (routingUrlValidationResult.valid) {
    await updateDynamicPageUrl({
      editorSdkProxy,
      collectionsApi,
      pageRef,
      collectionId,
      newUrl: lowerCasedUrl,
    })
  }

  return { lowerCasedUrl, routingUrlValidationResult }
}
