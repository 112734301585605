import { FieldType } from '@wix/wix-data-schema-types'
import { MUSICPLAYER_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { text, image, url, audio, stringArray, address } = FieldType

export default ({ i18n }) => ({
  componentName: 'Element_Name_Music_Player',
  isConnectGfppEnabled: true,
  panel: {
    height: 467,
    title: 'Connect_Panel_Title_Music_Player',
    helpId: 'df3089a4-3fce-4594-99f0-b741e07821ca',
  },
  sections: [
    {
      role: MUSICPLAYER_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_MusicPlayer_ArtistName_Label',
          prop: 'artistName',
          supportedFieldTypes: [text, address, stringArray],
          defaultFieldType: text,
          supportsReferenceFields: true,
          emptyValue: null,
        },
        {
          title: 'Connect_Panel_Connect_MusicPlayer_TrackName_Label',
          prop: 'trackName',
          supportedFieldTypes: [text, address, stringArray],
          defaultFieldType: text,
          supportsReferenceFields: true,
          emptyValue: null,
        },
        {
          title: 'Connect_Panel_Connect_MusicPlayer_Cover_Label',
          prop: 'coverImage',
          supportedFieldTypes: [image],
          defaultFieldType: image,
          supportsReferenceFields: true,
          emptyValue: null,
        },
        {
          title: 'Connect_Panel_Connect_MusicPlayer_Src_Label',
          prop: 'src',
          supportedFieldTypes: [audio, url],
          defaultFieldType: audio,
          supportsReferenceFields: true,
          emptyValue: null,
          requiresPrimaryConnection: true,
        },
      ],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'audio_player_live_preview_gfpp_desktop_main_action',
              label: i18n.t(
                'Audio_Player_Live_Preview_GFPP_Desktop_Main_Action',
              ),
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Audio_Player_Manage_Content_Panel_Description',
      },
    },
  ],
  compactErrorPanel: true,
})
