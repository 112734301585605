import { parseUrlPattern } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/routing-url/utils'
import { isDynamicRoute } from '../../isDynamicRoute'
import { getSeoValues } from './getSeoValues'

export const generateSeoMetaTags = ({ collection, editorType, route }) => {
  const seoMetaTags = {}

  if (isDynamicRoute({ route })) {
    const { fields } = parseUrlPattern(route)

    const { description, image } = getSeoValues({
      collection,
      editorType,
      title: fields[0],
    })

    if (description) {
      seoMetaTags.description = description
    }

    if (image) {
      seoMetaTags['og:image'] = image
    }
  }

  return seoMetaTags
}
