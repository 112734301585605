export default (
  controllerRef,
  displayName,
  collectionName,
  type,
  readWriteType,
) => ({
  controllerRef,
  datasetName: displayName,
  collectionName,
  disabled: !collectionName,
  type,
  readWriteType,
})
