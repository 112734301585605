import { generateCollectionIdAndName } from './generateCollectionIdAndName'

const generateCollectionNameAndIdFromPage = async ({
  pageRef,
  editorSdkProxy,
  collectionsApi,
}) => {
  const [pageData, collections] = await Promise.all([
    editorSdkProxy.pages.getPageData({ pageRef }),
    collectionsApi.getAll(),
  ])

  return generateCollectionIdAndName({
    allCollections: collections,
    baseName: pageData.title,
  })
}

export default generateCollectionNameAndIdFromPage
