import { APP_DEF_ID } from '@wix/wix-data-client-common/src/consts'
import { DATASET } from '@wix/wix-data-client-common/src/datasetTypes'

export default (displayName, config) => ({
  componentType: 'platform.components.AppController',
  data: {
    controllerType: DATASET,
    name: displayName,
    applicationId: APP_DEF_ID,
    settings: JSON.stringify(config),
  },
})
