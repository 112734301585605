import Maybe from 'folktale/maybe'
import { defaults } from 'lodash'
import ComponentTypes from '@wix/dbsm-common/src/componentTypes'
import hasInputProperties from './hasInputProperties'
import Button from './components/button.js'
import Checkbox from './components/checkbox.js'
import Column from './components/column.js'
import DatePicker from './components/datePicker.js'
import Dropdown from './components/dropdown.js'
import Gallery from './components/gallery.js'
import IconButton from './components/iconButton.js'
import Image from './components/image.js'
import MediaContainer from './components/mediaContainer.js'
import Page from './components/page.js'
import Pagination from './components/pagination.js'
import RadioButtonGroup from './components/radioButtonGroup.js'
import RatingsDisplay from './components/ratingsDisplay.js'
import RatingsInput from './components/ratingsInput.js'
import Repeater from './components/repeater.js'
import Slider from './components/slider.js'
import StripContainer from './components/stripContainer.js'
import Table from './components/table.js'
import Text from './components/text.js'
import TextBox from './components/textBox.js'
import RichTextBox from './components/richTextBox.js'
import TextInput from './components/textInput.js'
import ToggleSwitch from './components/toggleSwitch.js'
import UploadButton from './components/uploadButton.js'
import Video from './components/video.js'
import VideoPlayer from './components/videoPlayer.js'
import VideoBox from './components/videoBox.js'
import MusicPlayer from './components/musicPlayer.js'
import AddressInput from './components/addressInput.js'
import TimePicker from './components/timePicker.js'
import GoogleMap from './components/googleMap.js'
import CheckboxGroup from './components/checkboxGroup.js'
import StylableButton from './components/stylableButton.js'
import ClassicSection from './components/classicSection.js'
import ProgressBar from './components/progressBar.js'
import VectorImage from './components/vectorImage.js'
import SelectionTags from './components/selectionTags.js'
import SignatureInput from './components/signatureInput.js'
import RichContent from './components/richContent.js'

const definitionsByType = {
  [ComponentTypes.AddressInput]: AddressInput,
  [ComponentTypes.Button]: Button,
  [ComponentTypes.Checkbox]: Checkbox,
  [ComponentTypes.Column]: Column,
  [ComponentTypes.DatePicker]: DatePicker,
  [ComponentTypes.Dropdown]: Dropdown,
  [ComponentTypes.Gallery]: Gallery,
  [ComponentTypes.GoogleMap]: GoogleMap,
  [ComponentTypes.IconButton]: IconButton,
  [ComponentTypes.Image]: Image,
  [ComponentTypes.MediaContainer]: MediaContainer,
  [ComponentTypes.Page]: Page,
  [ComponentTypes.Pagination]: Pagination,
  [ComponentTypes.RadioButtonGroup]: RadioButtonGroup,
  [ComponentTypes.RatingsDisplay]: RatingsDisplay,
  [ComponentTypes.RatingsInput]: RatingsInput,
  [ComponentTypes.Repeater]: Repeater,
  [ComponentTypes.Slider]: Slider,
  [ComponentTypes.StripColumnsContainer]: StripContainer,
  [ComponentTypes.Table]: Table,
  [ComponentTypes.Text]: Text,
  [ComponentTypes.TextBox]: TextBox,
  [ComponentTypes.TimePicker]: TimePicker,
  [ComponentTypes.RichTextBox]: RichTextBox,
  [ComponentTypes.TextInput]: TextInput,
  [ComponentTypes.ToggleSwitch]: ToggleSwitch,
  [ComponentTypes.UploadButton]: UploadButton,
  [ComponentTypes.Video]: Video,
  [ComponentTypes.VideoPlayer]: VideoPlayer,
  [ComponentTypes.VideoBox]: VideoBox,
  [ComponentTypes.MusicPlayer]: MusicPlayer,
  [ComponentTypes.CheckboxGroup]: CheckboxGroup,
  [ComponentTypes.StylableButton]: StylableButton,
  [ComponentTypes.ProgressBar]: ProgressBar,
  [ComponentTypes.VectorImage]: VectorImage,
  [ComponentTypes.SelectionTags]: SelectionTags,
  [ComponentTypes.ClassicSection]: ClassicSection,
  [ComponentTypes.SignatureInput]: SignatureInput,
  [ComponentTypes.RichContent]: RichContent,
}

const addDefaulsToDefinition = definition =>
  defaults({}, definition, {
    hasInputProperties: hasInputProperties(definition),
    compactErrorPanel: false,
    supportsDefaultSelectedDataset: true,
  })

export const getBindingDefinitions = async ({
  componentType,
  bindingDefinitionLoaders = new Map(),
  experimentsManager,
  i18n,
}) => {
  if (bindingDefinitionLoaders.has(componentType)) {
    const loader = bindingDefinitionLoaders.get(componentType)
    const { dataBindingPanel: definition } = await loader()
    return Maybe.Just(addDefaulsToDefinition(definition))
  }

  return Maybe.fromNullable(definitionsByType[componentType]).map(
    definitionCreator =>
      addDefaulsToDefinition(definitionCreator({ i18n, experimentsManager })),
  )
}

export const getAllBindingDefinitions = async ({
  bindingDefinitionLoaders = new Map(),
  experimentsManager,
  i18n,
}) => {
  const resultDefinitions = {}
  for (const [sdkType, definitionCreator] of Object.entries(
    definitionsByType,
  )) {
    resultDefinitions[sdkType] = addDefaulsToDefinition(
      definitionCreator({ i18n, experimentsManager }),
    )
  }

  for (const [sdkType, loader] of bindingDefinitionLoaders.entries()) {
    const { dataBindingPanel: definition } = await loader()
    resultDefinitions[sdkType] = addDefaulsToDefinition(definition)
  }

  return resultDefinitions
}

// old implementation for create repeater collection panel only
// to be removed in the future
export const getBindingDefinitionsSync = ({
  componentType,
  experimentsManager,
  i18n,
}) => {
  return Maybe.fromNullable(definitionsByType[componentType]).map(
    definitionCreator =>
      addDefaulsToDefinition(definitionCreator({ experimentsManager, i18n })),
  )
}

export const bindableComponentTypes = Object.keys(definitionsByType)
