import openConfirmationPanel from './openConfirmationPanel'
import { getAssetUrlInWorker } from '../getAssetUrl'

export default async ({
  editorSdkProxy,
  logger,
  t,
  headerText,
  descriptionText,
  mainActionText,
}) => {
  const panelTexts = {
    headerText: t('Workspace_Error_Panel_Title_General'),
    descriptionText: t('Workspace_Error_Panel_Text_General'),
    mainActionText: t('Workspace_Error_Panel_Button'),
  }

  openConfirmationPanel({
    editorSdkProxy,
    logger,
    illustration: await getAssetUrlInWorker(
      editorSdkProxy,
      'panel-icons/generic_error.svg',
    ),
    headerText: headerText || panelTexts.headerText,
    descriptionText: descriptionText || panelTexts.descriptionText,
    mainActionText: mainActionText || panelTexts.mainActionText,
  })
}
