const setExtras = (scope, extras) => {
  if (extras) {
    scope.setExtras(extras)
  }
}

const updateContext =
  Sentry =>
  ({ userId, tags }) => {
    if (userId !== undefined) {
      Sentry.setUser({ id: userId })
    }
    if (tags) {
      Sentry.setTags(tags)
    }
  }

export const sentryAdapter = Sentry => {
  const adapter = {
    error: (error, extras) =>
      Sentry.withScope(scope => {
        setExtras(scope, extras)
        Sentry.captureException(error)
      }),
    info: (message, extras) =>
      Sentry.withScope(scope => {
        setExtras(scope, extras)
        Sentry.captureMessage(message, 'info')
      }),
    breadcrumb: ({ message, category, data }) => {
      Sentry.addBreadcrumb({
        message,
        category,
        data,
      })
    },
    updateContext: ({ scopedOperation, ...rest }) => {
      if (scopedOperation) {
        Sentry.withScope(scope => {
          updateContext(scope)(rest)
          scopedOperation(adapter)
        })
      } else {
        updateContext(Sentry)(rest)
      }
    },
  }

  return adapter
}
