import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'
import { removeSchema } from '@wix/wix-code-collections-api'
import {
  AppCollectionVisibility,
  appCollectionVisibilityChanged,
} from '@wix/wix-data-client-common/src/appCollectionVisibility'
import createDeferred from '@wix/wix-data-client-common/src/utils/createDeferred'
import getDeveloperMode from '@wix/wix-data-client-common/src/utils/getDeveloperMode'
import openContentManager from '../panels/openContentManager'
import createExternalDbDriverPanelOpener from '../panels/openExternalDbDriverPanel'
import createCollectionPanelOpener from '../panels/openCreateCollectionPanel'

import {
  openAutoCMSItemViewForCurrentDynamicPage,
  openAutoCMSNewItemView,
  openAutoCMSCollectionView,
  openAutoCMSCollectionsList,
  openAutoCMSAdvancedSettings,
} from '../panels/openAutoCMS'

export default ({
  experimentsManager,
  i18n,
  logger,
  editorSdkProxy,
  collectionsApi,
  hostname,
  finalAppApiPromise,
  fedopsLogger,
  editorType,
  wixData,
  livePreview,
  appCollectionVisibilityPreference,
}) => {
  let collectionSchemaRefreshIsAllowed = true
  let deferredCollectionSchemaRefresh = null

  return {
    async removeCollection({ collectionId }) {
      await collectionsApi.execute(removeSchema(collectionId))
      ;(await finalAppApiPromise).emit('collectionRemoved', collectionId)
    },

    async onExternalDbDriverRemoved({ namespace }) {
      ;(await finalAppApiPromise).emit('externalDbDriverRemoved', namespace)
    },

    async openContentManager({ collectionId, origin, isModal }) {
      return openContentManager({
        hostname,
        editorSdkProxy,
        collectionName: collectionId,
        isModal,
        origin,
        wixData,
        collectionsApi,
        livePreview,
        i18n,
      })
    },

    async openAutoCmsItemViewForCurrentDynamicPage({ origin }) {
      return openAutoCMSItemViewForCurrentDynamicPage({
        editorSdkProxy,
        collectionsApi,
        origin,
        wixData,
        livePreview,
        hostname,
        i18n,
      })
    },

    async openAutoCmsNewItemView({ collectionId, origin }) {
      return openAutoCMSNewItemView({
        editorSdkProxy,
        collectionId,
        origin,
        wixData,
        collectionsApi,
        livePreview,
        hostname,
        i18n,
      })
    },
    async openAutoCmsCollectionView({ collectionId, origin }) {
      return openAutoCMSCollectionView({
        editorSdkProxy,
        collectionId,
        collectionsApi,
        origin,
        wixData,
        livePreview,
        hostname,
        i18n,
      })
    },
    async openAutoCmsCollectionsList({ origin }) {
      return openAutoCMSCollectionsList({
        editorSdkProxy,
        origin,
        wixData,
        collectionsApi,
        livePreview,
        hostname,
        i18n,
      })
    },

    async openAutoCmsAdvancedSettings({ origin }) {
      return openAutoCMSAdvancedSettings({
        editorSdkProxy,
        origin,
        wixData,
        collectionsApi,
        livePreview,
        hostname,
        i18n,
      })
    },

    async openExternalDbDriverPanel(namespace) {
      return createExternalDbDriverPanelOpener({
        hostname,
        logger,
        editorSdkProxy,
      })(namespace)
    },

    async openCreateCollectionPanel({
      isEditMode,
      collectionId,
      origin,
      clickedInSiteStructure,
      initialScreen,
      supportsOnlyMultipleItemCollections,
    }) {
      const openCreateCollectionPanel = createCollectionPanelOpener({
        hostname,
        collectionsApi,
        fedopsLogger,
        logger,
        editorSdkProxy,
        i18n,
        experimentsManager,
        editorType,
        initialScreen,
        wixData,
        livePreview,
        appApi: this,
      })
      return openCreateCollectionPanel({
        isEditMode,
        collectionId,
        origin,
        clickedInSiteStructure,
        supportsOnlyMultipleItemCollections,
      })
    },

    async openCollectionPermissionsPanel({ collectionId }) {
      const isCreateCollectionPanelV2 = experimentsManager.enabled(
        'specs.wixDataClient.CreateCollectionPanelV2',
      )

      if (isCreateCollectionPanelV2) {
        const url = await getPanelUrl({
          hostname,
          relativeUrl: 'viewPermissionsPanel.html',
          editorSdkProxy,
        })

        await editorSdkProxy.editor
          .openModalPanel({
            url,
            width: 600,
            height: 647,
            shouldHideHeader: true,
            initialData: {
              collectionId,
            },
          })
          .catch(logger.error)
      } else {
        const url = await getPanelUrl({
          hostname,
          relativeUrl: 'collectionsPermissions.html',
          editorSdkProxy,
        })

        await editorSdkProxy.editor
          .openModalPanel({
            url,
            width: 600,
            height: 600,
            shouldHideHeader: true,
            initialData: {
              collectionName: collectionId,
            },
          })
          .catch(logger.error)
      }
    },

    async refreshCollectionSchemasWhenAllowed() {
      if (collectionSchemaRefreshIsAllowed) {
        return (await finalAppApiPromise).refreshSchemasCache()
      }

      if (deferredCollectionSchemaRefresh === null) {
        deferredCollectionSchemaRefresh = createDeferred()
      }
      return deferredCollectionSchemaRefresh.promise
    },

    disallowCollectionSchemaRefresh() {
      collectionSchemaRefreshIsAllowed = false
    },

    allowCollectionSchemaRefresh() {
      if (!collectionSchemaRefreshIsAllowed) {
        collectionSchemaRefreshIsAllowed = true
        if (deferredCollectionSchemaRefresh !== null) {
          finalAppApiPromise
            .then(api => api.refreshSchemasCache())
            .then(
              deferredCollectionSchemaRefresh.resolve,
              deferredCollectionSchemaRefresh.reject,
            )
          deferredCollectionSchemaRefresh = null
        }
      }
    },

    getAppCollectionVisibility: async () =>
      (await appCollectionVisibilityPreference.get()) ??
      ((await getDeveloperMode(editorSdkProxy))
        ? AppCollectionVisibility.VISIBLE
        : AppCollectionVisibility.HIDDEN),

    setAppCollectionVisibility: async visibility => {
      await appCollectionVisibilityPreference.set(visibility)

      const appApi = await finalAppApiPromise
      await appApi.refreshSchemasCache()
      appApi.emit(appCollectionVisibilityChanged, visibility)
    },
  }
}
