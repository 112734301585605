import { FieldType } from '@wix/wix-data-schema-types'

export const DYNAMIC_PAGES_PER_COLLECTION_LIMIT = 64

export const DPErrorCodes = {
  TOO_MANY_DYNAMIC_PAGES_FOR_COLLECTION:
    'TOO_MANY_DYNAMIC_PAGES_FOR_COLLECTION',
  NO_FIELD_KEY_IN_DB_DRIVER_COLLECTION: 'NO_FIELD_KEY_IN_DB_DRIVER_COLLECTION',
}

export const SEO_FIELD_TYPES = {
  title: [FieldType.text, FieldType.number],
  description: [FieldType.text, FieldType.number],
  keywords: [FieldType.text, FieldType.number],
  'og:image': [FieldType.image, FieldType.text],
}

export const NEW_DYNAMIC_PAGE_FLOWS = {
  ADD_FROM_COLLECTION: 'ADD_FROM_COLLECTION',
  ADD_FROM_PRESET: 'ADD_FROM_PRESET',
  ADD_PRESET_WITH_GIVEN_PAGES: 'ADD_PRESET_WITH_GIVEN_PAGES',
  CONVERT_STATIC_TO_DYNAMIC_FROM_SCRATCH:
    'CONVERT_STATIC_TO_DYNAMIC_FROM_SCRATCH',
  CONVERT_STATIC_TO_DYNAMIC_FROM_COLLECTION:
    'CONVERT_STATIC_TO_DYNAMIC_FROM_COLLECTION',
  CREATE_COLLECTION: 'CREATE_COLLECTION',
}

export const CLASSIC_PRESETS_ORDER = [
  'team',
  'projects',
  'news',
  'portfolio',
  'realEstateListings',
  'recipes',
  'jobs',
  'courses',
  'services',
]

export const CLASSIC_PRESETS_ORDER_V4 = [
  'projects',
  'services',
  'news',
  'portfolio',
  'recipes',
  'realEstateListings',
  'courses',
  'jobs',
  'team',
]

export const RESPONSIVE_PRESETS_ORDER = [
  'team',
  'projects',
  'news',
  'portfolio',
]
