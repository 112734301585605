import { FieldType } from '@wix/wix-data-schema-types'
import { VIDEO_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { url } = FieldType

export default () => ({
  componentName: 'Element_Name_Video',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Social_Video_Player',
    helpId: '2549c1e9-97fe-4e10-bae3-303c5c217ea8',
  },
  sections: [
    {
      role: VIDEO_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Video_URL',
          prop: 'videoUrl',
          supportedFieldTypes: [url],
          defaultFieldType: url,
          supportsReferenceFields: true,
        },
      ],
    },
  ],
})
