import {
  createPageLinkFields,
  getFields,
} from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/page-link-field/editorActions'
import { generateKey as generatePageLinkFieldKey } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/page-link-field/pageLinkField'

export const addPageLinkField = async ({
  collectionsApi,
  collectionId,
  routerPrefix,
  route,
  pageId,
  displayName,
  arePageLinksCreatedOnBackend,
}) => {
  const collectionFields = await getFields(collectionsApi, collectionId)
  const existingFieldKeys = Object.keys(collectionFields)

  const pageLinkFieldKey = generatePageLinkFieldKey({
    prefix: routerPrefix,
    pattern: route,
    existingFieldKeys,
    arePageLinksCreatedOnBackend,
  })

  await createPageLinkFields({
    collectionsApi,
    collectionId,
    fieldsData: [
      {
        key: pageLinkFieldKey,
        displayName,
        prefix: routerPrefix,
        pattern: route,
        pageId,
      },
    ],
  })
}
