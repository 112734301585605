import getPageRouter from '../utils/getPageRouter'
import getCollectionFields from '../utils/getCollectionFields'
import * as routerConfig from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/routerConfig'
import { parseUrl } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/routing-url/utils'
import { getExistingPatternsByPrefix } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/editorActions'
import { validateUrl as validateRoutingUrl } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/routing-url/validate'

export default async ({
  editorSdkProxy,
  collectionsApi,
  i18n,
  pageRef,
  routingUrl,
  oldUrl,
}) => {
  const router = await getPageRouter({ editorSdkProxy, pageRef })
  const {
    def: {
      config: { collection: collectionId },
    },
  } = routerConfig.getPatternDefForPage(router.config, router.pages, pageRef)

  const collectionFields = await getCollectionFields({
    collectionsApi,
    collectionId,
  })

  const { prefix } = parseUrl(routingUrl)
  const { prefix: oldPrefix, pattern: oldPattern } = parseUrl(oldUrl)

  const existingPatterns =
    prefix === oldPrefix
      ? routerConfig
          .getAllPatterns(router.config)
          .filter(existingPattern => existingPattern !== oldPattern)
      : await getExistingPatternsByPrefix(editorSdkProxy, prefix)

  const validationResult = await validateRoutingUrl(
    editorSdkProxy,
    routingUrl,
    {
      fields: collectionFields,
    },
    existingPatterns,
  )

  return {
    ...validationResult,
    invalidMessage: i18n.t(validationResult.error, validationResult.params),
  }
}
