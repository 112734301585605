import { isEqual } from 'lodash'
import { DETAILS_DATASET_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
import {
  READ,
  READ_WRITE,
} from '@wix/wix-data-client-common/src/dataset-configuration/readWriteModes'
import { hasFilterBindings } from '../connections/connectionConfigHelper'
import fetchConnectableDatasets from '../datasets/fetchDatasets'
import getControllerConnections from './getControllerConnections'

const getDetailsRefs = async (editorSdkProxy, masterRef) =>
  (await getControllerConnections(editorSdkProxy, masterRef))
    .filter(({ config }) => hasFilterBindings(config))
    .map(({ componentRef }) => componentRef)

const createMasterDetailConnection = ({
  masterRef,
  detailRef,
  connectionConfig,
}) => ({
  controllerRef: masterRef,
  componentRef: detailRef,
  config: connectionConfig,
  role: DETAILS_DATASET_ROLE,
})

const getMasterRefFromConnection = connection => connection.controllerRef

const getMasterControllerCandidates = async function (
  editorSdkProxy,
  currentControllerRef,
) {
  const connectableControllers = await fetchConnectableDatasets(
    editorSdkProxy,
    currentControllerRef,
  )
  return connectableControllers.filter(
    controller =>
      !isEqual(controller.controllerRef, currentControllerRef) &&
      ([READ, READ_WRITE].includes(controller.readWriteType) ||
        !!controller.collectionName),
  )
}

export {
  getDetailsRefs,
  createMasterDetailConnection,
  getMasterRefFromConnection,
  getMasterControllerCandidates,
}
