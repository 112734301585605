import {
  CLOUD_DATA_SCHEMA_NOTIFIER,
  WIX_CODE,
  DATA_BINDING,
  WIX_FORMS,
} from '@wix/app-definition-ids'
import { features } from '@wix/wix-data-client-common/src/features'
import createAppManifest from './app-manifest'
import * as editorSDKInfra from '@wix/wix-data-client-common/src/infra/editorSDKInfra'
import getAppVersion from '@wix/wix-data-client-common/src/infra/getAppVersion'
import { createCollectionsApi } from '@wix/wix-data-client-common/src/infra/createCollectionsApi'

import createCollectionsTransportWorker from './collections-transport/transport'
import createVfsAdapter from './collections-transport/vfsAdapter'
import createSchemasClientAdapter from './collections-transport/schemasClientAdapter'
import decorateAdapterWithAppVisibilityControl from './collections-transport/decorateAdapterWithAppVisibilityControl'
import createProxy from './proxy'
import createCallFunctionWhenValueChanges from '@wix/wix-data-client-common/src/infra/callFunctionWhenValueChanges'
import * as wixDataSettingsClientManager from '@wix/wix-data-client-common/src/wixDataSettingsClientManager'
import {
  getActionsBySchema,
  updateAppStateActions,
} from './schemaContextMenuActions'
import { Duplexer } from '@wix/duplexer-js'
import { createSafeUserPreferences } from '@wix/wix-data-client-common/src/user-preferences/safeUserPreferences'
import { resolveEditorType } from '@wix/wix-data-client-common/src/infra/editorType'
import assertUniqueMethods from './utils/assertUniqueMethods'
import createPublicApi from './publicApi'
import fixDynamicPages from './utils/fixDynamicPages'
import createAppCollectionVisibilityPreference from './createAppCollectionVisibilityPreference'
import setWorkerChunkBasePath from './setWorkerChunkBasePath'
import {
  createLogger,
  sentryAdapter,
  localDebugHandler,
  biAdapter,
  getBiDefaults,
} from '@wix/wix-data-client-common/src/infra/logger'
import { loadServerExperiments as loadServerExperimentsFromProd } from './experiments/loader'
import { HttpClient } from '@wix/http-client'
import editorSdkProxyCreator from '@wix/wix-code-editor-sdk-proxy'
import { editorAutomationsClientCreator } from '@wix/wix-code-automations-client'
import { createSentry, createSentryNext } from './sentry'
import { setUpTemporaryLogStorage } from './logger/storage'
import * as transliteration from './transliteration'
import errorToLoggerParams from '@wix/wix-data-client-common/src/infra/errorToLoggerParams'

export default ({
  hostname,
  i18nInstance,
  experimentsManager: pendingExperimentsManager,
  loadServerExperiments = loadServerExperimentsFromProd,
  fedopsLogger,
  i18nCreator,
  loadDataTypeManifests,
  bindingDefinitionLoaders = new Map(),
  automationsClient: initialAutomationsClient,
  WixDataSettings,
  WixDataConnectorSettings,
  createWixData,
  createWixDataSchemas,
  moduleExports,
  setUpListeningForCollectionSchemaChange = (codeAppInstance, listener) => {
    const duplexer = new Duplexer('duplexer.wix.com', {
      instanceUpdater: {
        getInstance() {
          return codeAppInstance
        },
      },
    })
    const connection = duplexer.connect({
      appDefId: CLOUD_DATA_SCHEMA_NOTIFIER,
    })
    const channel = connection.subscribe('wix-data-schema-change-notifications')
    channel.on('schema-change', () => {
      listener()
    })
  },
  setUpAppCollectionVisibilityPreference = createAppCollectionVisibilityPreference,
  Sentry: initialSentry,
  transliterateAndSlugify = transliteration.transliterateAndSlugify,
  bindingDefinitionsRegistry,
  appVersion: initialAppVersion,
}) => {
  const logger = createLogger()
  logger.registerHandler(localDebugHandler())
  const temporaryLogStorage = setUpTemporaryLogStorage(logger)

  let editorReadyPromise
  let automationsClient

  const publicApi = createPublicApi(
    moduleExports.map(({ publicApi }) => publicApi).filter(Boolean),
  )

  const collectionsTransportProxy = createProxy([
    'listSchemas',
    'loadSchema',
    'removeSchema',
    'saveSchema',
  ])

  const initTranslations = async function (locale) {
    await i18nInstance.init(locale)
    return i18nInstance
  }

  const onEditorReady = async function (
    uninitializedEditorSDK,
    appToken,
    editorEnv,
  ) {
    try {
      const editorSDK = editorSDKInfra.init(logger, uninitializedEditorSDK)
      const editorSdkProxy = editorSdkProxyCreator(editorSDK, appToken)
      const editorType = resolveEditorType({ editorEnv, editorSdkProxy })

      const appVersion =
        initialAppVersion || (await getAppVersion(editorSdkProxy))

      const Sentries = initialSentry
        ? [initialSentry]
        : [createSentry({ appVersion }), createSentryNext({ appVersion })]

      Sentries.forEach(Sentry => {
        logger.registerHandler(sentryAdapter(Sentry))
      })

      logger.updateContext({ userId: await editorSdkProxy.info.getUserId() })

      temporaryLogStorage.flush()

      logger.registerHandler(biAdapter())

      const wixCodePublicApi =
        await editorSdkProxy.document.application.getPublicAPI({
          appDefinitionId: WIX_CODE,
        })
      const { instance, gridAppId } =
        await wixCodePublicApi.getWixCodeAuthParams()

      const httpClient = new HttpClient({ getAppToken: () => instance })

      const [experimentsManager, serverSideExperiments] = await Promise.all([
        pendingExperimentsManager.ready().then(() => pendingExperimentsManager),
        loadServerExperiments({
          httpClient,
          instance,
        }),
      ])
      experimentsManager.add(serverSideExperiments)

      const wixDataSchemasClient = createWixDataSchemas({
        httpClient,
        instance,
        gridAppId,
      })

      const openGridAppEnabled = experimentsManager.enabled(
        'specs.WixCodeOpenCodeAppIdEnabled',
      )

      const adapter = openGridAppEnabled
        ? createSchemasClientAdapter({ wixDataSchemasClient })
        : createVfsAdapter({ editorSDK, appToken })

      const appCollectionVisibilityControlIsEnabled =
        experimentsManager.enabled(
          'specs.wixData.AppCollectionVisibilityControl',
        )

      let resolveAppApi
      const finalAppApiPromise = new Promise(resolve => {
        resolveAppApi = resolve
      })

      const collectionsTransport = createCollectionsTransportWorker({
        editorSDK,
        appToken,
        logger,
        adapter: appCollectionVisibilityControlIsEnabled
          ? decorateAdapterWithAppVisibilityControl({
              logger,
              adapter,
              editorSdkProxy,
              finalAppApiPromise,
            })
          : adapter,
        finalAppApiPromise,
        getActionsBySchema: async ({ schemas }) =>
          await getActionsBySchema({
            editorSdkProxy,
            experimentsManager,
            schemas,
            editorType,
          }),
      })
      collectionsTransportProxy.setTarget(collectionsTransport.public)

      const locale = await editorSdkProxy.environment.getLocale()
      const i18n = await initTranslations(locale)
      const t = i18n.t.bind(i18n)
      const collectionsApi = createCollectionsApi(
        editorSDK,
        appToken,
        collectionsTransport.public,
      )

      let metaSideId = await editorSdkProxy.info.getMetaSiteId()

      const variableBiDefaults = { msid: () => metaSideId }

      const dataModePromise = wixDataSettingsClientManager.isDataModeEnabled({
        editorSdkProxy,
        WixDataSettings,
        logger,
        httpClient,
      })

      logger.updateContext({
        tags: {
          locale,
          msid: metaSideId,
          resolvedEditorType: editorType,
          editorType: editorEnv.origin?.type,
          dataMode: await dataModePromise.catch(() => 'failedToGet'),
        },
      })

      const wixData = await createWixData({ editorSdkProxy, httpClient })
      const safeUserPreferences = createSafeUserPreferences({
        editorSdkProxy,
        logger,
        fedopsLogger,
        timeout: 1000,
      })

      const wixDataConnectorSettingsClient = new WixDataConnectorSettings(
        httpClient,
        instance,
      )

      await setWorkerChunkBasePath(editorSdkProxy)

      const livePreview = features.livePreview({
        editorType,
        experimentsManager,
      })
      const appPagesEnabled = await features.appPages({
        experimentsManager,
        editorSdkProxy,
        editorType,
      })

      automationsClient =
        initialAutomationsClient ||
        editorAutomationsClientCreator({ httpClient })

      logger.updateContext({
        biDefaults: {
          ...variableBiDefaults,
          ...(await getBiDefaults({ appVersion, editorSdkProxy })),
        },
      })

      const moduleServices = {
        i18n,
        logger,
        httpClient,
        editorSdkProxy,
        editorType,
        collectionsApi,
        collectionsTransport,
        fedopsLogger,
        experimentsManager,
        safeUserPreferences,
        wixData,
        hostname,
        WixDataSettings,
        automationsClient,
        bindingDefinitionLoaders,
        wixDataConnectorSettingsClient,
        livePreview,
        appPagesEnabled,
        sdkVersion: editorSdkProxy.document.info.getSdkVersion().version,
        appCollectionVisibilityPreference:
          appCollectionVisibilityControlIsEnabled
            ? setUpAppCollectionVisibilityPreference(instance)
            : null,
        transliterateAndSlugify,
        bindingDefinitionsRegistry,
        finalAppApiPromise,
      }
      const moduleFactories = await Promise.all(
        moduleExports.map(({ createModuleFactories }) =>
          createModuleFactories(moduleServices),
        ),
      )

      const moduleAppApis = await Promise.all(
        moduleFactories.map(({ createAppApi }) =>
          createAppApi({ finalAppApiPromise }),
        ),
      )

      const appApi = moduleAppApis.reduce((api, moduleApi) => {
        assertUniqueMethods(api, moduleApi)
        return { ...api, ...moduleApi }
      }, {})
      for (const [methodName, method] of Object.entries(appApi)) {
        appApi[methodName] = method.bind(appApi)
      }
      await editorSdkProxy.editor.setAppAPI(appApi)
      resolveAppApi(appApi)

      updateAppStateActions({ editorSdkProxy, t })

      wixDataSettingsClientManager.updateAppStateDataModeValue({
        WixDataSettings,
        editorSdkProxy,
        logger,
        experimentsManager,
        appApi,
        httpClient,
      })

      const moduleEventHandlers = await Promise.all(
        moduleFactories.map(({ createEventHandlers }) =>
          createEventHandlers({ appApi }),
        ),
      )

      const doSetUpListeningForCollectionSchemaChange = codeAppInstance => {
        setUpListeningForCollectionSchemaChange(codeAppInstance, async () => {
          await appApi.refreshCollectionSchemasWhenAllowed()
          if (appPagesEnabled) {
            editorSdkProxy.document.application.reloadManifest()
          }
        })
      }

      const siteIsSaved = await editorSdkProxy.info.isSiteSaved()
      if (siteIsSaved) {
        doSetUpListeningForCollectionSchemaChange(instance)
      } else {
        appApi.addListener('siteWasFirstSaved', async () => {
          metaSideId = await editorSdkProxy.info.getMetaSiteId()
          logger.updateContext({ tags: { msid: metaSideId } })

          const changedWixCodeAuthParams =
            await wixCodePublicApi.getWixCodeAuthParams()
          doSetUpListeningForCollectionSchemaChange(
            changedWixCodeAuthParams.instance,
          )
        })
      }

      publicApi.init({
        editorSDK,
        token: appToken,
        i18n,
        editorType,
        collectionsApi,
        wixData,
        safeUserPreferences,
        logger,
        appApi,
        hostname,
        experimentsManager,
        WixDataSettings,
        httpClient,
      })

      const onEventFn = async ({ eventType, eventPayload }) => {
        logger.breadcrumb({
          category: 'event',
          message: `${eventType} - start processing`,
        })
        moduleEventHandlers.forEach(async handler => {
          try {
            await handler({ eventType, eventPayload })
          } catch (error) {
            const tags = {
              eventType,
              ...(eventPayload?.componentType
                ? { componentType: eventPayload.componentType }
                : {}),
            }
            logger.updateContext({
              tags,
              scopedOperation: adapter => {
                adapter.error(...errorToLoggerParams(error))
              },
            })
          }
        })
      }

      logger.breadcrumb({
        category: 'event',
        message: 'editorReady - done',
      })

      return {
        t,
        locale,
        onEventFn,
        experimentsManager,
        editorSdkProxy,
        wixDataSchemasClient,
        collectionsTransport,
        editorSDK,
        editorType,
        appApi,
        collectionsApi,
        appPagesEnabled,
      }
    } catch (e) {
      logger.error(e, {
        metaSiteId: await uninitializedEditorSDK.info
          .getMetaSiteId(appToken)
          .catch(() => {}),
      })
      throw e
    }
  }

  const editorReady = (...args) => {
    logger.breadcrumb({
      category: 'event',
      message: 'editorReady',
    })
    if (editorReadyPromise) {
      return // editorReady called twice. ignoring
    }
    editorReadyPromise = onEditorReady(...args)
  }

  const getAppManifest = async function () {
    if (!editorReadyPromise) {
      throw new Error('getAppManifest called before editorReady')
    }
    try {
      const {
        t,
        experimentsManager,
        editorSdkProxy,
        editorType,
        collectionsApi,
        appPagesEnabled,
      } = await editorReadyPromise

      const routers = appPagesEnabled
        ? await editorSdkProxy.routers.getAll({
            appDefinitionId: DATA_BINDING,
          })
        : undefined

      const { appApi } = await editorReadyPromise
      const bindingDefinitions = await appApi.getAllBindingDefinitions()

      if (appPagesEnabled) {
        await fixDynamicPages(routers, editorSdkProxy.document.pages.data)
      }

      return await createAppManifest({
        hostname,
        t,
        experimentsManager,
        editorSdkProxy,
        editorType,
        bindingDefinitions,
        collectionsApi,
        routers,
        appPagesEnabled,
      })
    } catch (e) {
      logger.error(e)
      throw e
    }
  }

  const getControllerPresets = () => {
    return Promise.resolve([
      {
        type: 'dataset',
        controllerConfig: {
          // initial data
        },
        serializedData: {
          name: 'Dataset (Unconfigured)',
        },
        uiDataForAddPanel: {
          name: 'Dataset',
        },
      },
    ])
  }

  const onEvent = async function ({ eventType, eventPayload }) {
    logger.breadcrumb({
      category: 'event',
      message: eventType,
      data: eventPayload,
    })
    if (!editorReadyPromise) {
      throw new Error('onEvent called before editorReady')
    }
    const { onEventFn } = await editorReadyPromise
    return onEventFn({ eventType, eventPayload })
  }

  const callFunctionWhenValueChanges = createCallFunctionWhenValueChanges()
  const appInstallsToIgnore = [DATA_BINDING, WIX_CODE, WIX_FORMS]

  const refreshCollectionSchemas = async ({ payload }) => {
    if (appInstallsToIgnore.includes(payload.appDefinitionId)) {
      return
    }
    const { appApi, wixDataSchemasClient } = await editorReadyPromise

    appApi.refreshCollectionSchemasWhenAllowed()
    callFunctionWhenValueChanges({
      pollTimes: 10,
      pollInterval: 3000,
      isEqual: (current, previous) =>
        current.length === previous.length &&
        current.every(id => previous.includes(id)),
      getValue: async () => Object.keys(await wixDataSchemasClient.list()),
      fn: () => {
        appApi.refreshCollectionSchemasWhenAllowed()
      },
    })
  }

  const handleAction = async ({ type, payload }) => {
    switch (type) {
      case 'appInstalled': {
        await refreshCollectionSchemas({ payload })
        break
      }
      case 'removeAppCompleted': {
        await refreshCollectionSchemas({ payload })
        break
      }
      default:
        break
    }
  }

  return {
    editorReady,
    handleAction,
    exports: {
      collectionsTransport: collectionsTransportProxy.proxy,
      ...collectionsTransportProxy.proxy,
      ...publicApi.api,
    },
    getAppManifest,
    getControllerPresets,
    onEvent,
  }
}
