/* eslint-disable quotes */
import { merge } from 'lodash'

const createBaseTypeManifest = (controllerName, viewerNames) => ({
  eventHandlers: {
    onCurrentIndexChanged: {
      type: 'currentIndexChanged',
      description:
        'Adds an event handler that runs when the current index changes.',
    },
    onItemValuesChanged: {
      type: 'itemValuesChanged',
      description:
        'Adds an event handler that runs when a value of the current item changes.',
    },
    onAfterSave: {
      type: 'afterSave',
      description: 'Adds an event handler that runs just after a save.',
    },
    onError: {
      type: 'datasetError',
      description: 'Adds an event handler that runs when an error occurs.',
    },
    onReady: {
      type: 'datasetReady',
      description: 'Adds an event handler that runs when the dataset is ready.',
    },
  },
  synthetic: false,
  inherits: {},
  members: {
    save: {
      description: 'Saves the current item.',
      kind: 'function',
    },
    next: {
      description: 'Saves the current item and moves to the next item.',
      kind: 'function',
    },
    nextPage: {
      description: 'Moves to the next page of data.',
      kind: 'function',
    },
    previous: {
      description: 'Saves the current item and moves to the previous item.',
      kind: 'function',
    },
    previousPage: {
      description: 'Moves to the previous page of data.',
      kind: 'function',
    },
    getCurrentItemIndex: {
      description: "Returns the current item's index.",
      kind: 'function',
    },
    setCurrentItemIndex: {
      description: 'Sets the current item by index.',
      kind: 'function',
    },
    setFilter: {
      description: 'Sets the dataset filter.',
      kind: 'function',
    },
    getItems: {
      description: 'Returns the selected items.',
      kind: 'function',
    },
    getTotalCount: {
      description:
        'Returns the number of items in the dataset that match its filter criteria.',
      kind: 'function',
    },
    getCurrentItem: {
      description: 'Returns the current item.',
      kind: 'function',
    },
    setFieldValue: {
      description: 'Updates the value of a field in the current item.',
      kind: 'function',
    },
    setFieldValues: {
      description: 'Updates the values of a set of fields in the current item.',
      kind: 'function',
    },
    hasNext: {
      description: 'Indicates if there is a next item.',
      kind: 'function',
    },
    hasNextPage: {
      description: 'Indicates if there is a next page of data.',
      kind: 'function',
    },
    hasPrevious: {
      description: 'Indicates if there is a previous item.',
      kind: 'function',
    },
    hasPreviousPage: {
      description: 'Indicates if there is a previous page of data.',
      kind: 'function',
    },
    new: {
      description: 'Create a new blank item.',
      kind: 'function',
    },
    remove: {
      description: 'Removes the current item.',
      kind: 'function',
    },
    revert: {
      description: 'Reverts the current item to its saved value.',
      kind: 'function',
    },
    refresh: {
      description: 'Refetches the contents of the dataset from the collection.',
      kind: 'function',
    },
    setSort: {
      description: 'Sets the dataset sort order.',
      kind: 'function',
    },
    onBeforeSave: {
      description: 'Adds an event handler that runs just before a save.',
      kind: 'function',
    },
    loadMore: {
      description:
        'Loads the next page of data in addition to the current data.',
      kind: 'function',
    },
    getPageSize: {
      description: "Gets the dataset's page size.",
      kind: 'function',
    },
    setPageSize: {
      description: "Sets the dataset's page size.",
      kind: 'function',
    },
    loadPage: {
      description: 'Loads the specified page.',
      kind: 'function',
    },
    getTotalPageCount: {
      description: 'Gets the number of pages in the dataset.',
      kind: 'function',
    },
    getCurrentPageIndex: {
      description: "Gets the index of the dataset's current page.",
      kind: 'function',
    },
  },
  description:
    'A dataset connects page elements to a set of items in a data collection.',
  viewernames: viewerNames
    ? viewerNames.reduce(
        (acc, controllerName) => Object.assign(acc, { [controllerName]: true }),
        {},
      )
    : {
        [controllerName]: true,
      },
})

const createRouterTypeManifest = () => ({
  eventHandlers: {
    getNextDynamicPage: {
      type: 'getNextDynamicPage',
      description: 'Gets the next dynamic page URL',
    },
    getPreviousDynamicPage: {
      type: 'getPreviousDynamicPage',
      description: 'Gets the previous dynamic page URL',
    },
  },
})

export default t => ({
  dataset: createBaseTypeManifest('dataset'),
  router_dataset: merge(
    createBaseTypeManifest('router_dataset'),
    createRouterTypeManifest(),
  ),
  'wix.dataBinding.Dataset': createBaseTypeManifest('dataset', [
    'dataset',
    'router_dataset',
  ]), //for backwards compat
})
