import { get, defaultsDeep } from 'lodash'
import * as defaultDatasetConfiguration from '@wix/wix-data-client-common/src/dataset-configuration/defaults'
import { readWriteType as DEFAULT_READ_WRITE_TYPE } from '@wix/wix-data-client-common/src/dataset-configuration/defaults'
import {
  CollectionType,
  getCollectionType,
} from '@wix/wix-code-collections-api'

const isDatasetConfigured = config => !!get(config, 'dataset.collectionName')

const isDatasetConnectedToDbDriverCollection = ({ config, collections }) => {
  const collectionId = get(config, 'dataset.collectionName')
  const collection = collections.find(({ id }) => collectionId === id)
  if (!collection) {
    return false
  }
  return getCollectionType(collection) === CollectionType.DB_DRIVER
}
const getCollectionName = config => get(config, 'dataset.collectionName')

const getReadWriteType = config =>
  get(config, 'dataset.readWriteType', DEFAULT_READ_WRITE_TYPE)

const setIncludes = (config, includes) =>
  defaultsDeep(
    {},
    {
      dataset: {
        includes: includes.length
          ? includes
          : defaultDatasetConfiguration.includes,
      },
    },
    config,
  )

const getPageSize = config => get(config, 'dataset.pageSize')

const getFilter = config => get(config, 'dataset.filter')

const getSort = config => get(config, 'dataset.sort')

export {
  isDatasetConfigured,
  isDatasetConnectedToDbDriverCollection,
  getCollectionName,
  getReadWriteType,
  setIncludes,
  getPageSize,
  getFilter,
  getSort,
}
