let slugify = null
const fallback = phrase => phrase

export function transliterateAndSlugify(phrase) {
  if (slugify === null) {
    global.importScripts(
      'https://static.parastorage.com/unpkg/transliteration@2.2.0/dist/browser/bundle.umd.min.js',
    )
    try {
      slugify = exports?.slugify
    } catch {
      try {
        slugify = window?.slugify
      } catch {
        slugify = fallback
      }
    }
  }

  return Promise.resolve(slugify(phrase, { allowedChars: 'a-zA-Z0-9' }))
}
