import onEventCreator from './events'
import appApiCreator from './api'
import { createPublicApi } from './public-api'

const createModuleFactories = async services => ({
  createAppApi: ({ finalAppApiPromise }) =>
    appApiCreator({ ...services, finalAppApiPromise }),
  createEventHandlers: ({ appApi }) => onEventCreator({ ...services, appApi }),
})

const publicApi = createPublicApi()

export default { createModuleFactories, publicApi }
