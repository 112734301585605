import { presetScanningPanel } from '@wix/wix-data-client-common/src/panels/panelDefs'
import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'

export default async ({ hostname, editorSdkProxy, ...initialData }) => {
  return editorSdkProxy.editor.openModalPanel({
    url: await getPanelUrl({
      hostname,
      relativeUrl: presetScanningPanel.url,
      editorSdkProxy,
    }),
    width: 700,
    height: 350,
    shouldHideHeader: true,
    initialData,
  })
}
