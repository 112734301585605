import openConfirmationPanel from '@wix/wix-data-client-common/src/panels/error-panel/openConfirmationPanel'
import openGenericConfirmationPanel from '@wix/wix-data-client-common/src/panels/error-panel/openGenericConfirmationPanel'
import { getAssetUrlInWorker } from '@wix/wix-data-client-common/src/panels/getAssetUrl'
import {
  DynamicPageError,
  DPErrorPanelData,
  DBDriverDynamicPageError,
} from '../../business-logic/dynamic-pages/dynamicPageErrors'

export const createDPErrorHandler =
  ({ logger, editorSdkProxy, i18n, experimentsManager }) =>
  async (fn, propagateErrors) => {
    try {
      return await fn()
    } catch (error) {
      logger.error(error)

      if (propagateErrors) {
        throw error
      }

      const t = i18n.t.bind(i18n)

      if (
        error instanceof DynamicPageError ||
        error instanceof DBDriverDynamicPageError
      ) {
        const { title, text, buttonLabel, helpId } = DPErrorPanelData[
          error.code
        ]({
          t,
        })

        openConfirmationPanel({
          editorSdkProxy,
          logger,
          headerText: title,
          descriptionText: text,
          mainActionText: buttonLabel,
          helpId,
          illustration: await getAssetUrlInWorker(
            editorSdkProxy,
            'panel-icons/generic_warning.svg',
          ),
        })
      } else {
        openGenericConfirmationPanel({
          editorSdkProxy,
          logger,
          t,
        })
      }

      return { error }
    }
  }
