const getDeveloperMode = async editorSdkProxy => {
  // workaround due to ADI platform implementation missing developerMode namespace
  try {
    return await editorSdkProxy.editor.developerMode.isEnabled()
  } catch {
    return false
  }
}

export default getDeveloperMode
