import openConfirmationPanel from '@wix/wix-data-client-common/src/panels/error-panel/openConfirmationPanel'
import { getAssetUrlInWorker } from '@wix/wix-data-client-common/src/panels/getAssetUrl'

export default async ({
  editorSdkProxy,
  openExternalDbDriverPanel,
  logger,
  i18n,
  collectionName,
}) => {
  const t = i18n.t.bind(i18n)

  await openConfirmationPanel({
    editorSdkProxy,
    logger,
    headerText: t('Workspace_Configuration_Problem_Note_Title'),
    descriptionText: t('Workspace_Configuration_Problem_Note_Text'),
    mainActionText: t('Workspace_Configuration_Problem_Note_Edit_Button'),
    secondaryActionText: t(
      'Workspace_Configuration_Problem_Note_Cancel_Button',
    ),
    illustration: await getAssetUrlInWorker(
      editorSdkProxy,
      'panel-icons/generic_warning.svg',
    ),
    onMainActionClick: () => {
      const namespace = collectionName.split('/')[0]
      openExternalDbDriverPanel(namespace)
    },
  })
}
