import getDeveloperMode from './utils/getDeveloperMode'
import { getInstance } from './utils/getInstance'
import { INSUFFICIENT_PERMISSIONS_ERROR_REGEX } from './infra/ignoredErrors'
import disableSandboxMode from './utils/disableSandboxMode'

const maxWaitTime = 60000

const waitUntilSiteSaved = async ({ logger, appApi }) => {
  return new Promise(resolve => {
    const timer = setTimeout(() => {
      logger.info(`Site still not saved after waiting ${maxWaitTime}`)
    }, maxWaitTime)

    appApi.addListener('siteWasFirstSaved', () => {
      clearTimeout(timer)
      resolve()
    })
  })
}

const updateDataModeValues = ({ logger, editorSdkProxy, dataMode }) => {
  logger.updateContext({ tags: { dataMode } })
  return editorSdkProxy.editor.updateAppState({ dataMode })
}

export const enableDataMode = async (
  editorSdkProxy,
  WixDataSettings,
  experimentsManager,
  logger,
  appApi,
  httpClient,
) => {
  const isSiteSaved = await editorSdkProxy.info.isSiteSaved()
  // experiment is not merged yet because of WEED-18481 & WEED-18477
  const canSaveSite = experimentsManager.enabled(
    'specs.wixCode.SaveSiteWhenDataModeIsEnabled',
  )
  if (!isSiteSaved && !canSaveSite) {
    // nothing to do here, the request will fail because we're in template and cannot save
    return
  } else if (!isSiteSaved) {
    logger.breadcrumb({
      category: 'dataMode',
      message: 'Attempting to save site',
    })
    await editorSdkProxy.document.save().catch(async err => {
      logger.breadcrumb({
        category: 'dataMode',
        message: `Site save failed ${err}`,
        data: {
          msid: await editorSdkProxy.info.getMetaSiteId(),
        },
      })
      return waitUntilSiteSaved({
        logger,
        appApi,
      })
    })
  }

  logger.breadcrumb({
    category: 'dataMode',
    message: 'Attempting to enable',
  })
  const dataModeSettings = await createDataModeSettings({
    editorSdkProxy,
    WixDataSettings,
    httpClient,
  })
  await dataModeSettings.enableDataMode()
  await updateDataModeValues({ logger, editorSdkProxy, dataMode: true })
  const shouldDisableSandboxMode = experimentsManager.enabled(
    'specs.wixData.SandboxDisabledClientSide',
  )
  if (shouldDisableSandboxMode) {
    await disableSandboxMode({
      instance: await getInstance(editorSdkProxy),
    })
  }
}

const createDataModeSettings = async ({
  editorSdkProxy,
  WixDataSettings,
  httpClient,
}) => {
  const instance = await getInstance(editorSdkProxy)
  return new WixDataSettings(httpClient, instance)
}

const getIsDataModeEnabled = async ({
  editorSdkProxy,
  WixDataSettings,
  logger,
  httpClient,
}) => {
  const dataModeSettings = await createDataModeSettings({
    editorSdkProxy,
    WixDataSettings,
    httpClient,
  })

  logger.breadcrumb({
    category: 'dataMode',
    message: 'Attempting to read',
  })

  try {
    const { enabled } = await dataModeSettings.isDataModeEnabled()
    return enabled
  } catch (error) {
    if (INSUFFICIENT_PERMISSIONS_ERROR_REGEX.test(error.message)) {
      return false
    }
    throw new Error(`Failed to get DataMode: ${error.message}`)
  }
}

export const updateAppStateDataModeValue = async ({
  WixDataSettings,
  editorSdkProxy,
  logger,
  experimentsManager,
  appApi,
  httpClient,
}) => {
  try {
    const [isDataModeEnabled, isDeveloperModeEnabled] = await Promise.all([
      getIsDataModeEnabled({
        editorSdkProxy,
        WixDataSettings,
        logger,
        httpClient,
      }),
      getDeveloperMode(editorSdkProxy),
    ])

    if (!isDataModeEnabled && isDeveloperModeEnabled) {
      await enableDataMode(
        editorSdkProxy,
        WixDataSettings,
        experimentsManager,
        logger,
        appApi,
        httpClient,
      )
    } else {
      updateDataModeValues({
        logger,
        editorSdkProxy,
        dataMode: isDataModeEnabled,
      })
    }
  } catch (error) {
    if (error.message === 'Unable to fetch result (Failed to fetch)') {
      logger.info(error.message)
    } else {
      logger.error(error)
    }
  }
}

export const isDataModeEnabled = getIsDataModeEnabled
