import { generateCollectionIdAndName } from '../business-logic/dynamic-pages/generateCollectionIdAndName'
import { getPresetDefinitionById } from '../business-logic/presets/getPresetDefinitionById'

export const addPreset = async ({
  appApi,
  collectionsApi,
  editorType,
  presetId,
  origin,
  isFirstTimeExperience = false,
}) => {
  const preset = await getPresetDefinitionById(presetId, editorType)
  const pagesToAdd = preset.pages.map(page => ({ key: page.type }))

  const allCollections = await collectionsApi.getAll()

  const { collectionId, collectionName } = generateCollectionIdAndName({
    allCollections,
    baseName: preset.collections[0].schema.displayName,
  })

  return appApi.addDynamicPages({
    presetId,
    pagesToAdd,
    collectionId,
    collectionName,
    biDefaults: {
      origin,
      is_first_data_modal: isFirstTimeExperience,
    },
  })
}
