import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'

export default async ({ hostname, editorSdkProxy, i18n, collectionId }) => {
  const url = await getPanelUrl({
    hostname,
    relativeUrl: 'hooksPanel.html',
    editorSdkProxy,
  })

  await editorSdkProxy.editor.openModalPanel({
    url,
    width: 648,
    height: 414,
    initialData: {
      collectionName: collectionId,
    },
    title: i18n.t('ADD_HOOKS_PANEL_TITLE'),
  })
}
