'use strict';
const BiLoggerClientFactory = require('./bi-logger-factory');
const BiLogger = require('./bi-logger');
const biLoggerManager = require('./bi-logger-manager');
const BatchQueue = require('./utils/batch-queue');

module.exports.BiLoggerFactory = BiLoggerClientFactory;
module.exports.BiLogger = BiLogger;
module.exports.BiLoggerManager = biLoggerManager.BiLoggerManager;

module.exports.factory = () => new BiLoggerClientFactory();
module.exports.manager = biLoggerManager.manager;
module.exports.createBatchQueue = () => new BatchQueue();
