import openConfirmationPanel from '@wix/wix-data-client-common/src/panels/error-panel/openConfirmationPanel'

export default ({ editorSdkProxy, i18n, logger, collectionId }) =>
  openConfirmationPanel({
    editorSdkProxy,
    logger,
    headerText: i18n.t('Dynamic_Pages_Warning_RemovedCollection_Title'),
    descriptionText: i18n.t('Dynamic_Pages_Warning_RemovedCollection_Text', {
      collection_name: collectionId,
    }),
    mainActionText: i18n.t('Dynamic_Pages_Warning_RemovedCollection_Button'),
  })
