import { HttpClient } from '@wix/http-client'

const BASE_URL = process.env.JEST_WORKER_ID ? 'http://wix.com' : ''

export const getIsSandboxEnabled = async ({
  instance,
  httpClient = new HttpClient({ getAppToken: () => instance }),
}) => {
  try {
    const response = await httpClient.get(
      `${BASE_URL}/_api/data/storage-settings/sandbox-mode`,
    )
    return !response.data || !response.data.disabled
  } catch {
    return true
  }
}
