import { get, curry, includes } from 'lodash'
import { FieldType } from '@wix/wix-data-schema-types'
import { getReferencingField } from './fieldSelectors'
import {
  getFieldFromReferencedCollectionName,
  FIELD_PATH_SEPARATOR,
} from '@wix/dbsm-common/src/reference-fields/fieldPath'
const {
  multiReference,
  reference,
  number,
  document,
  pageLink,
  dateTime,
  stringArray,
  boolean,
} = FieldType

const getFieldName = field => get(field, 'name')
export const getFieldType = field => get(field, 'type')

export const isSystemField = field => !!field.systemField

export const fieldNameMatches = (field, name) => {
  const fieldName = getFieldName(field)
  return Boolean(
    fieldName === name ||
      (isFieldFromReferencedCollection(field) &&
        getFieldFromReferencedCollectionName(fieldName) === name),
  )
}

export const isOwnerField = field => {
  if (!field || !isSystemField(field)) {
    return false
  }
  return fieldNameMatches(field, '_owner')
}

const isOfFieldType = curry(
  (fieldType, field) => getFieldType(field) === fieldType,
)
export const isMultiReferenceField = isOfFieldType(multiReference)

export const isReferenceField = isOfFieldType(reference)

export const isReferencingField = field =>
  isOfFieldType(reference, field) || isOfFieldType(multiReference, field)

export const isNumberField = isOfFieldType(number)
export const isDocumentField = isOfFieldType(document)
export const isPagelinkField = isOfFieldType(pageLink)
export const isDatetimeField = isOfFieldType(dateTime)
export const isStringArrayField = isOfFieldType(stringArray)
export const isBooleanField = isOfFieldType(boolean)

export const isParentMultiRef = (fields, field) =>
  getReferencingField(fields, field.name)
    .map(parentField => isMultiReferenceField(parentField))
    .getOrElse(false)

export const isFieldNested = field => Boolean(field?.isNested)

export const isFieldFromReferencedCollection = field =>
  Boolean(field) &&
  isFieldReferencedOrNested(field.name) &&
  !isFieldNested(field)

export const isFieldReferencedOrNested = fieldPath =>
  includes(fieldPath, FIELD_PATH_SEPARATOR)
