import { WIX_CODE } from '@wix/app-definition-ids'
import checkIfRoutePatternIncludesFields from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/checkIfRoutePatternIncludesFields'
import { findFirstItemMatchingDynamicPage } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/findFirstItemMatchingDynamicPage'
import { getCollectionIdAndPatternOfCurrentDynamicPage } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/getCollectionIdAndPatternOfCurrentDynamicPage'
import getInnerRouteForDynamicPageItem from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/getInnerRouteForDynamicPageItem'
import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'
import { AUTO_CMS_EDITOR_PANEL_BASENAME } from '@wix/wix-data-client-common/src/consts'

const autoCMSPathDescriptor = ({
  parts: { collectionId, isNewItem, itemId, settings } = {},
  origin,
  hostname,
  locale,
  editorSdkProxy,
}) => ({
  parts: { collectionId, isNewItem, itemId, settings },
  origin,
  hostname,
  locale,
  editorSdkProxy,
})

const autoCMSEditorPanelURL = async ({
  parts: { collectionId, isNewItem, itemId, settings },
  origin,
  hostname,
  editorSdkProxy,
}) => {
  const params = { origin }

  if (typeof collectionId === 'string') {
    params.collectionId = encodeURIComponent(collectionId)

    if (isNewItem) {
      params.isNewItem = true
    } else if (typeof itemId === 'string') {
      params.itemId = encodeURIComponent(itemId)
    }
  } else if (settings) {
    params.settings = true
  }

  const wixCodePublicApi =
    await editorSdkProxy.document.application.getPublicAPI({
      appDefinitionId: WIX_CODE,
    })
  const { instance } = await wixCodePublicApi.getWixCodeAuthParams()
  params.instance = instance

  const panelURL = await getPanelUrl({
    hostname,
    panelBasename: AUTO_CMS_EDITOR_PANEL_BASENAME,
    relativeUrl: './editor-panel-app.html',
    params,
    editorSdkProxy,
    panelWithSeparateGaLifecycle: true,
  })

  return panelURL
}

const openAutoCMSPanel = async ({ editorSdkProxy, pathDescriptor, i18n }) =>
  editorSdkProxy.editor.openModalPanel({
    title: i18n.t('Content_Manager_Panel_Title'),
    url: await autoCMSEditorPanelURL(pathDescriptor),
    width: '94%',
    height: '94%',
    centered: true,
    noPanelPadding: true,
  })

const _openAutoCMS = async ({ editorSdkProxy, pathDescriptor, i18n }) => {
  const result = await openAutoCMSPanel({
    editorSdkProxy,
    pathDescriptor,
    i18n,
  })

  updateEditorView(editorSdkProxy)

  return result
}

const openAutoCMSAndUpdateInnerRouteAfterClosingIfNeeded = async ({
  wixData,
  editorSdkProxy,
  collectionsApi,
  buildPathDescriptor,
  livePreview,
  shouldResolveCurrentDynamicPageItem = false,
  i18n,
}) => {
  const fallbackPathParts = {}
  if (livePreview || shouldResolveCurrentDynamicPageItem) {
    const collectionIdAndPattern =
      await getCollectionIdAndPatternOfCurrentDynamicPage({ editorSdkProxy })
    const currentPageIsValidDynamicPage = collectionIdAndPattern !== null
    if (currentPageIsValidDynamicPage) {
      const { collectionId, pattern } = collectionIdAndPattern
      Object.assign(fallbackPathParts, { collectionId })

      const routePatternIncludesFields =
        checkIfRoutePatternIncludesFields(pattern)
      // If a pattern does not include a field, the page is considered an index page.
      // All items have the same links to an index page so the inner route does not change as a result of changing an item matching the dynamic page.
      if (routePatternIncludesFields) {
        const { innerRoute, prefix } =
          await editorSdkProxy.document.routers.getCurrentDynamicRouting()
        const innerRouteIsDefined = typeof innerRoute !== 'undefined'
        // The inner route can be undefined for different reasons:
        // - there are no items in the collection,
        // - there are items in the collection but the routing data has not yet been loaded,
        // - the dynamic page is broken (there is no route pattern for the page).
        if (innerRouteIsDefined) {
          const { _id: itemId } = await findFirstItemMatchingDynamicPage({
            collectionId,
            innerRoute,
            pattern,
            wixData,
          })

          const valueRetunedByAutoCMS = await openAutoCMSPanel({
            editorSdkProxy,
            pathDescriptor: buildPathDescriptor({ collectionId, itemId }),
            i18n,
          })

          let changedInnerRouteIfAny
          if (livePreview) {
            const innerRouteOfItem = await getInnerRouteForDynamicPageItem({
              collectionId,
              collectionsApi,
              itemId,
              pattern,
              prefix,
              wixData,
            })
            if (
              typeof innerRouteOfItem !== 'undefined' &&
              innerRouteOfItem !== innerRoute
            ) {
              changedInnerRouteIfAny = innerRouteOfItem
            }
          }

          await updateEditorView(editorSdkProxy, changedInnerRouteIfAny)

          return valueRetunedByAutoCMS
        }
      }
    }
  }

  return _openAutoCMS({
    editorSdkProxy,
    pathDescriptor: buildPathDescriptor(fallbackPathParts),
    i18n,
  })
}

const updateEditorView = (editorSdkProxy, selectedRoute) =>
  editorSdkProxy.editor.routers
    .refresh({
      selectedRoute,
    })
    .then(() => {
      //TODO: check do we need it in TB, because we don't need it in Bolt
      editorSdkProxy.document.application.livePreview.refresh({
        source: 'AFTER_DB_CHANGE',
        shouldFetchData: true,
      })
    })
    .catch(() => {}) // Editor X does not have implementation for it yet

export const openAutoCMSNewItemView = ({
  editorSdkProxy,
  collectionsApi,
  collectionId,
  origin,
  wixData,
  livePreview,
  hostname,
  i18n,
}) =>
  openAutoCMSAndUpdateInnerRouteAfterClosingIfNeeded({
    editorSdkProxy,
    collectionsApi,
    buildPathDescriptor: () =>
      autoCMSPathDescriptor({
        parts: { collectionId, isNewItem: true },
        origin,
        hostname,
        locale: i18n.language,
        editorSdkProxy,
      }),
    wixData,
    livePreview,
    i18n,
  })

export const openAutoCMSCollectionView = ({
  editorSdkProxy,
  collectionsApi,
  collectionId,
  origin,
  wixData,
  livePreview,
  hostname,
  i18n,
}) =>
  openAutoCMSAndUpdateInnerRouteAfterClosingIfNeeded({
    editorSdkProxy,
    collectionsApi,
    buildPathDescriptor: () =>
      autoCMSPathDescriptor({
        parts: { collectionId },
        origin,
        hostname,
        locale: i18n.language,
        editorSdkProxy,
      }),
    wixData,
    livePreview,
    i18n,
  })

export const openAutoCMSCollectionsList = ({
  editorSdkProxy,
  collectionsApi,
  origin,
  wixData,
  livePreview,
  hostname,
  i18n,
}) =>
  openAutoCMSAndUpdateInnerRouteAfterClosingIfNeeded({
    editorSdkProxy,
    collectionsApi,
    buildPathDescriptor: () =>
      autoCMSPathDescriptor({
        origin,
        hostname,
        locale: i18n.language,
        editorSdkProxy,
      }),
    wixData,
    livePreview,
    i18n,
  })

export const openAutoCMSAdvancedSettings = ({
  editorSdkProxy,
  collectionsApi,
  origin,
  wixData,
  livePreview,
  hostname,
  i18n,
}) =>
  openAutoCMSAndUpdateInnerRouteAfterClosingIfNeeded({
    editorSdkProxy,
    collectionsApi,
    buildPathDescriptor: () =>
      autoCMSPathDescriptor({
        parts: { settings: true },
        origin,
        hostname,
        locale: i18n.language,
        editorSdkProxy,
      }),
    wixData,
    livePreview,
    i18n,
  })

export const openAutoCMSItemViewForCurrentDynamicPage = async ({
  editorSdkProxy,
  collectionsApi,
  origin,
  wixData,
  hostname,
  livePreview,
  i18n,
}) => {
  return openAutoCMSAndUpdateInnerRouteAfterClosingIfNeeded({
    wixData,
    editorSdkProxy,
    collectionsApi,
    buildPathDescriptor: ({ collectionId, itemId }) =>
      autoCMSPathDescriptor({
        parts: { collectionId, itemId },
        origin,
        hostname,
        locale: i18n.language,
        editorSdkProxy,
      }),
    livePreview,
    shouldResolveCurrentDynamicPageItem: true,
    i18n,
  })
}
