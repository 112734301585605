import { promiseTimeout } from './promiseTimeout'

export const createSafeUserPreferences = ({
  editorSdkProxy,
  logger,
  fedopsLogger,
  timeout = 3000,
}) => {
  return {
    get: async (keys, defaultValue) => {
      try {
        const { timeoutId } =
          fedopsLogger.interactionStarted('getUserPreferences')
        const result = await promiseTimeout(
          editorSdkProxy.userPreferences.site.get(keys),
          timeout,
        )
        fedopsLogger.interactionEnded('getUserPreferences', { timeoutId })
        return result
      } catch {
        logger.info(
          `editorSdkProxy.userPreferences.site.get promise was not resolved within ${timeout}`,
        )
        return defaultValue
      }
    },

    set: async value => {
      try {
        const { timeoutId } =
          fedopsLogger.interactionStarted('setUserPreferences')
        const result = await promiseTimeout(
          editorSdkProxy.userPreferences.site.set(value),
          timeout,
        )
        fedopsLogger.interactionEnded('setUserPreferences', { timeoutId })
        return result
      } catch {
        logger.info(
          `editorSdkProxy.userPreferences.site.set promise was not resolved within ${timeout}`,
        )
      }
    },
  }
}
