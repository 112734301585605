import { FieldType } from '@wix/wix-data-schema-types'
import { TOGGLESWITCH_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { boolean } = FieldType

export default () => ({
  componentName: 'Element_Name_Toggle_Switch',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_ToggleSwitch',
    helpId: '11124879-03b8-42ab-8c88-0d95c8a9e92e',
  },
  sections: [
    {
      role: TOGGLESWITCH_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Toggled_Value_Label',
          prop: 'checked',
          supportedFieldTypes: [boolean],
          defaultFieldType: boolean,
          isInputProperty: true,
        },
      ],
    },
  ],
  compactErrorPanel: true,
})
