import { flatten, uniq } from 'lodash'
import { getCollectionsBoundToRouter } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/editorActions'

export const getFirstRouterCollectionId = async ({
  editorSdkProxy,
  routerRef,
}) => {
  const routerCollectionIds = await getCollectionsBoundToRouter(
    editorSdkProxy,
    routerRef,
  )

  if (routerCollectionIds.length === 0) {
    throw new Error('Broken router configuration - it has no collections')
  }

  return routerCollectionIds[0]
}

export const getRouterCollectionIdsFromNamespace = async ({
  editorSdkProxy,
  namespace,
}) => {
  const routers = await editorSdkProxy.routers.getAll()
  const collectionIds = uniq(
    flatten(
      routers.map(router =>
        Object.values(router.config.patterns).map(
          pattern => pattern.config.collection,
        ),
      ),
    ),
  )
  return collectionIds.filter(colName => colName.startsWith(`${namespace}/`))
}
