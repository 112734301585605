import { isResponsiveEditor } from '@wix/wix-data-client-common/src/infra/editorType'
import * as classicPresets from './classic/definitionLookup'
import * as responsivePresets from './responsive/definitionLookup'

const getDeferredDefinition = (presetId, editorType) => {
  const definitions = isResponsiveEditor(editorType)
    ? responsivePresets
    : classicPresets
  return definitions[presetId]
}

export const getPresetDefinitionById = async (presetId, editorType) => {
  const deferred = getDeferredDefinition(presetId, editorType)
  const { default: definition } = await deferred()
  return definition
}

export const presetExists = ({ presetId, editorType }) => {
  const preset = getDeferredDefinition(presetId, editorType)
  return Boolean(preset)
}
