import { ensureDataModeIsEnabled } from '../enableDataMode'

export const addPresetById = async ({
  presetId,
  options,
  editorSdkProxy,
  WixDataSettings,
  logger,
  experimentsManager,
  safeUserPreferences,
  appApi,
  httpClient,
}) => {
  const { enableDataModeTriggered } = await ensureDataModeIsEnabled({
    editorSdkProxy,
    WixDataSettings,
    experimentsManager,
    safeUserPreferences,
    appApi,
    logger,
    httpClient,
  })

  const { pageRefs } = await appApi.addPreset({
    presetId,
    origin: options.origin,
    isFirstTimeExperience: enableDataModeTriggered,
    propagateErrors: true,
  })

  return { pageRefs }
}
