import { values } from 'lodash'
import * as ALLOWED_OPERATIONS from './allowedOperations'
import {
  readsAllowed,
  insertsAllowed,
  updatesAllowed,
  removalsAllowed,
} from '@wix/wix-data-schema-utils/lib/permission-utils'

export default serverAllowedOperations => {
  if (!serverAllowedOperations) {
    return values(ALLOWED_OPERATIONS)
  }

  return []
    .concat(
      readsAllowed({ allowedOperations: serverAllowedOperations })
        ? [ALLOWED_OPERATIONS.READ]
        : [],
    )
    .concat(
      insertsAllowed({ allowedOperations: serverAllowedOperations })
        ? [ALLOWED_OPERATIONS.INSERT]
        : [],
    )
    .concat(
      updatesAllowed({ allowedOperations: serverAllowedOperations })
        ? [ALLOWED_OPERATIONS.UPDATE]
        : [],
    )
    .concat(
      removalsAllowed({ allowedOperations: serverAllowedOperations })
        ? [ALLOWED_OPERATIONS.REMOVE]
        : [],
    )
}
