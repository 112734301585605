import * as UNDO_REDO_LABELS from '@wix/wix-data-client-common/src/undoRedoLabels'
import { DATA_BINDING } from '@wix/app-definition-ids'
import { isEmpty, get, last } from 'lodash'
import { getPageName as generateNewPageName } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/templates'

import {
  deletePattern,
  getPatternDefForPage,
  updatePagePattern,
  getPatternDef,
  updatePatternDef,
} from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/routerConfig'

import { addOrUpdateRouterWithPattern } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/editorActions'
import {
  setPageTitle,
  mergeSeoMetaTags,
  getCollection,
} from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/patternDef'
import {
  deletePageLinkFields,
  updatePageLinkField,
} from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/page-link-field/editorActions'

import { parseUrl } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/routing-url/utils'
import fetchRouterDatasetFromPage from './fetchRouterDatasetFromPage'
import { digestSchema } from '@wix/wix-data-client-common/src/business-logic/collections/digestSchema'

import {
  getProgressBar,
  openPagesPanelIfNeeded,
} from '../progress-bar/executeSteps'
import { getPresetDefinitionById } from '../presets/getPresetDefinitionById'
import truncateCollectionName from './truncateCollectionName'
import { getFedopsInteractionName } from './getFedopsInteractionName'
import { getDpStepBuilder } from './add-dynamic-pages/dpCreationSteps'
import { prepareDataForCollection } from './add-dynamic-pages/flows/conversion/prepareDataForCollection'
import { handleFirstTimeDpConversion } from './isFirstTimeConversionHandler'

export const disconnectDynamicPage = async ({
  collectionsApi,
  editorSdkProxy,
  routerRef,
  pageRef,
  appApi,
}) => {
  const router = await editorSdkProxy.routers.get({ routerRef })
  await editorSdkProxy.routers.pages.disconnect({ routerRef, pageRef })

  const { pattern, def: patternDef } = getPatternDefForPage(
    router.config,
    router.pages,
    pageRef,
  )

  const isLastPage = router.pages.length === 1
  isLastPage
    ? await editorSdkProxy.routers.remove({ routerRef })
    : await editorSdkProxy.routers.update({
        routerRef,
        config: deletePattern(router.config, pattern),
      })

  const { managingAppDefId } = await getPageData(editorSdkProxy, pageRef)

  if (managingAppDefId === DATA_BINDING) {
    await editorSdkProxy.document.pages.data.update({
      pageRef,
      data: { pageUriSEO: router.prefix, managingAppDefId: undefined },
    })
  }

  const collection = getCollection(patternDef)
  await deletePageLinkFields(collectionsApi, collection, router.prefix, pattern)

  const { ref: routerDatasetRef } = await fetchRouterDatasetFromPage(
    editorSdkProxy,
    pageRef,
  )
  await appApi.removeRouterDatasetController({
    datasetRef: routerDatasetRef,
  })

  editorSdkProxy.editor.routers.refresh().catch(() => {}) // Editor X does not have implementation for it yet

  await editorSdkProxy.history.add({
    label: UNDO_REDO_LABELS.DISCONNECT_PAGE_FROM_DATA_BINDING_ROUTER,
  })
}

export const deleteDynamicPage = async ({
  collectionsApi,
  editorSdkProxy,
  routerRef,
  pageRef,
}) => {
  const router = await editorSdkProxy.routers.get({ routerRef })
  await editorSdkProxy.routers.pages.remove({ routerRef, pageRef })

  const { pattern, def: patternDef } = getPatternDefForPage(
    router.config,
    router.pages,
    pageRef,
  )

  const isLastPage = router.pages.length === 1
  isLastPage
    ? await editorSdkProxy.routers.remove({ routerRef })
    : await editorSdkProxy.routers.update({
        routerRef,
        config: deletePattern(router.config, pattern),
      })

  const collection = getCollection(patternDef)
  await deletePageLinkFields(collectionsApi, collection, router.prefix, pattern)

  await editorSdkProxy.history.add({
    label: UNDO_REDO_LABELS.REMOVE_PAGE_FROM_DATA_BINDING_ROUTER,
  })
}

const saveNewPrefixAndPattern = async function ({
  editorSdkProxy,
  collectionsApi,
  routerRef,
  pageRef,
  routerConfig,
  collection,
  previousPrefix,
  newPrefix,
  previousPattern,
  newPattern,
  routerPages,
}) {
  const isLastPage = routerPages.length <= 1
  const updatedRouterConfig = updatePagePattern(
    routerConfig,
    previousPattern,
    newPattern,
  )
  const newPatternDef = getPatternDef(updatedRouterConfig, newPattern)
  const newPageName = generateNewPageName(
    collection.displayName,
    newPattern,
    collection.fields,
  )
  const router = await editorSdkProxy.document.routers.get({ routerRef })
  const pageId = get(findPageByRole(router.pages, newPatternDef.pageRole), [
    'pageRef',
    'id',
  ])

  const newRouterRef = await addOrUpdateRouterWithPattern(
    editorSdkProxy,
    newPrefix,
    newPattern,
    newPatternDef,
  )
  await Promise.all([
    updatePageLinkField(
      collectionsApi,
      collection.id,
      previousPrefix,
      previousPattern,
      newPageName,
      newPrefix,
      newPattern,
      pageId,
    ),
    editorSdkProxy.routers.pages
      .move({ pageRef, fromRouterRef: routerRef, toRouterRef: newRouterRef })
      .then(() =>
        isLastPage
          ? editorSdkProxy.routers.remove({ routerRef })
          : editorSdkProxy.routers.update({
              routerRef,
              config: deletePattern(routerConfig, previousPattern),
            }),
      ),
    editorSdkProxy.pages.rename({ pageRef, title: newPageName }),
  ])
  return { routerRef: newRouterRef, pageRef }
}

const saveNewPattern = async function ({
  editorSdkProxy,
  collectionsApi,
  routerRef,
  pageRef,
  routerConfig,
  collection,
  previousPrefix,
  previousPattern,
  newPattern,
}) {
  const updatedRouterConfig = updatePagePattern(
    routerConfig,
    previousPattern,
    newPattern,
  )
  const newPatternDef = getPatternDef(updatedRouterConfig, newPattern)
  const newPageName = generateNewPageName(
    collection.displayName,
    newPattern,
    collection.fields,
  )
  const router = await editorSdkProxy.document.routers.get({ routerRef })
  const pageId = get(findPageByRole(router.pages, newPatternDef.pageRole), [
    'pageRef',
    'id',
  ])

  await Promise.all([
    editorSdkProxy.routers.update({ routerRef, config: updatedRouterConfig }),
    updatePageLinkField(
      collectionsApi,
      collection.id,
      previousPrefix,
      previousPattern,
      newPageName,
      previousPrefix,
      newPattern,
      pageId,
    ),
    editorSdkProxy.pages.rename({ pageRef, title: newPageName }),
  ])
  return { routerRef, pageRef }
}

export const updateDynamicPageUrl = async ({
  editorSdkProxy,
  collectionsApi,
  pageRef,
  collectionId,
  newUrl,
}) => {
  const routerRef = await editorSdkProxy.routers.getByPage({ pageRef })
  const router = await editorSdkProxy.routers.get({ routerRef })
  const { pattern: previousPattern } = getPatternDefForPage(
    router.config,
    router.pages,
    pageRef,
  )
  const previousPrefix = router.prefix
  const { prefix: newPrefix, pattern: newPattern } = parseUrl(newUrl)
  const rawSchema = await collectionsApi.get(collectionId)

  if (rawSchema.isDeleted) {
    throw new Error(
      `Cannot update page url, because ${rawSchema.displayName} collection is removed`,
    )
  }

  const collection = digestSchema(rawSchema)

  const result =
    previousPrefix !== newPrefix
      ? await saveNewPrefixAndPattern({
          editorSdkProxy,
          collectionsApi,
          routerRef,
          pageRef,
          routerConfig: router.config,
          collection,
          previousPrefix,
          newPrefix,
          previousPattern,
          newPattern,
          routerPages: router.pages,
        })
      : await saveNewPattern({
          editorSdkProxy,
          collectionsApi,
          routerRef,
          pageRef,
          routerConfig: router.config,
          collection,
          previousPrefix,
          previousPattern,
          newPattern,
        })

  await editorSdkProxy.history.add({
    label: UNDO_REDO_LABELS.UPDATE_DYNAMIC_PAGE_URL,
  })

  editorSdkProxy.editor.routers.refresh().catch(() => {}) // Editor X does not have implementation for it yet

  return result
}

export const updateDynamicPageSEO = async ({
  editorSdkProxy,
  pageRef,
  title,
  metatags,
}) => {
  const routerRef = await editorSdkProxy.routers.getByPage({ pageRef })
  const router = await editorSdkProxy.routers.get({ routerRef })
  const { pattern, def: originalPatternDef } = getPatternDefForPage(
    router.config,
    router.pages,
    pageRef,
  )

  let updatedPatternDef = originalPatternDef
  if (title) {
    updatedPatternDef = setPageTitle(updatedPatternDef, title)
  }
  if (metatags) {
    updatedPatternDef = mergeSeoMetaTags(updatedPatternDef, metatags)
  }

  const updatedRouterConfig = updatePatternDef(
    router.config,
    pattern,
    updatedPatternDef,
  )
  await editorSdkProxy.routers.update({
    routerRef,
    config: updatedRouterConfig,
  })

  await editorSdkProxy.history.add({
    label: UNDO_REDO_LABELS.UPDATE_DYNAMIC_PAGE_SEO_METATAGS,
  })
}

const findPageByRole = (pages, pageRole) =>
  pages.find(({ pageRoles }) => pageRoles.includes(pageRole))

const getPageData = (editorSdkProxy, pageRef) =>
  editorSdkProxy.pages.getPageData({ pageRef })

export const appHasDPsForCollection = async ({
  editorSdkProxy,
  collectionId,
  managingAppDefId = DATA_BINDING,
}) => {
  const allRouters = await editorSdkProxy.routers.getAll()

  for (const { config, pages } of allRouters) {
    for (const pattern of Object.values(config.patterns)) {
      if (pattern.config.collection === collectionId) {
        const page = findPageByRole(pages, pattern.pageRole)

        if (page) {
          const { managingAppDefId: pageManagingAppDefId = DATA_BINDING } =
            await getPageData(editorSdkProxy, page.pageRef)

          if (pageManagingAppDefId === managingAppDefId) {
            return true
          }
        }
      }
    }
  }

  return false
}

const getFedopsInteractionKey = ({ shouldBeBlank, pagesToAdd }) => {
  if (shouldBeBlank) {
    return 'addBlankFromCollection'
  }

  return pagesToAdd[0].key === 'itemPage'
    ? 'addItemPageFromCollection'
    : 'addListPageFromCollection'
}

const getIsIndexable = async ({ editorSdkProxy, pageRef }) => {
  const { indexable } = await editorSdkProxy.pages.getPageData({ pageRef })
  return indexable
}

export const getDpCreationFlows = ({
  collectionsApi,
  editorSdkProxy,
  wixData,
  biLogger,
  biCMLogger,
  i18n,
  editorType,
  isNewAppPagesEnabled,
  isSandboxEnabled,
  arePageLinksCreatedOnBackend,
  isDraftPublishEnabled,
  defaultDraftPublishStatus,
  pluginsPatches,
  logger,
  fedopsLogger,
  appApi,
  isFirstTimeConversionBannerEnabled,
  biDefaults,
}) => {
  const stepBuilder = getDpStepBuilder({
    collectionsApi,
    editorSdkProxy,
    wixData,
    biLogger,
    biCMLogger,
    i18n,
    editorType,
    isNewAppPagesEnabled,
    isSandboxEnabled,
    arePageLinksCreatedOnBackend,
    isDraftPublishEnabled,
    defaultDraftPublishStatus,
    pluginsPatches,
    appApi,
    logger,
    biDefaults,
  })

  const getInteractionName = getFedopsInteractionName(editorType)

  return {
    addPreset: async ({
      presetId,
      pagesToAdd,
      collectionId,
      collectionName,
      origin,
    }) => {
      const preset = await getPresetDefinitionById(presetId, editorType)

      const title = isEmpty(pagesToAdd)
        ? i18n.t('Adding_Collection_Native_Progress_Bar_Title', {
            collection_name: truncateCollectionName(collectionName),
            interpolation: { escapeValue: false },
          })
        : i18n.t('Adding_Dynamic_Pages_Native_Progress_Bar_Title')

      const progressBar = getProgressBar({
        editorSdkProxy,
        logger,
        totalSteps: pagesToAdd.length + 1,
        title,
      })

      const interactionKey =
        presetId === 'blank' ? 'addFromScratch' : 'addFromPreset'

      const interactionName = getInteractionName(interactionKey)

      fedopsLogger.interactionStarted(interactionName)

      const result = await stepBuilder.getStepsForAddingPagesFromPreset({
        preset,
        pagesToAdd,
        collectionId,
        collectionName,
        isIndexable: true,
        progressBar,
      })

      if (result.pageRefs.length) {
        await openPagesPanelIfNeeded({
          editorSdkProxy,
          origin,
          pageRef: last(result.pageRefs),
        })
      }

      fedopsLogger.interactionEnded(interactionName)

      appApi.openContentManager({
        collectionId,
        isModal: true,
        origin,
      })

      return result
    },
    addDefaultDynamicPages: async ({
      pagesToAdd,
      collectionId,
      collectionName,
      origin,
    }) => {
      const presetId = 'blank'
      const preset = await getPresetDefinitionById(presetId, editorType)

      const title = i18n.t('Adding_Dynamic_Pages_Native_Progress_Bar_Title')

      const progressBar = getProgressBar({
        editorSdkProxy,
        logger,
        totalSteps: pagesToAdd.length,
        title,
      })

      const shouldBeBlank = pagesToAdd.some(p => Boolean(p.shouldBeBlank))

      const interactionKey = getFedopsInteractionKey({
        shouldBeBlank,
        pagesToAdd,
      })

      const interactionName = getInteractionName(interactionKey)

      fedopsLogger.interactionStarted(interactionName)

      const result =
        await stepBuilder.getStepsForAddingDefaultPagesFromExistingCollection({
          preset,
          pagesToAdd,
          collectionId,
          collectionName,
          isIndexable: true,
          progressBar,
        })

      await openPagesPanelIfNeeded({
        editorSdkProxy,
        origin,
        pageRef: last(result.pageRefs),
      })

      fedopsLogger.interactionEnded(interactionName)

      return result
    },
    convertFromCollection: async ({
      pagesToAdd,
      collectionId,
      collectionName,
      origin,
    }) => {
      const presetId = 'blank'
      const preset = await getPresetDefinitionById(presetId, editorType)

      const pageRef = pagesToAdd.find(page => page.staticPageRef).staticPageRef
      const isIndexable = await getIsIndexable({ editorSdkProxy, pageRef })

      const interactionName = getInteractionName('convertStaticFromCollection')

      const title = i18n.t('Converting_Dynamic_Pages_Native_Progress_Bar_Title')

      const progressBar = getProgressBar({
        editorSdkProxy,
        logger,
        totalSteps: pagesToAdd.length,
        title,
      })

      fedopsLogger.interactionStarted(interactionName)

      const result =
        await stepBuilder.getStepsForAddingDefaultPagesFromExistingCollection({
          preset,
          pagesToAdd,
          collectionId,
          collectionName,
          isIndexable,
          progressBar,
        })

      await openPagesPanelIfNeeded({ editorSdkProxy, origin, pageRef })

      fedopsLogger.interactionEnded(interactionName)

      return result
    },
    convertFromScratch: async ({
      pagesToAdd,
      collectionId,
      collectionName,
      origin,
    }) => {
      const presetId = 'blank'
      const preset = await getPresetDefinitionById(presetId, editorType)

      const pageRef = pagesToAdd.find(page => page.staticPageRef).staticPageRef
      const isIndexable = await getIsIndexable({ editorSdkProxy, pageRef })

      const { schema, records } = preset.collections[0]

      const collectionToAdd = {
        collectionId,
        collectionName,
        displayField: schema.displayField,
        fields: schema.fields,
        records,
      }

      const interactionName = getInteractionName('convertStaticFromScratch')

      const title = i18n.t('Converting_Dynamic_Pages_Native_Progress_Bar_Title')

      const progressBar = getProgressBar({
        editorSdkProxy,
        logger,
        totalSteps: pagesToAdd.length + 1,
        title,
      })

      fedopsLogger.interactionStarted(interactionName)

      const result = await stepBuilder.getStepsForConvertingPageFromScratch({
        preset,
        pagesToAdd,
        collectionToAdd,
        isIndexable,
        progressBar,
      })

      await openPagesPanelIfNeeded({ editorSdkProxy, origin, pageRef })

      fedopsLogger.interactionEnded(interactionName)

      appApi.openContentManager({
        collectionId,
        isModal: true,
        origin,
      })

      return result
    },
    convertFromScratchAndBindComponents: async ({
      pageRef,
      collectionId,
      collectionName,
      fields,
      records,
      bindings,
      origin,
    }) => {
      const presetId = 'blank'
      const pagesToAdd = [{ key: 'itemPage', staticPageRef: pageRef, bindings }]

      const [preset, pageData] = await Promise.all([
        getPresetDefinitionById(presetId, editorType),
        editorSdkProxy.pages.getPageData({ pageRef }),
      ])

      const pageTitle = pageData.title

      const collectionToAdd = prepareDataForCollection({
        fields,
        records,
        collectionId,
        collectionName,
        pageTitle,
      })

      const interactionName = getInteractionName(
        'convertFromScratchAndBindComponents',
      )

      const title = i18n.t('Converting_Dynamic_Pages_Native_Progress_Bar_Title')

      const totalSteps =
        pagesToAdd.length +
        pagesToAdd.filter(({ bindings }) => bindings.length > 0).length +
        1

      const progressBar = getProgressBar({
        editorSdkProxy,
        logger,
        totalSteps,
        title,
      })

      fedopsLogger.interactionStarted(interactionName)

      const result = await stepBuilder.getStepsForConvertingPageFromScratch({
        preset,
        pagesToAdd,
        collectionToAdd,
        pageTitleBase: collectionName,
        isIndexable: pageData.indexable,
        progressBar,
      })

      await openPagesPanelIfNeeded({ editorSdkProxy, origin, pageRef })

      fedopsLogger.interactionEnded(interactionName)

      isFirstTimeConversionBannerEnabled &&
        handleFirstTimeDpConversion(collectionId, editorSdkProxy)

      appApi.openContentManager({
        collectionId,
        isModal: true,
        origin,
      })

      return result
    },
  }
}

export { duplicateDynamicPage } from './duplicate-dynamic-page/duplicateDynamicPage'
