import { FieldType } from '@wix/wix-data-schema-types'
import { ADDRESSINPUT_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { address } = FieldType

export default () => ({
  componentName: 'Element_Name_Address_Input',
  isConnectGfppEnabled: true,
  compactErrorPanel: true,
  panel: {
    height: 212,
    title: 'Connect_Panel_Title_Address_Input',
    helpId: '67ee895a-db8a-495f-a6ed-1cce3c9f4ae2',
  },
  sections: [
    {
      role: ADDRESSINPUT_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Value_Label',
          prop: 'value',
          supportedFieldTypes: [address],
          defaultFieldType: address,
          isInputProperty: true,
          validNonEmptyValue: { formatted: 'address' },
          emptyValue: null,
        },
      ],
    },
  ],
})
