export const validatePrefixWithLatinOnly = async ({
  editorSdkProxy,
  prefix,
}) => {
  if (/[^\d\-A-Za-z]+/.test(prefix)) {
    return {
      valid: false,
      message: 'Dynamic_Pages_Validations_No_Special_Chars',
      errorCode:
        editorSdkProxy.routers.InvalidPrefixReason
          .PREFIX_CONTAINS_INVALID_CHARACTERS,
    }
  }

  return editorSdkProxy.routers.isValidPrefix({
    prefix,
  })
}
