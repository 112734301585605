export default rawError => {
  if (rawError instanceof Error) {
    return [rawError]
  }
  const error = new Error(rawError?.message || JSON.stringify(rawError))
  if (rawError?.stack) {
    error.stack = rawError.stack
  }
  if (rawError?.type) {
    return [error, { type: rawError.type }]
  }
  return [error]
}
