import { isClassicalEditor, isResponsiveEditor } from './infra/editorType'
import { DATA_BINDING } from '@wix/app-definition-ids'

export const features = {
  livePreview: ({ editorType, experimentsManager }) =>
    (isClassicalEditor(editorType) ||
      (isResponsiveEditor(editorType) &&
        experimentsManager.enabled('specs.wixData.LivePreviewEditorX'))) &&
    experimentsManager.get('sv_livePreview_dataBinding') === 'new' &&
    experimentsManager.enabled('specs.wixData.LivePreview'),
  appPages: async ({ experimentsManager, editorSdkProxy, editorType }) => {
    if (isClassicalEditor(editorType)) {
      return true
    }

    return (
      experimentsManager.enabled('specs.wixDataClient.AppPagesInEditorX') ||
      pageDataFixed(editorSdkProxy)
    )
  },
}

async function pageDataFixed(editorSdkProxy) {
  const routers = await editorSdkProxy.routers.getAll({
    appDefinitionId: DATA_BINDING,
  })
  const pageData = editorSdkProxy.document.pages.data

  return (
    await Promise.all(
      routers
        .flatMap(({ pages }) => pages)
        .map(
          async ({ pageRef }) =>
            (
              await pageData.get({ pageRef })
            )?.managingAppDefId,
        ),
    )
  ).some(managingAppDefId => managingAppDefId === DATA_BINDING)
}
