import { FieldType } from '@wix/wix-data-schema-types'
import { RATINGSINPUT_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { number } = FieldType

export default () => ({
  componentName: 'Element_Name_Ratings_Input',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Ratings_Write',
    helpId: '5ab75d25-05a3-42aa-a6b7-b570b1e50e1d',
  },
  sections: [
    {
      role: RATINGSINPUT_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Rating_Value_Label',
          prop: 'value',
          supportedFieldTypes: [number],
          defaultFieldType: number,
          isInputProperty: true,
        },
      ],
    },
  ],
  compactErrorPanel: true,
})
