import { convertToDynamicPagePanel as convertToDynamicPagePanelDef } from '@wix/wix-data-client-common/src/panels/panelDefs'
import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'

export default async ({ hostname, editorSdkProxy, i18n }, initialData) => {
  return editorSdkProxy.editor.openModalPanel({
    url: await getPanelUrl({
      hostname,
      relativeUrl: convertToDynamicPagePanelDef.url,
      editorSdkProxy,
    }),
    width: 600,
    height: 355,
    initialData,
    shouldHideHeader: true,
  })
}
