import { isEqual } from 'lodash'
import { PLUGINS } from '@wix/wix-code-collections-api'
const { SINGLES } = PLUGINS

export const collectionTypes = {
  MULTI_ITEM_COLLECTION: 'MULTI_ITEM_COLLECTION',
  SINGLE_ITEM_COLLECTION: 'SINGLE_ITEM_COLLECTION',
}

export const getCollectionTypeFromSchema = ({ plugins }) => {
  const singlesPlugin = plugins?.[SINGLES.PLUGIN_NAME]

  return isEqual(singlesPlugin, SINGLES.PLUGIN_VALUE)
    ? collectionTypes.SINGLE_ITEM_COLLECTION
    : collectionTypes.MULTI_ITEM_COLLECTION
}

export const isSinglesCollectionSchema = schema =>
  getCollectionTypeFromSchema(schema) === collectionTypes.SINGLE_ITEM_COLLECTION

export const isMultiItemCollectionSchema = schema =>
  getCollectionTypeFromSchema(schema) === collectionTypes.MULTI_ITEM_COLLECTION
