import { READ, UPDATE, INSERT, REMOVE } from '../allowedOperations'

const legacyRoleByPermissionRole = {
  admin: 'siteOwner',
  siteMember: 'siteMember',
  siteMemberAuthor: 'dataOwner',
  anyone: 'anonymous',
}

export const toLegacyRole = role => legacyRoleByPermissionRole[role]

export const serializePermissions = permissions =>
  JSON.stringify({
    read: toLegacyRole(permissions[READ]),
    insert: toLegacyRole(permissions[INSERT]),
    update: toLegacyRole(permissions[UPDATE]),
    remove: toLegacyRole(permissions[REMOVE]),
  })
