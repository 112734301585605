import { HttpClient } from '@wix/http-client'

const BASE_URL = process.env.JEST_WORKER_ID ? 'http://wix.com' : ''

const disableSandboxMode = async ({
  instance,
  httpClient = new HttpClient({ getAppToken: () => instance }),
}) => {
  const url = `${BASE_URL}/_api/data/storage-settings/sandbox-mode`
  const payload = { disabled: true }
  const headers = {
    'Content-Type': 'application/json',
  }

  try {
    await httpClient.post(url, payload, { headers })
  } catch (error) {
    const reason = error.response?.data?.message || ''
    const message = `Unable to fetch result (${reason})`

    throw new Error(message)
  }
}

export default disableSandboxMode
