import { flatten, isEqual } from 'lodash'

// ControllerRef -> [{ControllerRef, ComponentRef, ConnectionConfig}]
const getControllerConnections = async (editorSdkProxy, controllerRef) => {
  const connectedComponents =
    await editorSdkProxy.controllers.listConnectedComponents({
      controllerRef,
    })

  return flatten(
    await Promise.all(
      connectedComponents.map(connectedComponent =>
        editorSdkProxy.controllers
          .listConnections({ componentRef: connectedComponent })
          .then(connections =>
            connections
              .filter(connection =>
                isEqual(connection.controllerRef, controllerRef),
              )
              .map(connection => ({
                ...connection,
                componentRef: connectedComponent,
              })),
          ),
      ),
    ),
  )
}

export default getControllerConnections
