import { trim, deburr } from 'lodash'

/*
 * the slash forces loading the module from userland, instead of platform
 * (e.g. node), which is needed to work around the eslint error:
 * https://github.com/mysticatea/eslint-plugin-node/blob/master/docs/rules/no-deprecated-api.md
 * (Note that userland modules don't hide core modules. For example,
 * require("punycode") still imports the deprecated core module even if you
 * executed yarn punycode. Use require("punycode/") to import userland
 * modules rather than core modules.)*/
import punycode from 'punycode/'

import sanitize from '@wix/dbsm-common/src/utils/sanitizeFieldKey'
import generateUniqueName from '@wix/dbsm-common/src/generateUniqueName'
import { MAX_LENGTH_OF_ID_AND_NAME } from './consts'

const append = (string, suffix, max) =>
  string.length + suffix.length <= max
    ? string + suffix
    : string.slice(0, max - suffix.length) + suffix

const appendCounterToMakeUnique = (baseCollectionId, collections) =>
  generateUniqueName(
    (prefix, i) => append(prefix, i.toString(), MAX_LENGTH_OF_ID_AND_NAME),
    baseCollectionId,
    collections.filter(({ isDeleted }) => !isDeleted).map(({ id }) => id),
    {
      allowNameWithoutSuffix: true,
      startIndex: 1,
      endIndex: Number.POSITIVE_INFINITY,
    },
  )

const hasOnlyASCIICharacters = string =>
  Array.from(string)
    .map(char => char.charCodeAt())
    .every(c => c < 128)

const convertNonAsciiToAscii = string => {
  string = deburr(string)
  return hasOnlyASCIICharacters(string) ? string : punycode.encode(string)
}

export default (collectionName, collections) => {
  if (trim(collectionName).length === 0) {
    return ''
  }
  const sanitizedCollectionName = sanitize(collectionName, {
    mapWord: convertNonAsciiToAscii,
    allowHyphens: true,
    allowLeadingDigits: true,
  })
  return appendCounterToMakeUnique(
    sanitizedCollectionName.length === 0
      ? '_'
      : sanitizedCollectionName.slice(0, MAX_LENGTH_OF_ID_AND_NAME),
    collections,
  )
}
