import {
  uniq,
  flatten,
  filter,
  map,
  flatMapDeep,
  keyBy,
  union,
  mapValues,
  intersection,
  difference,
} from 'lodash'
import componentTypes from '@wix/dbsm-common/src/componentTypes'
import { getRouterDatasetConfig } from '@wix/wix-data-client-data-binding/src/business-logic/datasets/routerDataset'
import {
  DATASET,
  ROUTER_DATASET,
} from '@wix/wix-data-client-common/src/datasetTypes'
import {
  READ_WRITE,
  WRITE,
} from '@wix/wix-data-client-common/src/dataset-configuration/readWriteModes'
import {
  generateAutomationsFieldName,
  isFieldSupported,
} from '@wix/wix-code-automations-client'
import { getEventBindingActionName } from '@wix/wix-data-client-data-binding/src/business-logic/connections/connectionConfigHelper'
import { schemaFetcherV2 } from '@wix/wix-data-client-common/src/business-logic/collections'

const publishFormSchemasToAutomations = async ({
  editorSdkProxy,
  collectionsApi,
  automationsClient,
}) => {
  const {
    Dropdown,
    DatePicker,
    Checkbox,
    TextInput,
    UploadButton,
    TextBox,
    RadioButtonGroup,
    ToggleSwitch,
    Slider,
    TimePicker,
    CheckboxGroup,
  } = componentTypes
  const validComponentSdkTypes = [
    Dropdown,
    DatePicker,
    Checkbox,
    TextInput,
    UploadButton,
    TextBox,
    RadioButtonGroup,
    ToggleSwitch,
    Slider,
    TimePicker,
    CheckboxGroup,
  ]

  const controllerHasConnectedSaveActionAndInputComponent =
    async controllerRef => {
      const controllerConnections =
        await editorSdkProxy.controllers.getControllerConnections({
          controllerRef,
        })

      const sdkTypesOfConnectedComponents = (
        await editorSdkProxy.components.get({
          componentRefs: controllerConnections.map(
            ({ componentRef }) => componentRef,
          ),
          properties: ['sdkType'],
        })
      ).map(({ sdkType }) => sdkType)

      const hasAtLeastOneConnectedInputComponent =
        intersection(sdkTypesOfConnectedComponents, validComponentSdkTypes)
          .length > 0

      const isConnectedToSaveAction = controllerConnections.some(
        ({ connection }) => {
          return (
            getEventBindingActionName(connection.config, 'onClick') === 'save'
          )
        },
      )

      return isConnectedToSaveAction && hasAtLeastOneConnectedInputComponent
    }

  const getControllersInfo = controllers =>
    Promise.all(
      controllers.map(async ({ controllerRef }) => {
        const data = await editorSdkProxy.controllers.getData({ controllerRef })
        const controllerRefId = controllerRef.id
        const { config, type, displayName } = data
        const dataset = config.dataset || {}
        if (type === ROUTER_DATASET) {
          try {
            const routerDatasetConfig = await getRouterDatasetConfig(
              editorSdkProxy,
              controllerRef,
            )
            Object.assign(dataset, routerDatasetConfig.dataset || {})
          } catch {}
        }
        const { collectionName, readWriteType } = dataset
        return {
          controllerRefId,
          controllerRef,
          type,
          displayName,
          collectionName,
          readWriteType,
        }
      }),
    )

  const controllerHasWritePermissions = controllerInfo =>
    !!controllerInfo.collectionName &&
    !!controllerInfo.readWriteType &&
    [DATASET, ROUTER_DATASET].includes(controllerInfo.type) &&
    [WRITE, READ_WRITE].includes(controllerInfo.readWriteType)

  const getFormControllers = async controllers => {
    return flatten(
      await Promise.all(
        controllers.map(async controller => {
          const isFormController =
            await controllerHasConnectedSaveActionAndInputComponent(
              controller.controllerRef,
            )

          return isFormController ? [controller] : []
        }),
      ),
    )
  }

  const getUniqueCollectionsUsedByControllers = async controllers => {
    const rootCollections = uniq(controllers.map(e => e.collectionName))
    const rootCollectionsFields = await findCollectionFields(rootCollections)
    const referencedCollections = map(
      flatMapDeep(
        rootCollectionsFields.map(({ collectionFields }) =>
          collectionFields.filter(({ type }) => type === 'reference'),
        ),
      ),
      'referencedCollection',
    )
    const referencedCollectionsFields = await findCollectionFields(
      difference(referencedCollections, rootCollections),
    )
    const allCollections = union(rootCollections, referencedCollections)
    const allCollectionsFields = union(
      rootCollectionsFields,
      referencedCollectionsFields,
    )

    const collectionsSchemas = mapValues(
      keyBy(allCollectionsFields, 'collectionName'),
      'collectionFields',
    )
    return {
      rootCollections,
      referencedCollections,
      allCollections,
      collectionsSchemas,
    }
  }

  const findCollectionFields = collections => {
    return Promise.all(
      collections.map(async collectionName => {
        const collectionsSchema = await schemaFetcherV2.fetch(
          collectionsApi,
          collectionName,
        )
        return {
          collectionName,
          collectionFields:
            collectionsSchema && collectionsSchema.fields
              ? collectionsSchema.fields
              : [],
        }
      }),
    )
  }

  const _callAutomationApiPublishForms = async ({
    collectionsSchemas,
    formControllers,
  }) => {
    const forms = await Promise.all(
      formControllers.map(async ({ controllerRef, collectionName }) => {
        const formId = await editorSdkProxy.info.getEntityIdFromRef(
          controllerRef,
        )
        const formPageRef = await editorSdkProxy.components.getPage({
          componentRef: controllerRef,
        })
        const formPage = await editorSdkProxy.pages.getPageData({
          pageRef: formPageRef,
        })
        const formName = `${formPage.title} - submit into ${collectionName} collection`
        const collectionId = collectionName

        const validFields = filter(collectionsSchemas[collectionId], field =>
          isFieldSupported(field),
        )
        const fields = validFields.map(({ label, name }) => ({
          fieldName: label,
          fieldId: generateAutomationsFieldName(formId, name),
        }))
        return {
          formId,
          formName,
          collectionId,
          fields,
        }
      }),
    )
    return automationsClient.publishEditorFormsToAutomation({
      forms,
    })
  }

  const controllers = await editorSdkProxy.controllers.listAllControllers()
  const controllersInfo = await getControllersInfo(controllers)
  const controllersWithWritePermissions = controllersInfo.filter(
    controllerHasWritePermissions,
  )
  const formControllers = await getFormControllers(
    controllersWithWritePermissions,
  )

  const { collectionsSchemas } = await getUniqueCollectionsUsedByControllers(
    formControllers,
  )

  await _callAutomationApiPublishForms({
    collectionsSchemas,
    formControllers,
  })
}

const tryToPublishFormSchemasToAutomations = async ({
  editorSdkProxy,
  collectionsApi,
  automationsClient,
  logger,
}) => {
  try {
    return await publishFormSchemasToAutomations({
      editorSdkProxy,
      collectionsApi,
      automationsClient,
    })
  } catch (error) {
    if (
      error.message.startsWith(
        'Fetch exception at publishEditorFormsToAutomation',
      )
    ) {
      logger.info(error.message)
    } else {
      logger.error(error)
    }
  }
}

export default tryToPublishFormSchemasToAutomations
