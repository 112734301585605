function throttle(func, wait) {
  let timeout;

  return (...args) => {
    if (!timeout) {
      timeout = setTimeout(() => {
        func(...args);
        timeout = null;
      }, wait);
    }
  };
}

module.exports = throttle;
