import { values } from 'lodash'
import { FieldType } from '@wix/wix-data-schema-types'

export const TYPE_TRANSLATIONS = {
  [FieldType.text]: 'Datatype_Name_Text',
  [FieldType.number]: 'Datatype_Name_Number',
  [FieldType.image]: 'Datatype_Name_Image',
  [FieldType.dateTime]: 'Datatype_Name_DateTime',
  [FieldType.boolean]: 'Datatype_Name_Boolean',
  [FieldType.richText]: 'Datatype_Name_RichText',
  [FieldType.url]: 'Datatype_Name_URL',
  [FieldType.document]: 'Datatype_Name_Document',
  [FieldType.video]: 'Datatype_Name_Video',
  [FieldType.audio]: 'Datatype_Name_Audio',
  [FieldType.color]: 'Datatype_Name_Color',
  [FieldType.reference]: 'Datatype_Name_Reference',
  [FieldType.address]: 'Datatype_Name_Address',
  [FieldType.object]: 'Datatype_Name_JSObject',
  [FieldType.mediaGallery]: 'Datatype_Name_MediaGallery',
  [FieldType.time]: 'Datatype_Name_Time_Type',
  [FieldType.stringArray]: 'Datatype_Name_StringArray',
  [FieldType.array]: 'Datatype_Name_Array',
  [FieldType.language]: 'Datatype_Name_Language',
  [FieldType.richContent]: 'Datatype_Name_RichContent',
  [FieldType.documentArray]: 'Datatype_Name_DocumentArray',
}

export const ALL_FIELD_TYPES = values(FieldType)

export const FILTERABLE_PATHS = {
  [FieldType.address]: 'formatted',
}

export const MAX_LENGTH_OF_ID_AND_NAME = 36
