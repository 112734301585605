import { preferences } from '@wix/wix-data-client-common/src/createOrEditCollectionPanel'
import * as wixDataSettingsClientManager from '@wix/wix-data-client-common/src/wixDataSettingsClientManager'

const enableDataMode = async ({
  editorSdkProxy,
  WixDataSettings,
  experimentsManager,
  safeUserPreferences,
  appApi,
  logger,
  httpClient,
}) => {
  await wixDataSettingsClientManager
    .enableDataMode(
      editorSdkProxy,
      WixDataSettings,
      experimentsManager,
      logger,
      appApi,
      httpClient,
    )
    .catch(error => logger.error(error))

  safeUserPreferences.set({
    [preferences.INTRO_WAS_SHOWN]: true,
  })
}

export const ensureDataModeIsEnabled = async ({
  editorSdkProxy,
  WixDataSettings,
  experimentsManager,
  safeUserPreferences,
  appApi,
  logger,
  httpClient,
}) => {
  const isDataModeEnabled =
    await wixDataSettingsClientManager.isDataModeEnabled({
      editorSdkProxy,
      WixDataSettings,
      logger,
      httpClient,
    })

  if (!isDataModeEnabled) {
    enableDataMode({
      editorSdkProxy,
      WixDataSettings,
      experimentsManager,
      safeUserPreferences,
      appApi,
      logger,
      httpClient,
    })
  }

  return { enableDataModeTriggered: !isDataModeEnabled }
}
