import { get } from 'lodash'
import set from 'lodash/fp/set'
import { parseUrlPattern } from '../routing-url/utils'
import { addIncrementingSuffix } from '../../../addIncrementingSuffix'

export const FIELD_TYPE_PAGE_LINK = 'pagelink'
const FIELDS_PATTERN_CALCULATOR_ID = 'FIELDS_PATTERN'

const getFieldKey = (prefix, pattern, delimiter) => {
  const { fields, nonFields } = parseUrlPattern(pattern)
  if (fields.length <= 0) {
    fields.push('all')
  }
  return ['link', prefix].concat(nonFields).concat(fields).join(delimiter)
}

export const generateKey = ({
  prefix,
  pattern,
  existingFieldKeys,
  arePageLinksCreatedOnBackend,
}) => {
  const delimiter = arePageLinksCreatedOnBackend ? '_' : '-'
  const fieldKey = getFieldKey(prefix, pattern, delimiter)
  const uniqueFieldKey = addIncrementingSuffix(
    fieldKey,
    existingFieldKeys,
    delimiter,
  )
  return uniqueFieldKey
}

export const generateDefinition = ({ pageTitle, prefix, pattern, pageId }) => ({
  displayName: pageTitle,
  type: FIELD_TYPE_PAGE_LINK,
  calculator: {
    id: FIELDS_PATTERN_CALCULATOR_ID,
    config: {
      pattern: '/' + prefix + pattern,
      lowercase: true,
    },
  },
  linkedRouterPage: pageId,
})

export const isPageLinkField = (field, prefix, pattern) =>
  field.type === FIELD_TYPE_PAGE_LINK &&
  get(field, 'calculator.config.pattern') === '/' + prefix + pattern

export const updatePattern = (fieldDefinition, newPrefix, newPattern) =>
  set(
    'calculator.config.pattern',
    '/' + newPrefix + newPattern,
    fieldDefinition,
  )

export const updateDisplayName = (fieldDefinition, displayName) => ({
  ...fieldDefinition,
  displayName,
})
