import editorSdkProxyCreator from '@wix/wix-code-editor-sdk-proxy'
import _openManageContentPanel from '../../panels/manage-content-panel/openPanel'
import { openConnectPanel as openConnectionConfigPanel } from '../../panels/connection-config-panel/openPanel'
import * as datasetApi from '../../business-logic/datasets/datasetApi'
import { PagingMode } from '@wix/wix-data-schema-types'

const createPublicApi = () => {
  let editorSdkProxy = null
  let i18n = null
  let editorType = null
  let collectionsApi = null
  let appApi = null
  let experimentsManager = null
  let logger = null
  let hostname = null

  return {
    init({
      editorSDK,
      token,
      i18n: _i18n,
      editorType: _editorType,
      collectionsApi: _collectionsApi,
      appApi: _appApi,
      experimentsManager: _experimentsManager,
      logger: _logger,
      hostname: _hostname,
    }) {
      editorSdkProxy = editorSdkProxyCreator(editorSDK, token)
      i18n = _i18n
      editorType = _editorType
      collectionsApi = _collectionsApi
      appApi = _appApi
      experimentsManager = _experimentsManager
      logger = _logger
      hostname = _hostname
    },

    api: {
      openConnectPanel({ componentRef, origin = 'public_api' }) {
        return openConnectionConfigPanel(
          {
            hostname,
            appApi,
            editorSdkProxy,
            experimentsManager,
            logger,
            i18n,
            editorType,
            initiator: 'manually',
            origin,
          },
          { componentRef },
        )
      },
      openManageContentPanel({ componentRef, role, origin = 'public_api' }) {
        return _openManageContentPanel(
          {
            hostname,
            appApi,
            editorSdkProxy,
            experimentsManager,
            collectionsApi,
            logger,
            i18n,
            editorType,
          },
          { componentRef, role, origin },
        )
      },
      async isDatasetUsingCursors({ controllerRef }) {
        let config
        try {
          const dataset = await datasetApi.fetchDataset(
            editorSdkProxy,
            controllerRef,
          )
          config = dataset.config
        } catch {
          throw new Error(`Dataset ${controllerRef} is not found`)
        }

        let collection
        try {
          collection = await collectionsApi.get(config.collectionId)
        } catch {
          throw new Error(
            `Dataset's collection ${config.collectionId} is not found`,
          )
        }

        const collectionSupportsCursors = collection.pagingMode?.includes(
          PagingMode.Cursor,
        )
        const collectionSupportsOffsets = collection.pagingMode?.includes(
          PagingMode.Offset,
        )
        const collectionSupportsOnlyCursors =
          collection?.pagingMode?.length === 1 && collectionSupportsCursors
        const collectionSupportsBothPagingModes =
          collectionSupportsCursors && collectionSupportsOffsets

        const pagingMode =
          collectionSupportsOnlyCursors ||
          (collectionSupportsBothPagingModes && config.cursor)
            ? PagingMode.Cursor
            : PagingMode.Offset

        return pagingMode === PagingMode.Cursor
      },
    },
  }
}

export { createPublicApi }
