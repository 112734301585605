export const withRetry =
  (fn, maxRetries) =>
  async (...args) => {
    let attemptsLeft = maxRetries
    while (true) {
      try {
        return await fn(...args)
      } catch (error) {
        if (attemptsLeft > 1) {
          attemptsLeft--
          continue
        }
        throw error
      }
    }
  }
