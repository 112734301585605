import { FieldType } from '@wix/wix-data-schema-types'
import {
  COLUMN_ROLE,
  STRIP_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
const { image, video } = FieldType

export default ({ i18n }) => ({
  isConnectGfppEnabled: true,
  panel: {
    height: 212,
    title: 'Connect_Panel_Title_Strip_Column',
    helpId: 'dfac1126-f8e9-4f91-a74c-97e441181252',
  },
  sections: [
    {
      role: COLUMN_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Column_Background_Label',
          prop: 'background.src',
          supportedFieldTypes: [image, video],
          defaultFieldType: image,
          supportsReferenceFields: true,
          requiresPrimaryConnection: true,
        },
      ],
    },
    {
      role: STRIP_ROLE,
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'strip_live_preview_gfpp_desktop_main_action',
              label: 'Strip_Live_Preview_GFPP_Desktop_Main_Action',
            },
            mainAction2: 'HIDE',
          },
        },
      },
      manageContentPanel: {
        description: 'Strip_Manage_Content_Panel_Description',
      },
    },
  ],
})
