export default methods => {
  let setTarget
  const target = new Promise(resolve => (setTarget = resolve))
  return {
    setTarget,
    proxy: methods.reduce(
      (proxy, method) =>
        Object.assign(proxy, {
          [method]: async (...args) => (await target)[method](...args),
        }),
      {},
    ),
  }
}
