import { FieldType } from '@wix/wix-data-schema-types'
import { BUTTON_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const {
  document,
  url,
  image,
  video,
  audio,
  text,
  number,
  address,
  stringArray,
  dateTime,
} = FieldType

export default ({ i18n }) => ({
  componentName: 'Element_Name_Button',
  isConnectGfppEnabled: true,
  panel: {
    height: 467,
    title: 'Connect_Panel_Title_Button',
    helpId: 'f3e95690-e2c0-4172-bd5f-f6b77335279d',
  },
  sections: [
    {
      role: BUTTON_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Link_Action',
          prop: 'link',
          event: 'onClick',
          supportedFieldTypes: [document, url, image, video, audio],
          defaultFieldType: url,
          supportPageLinks: true,
          supportsActions: true,
          supportsReferenceFields: true,
          supportsPostSubmit: true,
        },
        {
          title: 'Connect_Panel_Connect_Label_Label',
          prop: 'label',
          supportedFieldTypes: [text, number, dateTime, address, stringArray],
          defaultFieldType: text,
          supportsReferenceFields: true,
          requiresPrimaryConnection: true,
        },
      ],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'button_live_preview_gfpp_desktop_main_action',
              label: 'Button_Live_Preview_GFPP_Desktop_Main_Action',
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Button_Manage_Content_Panel_Description',
      },
    },
  ],
})
