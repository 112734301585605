import { WIX_CODE_DISABLED_MODE_ERROR } from '@wix/wix-data-client-common/src/infra/ignoredErrors'

export default ({
  editorSDK,
  adapter,
  appToken,
  logger,
  finalAppApiPromise,
  getActionsBySchema,
}) => {
  const reportError = async error => {
    if (error?.message === WIX_CODE_DISABLED_MODE_ERROR) {
      return
    }
    const metaSiteId = await editorSDK.info
      .getMetaSiteId(appToken)
      .catch(() => {})
    if (!(error instanceof Error)) {
      logger.error(new Error(error.message || JSON.stringify(error)), {
        status: error.status,
        requestId: error.requestId,
        responseText: error.responseText,
        metaSiteId,
      })
    } else {
      logger.error(error, { metaSiteId })
    }
    return Promise.reject(error)
  }

  const reportErrorAndResolve = error => reportError(error).catch(() => {})

  const updateAppStore = async () => {
    const schemas = await adapter.listSchemas({})
    editorSDK.editor
      .updateAppState({
        schemas,
        actionsBySchema: await getActionsBySchema({ schemas }),
      })
      .catch(() => {})
    // TODO remove the catch above, when ADI implements editor.updateAppState method or platform provides fallback noop implementations for all methods
  }

  const refreshCache = async () => {
    await adapter.clearCache()
    updateAppStore().catch(reportErrorAndResolve)
  }

  const saveSiteIfNeeded = async () => {
    if (!(await editorSDK.info.isSiteSaved(appToken))) {
      try {
        await editorSDK.document.save(appToken)
      } catch {}
    }
  }

  const listSchemas = async ({
    includeDeletedCollections = false,
    includeHiddenAppCollections = false,
  } = {}) =>
    adapter.listSchemas({
      includeDeletedCollections,
      includeHiddenAppCollections,
    })

  const loadSchema = async collectionId =>
    adapter.loadSchema(collectionId).catch(reportError)

  const saveSchema = async (collectionId, schema) => {
    await saveSiteIfNeeded()
    await adapter.saveSchema(collectionId, schema).catch(error => {
      finalAppApiPromise.then(async appApi => {
        const errorIsConcurrentEditingError =
          await appApi.checkIfErrorIsConcurrentEditingError(error)

        if (errorIsConcurrentEditingError) {
          appApi.refreshSchemasCache()
        }
      })

      return reportError(error)
    })
    updateAppStore()
  }

  const removeSchema = async collectionId => {
    await saveSiteIfNeeded()
    await adapter.removeSchema(collectionId).catch(reportError)
    updateAppStore()
  }

  updateAppStore().catch(reportErrorAndResolve)

  return {
    private: {
      refreshCache,
    },
    public: {
      listSchemas,
      loadSchema,
      saveSchema,
      removeSchema,
    },
  }
}
