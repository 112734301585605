'use strict';

const assert = require('./assert');

class BiLoggerManager {
  constructor() {
    this.reset();
  }

  reset() {
    this._handlers = [];
  }

  onLoggerCreated(handler) {
    assert.defined(handler, 'Handler must be provided.');
    assert.func(handler, 'Handler must be a function.');

    this._handlers.push(handler);

    return () => {
      const index = this._handlers.indexOf(handler);

      if (index !== -1) {
        this._handlers.splice(index, 1);
      }
    };
  }

  notifyLoggerCreated(logger) {
    this._handlers.forEach(handler => handler(logger));
  }
}

module.exports = {
  manager: new BiLoggerManager(),
  BiLoggerManager
};
