import { REPEATER_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'

export default ({ i18n }) => ({
  isConnectGfppEnabled: true,
  panel: {
    height: 525,
    title: 'Connect_Panel_Title_Repeater',
    helpId: '85e68e6c-791e-4713-bdca-f8543b1e343d',
  },
  sections: [
    {
      role: REPEATER_ROLE,
      bindings: [],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'repeater_live_preview_gfpp_desktop_main_action',
              label: 'Repeater_Live_Preview_GFPP_Desktop_Main_Action',
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Repeater_Manage_Content_Panel_Description',
      },
    },
  ],
  compactErrorPanel: true,
  supportsDefaultSelectedDataset: false,
})
