import Maybe from 'folktale/maybe'
import { MediaContainer } from '@wix/dbsm-common/src/componentTypes'

export default async function ({ componentRef, editorSdkProxy }) {
  const ancestorsRefs = await editorSdkProxy.components.getAncestors({
    componentRef,
  })
  if (ancestorsRefs.length === 0) {
    return Maybe.Nothing()
  }

  const ancestors = await editorSdkProxy.components.get({
    componentRefs: ancestorsRefs,
    properties: ['sdkType', 'connections'],
  })
  const firstRepeaterItemAncestor = ancestors.find(
    ({ sdkType }) => sdkType === MediaContainer,
  )
  if (!firstRepeaterItemAncestor) {
    return Maybe.Nothing()
  }
  return Maybe.Just(firstRepeaterItemAncestor.componentRef)
}
