import typeManifest from './typeManifest'
import pagesManifest from './pagesManifest'
import routersManifest from './routersManifest'
import controllersStageDataManifest from './controllersStageDataManifest'

const getAppManifest = async ({
  hostname,
  t,
  experimentsManager,
  editorSdkProxy,
  editorType,
  bindingDefinitions,
  collectionsApi,
  routers,
  appPagesEnabled,
}) => ({
  exports: typeManifest(t),
  routers: await routersManifest(
    hostname,
    t,
    experimentsManager,
    editorSdkProxy,
  ),
  pages: appPagesEnabled
    ? await pagesManifest({
        hostname,
        t,
        collectionsApi,
        routers,
        editorSdkProxy,
      })
    : undefined,
  controllersStageData: controllersStageDataManifest(
    t,
    experimentsManager,
    editorType,
    bindingDefinitions,
  ),
})

export default getAppManifest
