import { getPatternDefForPage } from './router-config/routerConfig'
import { ITEM_PAGE, INDEX_PAGE } from './dynamicPageTypes'

export default async function getTypeOfProbableDynamicPage({
  editorSdkProxy,
  pageRef,
}) {
  const routerRef = await editorSdkProxy.routers.getByPage({ pageRef })
  if (!routerRef) return

  const routerData = await editorSdkProxy.routers.get({ routerRef })
  if (
    !routerData ||
    !routerData.config ||
    !routerData.config.patterns ||
    !Array.isArray(routerData.pages)
  ) {
    return
  }

  const { pattern } = getPatternDefForPage(
    routerData.config,
    routerData.pages,
    pageRef,
  )
  if (!pattern) return

  return pattern.includes('{') ? ITEM_PAGE : INDEX_PAGE
  // An item page has at least one parameter in its path.
  // Otherwise, it is an index (aka catalog or all items) page.
}
