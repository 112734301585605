import tryToPublishFormSchemasToAutomations from '../../tryToPublishFormSchemasToAutomations'

export default async ({
    editorSdkProxy,
    collectionsApi,
    automationsClient,
    logger,
  }) =>
  async ({ eventType }) => {
    switch (eventType) {
      case 'siteWasPublished':
        tryToPublishFormSchemasToAutomations({
          editorSdkProxy,
          collectionsApi,
          automationsClient,
          logger,
        })
        break
    }
  }
