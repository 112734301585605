import { createSafeUserPreferences } from '@wix/wix-data-client-common/src/user-preferences/safeUserPreferences'
import {
  getScreenDimensions,
  screens,
} from '@wix/wix-data-client-common/src/createOrEditCollectionPanel'
import openContentManager from './openContentManager'
import getDeveloperMode from '@wix/wix-data-client-common/src/utils/getDeveloperMode'
import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'
import { isResponsiveEditor } from '@wix/wix-data-client-common/src/infra/editorType'
import { getCollectionTypeFromSchema } from './create-new-collection/utils/singles'
import { getScreenDimensionsV2 } from './create-collection-panel/getScreenDimensions'
import {
  shouldShowIntroPanel,
  openIntroPanel,
  MAIN_ACTION_CLICKED,
} from './introPanelForVelo'

export default ({
  hostname,
  collectionsApi,
  fedopsLogger,
  logger,
  editorSdkProxy,
  experimentsManager,
  editorType,
  initialScreen,
  wixData,
  livePreview,
  i18n,
  appApi,
}) => {
  const safeUserPreferences = createSafeUserPreferences({
    editorSdkProxy,
    logger,
    fedopsLogger,
    timeout: 1000,
  })

  const responsiveEditor = isResponsiveEditor(editorType)

  return async initialData => {
    appApi.disallowCollectionSchemaRefresh()
    try {
      const developerMode = await getDeveloperMode(editorSdkProxy)

      const editMode = Boolean(initialData.collectionId)
      const inAppBuilder = Boolean(editorSdkProxy.appBuilder)

      if (
        await shouldShowIntroPanel({
          editMode,
          inAppBuilder,
          safeUserPreferences,
          initialScreen,
          experimentsManager,
        })
      ) {
        const result = await openIntroPanel({
          editorSdkProxy,
          safeUserPreferences,
          t: i18n.t.bind(i18n),
        })

        if (result !== MAIN_ACTION_CLICKED) {
          return
        }
      }

      const resolveInitialScreen = async () => {
        if (initialScreen) {
          return initialScreen
        }

        return screens.DETAILS
      }

      const screen = await resolveInitialScreen()

      const isDraftPublishEnabled = experimentsManager.enabled(
        'specs.wixCode.DraftPublish',
      )
      const isCreateCollectionWithoutTabsEnabled = experimentsManager.enabled(
        'specs.wixDataClient.CreateCollectionWithoutTabs',
      )

      const getCollectionType = async () => {
        const { collectionId } = initialData
        if (!collectionId) {
          return
        }

        const collectionSchema = await collectionsApi.get(collectionId)
        return getCollectionTypeFromSchema(collectionSchema)
      }

      const isCreateCollectionPanelV2 = experimentsManager.enabled(
        'specs.wixDataClient.CreateCollectionPanelV2',
      )
      const isEditCollectionPanelV2 = isCreateCollectionPanelV2 && editMode

      const { width, height } = isEditCollectionPanelV2
        ? { width: '600', height: '647' }
        : isCreateCollectionPanelV2
        ? getScreenDimensionsV2({ developerMode })
        : getScreenDimensions({
            screen,
            developerMode,
            isDraftPublishEnabled,
            isEditMode: editMode,
            collectionType: await getCollectionType(),
            isCreateCollectionWithoutTabsEnabled,
          })

      const url = await getPanelUrl({
        hostname,
        relativeUrl: isEditCollectionPanelV2
          ? 'editCollectionPanel.html'
          : isCreateCollectionPanelV2
          ? 'createCollectionPanel.html'
          : 'create-new-collection.html',
        editorSdkProxy,
      })

      const panelResult = await editorSdkProxy.editor
        .openModalPanel({
          url,
          width,
          height,
          shouldHideHeader: true,
          initialData: {
            responsiveEditor,
            initialScreen,
            ...initialData,
          },
        })
        .catch(logger.error)

      if (panelResult && !editMode) {
        openContentManager({
          hostname,
          editorSdkProxy,
          collectionName: panelResult.collectionId,
          isModal: true,
          origin: initialData.origin,
          experimentsManager,
          collectionsApi,
          wixData,
          livePreview,
          i18n,
        }).catch(logger.error)
      }

      return panelResult
    } finally {
      appApi.allowCollectionSchemaRefresh()
    }
  }
}
