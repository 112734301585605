export const URL_TOO_LONG = 'Dynamic_Pages_Validations_URL_Too_Long'
export const PREFIX_STARTS_WITH_TEXT =
  'DYNAMIC_PAGES_VALIDATIONS_PREFIX_STARTS_WITH_TEXT'
export const NO_FIELD_IN_PREFIX = 'Dynamic_Pages_Validations_No_Field_In_Prefix'
export const NO_SPECIAL_CHARS = 'Dynamic_Pages_Validations_No_Special_Chars'
export const NO_DELETED_FIELD = 'Dynamic_Pages_Validations_No_Deleted_Fields'
export const ONLY_EXISTING_FIELDS =
  'Dynamic_Pages_Validations_Only_Existing_Fields'
export const PATTERN_ALREADY_EXISTS =
  'Dynamic_Pages_Validations_Pattern_Already_Exists'
export const SEPARATE_FIELDS =
  'Dynamic_Pages_Validations_Separate_Dynamic_Fields'
export const UNSUPPORTED_FIELDS =
  'Dynamic_Pages_Pages_Info_Error_URL_Unsupported_Field'
export const URL_EMPTY = 'DYNAMIC_PAGES_ITEM_SETUP_SCREEN_ERROR'
