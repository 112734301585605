import onEventCreator from './events'
import appApiCreator from './api'

const createModuleFactories = async services => {
  const { editorSdkProxy } = services

  await editorSdkProxy.document.application.registerToCustomEvents({
    eventTypes: ['developerModeStatusChanged'],
  })

  return {
    createAppApi: () => appApiCreator(services),
    createEventHandlers: ({ appApi }) =>
      onEventCreator({ ...services, appApi }),
  }
}

export default { createModuleFactories }
