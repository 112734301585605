import set from 'lodash/fp/set'
import * as datasetApi from '../business-logic/datasets/datasetApi'
import { isFilterByUserInput } from '../business-logic/filter/transform'
import { isFilterComponentConnectable } from '../business-logic/filter/filterByUserInput'

const removeDisconnectedDatasetFilters = async ({
  controllerRef,
  editorSdkProxy,
}) => {
  const dataset = await datasetApi.fetchDataset(editorSdkProxy, controllerRef)
  const cleanedFilters = dataset.config.filters.filter(
    f => !isFilterByUserInput(f) || isFilterComponentConnectable(f),
  )
  if (cleanedFilters.length !== dataset.config.filters.length) {
    const cleanedConfig = set('filters', cleanedFilters, dataset.config)
    await datasetApi.updateConfig(editorSdkProxy, controllerRef, cleanedConfig)
  }
}

export default removeDisconnectedDatasetFilters
