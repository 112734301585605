import crawl from 'tree-crawl'
import { WIX_PRO_GALLERY, RICH_CONTENT } from '@wix/app-definition-ids'
import { viewerTypes } from '@wix/dbsm-common/src/componentTypes'

const supportedApps = [WIX_PRO_GALLERY, RICH_CONTENT]

const getComponents = component => component.components || []

const installApp = async (editorSdkProxy, appDefinitionId) => {
  const result = await editorSdkProxy.document.application.install({
    appDefinitionId,
  })
  if (!result.success) {
    throw new Error(`Failed to install app ${appDefinitionId}`)
  }
  return result
}

export const findAppsToInstallInPage = pageDefinition => {
  const appsToInstall = new Set()

  const findSupportedApps = component => {
    const isTPA = component.componentType === viewerTypes.TPAWidget
    const isSupportedTPA = supportedApps.includes(
      component?.data?.appDefinitionId,
    )

    if (isTPA && isSupportedTPA) {
      appsToInstall.add(component.data.appDefinitionId)
    } else if (isTPA && !isSupportedTPA) {
      throw new Error(
        `App with ${component.data.appDefinitionId} is not supported`,
      )
    }
  }

  crawl(pageDefinition, findSupportedApps, { getChildren: getComponents })

  return Array.from(appsToInstall)
}

export const installAppsInPage = async (editorSdkProxy, page) => {
  const appsToInstall = findAppsToInstallInPage(page.definition)

  const installedAppIds = new Map()

  await Promise.all(
    appsToInstall.map(async appDefinitionId => {
      const { applicationId } = await installApp(
        editorSdkProxy,
        appDefinitionId,
      )
      installedAppIds.set(appDefinitionId, applicationId)
    }),
  )

  return installedAppIds
}
