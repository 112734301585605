import { FieldType } from '@wix/wix-data-schema-types'
import { GOOGLEMAP_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { text, url, stringArray, address } = FieldType

export default () => ({
  isConnectGfppEnabled: true,
  panel: {
    height: 550,
    title: 'Connect_Panel_Title_Google_Maps',
    helpId: '3af0fa61-098e-4b94-9abb-2312a7cf16a2',
  },
  sections: [
    {
      role: GOOGLEMAP_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Google_Maps_Location_Address_Label',
          prop: 'address',
          supportedFieldTypes: [address],
          defaultFieldType: address,
          supportsReferenceFields: true,
        },
        {
          title: 'Connect_Panel_Connect_Google_Maps_Location_Title_Label',
          prop: 'title',
          supportedFieldTypes: [text, address, stringArray],
          defaultFieldType: text,
          supportsReferenceFields: true,
        },
        {
          title: 'Connect_Panel_Connect_Google_Maps_Location_Description_Label',
          prop: 'description',
          supportedFieldTypes: [text, address, stringArray],
          defaultFieldType: text,
          supportsReferenceFields: true,
        },
        {
          title: 'Connect_Panel_Connect_Google_Maps_Location_Link_Label',
          prop: 'link',
          supportedFieldTypes: [url],
          defaultFieldType: url,
          supportsReferenceFields: true,
        },
        {
          title: 'Connect_Panel_Connect_Google_Maps_Link_Text_Label',
          prop: 'linkTitle',
          supportedFieldTypes: [text, address, stringArray],
          defaultFieldType: text,
          supportsReferenceFields: true,
        },
        {
          title: 'Connect_Panel_Connect_Google_Maps_Pin_Label',
          prop: 'icon',
          supportedFieldTypes: [url],
          defaultFieldType: url,
          supportsReferenceFields: true,
        },
      ],
    },
  ],
  compactErrorPanel: true,
})
