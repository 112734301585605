import { intersection } from 'lodash'

export default (a, b) => {
  const duplicates = intersection(Object.keys(a), Object.keys(b))
  if (duplicates.length > 0) {
    throw new Error(
      `Attempting to define duplicate API methods: ${duplicates.join(', ')}`,
    )
  }
}
