export const DATASET_LIVE_PREVIEW_PAGE_SIZE_LIMIT = 12
export const DATASET_PAGE_SIZE_PERFORMANCE_WARNING_LIMIT = 48

export const PANEL_DIMENSIONS_FOR_SINGLES_COLLECTION = {
  width: 288,
  height: 252,
}

export const PANEL_DIMENSIONS_FOR_MULTI_COLLECTION = {
  width: 288,
  height: 546,
}
