import { Storage } from '@wix/wix-data-schema-types'
import getDeveloperMode from '@wix/wix-data-client-common/src/utils/getDeveloperMode'
import getFieldKeyForUrl from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/getFieldKeyForUrl'
import { digestSchema } from '@wix/wix-data-client-common/src/business-logic/collections/digestSchema'
import { isBlocks } from '@wix/wix-data-client-common/src/infra/editorType'

const ACTION_NAMES = {
  VIEW_PERMISSIONS: 'VIEW_PERMISSIONS',
  EDIT_SETTINGS: 'EDIT_SETTINGS',
  PERMISSIONS_AND_PRIVACY: 'PERMISSIONS_AND_PRIVACY',
  ADD_BLANK_DYNAMIC_PAGE: 'ADD_BLANK_DYNAMIC_PAGE',
  ADD_DYNAMIC_ITEM_PAGE: 'ADD_DYNAMIC_ITEM_PAGE',
  ADD_DYNAMIC_LIST_PAGE: 'ADD_DYNAMIC_LIST_PAGE',
  ADD_REMOVE_HOOKS: 'ADD_REMOVE_HOOKS',
  REMOVE_COLLECTION: 'REMOVE_COLLECTION',
}

const getContextMenuActions = ({ t }) => ({
  [ACTION_NAMES.VIEW_PERMISSIONS]: {
    title: t('WixCode_TreeView_FileTree_QuickActions_UpdatePermissions'),
    icon: 'contexMenuActionEditPermissions',
    event: 'openCollectionPermissionsClicked',
  },
  [ACTION_NAMES.EDIT_SETTINGS]: {
    title: t('WixCode_TreeView_FileTree_QuickActions_Edit_Collection_Settings'),
    icon: 'editCollection',
    event: 'editCollectionClicked',
  },
  [ACTION_NAMES.PERMISSIONS_AND_PRIVACY]: {
    title: t('WixCode_TreeView_FileTree_QuickActions_Permissions_And_Privacy'),
    icon: 'contexMenuActionEditPermissions',
    event: 'permissionsAndPrivacyClicked',
  },
  [ACTION_NAMES.ADD_BLANK_DYNAMIC_PAGE]: {
    title: t('Collections_Tree_Context_Menu_Actions_Add_New_Blank_Page'),
    event: 'addBlankDynamicPageClicked',
    icon: 'add_new_item',
  },
  [ACTION_NAMES.ADD_DYNAMIC_ITEM_PAGE]: {
    title: t('WixCode_TreeView_FileTree_QuickActions_Add_Dynamic_Page'),
    icon: 'add_new_item',
    event: 'addDefaultDynamicItemPage',
  },
  [ACTION_NAMES.ADD_DYNAMIC_LIST_PAGE]: {
    title: t('App_Pages_Group_Menu_Add_List_Page'),
    event: 'addDefaultDynamicListPage',
    icon: 'add_new_item',
  },
  [ACTION_NAMES.ADD_REMOVE_HOOKS]: {
    title: t('Collections_Tree_Context_Menu_Actions_AddRemoveHooks'),
    event: 'addRemoveHooksClicked',
    icon: 'add_hook_icon',
  },
  [ACTION_NAMES.REMOVE_COLLECTION]: {
    title: t('Collections_Tree_Context_Menu_Actions_Remove'),
    event: 'removeCollectionClicked',
    icon: 'delete_icon',
  },
})

const isActionAllowedForCollection = ({
  action,
  isUserCollection,
  hasPermissionToAddPages,
  hasPermissionToManageContent,
  isDeveloperModeOn,
  shouldShowHooksIfInReadOnlyMode,
  isDPCreationSupportedBySchema,
  isInBlocks,
  isCreateCollectionPanelV2Enabled,
}) => {
  const shouldShowPageCreationOption =
    hasPermissionToAddPages && isDPCreationSupportedBySchema && !isInBlocks

  switch (action) {
    case ACTION_NAMES.VIEW_PERMISSIONS:
      return !isUserCollection || !hasPermissionToManageContent
    case ACTION_NAMES.EDIT_SETTINGS:
      return isUserCollection && hasPermissionToManageContent
    case ACTION_NAMES.PERMISSIONS_AND_PRIVACY:
      return (
        isCreateCollectionPanelV2Enabled &&
        isUserCollection &&
        hasPermissionToManageContent
      )
    case ACTION_NAMES.ADD_DYNAMIC_ITEM_PAGE:
      return shouldShowPageCreationOption
    case ACTION_NAMES.ADD_BLANK_DYNAMIC_PAGE:
      return shouldShowPageCreationOption
    case ACTION_NAMES.ADD_DYNAMIC_LIST_PAGE:
      return shouldShowPageCreationOption
    case ACTION_NAMES.ADD_REMOVE_HOOKS:
      return (
        hasPermissionToManageContent &&
        isDeveloperModeOn &&
        shouldShowHooksIfInReadOnlyMode &&
        !isInBlocks
      )
    case ACTION_NAMES.REMOVE_COLLECTION:
      return hasPermissionToManageContent && isUserCollection
  }
}

export const updateAppStateActions = ({ editorSdkProxy, t }) => {
  editorSdkProxy.editor.updateAppState({
    actions: getContextMenuActions({ t }),
  })
}

export const getActionsBySchema = async ({
  editorSdkProxy,
  experimentsManager,
  schemas,
  editorType,
}) => {
  const hasPermissionToAddPages =
    await editorSdkProxy.document.permissions.isCustomPermissionsGranted({
      permissions: ['DOCUMENT.EDIT_STRUCTURE', 'WIX_DATA.UPDATE_SCHEMA'],
    })

  const hasPermissionToManageContent =
    await editorSdkProxy.document.permissions.isCustomPermissionsGranted({
      permissions: ['WIX_DATA.UPDATE_SCHEMA'],
    })

  const isCreateCollectionPanelV2Enabled = experimentsManager.enabled(
    'specs.wixDataClient.CreateCollectionPanelV2',
  )

  const isDeveloperModeOn = await getDeveloperMode(editorSdkProxy)

  const isInBlocks = isBlocks(editorType)

  const shouldShowHooksIfInReadOnlyMode =
    !(await editorSdkProxy.editor.developerMode.isReadOnly())

  return schemas.reduce((collectionsTypes, schema) => {
    const { fields, displayField, isTPACollection, namespace, plugins, id } =
      digestSchema(schema)

    const fieldKeyForUrlExists = !!getFieldKeyForUrl({
      collectionFields: fields,
      displayField,
      isTPACollection,
    })

    const isUserCollection =
      !namespace || (schema.storage === Storage.staticAppSchema && isInBlocks)

    const isSingleItemCollection = !!plugins?.singleItem

    const isDPCreationSupportedBySchema =
      !isSingleItemCollection && fieldKeyForUrlExists

    collectionsTypes[id] = Object.values(ACTION_NAMES).filter(action =>
      isActionAllowedForCollection({
        action,
        isUserCollection,
        hasPermissionToAddPages,
        hasPermissionToManageContent,
        isDeveloperModeOn,
        shouldShowHooksIfInReadOnlyMode,
        isDPCreationSupportedBySchema,
        isInBlocks,
        isCreateCollectionPanelV2Enabled,
      }),
    )

    return collectionsTypes
  }, {})
}

export const updateAppStateActionsBySchema = async ({
  editorSdkProxy,
  collectionsApi,
  experimentsManager,
  editorType,
}) => {
  const schemas = await collectionsApi.getAll()
  const actionsBySchema = await getActionsBySchema({
    editorSdkProxy,
    experimentsManager,
    schemas,
    editorType,
  })

  editorSdkProxy.editor.updateAppState({
    actionsBySchema,
  })
}
