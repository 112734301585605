import { FieldType } from '@wix/wix-data-schema-types'
import { UPLOAD_BUTTON_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { image, document, video, audio, mediaGallery, documentArray } = FieldType

export default () => ({
  componentName: 'Element_Name_Upload_Button',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Upload_Button',
    helpId: 'f86b72be-a17e-4c38-8f47-fc2635d59a6c',
  },
  sections: [
    {
      role: UPLOAD_BUTTON_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_File_Label',
          prop: 'value',
          supportedFieldTypes: [
            image,
            document,
            video,
            audio,
            mediaGallery,
            documentArray,
          ],
          defaultFieldType: image,
          isInputProperty: true,
        },
      ],
    },
  ],
})
