import * as routeDefUtils from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/patternDef'
import { getSortDefinition } from './getSortDefinition'
import { generateSeoMetaTags } from './generateSeoMetaTags'

const findRouteByPageId = ({ routerPages, routerConfig, pageId }) => {
  const pageRole = routerPages.find(p => p.pageRef.id === pageId).pageRoles[0]

  return Object.keys(routerConfig.patterns).find(
    p => routerConfig.patterns[p].pageRole === pageRole,
  )
}

const generateDefinition = ({
  routerConfig,
  route,
  collectionId,
  isIndexable,
}) => {
  const routeDefinitionBase = routerConfig.patterns[route]
  const routeDefinition = routeDefUtils.generate({
    routeDefinitionBase,
    pattern: route,
    collectionId,
  })

  return routeDefUtils.setIndexing(routeDefinition, isIndexable)
}

const generateDefinitionAndAdjustToSchema = ({
  routerConfig,
  routerPages,
  route,
  pageId,
  collectionId,
  schema,
  editorType,
  isIndexable,
}) => {
  const presetRoute = findRouteByPageId({ routerPages, routerConfig, pageId })
  const routeDefinitionBase = routerConfig.patterns[presetRoute]

  const routeDefinition = routeDefUtils.generate({
    routeDefinitionBase,
    pattern: route,
    collectionId,
  })

  const sort = getSortDefinition({
    editorType,
    fields: schema.fields,
    sortDefinitionBase: routeDefinition.config.sort,
  })

  const seoMetaTags = generateSeoMetaTags({
    collection: schema,
    editorType,
    route,
  })

  const updatedRouteDefinition = {
    ...routeDefinition,
    seoMetaTags,
    config: {
      ...routeDefinition.config,
      sort,
    },
  }

  return routeDefUtils.setIndexing(updatedRouteDefinition, isIndexable)
}

export const generateRouteDefinition = ({
  routerConfig,
  routerPages,
  pageId,
  collectionId,
  schema,
  editorType,
  requiresAdjustingToSchema,
  route,
  isIndexable,
}) => {
  if (requiresAdjustingToSchema) {
    return generateDefinitionAndAdjustToSchema({
      routerConfig,
      routerPages,
      route,
      pageId,
      collectionId,
      schema,
      editorType,
      isIndexable,
    })
  }

  return generateDefinition({ routerConfig, route, collectionId, isIndexable })
}
