import { DB_PANEL_BASENAME } from '../consts'
import getAppVersion from '../infra/getAppVersion'
import getSdkVersion from '../infra/getSdkVersion'

const toQueryString = params =>
  Object.keys(params)
    .filter(key => Boolean(params[key]))
    .reduce(
      (arr, key) => arr.concat(`${key}=${encodeURIComponent(params[key])}`),
      [],
    )
    .join('&')

const getEditorParameters = async (
  editorSdkProxy,
  panelWithSeparateGaLifecycle,
) => {
  const [locale, panelVersion, sdkVersion] = await Promise.all([
    editorSdkProxy.environment.getLocale(),
    panelWithSeparateGaLifecycle ? undefined : getAppVersion(editorSdkProxy),
    getSdkVersion(editorSdkProxy),
  ])

  return {
    locale,
    panelVersion,
    sdkVersion,
  }
}

export default async ({
  hostname,
  panelBasename = DB_PANEL_BASENAME,
  relativeUrl,
  editorSdkProxy = null,
  params = {},
  panelWithSeparateGaLifecycle = false,
}) => {
  if (!hostname) {
    throw new Error('Hostname is not defined')
  }

  const queryParams = {
    debug: process.env.WIX_DATA_CLIENT_DEBUG ? 'true' : '',
    ...(editorSdkProxy
      ? await getEditorParameters(editorSdkProxy, panelWithSeparateGaLifecycle)
      : {}),
    ...params,
  }

  const url = `//${hostname}${panelBasename}/${relativeUrl.replace('./', '')}`
  const query = toQueryString(queryParams)

  return `${url}${query ? '?' : ''}${query}`
}
