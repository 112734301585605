import {
  dynamicPageCustomRouterCreatePageClicked,
  presetCollectionCreated,
  presetInstallationResult,
  presetDynamicPageCreated,
  presetPageCreated,
} from '@wix/bi-logger-platform/v2'
import { cmCollectionCreated } from '@wix/bi-logger-platform-cm/v2'
import { PAGE_TYPES } from '@wix/wix-data-client-common/src/business-data/dynamicPages'
import { serializePermissions } from '@wix/wix-data-client-common/src/bi/permissions'

export const getEventsLogger = ({
  logger,
  biDefaults,
  isSandboxEnabled,
  isDraftPublishEnabled,
  defaultDraftPublishStatus,
}) => {
  return {
    logForCollection: ({
      permissions,
      collectionId,
      collectionName,
      collectionType,
    }) =>
      logger.bi(
        cmCollectionCreated({
          collection_name: collectionName,
          collection_id: collectionId,
          collection_type: collectionType,
          permissions: serializePermissions(permissions),
          default_content: true,
          is_draft_published_enabled: isDraftPublishEnabled,
          default_new_item_status: isDraftPublishEnabled
            ? defaultDraftPublishStatus
            : undefined,
          origin: biDefaults.origin,
          is_first_data_modal: biDefaults.is_first_data_modal,
        }),
      ),

    logForAddedPages: ({
      state,
      collectionId,
      collectionName,
      newCollection,
    }) => {
      state.pages.forEach(({ pageRef, pageType, pageUrl, isAutoBound }) => {
        logger.bi(
          dynamicPageCustomRouterCreatePageClicked({
            page_type: pageType,
            collection_id: collectionId,
            collection_name: collectionName,
            new_url: pageUrl,
            is_auto_bound: isAutoBound,
            new_collection: newCollection,
            page_ids: pageRef.id,
            ...biDefaults,
          }),
        )
      })
    },
    logForPresetCollection: ({ preset, collectionId, collectionType }) =>
      logger.bi(
        presetCollectionCreated({
          collection_type: collectionType,
          collection_id: collectionId,
          collection_name: collectionId,
          preset_id: preset.biInfo.presetId,
          preset_name: preset.biInfo.presetName,
          is_sandbox_enabled: isSandboxEnabled,
          is_draft_published_enabled: isDraftPublishEnabled,
          default_new_item_status: isDraftPublishEnabled
            ? defaultDraftPublishStatus
            : undefined,
          ...biDefaults,
        }),
      ),
    logForAddedPreset: ({ state, preset, collectionId, startTime }) => {
      state.pages.forEach(({ pageRef, pageType, pageTitle }) => {
        logger.bi(
          pageType === PAGE_TYPES.ITEM
            ? presetDynamicPageCreated({
                page_name: pageTitle,
                pageId: pageRef.id,
                preset_id: preset.biInfo.presetId,
                collection_id: collectionId,
                ...biDefaults,
              })
            : presetPageCreated({
                page_name: pageTitle,
                pageId: pageRef.id,
                preset_id: preset.biInfo.presetId,
                collection_id: collectionId,
                ...biDefaults,
              }),
        )
      })

      const endTime = Date.now()

      if (state.pages.length > 0) {
        logger.bi(
          presetInstallationResult({
            preset_id: preset.biInfo.presetId,
            preset_name: preset.biInfo.presetName,
            time_to_complete: endTime - startTime,
            collection_id: collectionId,
            ...biDefaults,
          }),
        )
      }
    },
  }
}
