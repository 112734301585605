import { serverExperimentsFallback } from './fallback'

export const loadServerExperiments = ({ httpClient, instance }) =>
  httpClient
    .get('/_api/cm-server/server-experiments', {
      headers: {
        Authorization: instance,
      },
    })
    .then(response => response.data)
    .catch(_ => serverExperimentsFallback)
