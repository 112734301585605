import { DATA_BINDING } from '@wix/app-definition-ids'

const pageDataFixer =
  pageData =>
  async ({ pageRef }) => {
    const { managingAppDefId } = await pageData.get({
      pageRef,
    })

    if (!managingAppDefId) {
      await pageData.update({
        pageRef,
        data: { managingAppDefId: DATA_BINDING },
      })
    }
  }

const fixDynamicPages = (routers, pageData) =>
  Promise.all(
    routers.flatMap(({ pages }) => pages).map(pageDataFixer(pageData)),
  )

export default fixDynamicPages
