import { cloneDeep } from 'lodash'
import { GFPP_ACTION_IDS } from '@wix/wix-data-client-common/src/consts'
import {
  DATASET,
  ROUTER_DATASET,
} from '@wix/wix-data-client-common/src/datasetTypes'
import { features } from '@wix/wix-data-client-common/src/features'

// See https://github.com/wix-private/js-platform-editor-sdk/blob/master/js/manifest.ts
const ALWAYS_VISIBLE = 'EDITOR' // The value is undocumented.

export default (t, experimentsManager, editorType, bindingDefinitions) => ({
  [DATASET]: {
    default: {
      visibility: ALWAYS_VISIBLE,
      icon: makeUrlToSvg({
        fileName: 'dataset_unconfigured',
      }),
      gfpp: createSettingsOnlyGfppConfig({ t }),
    },

    configured: {
      visibility: ALWAYS_VISIBLE,
      ...(features.livePreview({ editorType, experimentsManager })
        ? {
            connections: createLivePreviewConnections({
              t,
              bindingDefinitions,
            }),
          }
        : {}),
      icon: makeUrlToSvg({
        fileName: 'dataset_configured',
      }),
      gfpp: createFullGfppConfig({ t }),
    },

    connectedToHiddenAppCollection: {
      visibility: ALWAYS_VISIBLE,
      ...(features.livePreview({ editorType, experimentsManager })
        ? {
            connections: createLivePreviewConnections({
              t,
              bindingDefinitions,
            }),
          }
        : {}),
      icon: makeUrlToSvg({
        fileName: 'dataset_unconfigured',
      }),
      gfpp: createFullGfppConfig({ t }),
    },
  },

  [ROUTER_DATASET]: {
    default: {
      visibility: ALWAYS_VISIBLE,
      ...(features.livePreview({ editorType, experimentsManager })
        ? {
            connections: createLivePreviewConnections({
              t,
              bindingDefinitions,
            }),
          }
        : {}),
      icon: makeUrlToSvg({
        fileName: 'router_dataset_icon',
      }),
      enableShowOnAllPages: false,
      removable: false,
      duplicatable: false,
      gfpp: createFullGfppConfig({ t }),
    },

    removableController: {
      visibility: ALWAYS_VISIBLE,
      removable: true,
    },
  },
})

function createLivePreviewConnections({ t, bindingDefinitions }) {
  return Object.entries(bindingDefinitions).reduce(
    (livePreviewManifest, [type, binding]) => {
      const { sections } = binding

      sections.forEach(sectionDefinition => {
        const { role, manifest } = sectionDefinition
        if (manifest) {
          livePreviewManifest[role] = translateManifestLabels({ t, manifest })
        }
      })

      return livePreviewManifest
    },
    {},
  )
}

function translateManifestLabels({ t, manifest }) {
  const translatedManifest = cloneDeep(manifest)

  const mainAction1 = translatedManifest?.gfpp?.desktop?.mainAction1
  if (mainAction1?.label) {
    mainAction1.label = t(mainAction1.label)
  }

  const mainAction2 = translatedManifest?.gfpp?.desktop?.mainAction2
  if (mainAction2?.label) {
    mainAction2.label = t(mainAction2.label)
  }

  return translatedManifest
}

function makeUrlToSvg({ fileName }) {
  return `https://static.parastorage.com/services/dbsm-editor-app/1.1619.0/${fileName}.svg`
}

function createFullGfppConfig({ t }) {
  return {
    mainAction1: createManageContentAction({ t }),
    mainAction2: createSettingsAction({ t }),
  }
}

function createManageContentAction({ t }) {
  return {
    actionId: GFPP_ACTION_IDS.MANAGE_CONTENT_ACTION,
    label: t('gfpp_mainaction_dataset_manage_content'),
  }
}

function createSettingsOnlyGfppConfig({ t }) {
  return { mainAction1: createSettingsAction({ t }) }
}

function createSettingsAction({ t }) {
  return {
    actionId: GFPP_ACTION_IDS.SETTINGS_ACTION,
    label: t('gfpp_mainaction_dataset_settings'),
  }
}
