import createDynamicPageGroups from '../utils/createDynamicPageGroups'
import * as panelDefs from '@wix/wix-data-client-common/src/panels/panelDefs'
import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'

export default async ({
  hostname,
  t,
  collectionsApi,
  routers,
  editorSdkProxy,
}) => {
  const collections = await collectionsApi.getAll({
    includeHiddenAppCollections: true,
  })

  const [customGroups, isPermittedToAddNewItem, isPermittedToEditStructure] =
    await Promise.all([
      createDynamicPageGroups({
        routers,
        collections,
        t,
        editorSdkProxy,
      }),
      editorSdkProxy.document.permissions.isCustomPermissionsGranted({
        permissions: ['WIX_DATA.INSERT'],
      }),
      editorSdkProxy.document.permissions.isCustomPermissionsGranted({
        permissions: ['DOCUMENT.EDIT_STRUCTURE'],
      }),
    ])

  return {
    applicationSettings: {
      default: {
        displayName: t('App_Pages_Display_Name'),
        helpId: '1e76f0cc-531a-45b3-bfe0-8907cf391fca',
        grouping: {
          customGroups,
          groupBy: 'custom',
        },
      },
    },
    applicationActions: {
      default: [
        {
          title: t('App_Pages_Add_Button_Title'),
          event: 'addDynamicPageClicked',
          icon: 'addPagePagesPanel',
          type: 'add_page',
        },
        {
          title: t('App_Pages_Actions_Manage_Collections'),
          event: 'openContentManager',
          icon: 'settingsAction',
        },
      ],
    },
    pageActions: {
      default: [
        'Pages_Actions_Page_Rename',
        ...[
          {
            title: t('Dynamic_Pages_Page_Actions_Add_New_Item'),
            event: 'addNewItemClicked',
            icon: 'add_new_item',
          },
        ].filter(() => isPermittedToAddNewItem),
        ...[
          {
            title: t('Dynamic_Pages_Page_Actions_Duplicate'),
            event: 'duplicateDynamicPage',
            icon: 'duplicateAction',
          },
        ].filter(() => isPermittedToEditStructure),
        ...[
          {
            title: t('Dynamic_Pages_Page_Actions_Remove_Dynamic_Connections'),
            event: 'disconnectPageFromRouterClicked',
            icon: 'unlink_icon',
          },
          {
            title: t('Dynamic_Pages_Page_Actions_Page_Delete'),
            event: 'deleteDynamicPageClicked',
            icon: 'delete_icon',
          },
        ].filter(() => isPermittedToEditStructure),
      ],
    },
    pageSettings: {
      default: [
        {
          type: 'page_info',
          title: t('Dynamic_Pages_Pages_Info_Tab_Title'),
          url: await getPanelUrl({
            hostname,
            editorSdkProxy,
            relativeUrl: panelDefs.dynamicPageInfoPanel.url,
          }),
          helpId: 'e200b3b9-2045-4551-86d9-d9d74e90bd53',
        },
        {
          type: 'layout',
          title: t('Pages_Layouts_Tab_Title'),
          helpId: 'd985e594-b73a-46ea-adb7-044ecc3c1acc',
        },
        {
          type: 'permissions',
          title: t('Pages_Permissions_Tab_Title'),
          helpId: '6c369b66-9450-45a1-aedb-f347459f9e97',
        },
        {
          type: 'seo',
          title: t('DYNAMIC_PAGES_PAGES_SEO_TAB_TITLE'),
          url: await getPanelUrl({
            hostname,
            editorSdkProxy,
            relativeUrl: panelDefs.dynamicPageSeoPanel.url,
          }),
          helpId: '4c46a5f2-fabb-416e-84b9-7971b56d386b',
        },
      ],
    },
    pageDescriptors: {
      default: {
        icon: 'page_dynamic_icon',
      },
    },
  }
}
