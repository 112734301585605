import { get, first, pickBy } from 'lodash'
import { PAGE_TYPES } from '@wix/wix-data-client-common/src/business-data/dynamicPages'
import { FieldType } from '@wix/wix-data-schema-types'
import { getPageName as generateNewPageName } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/templates'
import isDbDriverCollection from '@wix/wix-data-client-common/src/business-logic/collections/isDbDriverCollection'
import createOrConnectDynamicPage from './new-page/createOrConnectDynamicPage'
import { applyDataFixers } from './new-page/applyDataFixers'
import addDPLinkToSiteMenu from '../addDPLinkToSiteMenu'
import { generateRouteDefinition } from './new-page/generateRouteDefinition'
import { isDynamicRoute } from '../isDynamicRoute'
import { prepareDataForPages } from './prepareDataForPages'
import { DATA_BINDING } from '@wix/app-definition-ids'

const getPageLinkFieldKey = ({ state, pageKey }) =>
  get(state.pageDefinitions[pageKey], 'pageLinkFieldKey')

const getShouldAddComponents = ({ shouldBeBlank, staticPageRef }) => {
  if (shouldBeBlank || staticPageRef) {
    return false
  }

  return true
}

const needsManagingAppDefIdUpdate = ({
  managingAppDefId,
  isNewAppPagesEnabled,
  staticPageRef,
}) => managingAppDefId && (isNewAppPagesEnabled || !staticPageRef)

const getPageDataUpdates = ({
  managingAppDefId,
  isNewAppPagesEnabled,
  staticPageRef,
}) =>
  needsManagingAppDefIdUpdate({
    managingAppDefId,
    isNewAppPagesEnabled,
    staticPageRef,
  }) && { managingAppDefId }

const isFirst = index => index === 0

const isNotPageLink = field => field.type !== FieldType.pageLink

const formatFields = ({ rawFields, isFromExistingCollection }) => {
  const fields = isFromExistingCollection
    ? rawFields
    : pickBy(rawFields, isNotPageLink)

  return {
    ...pickBy(fields, field => !field.systemField),
    ...pickBy(fields, field => field.systemField),
  }
}

export const getPagesStepsCreator =
  ({
    editorType,
    editorSdkProxy,
    isNewAppPagesEnabled,
    arePageLinksCreatedOnBackend,
  }) =>
  ({
    orderedPages,
    collectionId,
    collectionName,
    pageTitleBase = collectionName,
    preset,
    isFromExistingCollection,
    isIndexable,
    schema,
  }) =>
    orderedPages.map(
      ({ key, staticPageRef, shouldBeBlank, bindings }, stepIndex) => {
        const router = first(preset.routers)
        const routerConfig = router.config

        const pageType =
          key === 'indexPage' ? PAGE_TYPES.COLLECTION : PAGE_TYPES.ITEM

        return {
          action: async ({ state }) => {
            const fields = formatFields({
              rawFields: schema.fields,
              isFromExistingCollection,
            })

            const collectionFields = Object.entries(fields).map(
              ([name, fieldDef]) => ({
                ...fieldDef,
                label: fieldDef.displayName,
                name,
              }),
            )

            if (isFirst(stepIndex)) {
              const { routerPrefix, managingAppDefId, pageLinks } =
                await prepareDataForPages({
                  editorSdkProxy,
                  collectionId,
                  collectionName,
                  pagesToAdd: orderedPages,
                  collectionFields,
                  isNewAppPagesEnabled,
                  arePageLinksCreatedOnBackend,
                  displayField: schema.displayField,
                  isTPACollection: isDbDriverCollection(schema),
                })

              state.routerPrefix = routerPrefix
              state.managingAppDefId = managingAppDefId
              state.pageDefinitions = pageLinks
            }

            const { pattern, url } = state.pageDefinitions[key]

            const shouldAddComponents = getShouldAddComponents({
              shouldBeBlank,
              staticPageRef,
            })

            const itemPageLinkFieldKey = getPageLinkFieldKey({
              state,
              pageKey: 'itemPage',
            })

            const indexPageLinkFieldKey = getPageLinkFieldKey({
              state,
              pageKey: 'indexPage',
            })

            const page = preset.pages.find(p => p.type === key)

            const { createPreset, installedAppIds = new Map() } =
              page &&
              shouldAddComponents &&
              (await applyDataFixers({
                page,
                pageType,
                presetId: preset.id,
                editorType,
                fields,
                itemPageLinkFieldKey,
                indexPageLinkFieldKey,
                editorSdkProxy,
              }))

            state.installedAppIds = new Map([
              ...state.installedAppIds,
              ...installedAppIds,
            ])

            const convertedPage = orderedPages.find(page => page.staticPageRef)

            const routeDefinition = generateRouteDefinition({
              schema,
              editorType,
              collectionId,
              routerConfig,
              routerPages: router.pages,
              pageId: page.id,
              route: pattern,
              isIndexable,
              requiresAdjustingToSchema:
                isFromExistingCollection || convertedPage,
            })

            const pageDataUpdates = getPageDataUpdates({
              managingAppDefId: state.managingAppDefId,
              isNewAppPagesEnabled,
              staticPageRef,
            })

            const pageTitle = generateNewPageName(
              pageTitleBase,
              pattern,
              collectionFields,
            )

            const { pageRef } = await createOrConnectDynamicPage({
              editorSdkProxy,
              pageType,
              prefix: state.routerPrefix,
              pattern,
              createPreset,
              routeDefinition,
              pageRef: staticPageRef,
              pageDataUpdates,
              pageTitle,
              collectionName,
            })

            const isAutoBound =
              Boolean(bindings?.length) || (!staticPageRef && !shouldBeBlank)

            state.pages.push({
              key,
              pageType,
              pageRef,
              wasStatic: Boolean(staticPageRef),
              isAutoBound,
              pageUrl: url,
              pageTitle,
            })

            const isStaticRoute = !isDynamicRoute({ route: pattern })
            const isRegularDynamicPage =
              state.managingAppDefId === undefined ||
              state.managingAppDefId === DATA_BINDING

            const needsLink = isStaticRoute && isRegularDynamicPage
            if (needsLink) {
              await addDPLinkToSiteMenu({
                editorSdkProxy,
                pageRef,
                innerRoute: pattern,
                label: collectionName,
              })
            }
          },
        }
      },
    )
