import { getFieldKeysForPreset } from './getFieldKeysForPreset'

export const createBlankPresetFieldMap = ({
  fields,
  itemPageLinkFieldKey,
  indexPageLinkFieldKey,
}) => {
  const fieldKeysForPreset = getFieldKeysForPreset({
    fields,
  })

  return {
    title: fieldKeysForPreset.titleFieldKey,
    subtitle: fieldKeysForPreset.descriptionFieldKey,
    itemPageText: fieldKeysForPreset.longDescriptionFieldKey,
    altText: fieldKeysForPreset.altTextFieldKey,
    image: fieldKeysForPreset.imageFieldKey,
    'link-items-title': itemPageLinkFieldKey,
    'link-items-all': indexPageLinkFieldKey,
  }
}
