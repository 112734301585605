import { get } from 'lodash'

const EDITOR_TYPE = {
  RESPONSIVE: 'responsive',
  CLASSICAL: 'classical',
  BLOCKS: 'blocks',
}

export const resolveEditorType = ({ editorEnv, editorSdkProxy }) => {
  if (editorSdkProxy.appBuilder) {
    return EDITOR_TYPE.BLOCKS
  } else if (get(editorEnv, 'origin.type') === 'RESPONSIVE') {
    return EDITOR_TYPE.RESPONSIVE
  }

  return EDITOR_TYPE.CLASSICAL
}

export const isClassicalEditor = editorType =>
  editorType === EDITOR_TYPE.CLASSICAL

export const isResponsiveEditor = editorType =>
  editorType === EDITOR_TYPE.RESPONSIVE

export const isBlocks = editorType => editorType === EDITOR_TYPE.BLOCKS
