const MAIN_MENU_ID = 'CUSTOM_MAIN_MENU'
const LINK_TYPES = { DYNAMIC_PAGE_LINK: 'DynamicPageLink' }

const getRouterByPage = async ({ editorSdkProxy, pageRef }) => {
  const routerRef = await editorSdkProxy.routers.getByPage({ pageRef })
  return editorSdkProxy.routers.get({ routerRef })
}

const addDPLinkToSiteMenu = async ({
  editorSdkProxy,
  pageRef,
  innerRoute,
  label,
}) => {
  const router = await getRouterByPage({ editorSdkProxy, pageRef })
  const menuId =
    (await editorSdkProxy.document.menu.getDefaultMenuId()) || MAIN_MENU_ID
  return editorSdkProxy.document.menu.addItem({
    menuId,
    menuItem: {
      link: {
        innerRoute,
        routerId: router.id,
        target: '_self',
        type: LINK_TYPES.DYNAMIC_PAGE_LINK,
      },
      label,
    },
  })
}

export default addDPLinkToSiteMenu
