import {
  panelOpened,
  panelClosed,
  removeExternalCollectionsClicked,
} from '@wix/bi-logger-platform-cm/v2'
import openErrorPanel from '@wix/wix-data-client-common/src/panels/error-panel/openErrorPanel'
import { getAssetUrlInWorker } from '@wix/wix-data-client-common/src/panels/getAssetUrl'

const PANEL_NAME_FOR_BI = 'delete external collection'

export default async ({
  editorSdkProxy,
  logger,
  appApi,
  wixDataConnectorSettingsClient,
  i18n,
  onError,
  namespace,
}) => {
  const t = i18n.t.bind(i18n)

  logger.bi(
    panelOpened({
      panel_name: PANEL_NAME_FOR_BI,
    }),
  )

  await openErrorPanel({
    editorSdkProxy,
    logger,
    headerText: t('Delete_External_Collection_Warning_Title'),
    topDescriptionText: t('Delete_External_Collection_Warning_Paragraph'),
    bottomDescriptionText: t('Delete_External_Collection_Warning_Paragraph2'),
    illustration: await getAssetUrlInWorker(
      editorSdkProxy,
      'panel-icons/generic_trash.svg',
    ),
    mainActionText: t('Delete_External_Collection_Warning_Button_Delete'),
    secondaryActionText: t('Delete_External_Collection_Warning_Button_Cancel'),
    onMainActionClick: async () => {
      try {
        logger.bi(
          removeExternalCollectionsClicked({
            collections_namespace: namespace,
            endpoint_url: '',
          }),
        )

        await wixDataConnectorSettingsClient.unregisterConnector(namespace)
        await appApi.onExternalDbDriverRemoved({ namespace })
        await appApi.refreshSchemasCache()
        await editorSdkProxy.document.application.livePreview.refresh({
          source: 'AFTER_EXTERNAL_DB_DELETION',
          shouldFetchData: true,
        })
      } catch {
        onError()
      }
    },
    onSecondaryActionClick: () => {
      logger.bi(
        panelClosed({
          panel_name: PANEL_NAME_FOR_BI,
        }),
      )
    },
    onCloseClick: () => {
      logger.bi(
        panelClosed({
          panel_name: PANEL_NAME_FOR_BI,
        }),
      )
    },
  })
}
