import { get } from 'lodash'
import * as schemaFetcherV2 from '@wix/wix-data-client-common/src/business-logic/collections/schemaFetcherV2'
import getDPCollectionName from './getDPCollectionName'

const SANTA_TO_BI_ORIGINS = {
  idePagesTree: 'Left_tree',
  pagesPanel: 'pages_menu',
}

const getCollectionByPageRef = async ({
  editorSdkProxy,
  collectionsApi,
  routerRef,
  pageRef,
}) => {
  const collectionName = await getDPCollectionName({
    editorSdkProxy,
    routerRef,
    pageRef,
  })

  const collection = collectionName
    ? await schemaFetcherV2.fetch(collectionsApi, collectionName)
    : undefined

  return collection
}

export const getBiDefaults = async ({
  editorSdkProxy,
  collectionsApi,
  pageRef,
  routerRef,
  origin,
  panelName,
}) => {
  const collection = await getCollectionByPageRef({
    editorSdkProxy,
    collectionsApi,
    routerRef,
    pageRef,
  })
  return {
    pageId: pageRef.id,
    panel_name: panelName,
    origin: SANTA_TO_BI_ORIGINS[origin],
    collection_id: get(collection, 'id', null),
    collection_name: get(collection, 'displayName', null),
  }
}
