const NAMESPACES = ['breadcrumb', 'info', 'error']

const createStorage = () => {
  let logs = []

  const flush = logger => {
    logs.forEach(({ namespace, args }) => {
      logger[namespace].apply(null, args)
    })
    logs = []
  }

  return NAMESPACES.reduce(
    (acc, namespace) => ({
      ...acc,
      [namespace]: (...args) => logs.push({ namespace, args }),
    }),
    { flush },
  )
}

export const setUpTemporaryLogStorage = logger => {
  const logStorage = createStorage()
  const removeLogStorageFromLogger = logger.registerHandler(logStorage)

  return {
    ...logStorage,
    flush: () => {
      removeLogStorageFromLogger()
      logStorage.flush(logger)
    },
  }
}
