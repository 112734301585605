import * as panelDefs from '@wix/wix-data-client-common/src/panels/panelDefs'
import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'

export default async (hostname, t, experimentsManager, editorSdkProxy) => {
  const [
    isPermittedToAddNewItem,
    isPermittedToManageItems,
    isPermittedToEditStructure,
  ] = await Promise.all([
    editorSdkProxy.document.permissions.isCustomPermissionsGranted({
      permissions: ['WIX_DATA.INSERT'],
    }),
    editorSdkProxy.document.permissions.isCustomPermissionsGranted({
      permissions: ['WIX_DATA.FIND'],
    }),
    editorSdkProxy.document.permissions.isCustomPermissionsGranted({
      permissions: ['DOCUMENT.EDIT_STRUCTURE'],
    }),
  ])

  const addDynamicPageActionTitle = t(
    'DYNAMIC_PAGES_TREEVIEW_QUICKACTIONS_ADD_DYNAMIC_PAGE',
  )

  return {
    routerActions: {
      default: [
        ...[
          isPermittedToAddNewItem
            ? {
                title: t('Dynamic_Pages_TreeView_QuickActions_Add_New_Item'),
                event: 'addNewRouterItemClicked',
                icon: 'page_dynamic_icon',
                type: 'add_page',
              }
            : null,
        ].filter(Boolean),
        {
          title: addDynamicPageActionTitle,
          event: 'addDynamicPageClicked',
          icon: 'page_dynamic_icon',
          type: 'add_page',
        },
      ],
    },
    routerViewActions: {
      default: isPermittedToAddNewItem
        ? {
            mainAction: {
              event: 'addNewRouterItemClicked',
              title: t('Dynamic_Pages_TreeView_QuickActions_Add_New_Item'),
              biActionName: 'cm_item',
            },
            secondaryActions: [
              {
                event: 'addDynamicPageClicked',
                icon: 'add_dynamic_page',
                tooltip: addDynamicPageActionTitle,
                biActionName: 'dynamic page',
              },
            ],
          }
        : {
            mainAction: {
              event: 'addDynamicPageClicked',
              title: addDynamicPageActionTitle,
              biActionName: 'dynamic page',
            },
          },
    },
    pageActions: {
      default: [
        'Pages_Actions_Page_Rename',
        ...[
          isPermittedToAddNewItem && {
            title: t('Dynamic_Pages_Page_Actions_Add_New_Item'),
            event: 'addNewItemClicked',
            icon: 'add_new_item',
          },
          isPermittedToManageItems && {
            title: t('Dynamic_Pages_Page_Actions_Manage_Content'),
            event: 'manageContentClicked',
            icon: 'manage_content',
          },
          isPermittedToEditStructure && {
            title: t('Dynamic_Pages_Page_Actions_Duplicate'),
            event: 'duplicateDynamicPage',
            icon: 'duplicateAction',
          },
        ].filter(Boolean),
        {
          title: t('Dynamic_Pages_Page_Actions_Remove_Dynamic_Connections'),
          event: 'disconnectPageFromRouterClicked',
          icon: 'unlink_icon',
        },
        {
          title: t('Dynamic_Pages_Page_Actions_Page_Delete'),
          event: 'deleteDynamicPageClicked',
          icon: 'delete_icon',
        },
        ...[
          {
            title: 'Get Preset Definition',
            event: 'openPresetScanningPanel',
          },
        ].filter(() =>
          experimentsManager.enabled('specs.wixData.ScanDynamicPagePreset'),
        ),
      ],
    },
    pageSettings: {
      default: [
        {
          type: 'page_info',
          title: t('Dynamic_Pages_Pages_Info_Tab_Title'),
          url: await getPanelUrl({
            hostname,
            relativeUrl: panelDefs.dynamicPageInfoPanel.url,
            editorSdkProxy,
          }),
          helpId: 'e200b3b9-2045-4551-86d9-d9d74e90bd53',
        },
        {
          type: 'layout',
          title: t('Pages_Layouts_Tab_Title'),
          helpId: 'd985e594-b73a-46ea-adb7-044ecc3c1acc',
        },
        {
          type: 'permissions',
          title: t('Pages_Permissions_Tab_Title'),
          helpId: '6c369b66-9450-45a1-aedb-f347459f9e97',
        },
        {
          type: 'seo',
          title: t('DYNAMIC_PAGES_PAGES_SEO_TAB_TITLE'),
          url: await getPanelUrl({
            hostname,
            relativeUrl: panelDefs.dynamicPageSeoPanel.url,
            editorSdkProxy,
          }),
          helpId: '4c46a5f2-fabb-416e-84b9-7971b56d386b',
        },
      ],
    },
  }
}
