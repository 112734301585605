import getDPCollectionName from './getDPCollectionName'

const isDPCollectionDeleted = async ({
  editorSdkProxy,
  routerRef,
  pageRef,
  isCollectionDeleted,
}) => {
  const collectionName = await getDPCollectionName({
    editorSdkProxy,
    routerRef,
    pageRef,
  })

  return !collectionName || isCollectionDeleted(collectionName)
}

export default isDPCollectionDeleted
