import { AppCollectionVisibility } from '@wix/wix-data-client-common/src/appCollectionVisibility'
import { HttpClient } from '@wix/http-client'

/**
 * @param {string} signedInstance
 * @returns {{
 *   get: () => Promise<undefined|"hidden"|"visible">,
 *   set: (visibility: "hidden"|"visible") => Promise<void>
 * }}
 */
export default function createAppCollectionVisibilityPreference(
  signedInstance,
) {
  const httpClient = new HttpClient({ getAppToken: () => signedInstance })
  let cachedVisibilityPromise = null
  getPreference()

  return { get: getPreference, set: setPreference }

  /**
   * @returns {Promise<undefined|"hidden"|"visible">} Undefined means the user hasn't set their preference yet.
   */
  function getPreference() {
    if (cachedVisibilityPromise === null) {
      cachedVisibilityPromise = new Promise((resolve, reject) => {
        makeRequest({ method: 'GET' })
          .then(async response => {
            const { visible } = response.data
            const visibility =
              typeof visible === 'undefined'
                ? undefined
                : visible
                ? AppCollectionVisibility.VISIBLE
                : AppCollectionVisibility.HIDDEN
            resolve(visibility)
          })
          .catch(error => {
            cachedVisibilityPromise = null // Will try to to get the visibility again on the next call.
            if (!error.response) {
              return reject(error)
            }
            const message = describeUnsuccessfulResponse(error.response)
            reject(new Error(message))
          })
      })
    }

    return cachedVisibilityPromise
  }

  /**
   * @param {"hidden"|"visible"} visibility
   * @returns {Promise<void>}
   */
  function setPreference(visibility) {
    return new Promise((resolve, reject) => {
      makeRequest({
        method: 'POST',
        data: {
          visible: visibility === AppCollectionVisibility.VISIBLE,
        },
      })
        .then(async () => {
          cachedVisibilityPromise = Promise.resolve(visibility)
          resolve()
        })
        .catch(error => {
          const message = describeUnsuccessfulResponse(error.response)
          reject(new Error(message))
        })
    })
  }

  function makeRequest({ method, data } = {}) {
    return httpClient.request({
      url: '/_api/data/data-settings/app-collection-visibility',
      method,
      data,
    })
  }

  function describeUnsuccessfulResponse({ data, status, statusText }) {
    return typeof data === 'string' && data.length > 0
      ? data
      : data?.message || `${status} ${statusText}`
  }
}
