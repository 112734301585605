import { FieldType } from '@wix/wix-data-schema-types'
import { MEDIACONTAINER_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { image } = FieldType

export default ({ i18n }) => ({
  componentName: 'Element_Name_Media_Container',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Repeater_Item',
    helpId: 'ed5207f7-0ccb-452e-84c3-f0e1d4fe604f',
  },
  sections: [
    {
      role: MEDIACONTAINER_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Item_Background_Label',
          prop: 'background.src',
          supportedFieldTypes: [image],
          defaultFieldType: image,
          supportsReferenceFields: true,
          requiresPrimaryConnection: true,
        },
      ],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'container_live_preview_gfpp_desktop_main_action',
              label: 'Container_Live_Preview_GFPP_Desktop_Main_Action',
            },
            mainAction2: 'HIDE',
          },
        },
      },
      manageContentPanel: {
        description: 'Container_Manage_Content_Panel_Description',
      },
    },
  ],
})
