import { FieldType } from '@wix/wix-data-schema-types'
import {
  DROPDOWN_ROLE,
  DROPDOWN_OPTIONS_ROLE,
  FILTER_INPUT_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
import { GenericRole } from '@wix/wix-data-client-common/src/connection-config/genericRole'
const { text, number, reference } = FieldType

export default ({ i18n }) => ({
  componentName: 'Element_Name_Dropdown',
  isConnectGfppEnabled: true,
  panel: {
    height: 436,
    title: 'Connect_Panel_Title_Dropdown',
    helpId: 'ab559ee7-1d56-4530-b93c-f5b2e4bc8b51',
  },
  sections: [
    {
      role: DROPDOWN_ROLE,
      genericRole: GenericRole.INPUT_VALUE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Value_Label',
          prop: 'value',
          supportedFieldTypes: [text, number, reference],
          defaultFieldType: text,
          isInputProperty: true,
        },
      ],
    },
    {
      role: DROPDOWN_OPTIONS_ROLE,
      genericRole: GenericRole.INPUT_VALUE_OPTIONS,
      bindings: [
        {
          title: 'Connect_Panel_Connect_List_Options_Values_Label',
          prop: 'value',
          supportedFieldTypes: [text, number],
          defaultFieldType: text,
          requiresPrimaryConnection: true,
        },
      ],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId:
                'dropdown_options_live_preview_gfpp_desktop_main_action',
              label: i18n.t(
                'Dropdown_Options_Live_Preview_GFPP_Desktop_Main_Action',
              ),
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Dropdown_Options_Manage_Content_Panel_Description',
      },
    },
    {
      role: FILTER_INPUT_ROLE,
      componentTypeName:
        'Connect_Panel_Dataset_Filter_Message_Element_Type_Dropdown',
      bindings: [],
    },
  ],
})
