import { get } from 'lodash'
import {
  createSchema,
  setDisplayName,
  setDisplayField,
  setPermissions,
  addField,
} from '@wix/wix-code-collections-api'
import { FieldType } from '@wix/wix-data-schema-types'

export default async ({
  collectionId,
  displayName,
  displayField,
  permissions,
  fields,
  records,
  collectionsApi,
  wixData,
  pluginsPatches = [],
}) => {
  await collectionsApi.execute(
    createSchema(
      collectionId,
      setDisplayName(displayName),
      setDisplayField(displayField),
      setPermissions(permissions),
      ...pluginsPatches,
      ...Object.entries(fields)
        .filter(([fieldKey, fieldConfig]) => !fieldConfig.systemField)
        .map(([fieldKey, fieldConfig]) =>
          addField(fieldKey, {
            displayName: fieldConfig.displayName,
            type: fieldConfig.type,
          }),
        ),
    ),
  )

  const recordsToRemove = records.map(({ _id }) => _id).filter(Boolean)

  if (recordsToRemove.length) {
    await wixData.bulkRemove(collectionId, recordsToRemove)
  }

  const fixDateValues = record =>
    Object.entries(record).reduce(
      (acc, [fieldKey, value]) => ({
        ...acc,
        [fieldKey]:
          get(fields, [fieldKey, 'type']) === FieldType.dateTime
            ? new Date(value)
            : value,
      }),
      {},
    )

  const result = records.map(fixDateValues)

  await wixData.bulkInsert(collectionId, result)
}
