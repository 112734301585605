const COLLECTION_FIELD = /[^{}]+(?=\})/g
const NON_LETTER_OR_NUMBER = /[^\dA-Za-z]/g
const STATIC_PARTS = /[^}]+(?=\{|$)/g

export const cleanUrl = url => url.replace(/(\/{2,})/g, '/').replace(/^\//, '')

export const parseUrlPattern = pattern => {
  pattern = cleanUrl(pattern)
  return {
    fields: pattern.match(COLLECTION_FIELD) || [],
    nonFields: pattern
      .replace(COLLECTION_FIELD, '')
      .split(NON_LETTER_OR_NUMBER)
      .filter(x => !!x),
  }
}

export const parseUrl = url => {
  url = cleanUrl(url)
  const urlParts = url.split('/').filter(s => s.length > 0)
  const pattern = '/' + urlParts.slice(1).join('/')
  return {
    prefix: urlParts[0],
    pattern,
  }
}

export const buildUrl = (prefix, pattern) => cleanUrl(`${prefix}/${pattern}`)

export const addField = (url, field) => cleanUrl(`${url}/{${field}}`)

export const isEqual = (url1, url2) => cleanUrl(url1) === cleanUrl(url2)

export const lowerCaseStaticUrlParts = url =>
  url.replace(STATIC_PARTS, s => s.toLowerCase())
