import { PAGE_TYPES } from '@wix/wix-data-client-common/src/business-data/dynamicPages'
import getFieldKeyForUrl from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/getFieldKeyForUrl'
import { DBDriverDynamicPageError } from '../../dynamicPageErrors'
import { DPErrorCodes } from '../../constants'

export const getItemPageUrl = ({
  routerPrefix,
  collectionFields,
  displayField,
  isTPACollection,
}) => {
  const fieldKeyForUrl = getFieldKeyForUrl({
    collectionFields,
    displayField,
    isTPACollection,
  })

  if (!fieldKeyForUrl) {
    if (isTPACollection) {
      throw new DBDriverDynamicPageError(
        DPErrorCodes.NO_FIELD_KEY_IN_DB_DRIVER_COLLECTION,
        'Field key for url does not exist',
      )
    } else {
      throw new Error('Field key for url does not exist')
    }
  }

  return `${routerPrefix}/{${fieldKeyForUrl}}/`
}

export const getIndexPageUrl = ({ routerPrefix }) => `${routerPrefix}/`

export const getPageUrl = ({
  pageType,
  routerPrefix,
  collectionFields,
  displayField,
  isTPACollection,
}) => {
  return pageType === PAGE_TYPES.ITEM
    ? getItemPageUrl({
        routerPrefix,
        collectionFields,
        displayField,
        isTPACollection,
      })
    : getIndexPageUrl({ routerPrefix })
}
