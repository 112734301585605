import Maybe from 'folktale/maybe'
import { get } from 'lodash'

const APP_WIDGET_VIEWER_TYPE = 'platform.components.AppWidget'

const getBindingDefinitions = async ({
  editorSdkProxy,
  appApi,
  componentType,
  componentViewerType,
  componentRef,
}) => {
  if (componentViewerType === APP_WIDGET_VIEWER_TYPE) {
    const appDefinitionId = get(
      await editorSdkProxy.components.data.get({ componentRef }),
      'applicationId',
    )
    const getBindingDefinitions = get(
      await editorSdkProxy.document.application.getPublicAPI({
        appDefinitionId,
      }),
      'getBindingDefinitions',
    )
    if (getBindingDefinitions) {
      return Maybe.Just(await getBindingDefinitions(componentRef))
    }
  }
  return Maybe.fromNullable(
    await appApi.getBindingDefinitions({ componentType }),
  )
}

export { getBindingDefinitions }
