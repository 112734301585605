import set from 'lodash/fp/set'
import { getRouterDatasetDefinition } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/templates'
import { addOrUpdateRouterWithPattern } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/editorActions'

const clearPageUriSEO = set('data.pageUriSEO', '')

export default async ({
  editorSdkProxy,
  prefix,
  pattern,
  pageType,
  pageRef,
  createPreset,
  routeDefinition,
  pageDataUpdates,
  pageTitle,
  collectionName,
}) => {
  const routerRef = await addOrUpdateRouterWithPattern(
    editorSdkProxy,
    prefix,
    pattern,
    routeDefinition,
  )
  const router = await editorSdkProxy.routers.get({ routerRef })

  if (createPreset) {
    const definition = clearPageUriSEO(
      createPreset({ collectionName, routerId: router.id }),
    )

    pageRef = await editorSdkProxy.document.pages.add({
      title: pageTitle,
      definition,
      shouldNavigateToPage: false,
    })
  }

  if (pageRef) {
    await Promise.all([
      editorSdkProxy.routers.pages.connect({
        routerRef,
        pageRef,
        pageRoles: [routeDefinition.pageRole],
      }),
      editorSdkProxy.pages.rename({ pageRef, title: pageTitle }),
    ])
  } else {
    pageRef = await editorSdkProxy.routers.pages.add({
      routerRef,
      pageTitle,
      pageRoles: [routeDefinition.pageRole],
    })
  }

  if (!createPreset) {
    await editorSdkProxy.components.add({
      pageRef,
      componentDefinition: getRouterDatasetDefinition({
        collectionName,
        pageType,
      }),
    })
  }

  if (pageDataUpdates) {
    await editorSdkProxy.document.pages.data.update({
      pageRef,
      data: pageDataUpdates,
    })
  }

  return { pageRef }
}
