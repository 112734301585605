export async function findFirstItemMatchingDynamicPage({
  collectionId,
  innerRoute,
  pattern,
  wixData,
}) {
  const fieldKeysPrefixedWithOpeningBrace = pattern.match(/\{[^}]+/g)
  const fieldKeys = fieldKeysPrefixedWithOpeningBrace.map(
    fieldKeyPrefixedWithOpeningBrace => {
      const fieldKey = fieldKeyPrefixedWithOpeningBrace.slice(1)
      return fieldKey
    },
  )

  const regExpForUriComponentsOfFieldValues = new RegExp(
    pattern.substring(1).replace(/\{[^}]+\}/g, '(.*)'),
  ) // Generate a regular expression from the pattern replacing parameters with capturing groups.
  const uriComponentsOfFieldValues = innerRoute
    .match(regExpForUriComponentsOfFieldValues)
    .slice(1) // Skip the whole match to get to the captured values.
  const fieldValues = uriComponentsOfFieldValues.map(decodeURIComponent)

  const queryMatchingFields = fieldKeys.reduce(
    (query, fieldKey, fieldIndex) => {
      const fieldValue = fieldValues[fieldIndex]
      const queryMatchingField = query._matchesUrlized(fieldKey, fieldValue)
      return queryMatchingField
    },
    wixData.query(collectionId),
  )

  const {
    items: [item],
  } = await queryMatchingFields.limit(1).find()
  return item
}
