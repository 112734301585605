import assertUniqueMethods from './utils/assertUniqueMethods'

export default modulePublicApis => {
  const api = modulePublicApis.reduce((api, moduleApi) => {
    assertUniqueMethods(api, moduleApi)
    return { ...api, ...moduleApi.api }
  }, {})

  return {
    init: services => {
      modulePublicApis.forEach(api => api.init(services))
    },
    api,
  }
}
