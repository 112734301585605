import {
  COLUMN_ROLE,
  GRID_ROLE,
  MEDIA_GALLERY_ROLE,
  REPEATER_ROLE,
  STRIP_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
import openConfirmationPanel from '@wix/wix-data-client-common/src/panels/error-panel/openConfirmationPanel'
import { features } from '@wix/wix-data-client-common/src/features'
import { getConnectableComponents } from './getConnectableComponents'
import {
  getBindingDefinitions,
  getAllBindingDefinitions,
} from '../binding-definitions'
import removeRouterDatasetController from '../business-logic/datasets/removeDatasetController'
import openDatasetConfigPanel from '../panels/dataset-config-panel/openPanel'
import { connectBindingSection } from '../business-logic/datasets/datasetApi'
import { Binding } from '../business-logic/bindings/Binding'
import { updateControllersState } from './controllersState'

export default ({
  experimentsManager,
  i18n,
  bindingDefinitionLoaders,
  editorSdkProxy,
  collectionsApi,
  hostname,
  logger,
  editorType,
  finalAppApiPromise,
}) => {
  let connectableComponentsPromise

  return {
    getConnectableComponents() {
      return connectableComponentsPromise
    },

    updateConnectableComponentsPromise({ controllerRef }) {
      connectableComponentsPromise = getConnectableComponents({
        editorSdkProxy,
        controllerRef,
        experimentsManager,
      })
    },

    async showLivePreviewPageSizeNotification() {
      await editorSdkProxy.editor.showPresetUserActionNotification({
        preset:
          editorSdkProxy.editor.NotificationPresetTypes
            .REPEATER_EDITOR_MAX_ITEMS,
      })
    },

    async getBindingDefinitions({ componentType }) {
      return (
        await getBindingDefinitions({
          i18n,
          experimentsManager,
          componentType,
          bindingDefinitionLoaders,
        })
      ).getOrElse(null)
    },

    async getAllBindingDefinitions() {
      return getAllBindingDefinitions({
        i18n,
        experimentsManager,
        bindingDefinitionLoaders,
      })
    },

    async removeRouterDatasetController({ datasetRef }) {
      return removeRouterDatasetController({ editorSdkProxy, datasetRef })
    },

    async openDatasetConfigPanel({
      controllerRef,
      controllerConfig,
      controllerDisplayName,
      controllerType,
    }) {
      return openDatasetConfigPanel(
        {
          hostname,
          collectionsApi,
          editorSdkProxy,
          i18n,
        },
        {
          controllerRef,
          controllerConfig,
          controllerDisplayName,
          controllerType,
        },
      )
    },

    async updateMainActionOfStrip({ stripRef }) {
      const stripConnections = await editorSdkProxy.controllers.listConnections(
        { componentRef: stripRef },
      )
      const stripConnectionWithStripRole = stripConnections.find(
        stripConnection => stripConnection.role === STRIP_ROLE,
      )
      const stripHasConnectionWithStripRole =
        typeof stripConnectionWithStripRole !== 'undefined'
      const stripHasPrimaryConnectionWithNonStripRole =
        !stripHasConnectionWithStripRole &&
        stripConnections.some(stripConnection => stripConnection.isPrimary)
      if (stripHasPrimaryConnectionWithNonStripRole) return

      const singleColumnConnectionWithColumnRoleAndDefinedConfiguration =
        await ifStripHasSingleColumnFindConnectionWithColumnRoleAndDefinedConfigurationOfThatColumn(
          { stripRef },
        )
      // Columns have only one bindable property—background.
      // When the background of a column is bound to data, the connection has a defined configuration.
      // When the property is not bound but the column is connected to a dataset, the configuration is undefined.
      // This is a simplified check for the binding:
      // since there can be only one binding, the presence of a configuration in the connection implies the existence of the binding.
      const stripHasSingleColumnThatIsBoundToData =
        typeof singleColumnConnectionWithColumnRoleAndDefinedConfiguration !==
        'undefined'

      if (
        stripHasSingleColumnThatIsBoundToData &&
        !stripHasConnectionWithStripRole
      ) {
        await editorSdkProxy.controllers.connect({
          controllerRef:
            singleColumnConnectionWithColumnRoleAndDefinedConfiguration.controllerRef,
          connectToRef: stripRef,
          role: STRIP_ROLE,
          isPrimary: true,
        })
      } else if (
        !stripHasSingleColumnThatIsBoundToData &&
        stripHasConnectionWithStripRole
      ) {
        await editorSdkProxy.controllers.disconnect({
          controllerRef: stripConnectionWithStripRole.controllerRef,
          connectToRef: stripRef,
          role: STRIP_ROLE,
        })
      }
    },

    async makeDataConnectionOfComponentPrimaryIfNeeded({ componentRef }) {
      const [component] = await editorSdkProxy.components.get({
        componentRefs: [componentRef],
        properties: ['connections', 'sdkType'],
      })
      const componentHasPrimaryConnection = component.connections.some(
        connection => connection.isPrimary,
      )
      if (componentHasPrimaryConnection) return // There is nothing to do because the component already has some primary connection.

      const dataConnectionRequiringPrimariness =
        await findDataConnectionRequiringPrimariness({
          connectionsOfComponent: component.connections,
          getBindingDefinitionsOfComponent: async () => {
            const bindingDefinitionsOfComponent =
              await this.getBindingDefinitions({
                componentType: component.sdkType,
              })
            return bindingDefinitionsOfComponent
          },
        })
      const thereIsNoDataConnectionRequiringPrimariness =
        typeof dataConnectionRequiringPrimariness === 'undefined'
      if (thereIsNoDataConnectionRequiringPrimariness) return

      await editorSdkProxy.controllers.connect({
        connectToRef: componentRef,
        controllerRef: dataConnectionRequiringPrimariness.controllerRef,
        role: dataConnectionRequiringPrimariness.role,
        connectionConfig: dataConnectionRequiringPrimariness.config,
        isPrimary: true,
      })
    },

    async checkIfErrorIsConcurrentEditingError(error) {
      return error.message?.startsWith('WDE0300:') ?? false
    },

    async handleConcurrentEditingError() {
      openConfirmationPanel({
        editorSdkProxy,
        headerText: i18n.t('Concurrent_Editing_Error_Panel_Header_Text'),
        descriptionText: i18n.t(
          'Concurrent_Editing_Error_Panel_Description_Text',
        ),
        mainActionText: i18n.t(
          'Concurrent_Editing_Error_Panel_Main_Action_Text',
        ),
        logger,
      })
    },

    // TODO: add tests for appApi.connectBindingSection method
    async connectBindingSection({
      componentRef,
      controllerRef,
      fieldBindings,
      newColumns,
      role,
      requiresPrimaryConnection,
    }) {
      const livePreview = features.livePreview({
        editorType,
        experimentsManager,
      })

      const newBindings = fieldBindings.map(({ prop, fieldKey }) =>
        Binding.Field({ prop, fieldPath: fieldKey }),
      )

      return connectBindingSection({
        editorSdkProxy,
        livePreview,
        componentRef,
        controllerRef,
        newBindings,
        newColumns,
        role,
        requiresPrimaryConnection,
        collections: await collectionsApi.getAll(),
      })
    },

    async refreshControllersState({ controllerRefs = null }) {
      const appApi = await finalAppApiPromise
      return updateControllersState({
        appApi,
        collectionsApi,
        controllerRefsToUpdate: controllerRefs,
        editorSdkProxy,
      })
    },
  }

  async function ifStripHasSingleColumnFindConnectionWithColumnRoleAndDefinedConfigurationOfThatColumn({
    stripRef,
  }) {
    const columnRefs = await editorSdkProxy.components.getChildren({
      componentRef: stripRef,
    })
    const { length: numberOfColumns } = columnRefs
    if (numberOfColumns !== 1) return

    const [singleColumnRef] = columnRefs
    const singleColumnConnections =
      await editorSdkProxy.controllers.listConnections({
        componentRef: singleColumnRef,
      })
    const singleColumnConnectionWithColumnRoleAndDefinedConfiguration =
      singleColumnConnections.find(
        singleColumnConnection =>
          singleColumnConnection.role === COLUMN_ROLE &&
          typeof singleColumnConnection.config !== 'undefined',
      )
    return singleColumnConnectionWithColumnRoleAndDefinedConfiguration
  }

  async function findDataConnectionRequiringPrimariness({
    connectionsOfComponent,
    getBindingDefinitionsOfComponent,
  }) {
    const itemContainerConnection = connectionsOfComponent.find(
      connection =>
        connection.role === GRID_ROLE ||
        connection.role === MEDIA_GALLERY_ROLE ||
        connection.role === REPEATER_ROLE,
    )
    const thereIsItemContainerConnection =
      typeof itemContainerConnection !== 'undefined'
    if (thereIsItemContainerConnection) return itemContainerConnection

    const bindingDefinitions = await getBindingDefinitionsOfComponent()
    const thereAreNoBindingDefinitions = bindingDefinitions === null
    if (thereAreNoBindingDefinitions) return

    // There can be only one binding requiring primary connection.
    // "Primary binding" is a shortening for "binding requiring primary connection".
    const sectionWithPrimaryBinding = bindingDefinitions.sections.find(
      section =>
        section.bindings.some(binding => binding.requiresPrimaryConnection),
    )
    const thereIsNoSectionWithPrimaryBinding =
      typeof sectionWithPrimaryBinding === 'undefined'
    if (thereIsNoSectionWithPrimaryBinding) return

    const primaryBinding = sectionWithPrimaryBinding.bindings.find(
      binding => binding.requiresPrimaryConnection,
    )
    const connectionForSectionWithPrimaryBinding = connectionsOfComponent.find(
      connection => connection.role === sectionWithPrimaryBinding.role,
    )
    const thereIsNoConnectionForSectionWithPrimaryBinding =
      typeof connectionForSectionWithPrimaryBinding === 'undefined'
    if (thereIsNoConnectionForSectionWithPrimaryBinding) return

    const {
      config: {
        properties: {
          [primaryBinding.prop]: configurationForPrimaryBinding,
        } = {},
      } = {},
    } = connectionForSectionWithPrimaryBinding
    const primaryBindingIsUnconfigured =
      typeof configurationForPrimaryBinding === 'undefined'
    if (primaryBindingIsUnconfigured) return

    return connectionForSectionWithPrimaryBinding
  }
}
