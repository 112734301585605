import { isEqual } from 'lodash'
import {
  ROUTER_DATASET,
  DATASET,
} from '@wix/wix-data-client-common/src/datasetTypes'
import * as panelDefs from '@wix/wix-data-client-common/src/panels/panelDefs'
import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'
import * as datasetApi from '../../business-logic/datasets/datasetApi'
import { isSinglesCollectionSchema } from '@wix/wix-data-client-common/src/collections/collectionTypes'

import {
  PANEL_DIMENSIONS_FOR_MULTI_COLLECTION,
  PANEL_DIMENSIONS_FOR_SINGLES_COLLECTION,
} from './consts'

const getControllerTitle = ({ controllerType }) => {
  switch (controllerType) {
    case ROUTER_DATASET:
      return 'DYNAMIC_PAGES_DATASET_PANEL_TITLE'
    case DATASET:
      return 'DATASET_SETTINGS_PANEL_TITLE'
  }
}

export default async (
  { hostname, collectionsApi, editorSdkProxy, i18n },
  {
    controllerRef,
    controllerConfig,
    controllerDisplayName,
    controllerType,
    editorType,
  },
) => {
  const initialLoadTimeForBI = Date.now()

  const oldDatasetConfig = await datasetApi.fetchDataset(
    editorSdkProxy,
    controllerRef,
  )
  const collectionId = oldDatasetConfig?.config?.collectionId
  const isSinglesCollection =
    collectionId &&
    isSinglesCollectionSchema(
      await collectionsApi.get(collectionId).catch(() => ({})),
    )

  const panelDimensions = isSinglesCollection
    ? PANEL_DIMENSIONS_FOR_SINGLES_COLLECTION
    : PANEL_DIMENSIONS_FOR_MULTI_COLLECTION

  await editorSdkProxy.editor.openComponentPanel({
    componentRef: controllerRef,
    title: i18n.t(
      getControllerTitle({
        controllerType,
      }),
    ),
    url: await getPanelUrl({
      hostname,
      relativeUrl: panelDefs.datasetConfig.url,
      editorSdkProxy,
    }),
    initialData: {
      controllerRef,
      controllerConfig,
      controllerDisplayName,
      controllerType,
      initialLoadTimeForBI,
      editorType,
    },
    ...panelDimensions,
    helpId:
      controllerType === ROUTER_DATASET
        ? 'c614e1b9-bc1b-409d-942f-0e865ecf3b74'
        : '3c9b0528-699b-475f-818c-2123885cb11f',
  })

  if (controllerType === ROUTER_DATASET) {
    const newDatasetConfig = await datasetApi.fetchDataset(
      editorSdkProxy,
      controllerRef,
    )
    const filtersConfigs = [oldDatasetConfig, newDatasetConfig].map(
      dataset => dataset.config.filters,
    )

    if (!isEqual(...filtersConfigs)) {
      editorSdkProxy.editor.routers.refresh().catch(() => {}) // Editor X does not have implementation for it yet
    }
  }
}
