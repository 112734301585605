import uuidv4 from 'uuid/v4'
import { isNil } from 'lodash'
import Maybe from 'folktale/maybe'

const createFilterExpression = ({
  field,
  condition,
  value = null,
  dataset = null,
  datasetField = null,
  component = null,
  componentType,
  filterId = null,
  referencedValue = Maybe.Nothing(),
  path,
  datasetFieldIsArray,
  componentValueIsArray,
}) => {
  if (!isNil(dataset)) {
    return {
      field,
      condition,
      dataset,
      datasetField,
      filterId: filterId || generateUniqueFilterId(),
      path,
      datasetFieldIsArray,
    }
  }

  if (!isNil(component)) {
    return {
      field,
      condition,
      component,
      componentType,
      filterId: filterId || generateUniqueFilterId(),
      componentValueIsArray,
    }
  }

  return referencedValue
    .map(val => ({
      field,
      condition,
      value,
      referencedValue: val,
      staticMultiRef: true,
    }))
    .getOrElse({
      field,
      condition,
      value,
      path,
    })
}

const generateUniqueFilterId = () => `filter-${uuidv4()}`

export { createFilterExpression, generateUniqueFilterId }
