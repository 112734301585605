import { FieldType } from '@wix/wix-data-schema-types'
import {
  SELECTION_TAGS_ROLE,
  SELECTION_TAGS_OPTIONS_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
import { GenericRole } from '@wix/wix-data-client-common/src/connection-config/genericRole'
const { stringArray, text, number } = FieldType

export default ({ i18n }) => ({
  componentName: 'Element_Name_Selection_Tags_List',
  isConnectGfppEnabled: true,
  panel: {
    height: 436,
    title: 'Connect_Panel_Title_Selection_Tags',
    helpId: '28dd3873-0bae-4033-8e9e-f2f6af69e243',
  },
  sections: [
    {
      role: SELECTION_TAGS_ROLE,
      genericRole: GenericRole.INPUT_VALUE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Selection_Tags_Tag_Value',
          prop: 'value',
          supportedFieldTypes: [stringArray],
          defaultFieldType: stringArray,
          isInputProperty: true,
          emptyValue: [],
        },
      ],
    },
    {
      role: SELECTION_TAGS_OPTIONS_ROLE,
      genericRole: GenericRole.INPUT_VALUE_OPTIONS,
      bindings: [
        {
          title: 'Connect_Panel_Connect_List_Options_Values_Label',
          prop: 'options',
          supportedFieldTypes: [text, number],
          defaultFieldType: text,
          requiresPrimaryConnection: true,
        },
      ],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId:
                'selection_tags_options_live_preview_gfpp_desktop_main_action',
              label: i18n.t(
                'Selection_Tags_Options_Live_Preview_GFPP_Desktop_Main_Action',
              ),
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Selection_Tags_Options_Manage_Content_Panel_Description',
      },
    },
  ],
  compactErrorPanel: true,
})
