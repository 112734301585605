import fetchRouterDatasetFromPage from '../fetchRouterDatasetFromPage'

export const bindComponents = async ({
  editorSdkProxy,
  appApi,
  pageRef,
  bindings,
}) => {
  const routerDataset = await fetchRouterDatasetFromPage(
    editorSdkProxy,
    pageRef,
  )

  for (const { componentRef, role, fieldKey, prop } of bindings) {
    await appApi.connectBindingSection({
      componentRef,
      role,
      controllerRef: routerDataset.ref,
      fieldBindings: [{ prop, fieldKey }],
      requiresPrimaryConnection: true,
    })
  }
}
