import { head } from 'lodash'
import { connectionConfig as connectionConfigDef } from '@wix/wix-data-client-common/src/panels/panelDefs'
import Maybe from 'folktale/maybe'
import getRepeaterAncestor from '../../business-logic/components/getRepeaterAncestor'
import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'
import * as datasetApi from '../../business-logic/datasets/datasetApi'
import { REPEATER_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
import COMPONENT_TYPES from '@wix/dbsm-common/src/componentTypes'
import { logOpenConnectionPanelToBi } from '../../worker/workerBiEventsLogger'
import { getComponentTypeData } from '../../business-logic/components/getComponentTypeData'
import { getBindingDefinitions } from '../../business-logic/bindings/componentBindingInfo'
import { features } from '@wix/wix-data-client-common/src/features'

const openConnectPanel = async (
  {
    hostname,
    appApi,
    editorSdkProxy,
    experimentsManager,
    logger,
    i18n,
    editorType,
    initiator,
    origin,
  },
  { componentRef: originalComponentRef },
) => {
  const livePreview = features.livePreview({
    editorType,
    experimentsManager,
  })

  appApi.disallowCollectionSchemaRefresh()
  try {
    let repeaterAncestor = Maybe.Nothing()

    const {
      sdkType: originalComponentSdkType,
      viewerType: originalComponentViewerType,
    } = await getComponentTypeData({
      editorSdkProxy,
      componentRef: originalComponentRef,
    })

    if (livePreview) {
      // workaround fix for repeater child connect panel closing when connecting repeater in live preview
      // fix is applied only when in live preview and repeater is not connected to reduce potential UX issues in irrelevant cases

      repeaterAncestor = await getRepeaterAncestor({
        componentRef: originalComponentRef,
        editorSdkProxy,
      })

      await repeaterAncestor.fold(
        async () => {},
        async ancestor => {
          const { componentRef: repeaterAncestorRef } = ancestor
          const repeaterConnections = await datasetApi.fetchComponentBindings(
            editorSdkProxy,
            repeaterAncestorRef,
          )
          const repeaterIsConnectedToDataset = Boolean(
            repeaterConnections.find(
              connection => connection.role === REPEATER_ROLE,
            ),
          )

          if (repeaterIsConnectedToDataset) {
            repeaterAncestor = Maybe.Nothing()
          }
        },
      )
    }

    const repeaterComponentRef = await repeaterAncestor.fold(
      async () => null,
      async ({ componentRef }) => componentRef,
    )
    const componentRef = await repeaterAncestor.fold(
      async () =>
        originalComponentSdkType === COMPONENT_TYPES.StripColumnsContainer
          ? head(
              await editorSdkProxy.components.getChildren({
                componentRef: originalComponentRef,
              }),
            )
          : originalComponentRef,
      async ({ componentRef }) => componentRef,
    )

    if (!componentRef) return

    logOpenConnectionPanelToBi(logger, editorSdkProxy, {
      componentRef,
      componentType: originalComponentViewerType,
    })

    const componentType = repeaterComponentRef
      ? COMPONENT_TYPES.Repeater
      : (
          await getComponentTypeData({
            editorSdkProxy,
            componentRef: originalComponentRef,
          })
        ).sdkType

    await getBindingDefinitions({
      editorSdkProxy,
      experimentsManager,
      componentRef,
      componentType,
      componentViewerType: originalComponentViewerType,
      appApi,
    }).then(result =>
      result.matchWith({
        Nothing: () =>
          logger.info(
            `Could not retrieve binding definitions for component of type ${componentType}`,
            { componentRef },
          ),
        Just: ({ value: bindingDefinitions }) =>
          datasetApi
            .fetchConnectableDatasets(editorSdkProxy, componentRef)
            .then(datasets =>
              openPanel(
                { hostname, editorSdkProxy, i18n },
                {
                  componentRef,
                  originalComponentRef,
                  componentType,
                  viewerComponentType: originalComponentViewerType,
                  datasets,
                  bindingDefinitions,
                  experimentsManager,
                  initiator,
                  origin,
                  editorType,
                },
              ),
            )
            .catch(logger.error),
      }),
    )
  } finally {
    appApi.allowCollectionSchemaRefresh()
  }
}

async function openPanel(
  { hostname, editorSdkProxy, i18n },
  {
    componentRef,
    originalComponentRef,
    componentType,
    viewerComponentType,
    datasets,
    bindingDefinitions,
    initiator,
    origin,
    editorType,
  },
) {
  const hasRepeaterAncestor = Maybe.Just.hasInstance(
    await getRepeaterAncestor({
      componentRef,
      editorSdkProxy,
    }),
  )

  const panelTitle = hasRepeaterAncestor
    ? 'Connect_Panel_Title_Repeater'
    : bindingDefinitions.panel.title
  const baseHeight = hasRepeaterAncestor ? 525 : bindingDefinitions.panel.height

  const initialLoadTimeForBI = Date.now()
  const panelResult = await editorSdkProxy.editor.openComponentPanel({
    componentRef,
    title: i18n.t(panelTitle),
    url: await getPanelUrl({
      hostname,
      relativeUrl: connectionConfigDef.url,
      editorSdkProxy,
    }),
    initialData: {
      componentRef,
      originalComponentRef,
      bindingDefinitions,
      componentType,
      viewerComponentType,
      initialLoadTimeForBI,
      datasets,
      initiator,
      origin,
      editorType,
    },
    width: 288,
    height: baseHeight + (datasets.length === 0 ? 24 : 0),
    helpId: bindingDefinitions.panel.helpId,
    type: 'connect',
  })
  editorSdkProxy.editor.selection.clearHighlights()
  return panelResult
}

export { openConnectPanel }
