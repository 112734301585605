import { FieldType } from '@wix/wix-data-schema-types'
import { RICH_TEXT_BOX_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { richText } = FieldType

export default () => ({
  componentName: 'Element_Name_Rich_Text_Box',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_RichTextBox',
    helpId: '11bbbdc1-c893-46e4-9314-939782008802',
  },
  sections: [
    {
      role: RICH_TEXT_BOX_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Value_Label',
          prop: 'value',
          supportedFieldTypes: [richText],
          defaultFieldType: richText,
          isInputProperty: true,
        },
      ],
    },
  ],
  compactErrorPanel: true,
})
