import { SUPPRTED_FIELD_TYPES_IN_URL } from '../../business-data/dynamicPages'
import { isOwnerField } from '../fields/fieldQueries'

export default (schema, fieldName) => {
  const field = schema.fields.find(field => field.name === fieldName)
  if (!field) {
    return false
  }

  if (!SUPPRTED_FIELD_TYPES_IN_URL.includes(field.type)) {
    return false
  }

  if (field.queryOperators && !field.queryOperators.includes('urlized')) {
    return false
  }

  if (isOwnerField(field) && !schema.isTPACollection) {
    return false
  }

  return true
}
