const pagesPanelFriendlyOrigins = [
  'dynamicPagesIntro',
  'pagesPanel',
  'addPagePanel',
  'duplicate_page_suggest_preset_modal',
  'duplicate_page_suggest_convert_to_dp',
  'quickActionsMenu',
  'panel/pages',
]

export const openPagesPanelIfNeeded = async ({
  editorSdkProxy,
  origin,
  pageRef,
}) => {
  const isDeveloperModeEnabled =
    await editorSdkProxy.editor.developerMode.isEnabled()

  if (!isDeveloperModeEnabled || pagesPanelFriendlyOrigins.includes(origin)) {
    await editorSdkProxy.editor.openPagesPanel({ pageRef }) // opens pages panel and navigates to page
  } else {
    await editorSdkProxy.pages.navigateTo({ pageRef })
  }
}

export const getProgressBar = ({
  editorSdkProxy,
  logger,
  totalSteps,
  title,
}) => {
  let currentStep = 0
  let lastStepTitle = undefined

  return {
    open: () =>
      editorSdkProxy.editor.openProgressBar({
        title,
        totalSteps,
      }),
    close: () => editorSdkProxy.editor.closeProgressBar(),
    update: async ({ stepTitle } = {}) => {
      const stepTitleToUse =
        currentStep === totalSteps ? lastStepTitle : stepTitle

      logger.breadcrumb({
        category: title,
        message: `Running "${stepTitleToUse}" step`,
      })

      await editorSdkProxy.editor.updateProgressBar({
        currentStep,
        stepTitle: stepTitleToUse,
      })

      currentStep++
      lastStepTitle = stepTitle
    },
  }
}
