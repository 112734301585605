import { flatten, union, sortBy } from 'lodash'
import isEqual from 'lodash/fp/isEqual'
import has from 'lodash/fp/has'
import ComponentTypes from '@wix/dbsm-common/src/componentTypes'
import { FieldType } from '@wix/wix-data-schema-types'
import {
  getFieldType,
  isOwnerField,
} from '@wix/wix-data-client-common/src/business-logic/fields/fieldQueries'
import {
  FILTER_INPUT_ROLE,
  DROPDOWN_OPTIONS_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
import { getComponentTypeData } from '../components/getComponentTypeData'
const {
  text: TEXT,
  number: NUMBER,
  boolean: BOOLEAN,
  stringArray: STRING_ARRAY,
} = FieldType

const COMPONENT_TYPES_FILTERABLE_BY_USER_INPUT = {
  [ComponentTypes.Dropdown]: {
    supportedFieldTypes: [TEXT, NUMBER],
    filterPanelLabel:
      'Dataset_Settings_Add_Filter_Panel_User_Input_Dropdown_Option_Label',
    filterPanelError:
      'Dataset_Settings_Add_Filter_Panel_User_Input_Dropdown_Error_Field_Type',
    datasetSettingsPanelLabel:
      'Dataset_Settings_Panel_Filter_By_User_Input_Dropdown_Element_Name',
    connectPanelLabel:
      'Connect_Panel_Dataset_Filter_Message_Element_Type_Dropdown',
  },
  [ComponentTypes.Checkbox]: {
    supportedFieldTypes: [BOOLEAN],
    filterPanelLabel:
      'Dataset_Settings_Add_Filter_Panel_User_Input_Checkbox_Option_Label',
    filterPanelError:
      'Dataset_Settings_Add_Filter_Panel_User_Input_Checkbox_Error_Field_Type',
    datasetSettingsPanelLabel:
      'Dataset_Settings_Panel_Filter_By_User_Input_Checkbox_Element_Name',
    connectPanelLabel:
      'Connect_Panel_Dataset_Filter_Message_Element_Type_Checkbox',
  },
  [ComponentTypes.CheckboxGroup]: {
    supportedFieldTypes: [STRING_ARRAY],
    filterPanelLabel:
      'Dataset_Settings_Add_Filter_Panel_User_Input_MultiSelect_Option_Label',
    filterPanelError:
      'Dataset_Settings_Add_Filter_Panel_User_Input_MultiSelect_Error_Field_Type',
    datasetSettingsPanelLabel:
      'Dataset_Settings_Panel_Filter_By_User_Input_MultiSelect_Element_Name',
    connectPanelLabel:
      'Connect_Panel_Dataset_Filter_Message_Element_Type_MultiSelect',
    spec: 'specs.wixCode.FilterByCheckboxGroupUserInput',
  },
}

const getFilterableComponentTypes = isExperimentEnabled =>
  Object.keys(COMPONENT_TYPES_FILTERABLE_BY_USER_INPUT).filter(key => {
    const { spec } = COMPONENT_TYPES_FILTERABLE_BY_USER_INPUT[key]
    return !spec || isExperimentEnabled(spec)
  })

const getFieldTypesFilterableByUserInput = isExperimentEnabled =>
  union(
    ...getFilterableComponentTypes(isExperimentEnabled).map(
      key => COMPONENT_TYPES_FILTERABLE_BY_USER_INPUT[key].supportedFieldTypes,
    ),
  )

const isFieldSupportedByUserInputFilter = (field, isExperimentEnabled) => {
  const fieldType = getFieldType(field)
  const filterableTypes =
    getFieldTypesFilterableByUserInput(isExperimentEnabled)
  return filterableTypes.includes(fieldType) && !isOwnerField(field)
}

const getFilterableComponentsSupportedByFieldType = (
  fieldType,
  isExperimentEnabled,
) =>
  getFilterableComponentTypes(isExperimentEnabled).filter(key =>
    COMPONENT_TYPES_FILTERABLE_BY_USER_INPUT[key].supportedFieldTypes.includes(
      fieldType,
    ),
  )

const createUserInputConnection = ({
  datasetRef,
  componentRef,
  connectionConfig,
}) => ({
  componentRef,
  controllerRef: datasetRef,
  config: connectionConfig,
  role: FILTER_INPUT_ROLE,
})

const getUserInputFilterComponentCandidates = async ({
  editorSdkProxy,
  isExperimentEnabled,
}) => {
  const [componentsInCurrentPage, connectedComponents] = await Promise.all([
    getComponentsInCurrentPage({
      editorSdkProxy,
    }),
    getConnectedComponents({ editorSdkProxy }),
  ])

  const componentsWithTypes = await Promise.all(
    componentsInCurrentPage.map(async componentRef => {
      const { sdkType } = await getComponentTypeData({
        editorSdkProxy,
        componentRef,
      })
      return {
        componentRef,
        sdkType,
      }
    }),
  )

  const componentsFilterableByUserInput = componentsWithTypes
    .filter(({ sdkType }) => {
      const component = COMPONENT_TYPES_FILTERABLE_BY_USER_INPUT[sdkType]

      return (
        component && (!component.spec || isExperimentEnabled(component.spec))
      )
    })
    .map(({ componentRef, sdkType }) => ({
      componentRef,
      componentType: sdkType,
      disabled: connectedComponents.some(isEqual(componentRef)),
    }))

  return sortBy(componentsFilterableByUserInput, 'componentRef.id')
}

const getComponentsInCurrentPage = async ({ editorSdkProxy }) => {
  const [currentPage, allComponents] = await Promise.all([
    editorSdkProxy.pages.getCurrent(),
    editorSdkProxy.components.getAllComponents(),
  ])

  const pagesOfAllComponents = await Promise.all(
    allComponents.map(componentRef =>
      editorSdkProxy.components
        .getPage({ componentRef })
        .then(page => ({ componentRef, page })),
    ),
  )

  return pagesOfAllComponents
    .filter(({ page }) => page.id === currentPage.id)
    .map(({ componentRef }) => componentRef)
}

const getConnectedComponents = async ({ editorSdkProxy }) => {
  const controllers = await editorSdkProxy.controllers.listControllers()

  const controllerConnections = await Promise.all(
    controllers.map(({ controllerRef }) =>
      editorSdkProxy.controllers.getControllerConnections({
        controllerRef,
      }),
    ),
  ).then(flatten)

  const connectedComponents = controllerConnections
    .filter(
      ({ connection: { role } }) =>
        role !== FILTER_INPUT_ROLE && role !== DROPDOWN_OPTIONS_ROLE,
    )
    .map(({ componentRef }) => componentRef)

  return connectedComponents
}

const isFilterComponentConnectable = has('component.id')

export {
  getUserInputFilterComponentCandidates,
  createUserInputConnection,
  isFieldSupportedByUserInputFilter,
  isFilterComponentConnectable,
  COMPONENT_TYPES_FILTERABLE_BY_USER_INPUT,
  getFilterableComponentsSupportedByFieldType,
}
