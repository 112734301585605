import { isEqual } from 'lodash'
import { PLUGINS } from '@wix/wix-code-collections-api'
import { collectionTypes } from '../components/details/collectionTypeDefinitions'

const { SINGLES } = PLUGINS

export const getCollectionTypeFromSchema = ({ plugins }) => {
  const singlesPlugin = plugins?.[SINGLES.PLUGIN_NAME]

  return isEqual(singlesPlugin, SINGLES.PLUGIN_VALUE)
    ? collectionTypes.SINGLE_ITEM_COLLECTION
    : collectionTypes.MULTIPLE_ITEM_COLLECTION
}
