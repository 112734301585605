import { isResponsiveEditor } from '@wix/wix-data-client-common/src/infra/editorType'
import { fieldPropertiesPanel } from '@wix/wix-data-client-common/src/panels/panelDefs'
import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'

export default async ({
  editorSdkProxy,
  hostname,
  i18n,
  initialData: {
    name,
    keyIsRequired,
    key,
    typeOptions,
    type,
    otherKeys,
    origin,
    editorType,
  },
  y,
}) =>
  editorSdkProxy.editor.openToolPanel({
    url: await getPanelUrl({
      editorSdkProxy,
      hostname,
      relativeUrl: fieldPropertiesPanel.url,
    }),
    title: i18n.t('FieldPropertiesPanel_Title'),
    height: keyIsRequired
      ? isResponsiveEditor(editorType)
        ? 321
        : 327
      : isResponsiveEditor(editorType)
      ? 232
      : 242,
    width: keyIsRequired ? 264 : 240,
    initialData: {
      name,
      keyIsRequired,
      key,
      typeOptions,
      type,
      otherKeys,
      origin,
      editorType,
    },
    x: 0,
    y,
  })
