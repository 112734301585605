import maybe from 'folktale/maybe'
import result from 'folktale/result'
import { isEqual } from 'lodash'
import * as DATASET_TYPES from '@wix/wix-data-client-common/src/datasetTypes'
import { FILTER_INPUT_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
import COMPONENT_TYPES from '@wix/dbsm-common/src/componentTypes'
import openGenericConfirmationPanel from '@wix/wix-data-client-common/src/panels/error-panel/openGenericConfirmationPanel'
import openDatasetConfigPanel from '../panels/dataset-config-panel/openPanel'
import { openConnectPanel } from '../panels/connection-config-panel/openPanel'
import openManageContentPanel from '../panels/manage-content-panel/openPanel'
import removeDisconnectedDatasetFilters from './removeDisconnectedDatasetFilters'
import * as datasetApi from '../business-logic/datasets/datasetApi'
import { GFPP_ACTION_IDS } from '@wix/wix-data-client-common/src/consts'
import { logOpenDatasetPanelToBi } from './workerBiEventsLogger'
import { getBindingDefinitions } from '../business-logic/bindings/componentBindingInfo'
import sequence from '@wix/dbsm-common/src/fp/sequence'
import { getRouterDatasetConfig } from '../business-logic/datasets/routerDataset'
import { getCollectionName } from '../business-logic/datasets/datasetConfigHelper'
import { getComponentTypeData } from '../business-logic/components/getComponentTypeData'
import getTypeOfProbableDynamicPage from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/getTypeOfProbableDynamicPage'
import * as DYNAMIC_PAGE_TYPES from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/dynamicPageTypes'

const { viewerTypes } = COMPONENT_TYPES

const getPayloadProp = (eventPayload, prop) =>
  maybe.fromNullable(eventPayload && eventPayload[prop])

export default async ({
  appApi,
  hostname,
  logger,
  experimentsManager,
  editorSdkProxy,
  i18n,
  collectionsApi,
  editorType,
}) => {
  const getControllerCollectionName = async (
    controllerType,
    controllerConfig,
    controllerRef,
  ) => {
    const actualConfig =
      controllerType === DATASET_TYPES.ROUTER_DATASET
        ? await getRouterDatasetConfig(
            editorSdkProxy,
            controllerRef,
            controllerConfig,
          )
        : controllerConfig

    return getCollectionName(actualConfig)
  }

  const updateLivePreviewIfDatasetChanged = ({ controllerRef }) => {
    const oldConfigPromise = datasetApi.fetchDataset(
      editorSdkProxy,
      controllerRef,
    )
    return async () => {
      const { config: oldControllerConfig } = await oldConfigPromise
      const { config: newControllerConfig } = await datasetApi.fetchDataset(
        editorSdkProxy,
        controllerRef,
      )
      return !isEqual(oldControllerConfig, newControllerConfig)
        ? editorSdkProxy.document.application.livePreview.refresh({
            forceWorkerRestart: true,
          })
        : null
    }
  }

  return async function ({ eventType, eventPayload }) {
    const getProp = prop => getPayloadProp(eventPayload, prop)
    const getPropResult = prop =>
      getPayloadProp(eventPayload, prop).matchWith({
        Nothing: () =>
          result.Error(`[${eventType}] eventPayload is missing ${prop}`),
        Just: ({ value }) => result.Ok(value),
      })
    const { origin } = eventPayload || {}

    switch (eventType) {
      case 'connectedComponentPasted':
        const { connection, componentRef } = eventPayload

        if (connection.role === FILTER_INPUT_ROLE) {
          editorSdkProxy.controllers.disconnect({
            controllerRef: connection.controllerRef,
            connectToRef: componentRef,
            role: connection.role,
          })
        }

        break
      case 'controllerAdded':
        const { controllerRef } = eventPayload
        if (origin) {
          break
        }
        await removeDisconnectedDatasetFilters({
          controllerRef,
          editorSdkProxy,
        })
        break
      case 'controllerSettingsButtonClicked':
        logOpenDatasetPanelToBi(
          logger,
          editorSdkProxy,
          collectionsApi,
          eventPayload,
        )
        sequence(
          result,
          ['controllerRef', 'controllerType'].map(getPropResult),
        ).matchWith({
          Error: ({ value: error }) => {
            logger.info(`${error}, not opening the dataset config panel`)
          },
          Ok: ({ value: [controllerRef, controllerType] }) => {
            openDatasetConfigPanel(
              { hostname, collectionsApi, editorSdkProxy, i18n },
              {
                controllerRef,
                controllerConfig:
                  getProp('controllerConfig').getOrElse(undefined),
                controllerDisplayName: getProp(
                  'controllerDisplayName',
                ).getOrElse(undefined),
                controllerType,
                editorType,
              },
            )
              .then(updateLivePreviewIfDatasetChanged({ controllerRef }))
              .catch(logger.error)
          },
        })
        break
      case 'controllerGfppClicked':
        switch (eventPayload.id) {
          case GFPP_ACTION_IDS.SETTINGS_ACTION:
            logOpenDatasetPanelToBi(
              logger,
              editorSdkProxy,
              collectionsApi,
              eventPayload,
            )
            sequence(
              result,
              ['controllerRef', 'controllerType', 'controllerConfig'].map(
                getPropResult,
              ),
            ).matchWith({
              Error: ({ value: error }) => {
                logger.info(`${error}, not opening the dataset config panel`)
              },
              Ok: async ({
                value: [controllerRef, controllerType, controllerConfig],
              }) => {
                openDatasetConfigPanel(
                  { hostname, collectionsApi, editorSdkProxy, i18n },
                  {
                    controllerRef,
                    controllerConfig:
                      getProp('controllerConfig').getOrElse(undefined),
                    controllerDisplayName: getProp(
                      'controllerDisplayName',
                    ).getOrElse(undefined),
                    controllerType,
                    editorType,
                  },
                )
                  .then(updateLivePreviewIfDatasetChanged({ controllerRef }))
                  .catch(logger.error)
              },
            })
            break
          case GFPP_ACTION_IDS.MANAGE_CONTENT_ACTION:
            sequence(result, [
              getPropResult('controllerType'),
              getPropResult('controllerConfig'),
              getPropResult('controllerRef'),
            ]).matchWith({
              Error: ({ value: error }) => {
                logger.info(`${error}, not opening the CM Editor`)
                openGenericConfirmationPanel({
                  editorSdkProxy,
                  logger,
                  t: i18n.t.bind(i18n),
                })
              },
              Ok: async ({
                value: [controllerType, controllerConfig, controllerRef],
              }) => {
                try {
                  const collectionName = await getControllerCollectionName(
                    controllerType,
                    controllerConfig,
                    controllerRef,
                  )

                  const schema = await collectionsApi.get(collectionName)
                  const collectionExists = Boolean(schema) && !schema.isDeleted

                  if (collectionExists) {
                    await appApi.openContentManager({
                      collectionId: collectionName,
                      isModal: true,
                      origin: 'dataset_gfpp',
                    })
                  } else {
                    await openDatasetConfigPanel(
                      { hostname, collectionsApi, editorSdkProxy, i18n },
                      {
                        controllerRef,
                        controllerConfig,
                        controllerType,
                        controllerDisplayName: getProp(
                          'controllerDisplayName',
                        ).getOrElse(undefined),
                        editorType,
                      },
                    ).then(updateLivePreviewIfDatasetChanged({ controllerRef }))
                  }
                } catch (e) {
                  logger.error(e)
                }
              },
            })
            break
        }
        break
      case 'componentConnectButtonClicked': {
        openConnectPanel(
          {
            hostname,
            appApi,
            editorSdkProxy,
            experimentsManager,
            logger,
            i18n,
            editorType,
            initiator: 'manually',
            origin: 'gfpp',
          },
          { componentRef: eventPayload.componentRef },
        )

        break
      }
      case 'componentAddedToStage': {
        const typeOfProbableDynamicPage = await getTypeOfProbableDynamicPage({
          editorSdkProxy,
          pageRef: await editorSdkProxy.pages.getCurrent(),
        })
        if (typeOfProbableDynamicPage !== DYNAMIC_PAGE_TYPES.ITEM_PAGE) break

        const { compRef: componentRef } = eventPayload
        const { sdkType: componentSdkType, viewerType: componentViewerType } =
          await getComponentTypeData({ editorSdkProxy, componentRef })

        const bindingDefinition = await getBindingDefinitions({
          editorSdkProxy,
          experimentsManager,
          appApi,
          componentType: componentSdkType,
          componentViewerType,
          componentRef,
        })

        const componentIsConnectable = maybe.Just.hasInstance(bindingDefinition)
        if (!componentIsConnectable) break

        openConnectPanel(
          {
            hostname,
            appApi,
            editorSdkProxy,
            experimentsManager,
            logger,
            i18n,
            editorType,
            initiator: 'automatically',
            origin: 'binding_suggestion',
          },
          { componentRef },
        )

        break
      }
      case 'componentGfppClicked': {
        const { componentRef, role } = eventPayload

        openManageContentPanel(
          {
            hostname,
            appApi,
            editorSdkProxy,
            experimentsManager,
            logger,
            i18n,
            editorType,
            collectionsApi,
          },
          { componentRef, role },
        )

        break
      }

      case 'componentSelectionChanged': {
        // The subscription to the event is behind experiments.
        const { componentRefs: refsOfSelectedComponents } = eventPayload
        const { length: numberOfSelectedComponents } = refsOfSelectedComponents
        if (numberOfSelectedComponents !== 1) break

        const [refOfSingleSelectedComponent] = refsOfSelectedComponents

        // TODO: Remove the following defensive code when https://jira.wixpress.com/browse/WEED-22503 is fixed.
        const componentIsStillSelected =
          await checkIfThereIsSingleSelectedComponentWithId(
            refOfSingleSelectedComponent.id,
          )
        if (!componentIsStillSelected) break
        const selectedComponentStillExists =
          await checkIfThereIsComponentWithId(refOfSingleSelectedComponent.id)
        if (!selectedComponentStillExists) break

        appApi.makeDataConnectionOfComponentPrimaryIfNeeded({
          componentRef: refOfSingleSelectedComponent,
        })

        const typeOfSingleSelectedComponent =
          await editorSdkProxy.components.getType({
            componentRef: refOfSingleSelectedComponent,
          })
        const singleSelectedComponentIsStrip =
          typeOfSingleSelectedComponent === viewerTypes.StripColumnsContainer
        if (singleSelectedComponentIsStrip) {
          appApi.updateMainActionOfStrip({
            stripRef: refOfSingleSelectedComponent,
          })
        }

        break
      }
    }
  }

  async function checkIfThereIsComponentWithId(id) {
    if (typeof editorSdkProxy.components.getById === 'undefined') return true
    // The method is not implemented in the test infra.
    // Since this solution is a temporary fix, keep it as is for now.

    const componentRef = await editorSdkProxy.components.getById({ id })
    return componentRef !== null
  }

  async function checkIfThereIsSingleSelectedComponentWithId(id) {
    if (typeof editorSdkProxy.selection === 'undefined') return true
    // The namespace is not implemented in the test infra.
    // Since this solution is a temporary fix, keep it as is for now.

    const selectedComponentRefs =
      await editorSdkProxy.selection.getSelectedComponents()
    if (selectedComponentRefs.length !== 1) return false

    const [{ id: singleSelectedComponentId }] = selectedComponentRefs
    return id === singleSelectedComponentId
  }
}
