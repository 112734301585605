import { openAutoCMSCollectionView } from './openAutoCMS'

export default async ({
  hostname,
  editorSdkProxy,
  collectionName,
  origin,
  wixData,
  collectionsApi,
  livePreview,
  i18n,
}) =>
  openAutoCMSCollectionView({
    editorSdkProxy,
    collectionId: collectionName,
    origin,
    wixData,
    collectionsApi,
    livePreview,
    hostname,
    i18n,
  })
