import { flatten } from 'lodash'
import getSchemaFilePath from '@wix/wix-data-client-common/src/utils/getSchemaFilePath'
import { withRetry } from '@wix/wix-data-client-common/src/infra/withRetry'

export default ({ editorSDK, appToken }) => {
  const { vfs } = editorSDK
  const listSchemas = async ({ includeDeletedCollections }) => {
    const children = await vfs.listChildren(appToken, { path: '.schemas' })
    const directories = await Promise.all(
      children
        .filter(({ isDirectory }) => isDirectory)
        .map(async directory => {
          const children = await vfs.listChildren(appToken, {
            path: directory.fullPath,
          })

          return children.map(file => {
            const collectionId = directory.fullPath.startsWith('.schemas/@')
              ? directory.fullPath.replace('.schemas/', '') + file.name
              : `${directory.name}/${file.name}`

            return {
              ...file,
              name: collectionId,
            }
          })
        }),
    )

    const schemaFiles = flatten(
      children.map(file => (file.isDirectory ? directories.shift() : file)),
    ).filter(file => file.name.endsWith('.json'))

    const allSchemas = await Promise.all(
      schemaFiles.map(file => loadSchema(file.name.replace('.json', ''))),
    )

    return allSchemas.filter(
      schema => includeDeletedCollections || !schema.isDeleted,
    )
  }

  const loadSchema = async collectionId =>
    JSON.parse(
      await vfs.readFile(appToken, { path: getSchemaFilePath(collectionId) }),
    )

  const saveSchema = async (collectionId, schema) => {
    await vfs.writeFile(appToken, {
      path: getSchemaFilePath(collectionId),
      content: JSON.stringify(schema),
    })
    await vfs.flush(appToken)
  }

  const removeSchema = async collectionId => {
    await vfs.deleteFile(appToken, {
      path: getSchemaFilePath(collectionId),
    })

    await vfs.flush(appToken)
    await clearCache()
  }

  const clearCache = async () => {
    await vfs.writeFile(appToken, {
      path: '.schemas/$commands/invalidateCache',
    })
  }

  return {
    listSchemas: withRetry(listSchemas, 3),
    loadSchema,
    saveSchema,
    removeSchema,
    clearCache,
  }
}
