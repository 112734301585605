import { getPatternDefForPage } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/routerConfig'
import { truncatePageTitle } from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/templates'
import * as routeDefUtils from '@wix/wix-data-client-common/src/business-logic/dynamic-pages/router-config/patternDef'
import generateUniquePrefix from '../generateUniquePrefix'
import { prefixTitleWithCopyOf, prefixUrlWithCopyOf } from './prefixWithCopyOf'

const getRouteToDuplicate = async ({ editorSdkProxy, pageRef }) => {
  const routerRef = await editorSdkProxy.document.routers.getByPage({
    pageRef,
  })

  const { config, pages, prefix } = await editorSdkProxy.document.routers.get({
    routerRef,
  })

  const { pattern, def } = getPatternDefForPage(config, pages, pageRef)

  return {
    routeDefinitionBase: def,
    prefix,
    route: pattern,
  }
}

const generatePageTitle = (i18n, originalTitle) => {
  const pageTitle = prefixTitleWithCopyOf(i18n, originalTitle)
  return truncatePageTitle(pageTitle)
}

const generateMenuItemLabel = pageTitle => pageTitle.replace(/ \(All\)$/, '')

export const getDataForNewPage = async ({ editorSdkProxy, i18n, pageRef }) => {
  const { prefix, route, routeDefinitionBase } = await getRouteToDuplicate({
    editorSdkProxy,
    pageRef,
  })

  const pageData = await editorSdkProxy.document.pages.data.get({ pageRef })
  const title = pageData.title.trim()

  const pageTitle = generatePageTitle(i18n, title)

  const menuItemLabel = generateMenuItemLabel(pageTitle)

  const inputsForPrefix = [prefixUrlWithCopyOf(prefix)]

  const routerPrefix = await generateUniquePrefix({
    inputsForPrefix,
    editorSdkProxy,
  })

  const routeDefinition = routeDefUtils.generate({
    routeDefinitionBase,
    pattern: route,
    collectionId: routeDefinitionBase.config.collection,
  })

  return {
    route,
    routerPrefix,
    routeDefinition,
    pageTitle,
    menuItemLabel,
  }
}
