import { FieldType } from '@wix/wix-data-schema-types'
import { RADIOGROUP_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { text, dateTime, number, url } = FieldType

export default () => ({
  componentName: 'Element_Name_Radio_Button_Group',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Radio_Buttons',
    helpId: 'dc7fa632-fdc8-49b3-9fa7-393483d1d848',
  },
  sections: [
    {
      role: RADIOGROUP_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_Value_Label',
          prop: 'value',
          supportedFieldTypes: [text, number, url, dateTime],
          defaultFieldType: text,
          isInputProperty: true,
        },
      ],
    },
  ],
  compactErrorPanel: true,
})
