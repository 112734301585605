export const blank = () =>
  import(
    /* webpackChunkName: "responsivePresets-blank" */ './definitions/blank.json'
  )
export const news = () =>
  import(
    /* webpackChunkName: "responsivePresets-news" */ './definitions/news.json'
  )
export const projects = () =>
  import(
    /* webpackChunkName: "responsivePresets-projects" */ './definitions/projects.json'
  )
export const team = () =>
  import(
    /* webpackChunkName: "responsivePresets-team" */ './definitions/team.json'
  )
export const portfolio = () =>
  import(
    /* webpackChunkName: "responsivePresets-portfolio" */ './definitions/portfolio.json'
  )
