import { FieldType } from '@wix/wix-data-schema-types'
import { VIDEOPLAYER_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
const { text, url, video, stringArray, address } = FieldType

export default ({ i18n }) => ({
  componentName: 'Element_Name_Video_Player',
  isConnectGfppEnabled: true,
  panel: {
    height: 297,
    title: 'Connect_Panel_Title_Video_Player',
    helpId: '791a5719-fd58-469a-affa-79d767872a17',
  },
  sections: [
    {
      role: VIDEOPLAYER_ROLE,
      bindings: [
        {
          title: 'Connect_Panel_Connect_VideoPlayer_Title_Label',
          prop: 'title',
          supportedFieldTypes: [text, address, stringArray],
          defaultFieldType: text,
          supportsReferenceFields: true,
          emptyValue: null,
        },
        {
          title: 'Connect_Panel_Connect_VideoPlayer_Src_Label',
          prop: 'src',
          supportedFieldTypes: [video, url],
          defaultFieldType: video,
          supportsReferenceFields: true,
          emptyValue: null,
          requiresPrimaryConnection: true,
        },
      ],
      manifest: {
        gfpp: {
          desktop: {
            mainAction1: {
              actionId: 'video_player_live_preview_gfpp_desktop_main_action',
              label: i18n.t(
                'Video_Player_Live_Preview_GFPP_Desktop_Main_Action',
              ),
            },
          },
        },
      },
      manageContentPanel: {
        description: 'Video_Player_Manage_Content_Panel_Description',
      },
    },
  ],
  compactErrorPanel: true,
})
