export default ({
  experimentsManager,
  collectionsTransport,
  hostname,
  sdkVersion,
  transliterateAndSlugify,
}) => {
  const listeners = {}

  const emit = (eventType, value) =>
    listeners[eventType] &&
    listeners[eventType].forEach(callback => callback(value))

  const { listSchemas, loadSchema, saveSchema, removeSchema } =
    collectionsTransport.public

  return {
    getHostname: () => hostname,

    getSdkVersion: () => sdkVersion,

    addListener: (eventType, callback) => {
      if (listeners[eventType]) {
        listeners[eventType].push(callback)
      } else {
        listeners[eventType] = [callback]
      }
    },

    removeListener: (eventType, callback) => {
      if (listeners[eventType]) {
        listeners[eventType] = listeners[eventType].filter(
          fn => fn !== callback,
        )
      }
    },

    emit,

    listSchemas,
    loadSchema,
    saveSchema,
    removeSchema,

    refreshSchemasCache: () => collectionsTransport.private.refreshCache(),

    async getAllExperiments() {
      await experimentsManager.ready()
      return experimentsManager.all()
    },

    transliterateAndSlugify: phrase => transliterateAndSlugify(phrase),
  }
}
