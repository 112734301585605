import { FieldType } from '@wix/wix-data-schema-types'

export const DEFAULT_COLLECTION_FIELDS = {
  LONG_DESCRIPTION: {
    fieldKey: 'longDescription',
    displayName: 'Long Description',
    type: FieldType.richText,
  },
  IMAGE: {
    fieldKey: 'image',
    displayName: 'Image',
    type: FieldType.image,
  },
  SHORT_DESCRIPTION: {
    fieldKey: 'shortDescription',
    displayName: 'Short Description',
    type: FieldType.text,
  },
  TITLE: {
    fieldKey: 'title',
    displayName: 'Title',
    type: FieldType.text,
  },
}
