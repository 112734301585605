import { digestSchema } from './digestSchema' // TODO: get rid of schema digestion and work with their original json structures ?
import { addReferencedFieldsToSchema } from './addReferencedFieldsToSchema'
import { addNestedFieldsToSchema } from './addNestedFieldsToSchema'

const fetchAllSchemas = (
  collectionsApi,
  { includeReferenceData = false, includeHiddenAppCollections = false } = {},
) =>
  collectionsApi
    .getAll({ includeHiddenAppCollections })
    .then(rawSchemas => rawSchemas.map(digestSchema))
    .then(digestedSchemas =>
      includeReferenceData
        ? digestedSchemas.map(schema =>
            addReferencedFieldsToSchema(schema, digestedSchemas),
          )
        : digestedSchemas,
    )
    .then(digestedSchemas => digestedSchemas.map(addNestedFieldsToSchema))

const fetchMultipleSchemas = async (
  collectionsApi,
  collectionIds,
  { includeReferenceData = false } = {},
) => {
  const allDigestedSchemas = await fetchAllSchemas(collectionsApi, {
    includeReferenceData: false,
  })
  return allDigestedSchemas
    .filter(schema => collectionIds.includes(schema.id))
    .map(digestedSchema =>
      includeReferenceData
        ? addReferencedFieldsToSchema(digestedSchema, allDigestedSchemas)
        : digestedSchema,
    )
}

const fetchSchema = async (
  collectionsApi,
  collectionId,
  { includeReferenceData = false } = {},
) =>
  fetchMultipleSchemas(collectionsApi, [collectionId], {
    includeReferenceData,
  }).then(digestedSchemaInArray => digestedSchemaInArray[0])

// exports

export const fetch = fetchSchema
export const fetchAll = fetchAllSchemas
export const fetchMultiple = fetchMultipleSchemas
