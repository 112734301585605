import { union } from '@wix/wix-data-client-common/src/infra/unionType'
import Maybe from 'folktale/maybe'

const BINDING_TYPES = {
  Field: 'Field',
  Action: 'Action',
  Behavior: 'Behavior',
}

const Binding = union('Binding', {
  Field: ({ prop, fieldPath, format }) => ({
    prop,
    fieldPath,
    format: Maybe.fromNullable(format),
  }),
  Action: ({ event, action, postAction }) => ({
    event,
    action,
    postAction: Maybe.fromNullable(postAction),
  }),
  Behavior: ({ behavior }) => ({ behavior }),
})

Binding.chain = function (fn) {
  return fn(this)
}

const updateFormat = (binding, format) =>
  Binding.Field({
    prop: binding.prop,
    fieldPath: binding.fieldPath,
    format,
  })

const updatePostAction = (binding, postAction) =>
  Binding.Action({
    event: binding.event,
    action: binding.action,
    postAction,
  })

export { Binding, BINDING_TYPES, updateFormat, updatePostAction }
