import { defaultPanelBiParams } from './defaultBiParams'
import { canBeReadByNonAdmin } from '@wix/wix-data-client-common/src/business-logic/collections/permissions'
import openConfirmationPanel from '@wix/wix-data-client-common/src/panels/error-panel/openConfirmationPanel'

export default async ({
  editorSdkProxy,
  origin,
  collectionId,
  namespace: dbDriverNamespace,
  pageRef,
  appApi,
  collectionsApi,
  logger,
  i18n,
  originatesFromDynamicPagesIntro,
  isSiteWithoutCollections,
  isFirstTimeExperience,
  experimentsManager,
}) => {
  const originatesFromTooManyPagesSuggestion =
    origin === 'too_many_pages_suggestion'

  const originatesFromPagesPanel = origin === 'pagesPanel'
  const originatesFromPagesTree = origin === 'idePagesTree'

  const originatesFromStaticToDynamic = Boolean(pageRef)

  const siteWithoutCollections = await isSiteWithoutCollections()

  if (originatesFromTooManyPagesSuggestion) {
    appApi.openAddDynamicPagePanelV4({
      origin,
      isFirstTimeExperience,
    })
    return
  }

  if (
    dbDriverNamespace &&
    (originatesFromPagesTree || originatesFromPagesPanel)
  ) {
    const collections = (
      await collectionsApi.getAll({ includeHiddenAppCollections: true })
    )
      .filter(({ namespace }) => namespace === dbDriverNamespace)
      .filter(canBeReadByNonAdmin)

    if (collections.length === 0) {
      return openConfirmationPanel({
        editorSdkProxy,
        logger,
        headerText: i18n.t(
          'Dynamic_Pages_Warning_Cannot_Add_Dynamic_Page_Title',
        ),
        descriptionText: i18n.t(
          'Dynamic_Pages_Warning_Cannot_Add_Dynamic_Page_Text',
        ),
        mainActionText: i18n.t(
          'Dynamic_Pages_Warning_Cannot_Add_Dynamic_Page_Button',
        ),
      })
    }

    if (collections.length === 1) {
      const [collection] = collections

      appApi.addDefaultDynamicItemPage({
        collectionId: collection.id,
        origin,
        panelName: 'NEW_DYNAMIC_PAGE',
      })
      return
    }

    appApi.openAddDynamicPagePanelV4({
      origin,
    })
    return
  }

  if (collectionId) {
    appApi.addDefaultDynamicItemPage({
      collectionId,
      origin,
      panelName: defaultPanelBiParams.panel_name,
    })
    return
  }

  if (originatesFromDynamicPagesIntro && !originatesFromStaticToDynamic) {
    appApi.openAddDynamicPagePanelV4({
      origin: 'dynamicPagesIntro',
    })
    return
  }

  if (originatesFromStaticToDynamic) {
    if (
      experimentsManager.enabled(
        'specs.wixDataClient.ConvertToDynamicPagePanelV2',
      )
    ) {
      appApi.openConvertToDynamicPagePanelV2({ pageRef, origin })
    } else {
      appApi.openConvertToDynamicPagePanel({
        origin,
        pageRef,
      })
    }
    return
  }

  if (siteWithoutCollections) {
    appApi.openCreateCollectionPanel({ origin })
    return
  }

  appApi
    .openAddDynamicPagePanelV4({
      origin,
    })
    .catch(logger.error)
}
