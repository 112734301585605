import { getPatternDefForPage } from './router-config/routerConfig'

export async function getCollectionIdAndPatternOfCurrentDynamicPage({
  editorSdkProxy,
}) {
  const pageRef = await editorSdkProxy.document.pages.getCurrent()
  const routerRef = await editorSdkProxy.document.routers.getByPage({ pageRef })
  if (routerRef) {
    const routerInfo = await editorSdkProxy.document.routers.get({ routerRef })
    const { pattern, def: patternDef } = getPatternDefForPage(
      routerInfo.config,
      routerInfo.pages,
      pageRef,
    )
    if (typeof pattern !== 'undefined') {
      return {
        collectionId: patternDef.config.collection,
        pattern,
      }
    }
  }
  return null
}
