import { AppCollectionVisibility } from '@wix/wix-data-client-common/src/appCollectionVisibility'
import {
  isDatasetConfigured,
  isDatasetConnectedToDbDriverCollection,
} from '../business-logic/datasets/datasetConfigHelper'

export const updateControllersState = async ({
  editorSdkProxy,
  collectionsApi,
  appApi,
  controllerRefsToUpdate = null,
}) => {
  const controllerRefsByState = await getConfiguredControllerRefsByState({
    editorSdkProxy,
    collectionsApi,
    appApi,
    controllerRefsToUpdate,
  })

  await editorSdkProxy.controllers.setState({
    state: controllerRefsByState,
  })
}

const getConfiguredControllerRefsByState = async function ({
  editorSdkProxy,
  collectionsApi,
  appApi,
  controllerRefsToUpdate,
}) {
  const [controllers, collections, appCollectionVisibility] = await Promise.all(
    [
      controllerRefsToUpdate
        ? Promise.resolve(
            controllerRefsToUpdate.map(controllerRef => ({ controllerRef })),
          )
        : editorSdkProxy.controllers.listAllControllers().catch(err => {
            if (
              /Cannot read properties of undefined \(reading '0'\)/.test(
                err.message,
              )
            ) {
              return []
            }
            throw err
          }),
      collectionsApi
        .getAll({ includeHiddenAppCollections: true })
        .catch(() => []),
      appApi
        .getAppCollectionVisibility()
        .catch(() => AppCollectionVisibility.VISIBLE),
    ],
  )

  const controllerRefsAndConfigs = await Promise.all(
    controllers.map(async ({ controllerRef }) => {
      const { config } = await editorSdkProxy.controllers.getData({
        controllerRef,
      })
      return { controllerRef, config }
    }),
  )

  const controllersByState = controllerRefsAndConfigs.reduce(
    (controllersByState, { config, controllerRef }) => {
      if (config && isDatasetConfigured(config)) {
        if (
          appCollectionVisibility === AppCollectionVisibility.HIDDEN &&
          isDatasetConnectedToDbDriverCollection({ config, collections })
        ) {
          controllersByState.connectedToHiddenAppCollection.push(controllerRef)
        } else {
          controllersByState.configured.push(controllerRef)
        }
      }
      return controllersByState
    },
    {
      configured: [],
      connectedToHiddenAppCollection: [],
    },
  )

  return controllersByState
}
