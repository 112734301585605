import { dataModeOnExistingCollectionsIndicator } from '@wix/bi-logger-platform/v2'
import { preferences } from '@wix/wix-data-client-common/src/createOrEditCollectionPanel'
import * as wixDataSettingsClientManager from '@wix/wix-data-client-common/src/wixDataSettingsClientManager'

import { updateAppStateActionsBySchema } from '../schemaContextMenuActions'

export default async ({
  appApi,
  logger,
  experimentsManager,
  editorSdkProxy,
  WixDataSettings,
  safeUserPreferences,
  collectionsApi,
  httpClient,
  editorType,
}) => {
  return async function ({ eventType, eventPayload }) {
    switch (eventType) {
      case 'siteWasFirstSaved':
        appApi.emit('siteWasFirstSaved')
        break
      case 'siteWasPublished':
        appApi.emit('siteWasPublished')
        break
      case 'enableDataModeClicked': {
        const { origin } = eventPayload
        const originToSilentlyEnableDataMode = 'responsivePagesPanel/turnOnData'

        try {
          wixDataSettingsClientManager
            .enableDataMode(
              editorSdkProxy,
              WixDataSettings,
              experimentsManager,
              logger,
              appApi,
              httpClient,
            )
            .catch(error => logger.error(error))

          safeUserPreferences.set({
            [preferences.INTRO_WAS_SHOWN]: true,
          })

          if (origin !== originToSilentlyEnableDataMode) {
            appApi.openAddDynamicPagePanelV4({
              origin: eventPayload.origin,
              isFirstTimeExperience: true,
            })
          }
          logger.bi(
            dataModeOnExistingCollectionsIndicator({
              is_existing_collection_before_data_on:
                (await collectionsApi.getAll()).length > 0,
            }),
          )
          break
        } catch (error) {
          logger.error(error)
          appApi.openErrorPanel()
        }

        break
      }
      case 'developerModeChanged':
        editorSdkProxy.document.application.reloadManifest()

        if (
          experimentsManager.enabled(
            'specs.wixData.AppCollectionVisibilityControl',
          )
        ) {
          appApi.refreshSchemasCache()
        } else {
          await updateAppStateActionsBySchema({
            editorSdkProxy,
            collectionsApi,
            experimentsManager,
            editorType,
          })
        }

        if (eventPayload.value === true) {
          try {
            const dataModeIsAlreadyEnabled =
              await wixDataSettingsClientManager.isDataModeEnabled({
                editorSdkProxy,
                WixDataSettings,
                logger,
                httpClient,
              })
            if (!dataModeIsAlreadyEnabled) {
              await wixDataSettingsClientManager.enableDataMode(
                editorSdkProxy,
                WixDataSettings,
                experimentsManager,
                logger,
                appApi,
                httpClient,
              )
            }
          } catch (error) {
            // In templates with Data Mode ON, attempt to enable Corvid
            // will fire the event before site is actually saved
            // thus the worker attemps to enable Data Mode with template instance.
            // Since enabling Data Mode on Corvid toggle is a side-effect,
            // we don't really want to show any feedback to the user for failed operation
            logger.breadcrumb({
              message: error.message,
              category: 'dataMode',
            })
          }
        }
        break
      case 'developerModeStatusChanged':
        editorSdkProxy.document.application.reloadManifest()
        break
      default:
        // TODO log something here?
        break
    }
  }
}
