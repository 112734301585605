import getSchemaFilePath from '@wix/wix-data-client-common/src/utils/getSchemaFilePath'
import getPanelUrl from '@wix/wix-data-client-common/src/panels/getPanelUrl'

export default ({ hostname, logger, editorSdkProxy }) => {
  return async namespace => {
    const url = await getPanelUrl({
      hostname,
      relativeUrl: 'externalDbDriver.html',
      editorSdkProxy,
    })

    const panelResult = await editorSdkProxy.editor
      .openModalPanel({
        url,
        width: '660',
        height: '475',
        shouldHideHeader: true,
        initialData: { namespace },
      })
      .catch(logger.error)

    if (panelResult) {
      const isTreePaneOpen = await editorSdkProxy.ide.treePane.isOpen()
      if (!isTreePaneOpen) {
        await editorSdkProxy.ide.treePane.toggle()
      }

      editorSdkProxy.ide.openFile({
        path: getSchemaFilePath(panelResult.collectionId),
      })
    }
  }
}
