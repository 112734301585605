import { AppCollectionVisibility } from '@wix/wix-data-client-common/src/appCollectionVisibility'
import getDeveloperMode from '@wix/wix-data-client-common/src/utils/getDeveloperMode'

export default function decorateAdapterWithAppVisibilityControl({
  logger,
  adapter,
  editorSdkProxy,
  finalAppApiPromise,
}) {
  return { ...adapter, listSchemas }

  async function listSchemas({ includeHiddenAppCollections, ...rest } = {}) {
    const schemas = await adapter.listSchemas(rest)
    if (includeHiddenAppCollections || schemas.length === 0) {
      return schemas
    }

    const appApi = await finalAppApiPromise
    const visibility = await appApi
      .getAppCollectionVisibility()
      .catch(async error => {
        logger.error(error)

        // An error when getting the visibility should not break the adapter.
        // Resort to the default based on the developer mode.
        const developerModeIsEnabled = await getDeveloperMode(editorSdkProxy)
        return developerModeIsEnabled
          ? AppCollectionVisibility.VISIBLE
          : AppCollectionVisibility.HIDDEN
      })

    if (visibility === AppCollectionVisibility.VISIBLE) {
      return schemas // Nothing to possibly filter out.
    }

    const schemasExcludingDbDrivers = schemas.filter(
      schema => schema.storage !== 'driver',
    )
    return schemasExcludingDbDrivers
  }
}
